import React, { createElement, useState } from 'react'

import { AccountCircle, Logout } from '@mui/icons-material';
import { Divider, IconButton, ListItemIcon, MenuItem, Menu } from '@mui/material';

import { useStyles } from '.'
import { Link, useNavigate } from 'react-router-dom';
import { postAuthRoutes, preAuthRoutes } from '../../../../routes';
import { useDispatch } from 'react-redux';
import { SignInAction } from '../../../../redux/actions';
import { Fragment } from 'react';

function AccountMenu() {

    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    /** Redux actions and state */
    const [anchorEl, setAnchorEl] = useState(null);

    const openMenu = Boolean(anchorEl);

    const signOut = () => dispatch(SignInAction.signOut()).then(() => {
        dispatch({ type: 'RESET_USER' })
        navigate(preAuthRoutes('login').path);
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Fragment>
            <IconButton onClick={handleClick}>
                <AccountCircle />
            </IconButton>
            <Menu anchorEl={anchorEl} open={openMenu} onClose={handleClose} onClick={handleClose} PaperProps={{ elevation: 0, className: classes.menuPaper }} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}   >
                <MenuItem component={Link} to={postAuthRoutes('profile_details').path}>
                    <ListItemIcon>{createElement(postAuthRoutes('profile_details').menuIcon)} </ListItemIcon>
                    {postAuthRoutes('profile_details').name}
                </MenuItem>
                <Divider />
                <MenuItem onClick={signOut}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </Fragment>
    )
}

export { AccountMenu }
