import React, { useEffect, useState } from 'react';
import { CartItemStyled } from '.';
import { Box, Button, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';

import { useDispatch, useSelector } from 'react-redux';
import { cookieServices, handleInputChange } from '../../../../../../../../_helpers';
import { RestaurantOrdersAction } from '../../../../../../../../redux/actions';
import { FormQuantityInput } from '../../../../../../../../_components';
import { CurrencySymbol } from '../../../../../../../../_components/currency-symbol';

const initialPayload = { item_quantity: 1 };

const CartItem = ({ ...props }) => {

	let orderId = cookieServices.get('orderId');
	const { item } = props;
	const { cart_id, image, title, description, item_discounted_price, portion, item_quantity, have_portions } = item;

	const dispatch = useDispatch();
	const updateCartItemQuantity = (params) => dispatch(RestaurantOrdersAction.updateCartItemQuantity(params));
	const getCartList = (params) => dispatch(RestaurantOrdersAction.getCartList(params));
	const removeItemFromCart = (params) => dispatch(RestaurantOrdersAction.removeItemFromCart(params));

	/** Initialize and declare state */
	const [payload, setPayload] = useState({ ...initialPayload, order_id: orderId, cart_id: cart_id, item_quantity: item_quantity });
	const [action, setAction] = useState({ isSubmitted: false });
	const [response, setResponse] = useState({});
	const [errors, setErrors] = useState({ ...initialPayload });

	const handleChange = (e) => {
		const { name, value } = handleInputChange(e);
		payload[name] = value;
		setPayload({ ...payload });
		updateItemQuantity();
	};

	const updateItemQuantity = async () => {
		let response = await updateCartItemQuantity(payload);
		setResponse(response);
	};

	useEffect(() => {

		if (response.status === 1) {
			getCartList({ order_id: orderId });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [response]);

	const removeItem = async () => {
		let response = await removeItemFromCart({ order_id: orderId, cart_id: cart_id });
		setResponse(response);
	};

	return (
		<CartItemStyled>
			<CardContent>
				<Grid container spacing={2} display={`flex`} alignItems={`center`} justifyContent={`center`}>
					<Grid item xs={2} display={`flex`} alignItems={`center`} justifyContent={`center`}>
						<CardMedia className="item-image" component="img" image={image} />
					</Grid>
					<Grid item xs={10}>
						<Grid container display={`flex`} alignItems={`center`} justifyContent={`center`}>
							<Grid item xs={12}>
								<Typography className="item-title" variant="h6">{title} {(have_portions && portion) ? `(${portion})` : ``} </Typography>
							</Grid>
							<Grid item xs={8}>
								<Typography variant="caption" component={'p'}>{description.length > 70 ? description.substring(0, 70) + '...' : description}</Typography>
								<Box className={`price-box`}>
									<Typography variant="body2" className='item-price'><CurrencySymbol />{item_discounted_price}</Typography>
									<Button size='small' variant='text' color='secondary' startIcon={<Delete />} onClick={removeItem}>Remove</Button>
								</Box>
							</Grid>
							<Grid item xs={4} display={`flex`} alignItems={`center`} justifyContent={`right`}>
								<FormQuantityInput name="item_quantity" value={payload.item_quantity} onChange={handleChange} />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</CartItemStyled>
	);
};

export { CartItem };
