import React from 'react';
import { Helmet } from "react-helmet";
import { APP_NAME } from '../../../_helpers';
import { Grid, Typography } from '@mui/material';
import { NotFoundStyled } from '.';
import ErrorImage from './404.svg'
import { Fragment } from 'react';

function NotFound() {
    return (
        <Fragment>
            <Helmet>
                <title>{`${APP_NAME} | Not Found`}</title>
            </Helmet>
            <NotFoundStyled container spacing={0} direction="column" alignItems="center" justifyContent="center" >
                <Grid item xs={4}>
                    <img src={ErrorImage} alt="Page not found" />
                    <Typography variant="h4">Awww...<span className={`text-wrong-turn`}>Wrong Turn</span></Typography>
                    <Typography variant="h1">Page <span className={`text-not-found`}>Not Found</span></Typography>
                    <Typography component="h1" variant="caption">The page you are looking for doesn't exist. Go back to choose a new direction.</Typography>
                </Grid>

            </NotFoundStyled>
        </Fragment>
    )
}


export { NotFound };
