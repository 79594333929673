import { InvoiceServices } from "../services";

export const INVOICE_REQUEST = 'INVOICE_REQUEST';
export const INVOICE_SUCCESS = 'INVOICE_SUCCESS';
export const INVOICE_FAILURE = 'INVOICE_FAILURE';

export const INVOICE_DETAILS_REQUEST = 'INVOICE_DETAILS_REQUEST';
export const INVOICE_DETAILS_SUCCESS = 'INVOICE_DETAILS_SUCCESS';
export const INVOICE_DETAILS_FAILURE = 'INVOICE_DETAILS_FAILURE';

export const InvoiceAction = {

    /**
     * Action for list plans
     * @param {Object} params - The params which are used for the api
     * @author Akshay N
     * @created_at 30 May 2022
     */
    listInvoices: (params) => {
        return dispatch => {
            dispatch(request(params));
            return InvoiceServices.listInvoices(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: INVOICE_REQUEST, request: request } }
        function success(request, response) { return { type: INVOICE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: INVOICE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get invoice details
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     */
    invoiceDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return InvoiceServices.invoiceDetails(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: INVOICE_DETAILS_REQUEST, request: request } }
        function success(request, response) { return { type: INVOICE_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: INVOICE_DETAILS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

};
