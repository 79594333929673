import {
    RESTAURANT_ORDERS_REQUEST, RESTAURANT_ORDERS_SUCCESS, RESTAURANT_ORDERS_FAILURE,
    CHANGE_RESTAURANT_ORDER_STATUS_REQUEST, CHANGE_RESTAURANT_ORDER_STATUS_SUCCESS, CHANGE_RESTAURANT_ORDER_STATUS_FAILURE,
    CHANGE_RESTAURANT_PRE_ORDER_STATUS_REQUEST, CHANGE_RESTAURANT_PRE_ORDER_STATUS_SUCCESS, CHANGE_RESTAURANT_PRE_ORDER_STATUS_FAILURE,
    RESTAURANT_ORDER_DETAILS_REQUEST, RESTAURANT_ORDER_DETAILS_SUCCESS, RESTAURANT_ORDER_DETAILS_FAILURE,
    CHANGE_RESTAURANT_ITEM_STATUS_REQUEST, CHANGE_RESTAURANT_ITEM_STATUS_SUCCESS, CHANGE_RESTAURANT_ITEM_STATUS_FAILURE,
    GENERATE_RESTAURANT_ORDER_BILL_REQUEST, GENERATE_RESTAURANT_ORDER_BILL_SUCCESS, GENERATE_RESTAURANT_ORDER_BILL_FAILURE,
    GENERATE_RESTAURANT_PRE_ORDER_BILL_REQUEST, GENERATE_RESTAURANT_PRE_ORDER_BILL_SUCCESS, GENERATE_RESTAURANT_PRE_ORDER_BILL_FAILURE,
    GET_RESTAURANT_ORDER_BILL_REQUEST, GET_RESTAURANT_ORDER_BILL_SUCCESS, GET_RESTAURANT_ORDER_BILL_FAILURE,
    MONTHLY_ORDER_COLLECTION_REQUEST, MONTHLY_ORDER_COLLECTION_SUCCESS, MONTHLY_ORDER_COLLECTION_FAILURE,
    DAILY_ORDER_COLLECTION_REQUEST, DAILY_ORDER_COLLECTION_SUCCESS, DAILY_ORDER_COLLECTION_FAILURE,
    TOTAL_ORDER_COLLECTION_REQUEST, TOTAL_ORDER_COLLECTION_SUCCESS, TOTAL_ORDER_COLLECTION_FAILURE,
    RESTAURANT_ORDERS_HISTORY_REQUEST, RESTAURANT_ORDERS_HISTORY_SUCCESS, RESTAURANT_ORDERS_HISTORY_FAILURE,
    TODAYS_RESTAURANT_ORDERS_REQUEST, TODAYS_RESTAURANT_ORDERS_SUCCESS, TODAYS_RESTAURANT_ORDERS_FAILURE,
	RESTAURANT_ORDERS_MOBILE_SELECT_LIST_REQUEST, RESTAURANT_ORDERS_MOBILE_SELECT_LIST_SUCCESS, RESTAURANT_ORDERS_MOBILE_SELECT_LIST_FAILURE,
    TODAYS_ITEM_QUANTITY_REQUEST, TODAYS_ITEM_QUANTITY_SUCCESS, TODAYS_ITEM_QUANTITY_FAILURE,
    TODAYS_ITEM_CHANGE_STATUS_REQUEST, TODAYS_ITEM_CHANGE_STATUS_SUCCESS, TODAYS_ITEM_CHANGE_STATUS_FAILURE,
	BRAND_CREATE_ORDER_REQUEST, BRAND_CREATE_ORDER_SUCCESS, BRAND_CREATE_ORDER_FAILURE,
	ADD_ITEM_TO_CART_REQUEST, ADD_ITEM_TO_CART_SUCCESS, ADD_ITEM_TO_CART_FAILURE,
	CART_REQUEST, CART_SUCCESS, CART_FAILURE,
    UPDATE_CART_ITEM_QUANTITY_REQUEST, UPDATE_CART_ITEM_QUANTITY_SUCCESS, UPDATE_CART_ITEM_QUANTITY_FAILURE,
    REMOVE_CART_ITEM_REQUEST, REMOVE_CART_ITEM_SUCCESS, REMOVE_CART_ITEM_FAILURE,
	RESTAURANT_DELIVERY_ORDERS_REQUEST, RESTAURANT_DELIVERY_ORDERS_SUCCESS, RESTAURANT_DELIVERY_ORDERS_FAILURE,

} from '../actions';

const initialState = {
	page: 1, per_page: 100, total: 0, total_pages: 0,
    restaurant_orders_loading: false, restaurant_orders: [], restaurant_orders_errors: {},
    change_restaurant_order_status_loading: false, change_restaurant_order_status_errors: {},
    change_restaurant_pre_order_status_loading: false, change_restaurant_pre_order_status_errors: {},
    restaurant_order_details_loading: false, restaurant_order_details: {}, restaurant_order_details_errors: {},
    change_restaurant_item_status_loading: false, change_restaurant_item_status_errors: {},
    generate_restaurant_order_bill_loading: false, generate_restaurant_order_bill: {}, generate_restaurant_order_bill_errors: {},
    generate_restaurant_pre_order_bill_loading: false, generate_restaurant_pre_order_bill: {}, generate_restaurant_pre_order_bill_errors: {},
    get_restaurant_order_bill_loading: false, get_restaurant_order_bill: {}, get_restaurant_order_bill_errors: {},
    monthly_order_collection_loading: false, monthly_order_collection: 0, monthly_order_collection_errors: {},
    daily_order_collection_loading: false, daily_order_collection: 0, daily_order_collection_errors: {},
    total_order_collection_loading: false, total_order_collection: 0, total_order_collection_errors: {},
    restaurant_orders_history_loading: false, restaurant_orders_history: [], restaurant_orders_history_errors: {},
    todays_restaurant_orders_loading: false, todays_restaurant_orders: [], todays_restaurant_orders_errors: {},
	restaurant_order_mobile_select_list_loading: false, restaurant_order_mobile_select_list: [], restaurant_order_mobile_select_list_errors: {},
    todays_item_quantity_loading: false, todays_item_quantity: [], todays_item_quantity_errors: {},
    todays_item_change_status_loading: false, todays_item_change_status_errors: {},
	brand_create_order_loading: false, brand_create_order_errors: {}, brand_create_order_requests: {},
	add_item_to_cart_loading: false, add_item_to_cart: {}, add_item_to_cart_errors: {},
    cart_loading: false, cart: [], cart_errors: {}, cart_total_actual_price: 0, cart_total_discount: 0, cart_total_discounted_price: 0, cart_total_gst_price: 0, gst: {},
    update_cart_item_quantity_loading: false, update_cart_item_quantity: {}, update_cart_item_quantity_errors: {},
    remove_cart_item_loading: false, remove_cart_item: {}, remove_cart_item_errors: {},
	restaurant_delivery_orders_loading: false, restaurant_delivery_orders: [], restaurant_delivery_orders_errors: {},

};

export function RestaurantOrdersReducer(state = initialState, action) {
    switch (action.type) {
		/** begin::List restaurant orders redux */
		case RESTAURANT_ORDERS_REQUEST: {
			return {
				...state,
				restaurant_orders_loading: true,
				restaurant_orders_errors: {},
				brand_create_order_requests: {}
			};
		}
		case RESTAURANT_ORDERS_SUCCESS: {
			let newArray = [...state.restaurant_orders]; //making a new array
			action.data.forEach((payload) => {
				const restaurantOrderIndex = newArray.findIndex((restaurant_order) => restaurant_order._id === payload._id);
				if (restaurantOrderIndex !== -1) {
					newArray[restaurantOrderIndex] = payload;
				} else {
					newArray = newArray.concat(payload);
				}
			});

			return {
				...state,
				restaurant_orders_loading: false,
				restaurant_orders: newArray,
				restaurant_orders_errors: {},
			};
		}
		case RESTAURANT_ORDERS_FAILURE: {
			return {
				...state,
				restaurant_orders_loading: false,
				restaurant_orders_errors: action.errors,
				restaurant_orders: [],
			};
		}
		/** end::List restaurant menu redux */

		/** begin::Change order status redux */
		case CHANGE_RESTAURANT_ORDER_STATUS_REQUEST: {
			return {
				...state,
				change_restaurant_order_status_loading: true,
				change_restaurant_order_status_errors: {},
			};
		}
		case CHANGE_RESTAURANT_ORDER_STATUS_SUCCESS: {
			let orderArray = [...state.restaurant_orders];
			let orderDeliveryArray = [...state.restaurant_delivery_orders];
			let orderDetails = state.restaurant_order_details;

			if (orderArray.length > 0) {
				orderArray.forEach((order) => {
					if (order.order_id === action.data._id) {
						order.items.forEach((item) => {
							const correspondingItem = action.data.order.find((dataItem) => dataItem._id === item.order_item_id);
							if (correspondingItem) {
								item.order_item_status = correspondingItem.status;
							}
						});

						order.order_status = action.data.status;
					}
				});
			}

			if (orderDeliveryArray.length > 0) {
				orderDeliveryArray.forEach((deliveryOrder) => {
					if (deliveryOrder.order_id === action.data._id) {
						deliveryOrder.items.forEach((item) => {
							const correspondingItem = action.data.order.find((dataItem) => dataItem._id === item.order_item_id);
							if (correspondingItem) {
								item.order_item_status = correspondingItem.status;
							}
						});

						deliveryOrder.order_status = action.data.status;
					}
				});
			}

			if (orderDetails && Object.keys(orderDetails).length > 0) {
				if (orderDetails._id === action.data._id) {
					orderDetails.status = action.data.status;
				}
			}

			return {
				...state,
				change_restaurant_order_status_loading: false,
				change_restaurant_order_status_errors: {},
				restaurant_orders: orderArray,
				restaurant_order_details: orderDetails,
				restaurant_delivery_orders: orderDeliveryArray,
			};
		}
		case CHANGE_RESTAURANT_ORDER_STATUS_FAILURE: {
			return {
				...state,
				change_restaurant_order_status_loading: false,
				change_restaurant_order_status_errors: action.errors,
			};
		}
		/** end::Change giveaway status redux */


		/** begin::Change order status redux */
		case CHANGE_RESTAURANT_PRE_ORDER_STATUS_REQUEST: {
			return {
				...state,
				change_restaurant_pre_order_status_loading: true,
				change_restaurant_pre_order_status_errors: {},
			};
		}
		case CHANGE_RESTAURANT_PRE_ORDER_STATUS_SUCCESS: {
			let orderArray = [...state.todays_restaurant_orders];

			if (orderArray.length > 0) {
				orderArray.forEach((order) => {
					const correspondingData = action.data.find((dataOrder) => dataOrder._id === order.pre_order_id);

					if (correspondingData) {
						order.order_status = correspondingData.status;

						const correspondingItem = correspondingData.order.find((dataItem) => dataItem.item_id === order.item_id);
						if (correspondingItem) {
							order.item_status = correspondingItem.status;
							order.item_status_text = correspondingItem.item_status_text;
						}
					}
				});
			}

			return {
				...state,
				change_restaurant_pre_order_status_loading: false,
				change_restaurant_pre_order_status_errors: {},
				todays_restaurant_orders: orderArray,
			};
		}
		case CHANGE_RESTAURANT_PRE_ORDER_STATUS_FAILURE: {
			return {
				...state,
				change_restaurant_pre_order_status_loading: false,
				change_restaurant_pre_order_status_errors: action.errors,
			};
		}
		/** end::Change giveaway status redux */

		/** begin::restaurant order details redux */
		case RESTAURANT_ORDER_DETAILS_REQUEST: {
			return {
				...state,
				restaurant_order_details_loading: true,
				restaurant_order_details_errors: {},
			};
		}
		case RESTAURANT_ORDER_DETAILS_SUCCESS: {
			return {
				...state,
				restaurant_order_details_loading: false,
				restaurant_order_details: action.data,
				restaurant_order_details_errors: {},
			};
		}
		case RESTAURANT_ORDER_DETAILS_FAILURE: {
			return {
				...state,
				restaurant_order_details_loading: false,
				restaurant_order_details_errors: action.errors,
			};
		}
		/** end::restaurant order details redux */

		/** begin::Change order status redux */
		case CHANGE_RESTAURANT_ITEM_STATUS_REQUEST: {
			return {
				...state,
				change_restaurant_item_status_loading: true,
				change_restaurant_item_status_errors: {},
			};
		}
		case CHANGE_RESTAURANT_ITEM_STATUS_SUCCESS: {
			let orderDetails = state.restaurant_order_details;
			let orderArray = [...state.restaurant_orders];

			if (orderArray.length > 0) {
				orderArray.forEach((order) => {
					if (order.items && order.items.length > 0) {
						const orderIndex = order.items.findIndex((restaurant_order) => restaurant_order.order_item_id === action.data._id);
						if (orderIndex !== -1) {
							order.items[orderIndex].order_item_status = action.data.status;
						}
					}
				});
			}

			// if (orderDetails && Object.keys(orderDetails).length > 0) {
			//     for (const orderDetail of orderDetails.order) {
			//         const matchingItem = action.data.order.find(val => val.item_id === orderDetail.item._id);

			//         if (matchingItem) {
			//             orderDetail.status = matchingItem.status;
			//         }
			//     }
			// }

			return {
				...state,
				change_restaurant_item_status_loading: false,
				change_restaurant_item_status_errors: {},
				restaurant_order_details: orderDetails,
				restaurant_orders: orderArray,
			};
		}
		case CHANGE_RESTAURANT_ITEM_STATUS_FAILURE: {
			return {
				...state,
				change_restaurant_item_status_loading: false,
				change_restaurant_item_status_errors: action.errors,
			};
		}
		/** end::Change giveaway status redux */

		/** begin::Generate restaurant bill redux */
		case GENERATE_RESTAURANT_ORDER_BILL_REQUEST: {
			return {
				...state,
				generate_restaurant_order_bill_loading: true,
				generate_restaurant_order_bill_errors: {},
			};
		}
		case GENERATE_RESTAURANT_ORDER_BILL_SUCCESS: {
			let orderArray = [...state.restaurant_orders];
			let orderDeliveryArray = [...state.restaurant_delivery_orders];

			if (orderArray.length > 0) {
				orderArray.forEach((order) => {
					if (order.order_id === action.data._id) {
						order.items.forEach((item) => {
							const correspondingItem = action.data.order.find((dataItem) => dataItem._id === item.order_item_id);
							if (correspondingItem) {
								item.order_item_status = correspondingItem.status;
							}
						});

						order.order_status = action.data.status;
					}
				});
			}

			if (orderDeliveryArray.length > 0) {
				orderDeliveryArray.forEach((deliveryOrder) => {
					if (deliveryOrder.order_id === action.data._id) {
						deliveryOrder.items.forEach((item) => {
							const correspondingItem = action.data.order.find((dataItem) => dataItem._id === item.order_item_id);
							if (correspondingItem) {
								item.order_item_status = correspondingItem.status;
							}
						});

						deliveryOrder.order_status = action.data.status;
					}
				});
			}

			return {
				...state,
				generate_restaurant_order_bill_loading: false,
				generate_restaurant_order_bill_errors: {},
				generate_restaurant_order_bill: action.data,
			};
		}
		case GENERATE_RESTAURANT_ORDER_BILL_FAILURE: {
			return {
				...state,
				generate_restaurant_order_bill_loading: false,
				generate_restaurant_order_bill_errors: action.errors,
			};
		}
		/** end::Generate restaurant bill redux */

				/** begin::Generate restaurant bill redux */
		case GENERATE_RESTAURANT_PRE_ORDER_BILL_REQUEST: {
			return {
				...state,
				generate_restaurant_pre_order_bill_loading: true,
				generate_restaurant_pre_order_bill_errors: {},
			};
		}
		case GENERATE_RESTAURANT_PRE_ORDER_BILL_SUCCESS: {
			let orderArray = [...state.todays_restaurant_orders];

			if (orderArray.length > 0) {
				orderArray.forEach((order) => {
					const correspondingData = action.data.find((dataOrder) => dataOrder._id === order.pre_order_id);

					if (correspondingData) {
						order.order_status = correspondingData.status;

						const correspondingItem = correspondingData.order.find((dataItem) => dataItem.item_id === order.item_id);
						if (correspondingItem) {
							order.item_status = correspondingItem.status;
							order.item_status_text = correspondingItem.item_status_text;
						}
					}
				});
			}

			return {
				...state,
				generate_restaurant_pre_order_bill_loading: false,
				generate_restaurant_pre_order_bill_errors: {},
				generate_restaurant_pre_order_bill: action.data,
				todays_restaurant_orders: orderArray
			};
		}
		case GENERATE_RESTAURANT_PRE_ORDER_BILL_FAILURE: {
			return {
				...state,
				generate_restaurant_pre_order_bill_loading: false,
				generate_restaurant_pre_order_bill_errors: action.errors,
			};
		}
		/** end::Generate restaurant bill redux */

		/** begin::Generate restaurant bill redux */
		case GET_RESTAURANT_ORDER_BILL_REQUEST: {
			return {
				...state,
				get_restaurant_order_bill_loading: true,
				get_restaurant_order_bill_errors: {},
			};
		}
		case GET_RESTAURANT_ORDER_BILL_SUCCESS: {
			return {
				...state,
				get_restaurant_order_bill_loading: false,
				get_restaurant_order_bill: action.data,
				get_restaurant_order_bill_errors: {},
			};
		}
		case GET_RESTAURANT_ORDER_BILL_FAILURE: {
			return {
				...state,
				get_restaurant_order_bill_loading: false,
				get_restaurant_order_bill_errors: action.errors,
			};
		}
		/** end::Generate restaurant bill redux */

		/** begin::Monthly order collection redux */
		case MONTHLY_ORDER_COLLECTION_REQUEST: {
			return {
				...state,
				monthly_order_collection_loading: true,
				monthly_order_collection_errors: {},
			};
		}
		case MONTHLY_ORDER_COLLECTION_SUCCESS: {
			return {
				...state,
				monthly_order_collection_loading: false,
				monthly_order_collection_errors: {},
				monthly_order_collection: action.data,
			};
		}
		case MONTHLY_ORDER_COLLECTION_FAILURE: {
			return {
				...state,
				monthly_order_collection_loading: false,
				monthly_order_collection_errors: action.errors,
			};
		}
		/** end::Monthly order collection redux */

		/** begin::daily order collection redux */
		case DAILY_ORDER_COLLECTION_REQUEST: {
			return {
				...state,
				daily_order_collection_loading: true,
				daily_order_collection_errors: {},
			};
		}
		case DAILY_ORDER_COLLECTION_SUCCESS: {
			return {
				...state,
				daily_order_collection_loading: false,
				daily_order_collection_errors: {},
				daily_order_collection: action.data,
			};
		}
		case DAILY_ORDER_COLLECTION_FAILURE: {
			return {
				...state,
				daily_order_collection_loading: false,
				daily_order_collection_errors: action.errors,
			};
		}
		/** end::daily order collection redux */

		/** begin::total order collection redux */
		case TOTAL_ORDER_COLLECTION_REQUEST: {
			return {
				...state,
				total_order_collection_loading: true,
				total_order_collection_errors: {},
			};
		}
		case TOTAL_ORDER_COLLECTION_SUCCESS: {
			return {
				...state,
				total_order_collection_loading: false,
				total_order_collection_errors: {},
				total_order_collection: action.data,
			};
		}
		case TOTAL_ORDER_COLLECTION_FAILURE: {
			return {
				...state,
				total_order_collection_loading: false,
				total_order_collection_errors: action.errors,
			};
		}
		/** end::total order collection redux */

		/** begin::List restaurant order history redux */
		case RESTAURANT_ORDERS_HISTORY_REQUEST: {
			return {
				...state,
				restaurant_orders_history_loading: true,
				restaurant_orders_history_errors: {},
			};
		}
		case RESTAURANT_ORDERS_HISTORY_SUCCESS: {
			let newArray = [...state.restaurant_orders_history]; //making a new array

			if (action.data.page === 1) {
				newArray = [];
			}

			action.data.restaurantOrderHistory.forEach((payload) => {
				const restaurantOrderHistoryIndex = newArray.findIndex((restaurant_order_history) => restaurant_order_history._id.toString() === payload._id.toString());
				if (restaurantOrderHistoryIndex !== -1) {
					newArray[restaurantOrderHistoryIndex] = payload;
				} else {
					newArray = newArray.concat(payload);
				}
			});

			return {
				...state,
				restaurant_orders_history_loading: false,
				restaurant_orders_history: newArray,
				restaurant_orders_history_errors: {},
				page: action.data.page,
				per_page: action.data.per_page,
				total: action.data.total,
			};
		}
		case RESTAURANT_ORDERS_HISTORY_FAILURE: {
			return {
				...state,
				restaurant_orders_history_loading: false,
				restaurant_orders_history_errors: action.errors,
				restaurant_orders_history: [],
			};
		}
		/** end::List restaurant order history redux */

		/** begin::List todays restaurant order redux */
		case TODAYS_RESTAURANT_ORDERS_REQUEST: {
			return {
				...state,
				todays_restaurant_orders_loading: true,
				todays_restaurant_orders_errors: {},
			};
		}
		case TODAYS_RESTAURANT_ORDERS_SUCCESS: {
			let newArray = [...state.todays_restaurant_orders]; //making a new array

			if (action.data.page === 1) {
				newArray = [];
			}

			action.data.forEach((payload) => {
				const todaysRestaurantOrderIndex = newArray.findIndex((todays_restaurant_order) => todays_restaurant_order._id === payload._id);
				if (todaysRestaurantOrderIndex !== -1) {
					newArray[todaysRestaurantOrderIndex] = payload;
				} else {
					newArray = newArray.concat(payload);
				}
			});

			return {
				...state,
				todays_restaurant_orders_loading: false,
				todays_restaurant_orders: action.data,
				todays_restaurant_orders_errors: {},
				page: action.data.page,
				per_page: action.data.per_page,
				total: action.data.total,
			};
		}
		case TODAYS_RESTAURANT_ORDERS_FAILURE: {
			return {
				...state,
				todays_restaurant_orders_loading: false,
				todays_restaurant_orders_errors: action.errors,
				todays_restaurant_orders: [],
			};
		}
		/** end::List todays restaurant order redux */

		/** begin::Restaurant Order Mobile select list redux */
		case RESTAURANT_ORDERS_MOBILE_SELECT_LIST_REQUEST: {
			return {
				...state,
				restaurant_order_mobile_select_list_loading: true,
				restaurant_order_mobile_select_list_errors: {},
			};
		}
		case RESTAURANT_ORDERS_MOBILE_SELECT_LIST_SUCCESS: {
			return {
				...state,
				restaurant_order_mobile_select_list_loading: false,
				restaurant_order_mobile_select_list: action.data,
				restaurant_order_mobile_select_list_errors: {},
			};
		}
		case RESTAURANT_ORDERS_MOBILE_SELECT_LIST_FAILURE: {
			return {
				...state,
				restaurant_order_mobile_select_list_loading: false,
				restaurant_order_mobile_select_list_errors: action.errors,
				restaurant_order_mobile_select_list: [],
			};
		}
		/** end::Restaurant Order Mobile select list redux */

		/** begin::Todays item quantity redux */
		case TODAYS_ITEM_QUANTITY_REQUEST: {
			return {
				...state,
				todays_item_quantity_loading: true,
				todays_item_quantity_errors: {},
			};
		}
		case TODAYS_ITEM_QUANTITY_SUCCESS: {

			return {
				...state,
				todays_item_quantity_loading: false,
				todays_item_quantity: action.data,
				todays_item_quantity_errors: {},
				page: action.data.page,
				per_page: action.data.per_page,
				total: action.data.total,
			};
		}
		case TODAYS_ITEM_QUANTITY_FAILURE: {
			return {
				...state,
				todays_item_quantity_loading: false,
				todays_item_quantity_errors: action.errors,
				todays_item_quantity: [],
			};
		}
		/** end::Todays item quantity redux */

		/** begin::Change order status redux */
		case TODAYS_ITEM_CHANGE_STATUS_REQUEST: {
			return {
				...state,
				todays_item_quantity_change_status_loading: true,
				todays_item_quantity_change_status_errors: {},
			};
		}
		case TODAYS_ITEM_CHANGE_STATUS_SUCCESS: {
			let orderArray = [...state.todays_item_quantity];


			if (orderArray.length > 0) {
				orderArray.forEach((order) => {
					const correspondingItem = action.data
					  .flatMap((dataOrder) => dataOrder.order)
					  .find((dataItem) => dataItem.item_id === order.item_id && (!order.portion_slug || order.portion_slug === dataItem.portion));

					if (correspondingItem) {
					  order.item_status = correspondingItem.status;
					  order.item_status_text = correspondingItem.item_status_text;
					}
				  });
			  }


			return {
				...state,
				todays_item_quantity_change_status_loading: false,
				todays_item_quantity_change_status_errors: {},
				todays_item_quantity: orderArray,
			};
		}
		case TODAYS_ITEM_CHANGE_STATUS_FAILURE: {
			return {
				...state,
				todays_item_quantity_change_status_loading: false,
				todays_item_quantity_change_status_errors: action.errors,
			};
		}
		/** end::Change giveaway status redux */

		/** begin::Create order redux */
        case BRAND_CREATE_ORDER_REQUEST: {
            return {
                ...state,
                brand_create_order_loading: true,
                brand_create_order_errors: {}
            };
        }
        case BRAND_CREATE_ORDER_SUCCESS: {
            return {
                ...state,
                brand_create_order_loading: false,
                brand_create_order_errors: {},
                brand_create_order_requests: action
            };
        }
        case BRAND_CREATE_ORDER_FAILURE: {
            return {
                ...state,
                brand_create_order_loading: false,
                brand_create_order_errors: action.errors
            };
        }
        /** end::Create order redux */

		case CART_REQUEST: {
            return {
                ...state,
                cart_loading: true,
                cart_errors: {},
                cart_total_actual_price: 0,
                cart_total_discount: 0,
                cart_total_discounted_price: 0,
                cart_total_gst_price: 0,
                gst: {}
            };
        }
        case CART_SUCCESS: {

            return {
                ...state,
                cart_loading: false,
                cart: action.data.items,
                cart_errors: {},
                cart_total_actual_price: action.data.total_actual_price,
                cart_total_discount: action.data.total_discount,
                cart_total_discounted_price: action.data.total_discounted_price,
                cart_total_gst_price: action.data.total_gst_price,
                gst: action.data.gst
            };
        }
        case CART_FAILURE: {
            return {
                ...state,
                cart_loading: false,
                cart_errors: action.errors,
                cart_total_actual_price: 0,
                cart_total_discount: 0,
                cart_total_discounted_price: 0,
                cart_total_gst_price: 0,
                gst: {}
            };
        }
		case ADD_ITEM_TO_CART_REQUEST: {
            return {
                ...state,
                add_item_to_cart_loading: true,
                add_item_to_cart_errors: {}
            };
        }
        case ADD_ITEM_TO_CART_SUCCESS: {
            return {
                ...state,
                add_item_to_cart_loading: false,
                add_item_to_cart: action.data,
                add_item_to_cart_errors: {}
            };
        }
        case ADD_ITEM_TO_CART_FAILURE: {
            return {
                ...state,
                add_item_to_cart_loading: false,
                add_item_to_cart_errors: action.errors
            };
        }

		case UPDATE_CART_ITEM_QUANTITY_REQUEST: {
            return {
                ...state,
                update_cart_item_quantity_loading: true,
                update_cart_item_quantity_errors: {}
            };
        }
        case UPDATE_CART_ITEM_QUANTITY_SUCCESS: {
            return {
                ...state,
                update_cart_item_quantity_loading: false,
                update_cart_item_quantity: action.data,
                update_cart_item_quantity_errors: {}
            };
        }
        case UPDATE_CART_ITEM_QUANTITY_FAILURE: {
            return {
                ...state,
                update_cart_item_quantity_loading: false,
                update_cart_item_quantity_errors: action.errors
            };
        }

		case REMOVE_CART_ITEM_REQUEST: {
            return {
                ...state,
                remove_cart_item_loading: true,
                remove_cart_item_errors: {}
            };
        }
        case REMOVE_CART_ITEM_SUCCESS: {
            return {
                ...state,
                remove_cart_item_loading: false,
                remove_cart_item: action.data,
                remove_cart_item_errors: {}
            };
        }
        case REMOVE_CART_ITEM_FAILURE: {
            return {
                ...state,
                remove_cart_item_loading: false,
                remove_cart_item_errors: action.errors
            };
        }

		/** begin::List restaurant delivery orders redux */
		case RESTAURANT_DELIVERY_ORDERS_REQUEST: {
			return {
				...state,
				restaurant_delivery_orders_loading: true,
				restaurant_delivery_orders_errors: {},
				brand_create_order_requests: {}
			};
		}
		case RESTAURANT_DELIVERY_ORDERS_SUCCESS: {
			let newArray = [...state.restaurant_delivery_orders]; //making a new array
			action.data.forEach((payload) => {
				const restaurantDeliveryOrderIndex = newArray.findIndex((restaurant_delivery_order) => restaurant_delivery_order._id === payload._id);
				if (restaurantDeliveryOrderIndex !== -1) {
					newArray[restaurantDeliveryOrderIndex] = payload;
				} else {
					newArray = newArray.concat(payload);
				}
			});

			return {
				...state,
				restaurant_delivery_orders_loading: false,
				restaurant_delivery_orders: newArray,
				restaurant_delivery_orders_errors: {},
			};
		}
		case RESTAURANT_DELIVERY_ORDERS_FAILURE: {
			return {
				...state,
				restaurant_delivery_orders_loading: false,
				restaurant_delivery_orders_errors: action.errors,
				restaurant_delivery_orders: [],
			};
		}
		/** end::List restaurant delivery order redux */

		default:
			return state;
	}
}