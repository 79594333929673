import { Alert, styled } from "@mui/material";

const AlertStyled = styled(({ ...props }) => (<Alert {...props} />))`
        margin-bottom: ${props => props.theme.spacing(1)};
        & .MuiPaper-root {
            display: flex;
            align-items: center;
            justify-content: center;
        }
`;

export { AlertStyled }