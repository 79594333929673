import React, { useEffect } from 'react';
import { StatisticsWidget, CurrencySymbol } from '../../../../_components';
import { useDispatch, useSelector } from 'react-redux';
import { WalletAction } from '../../../../redux//actions';
import { Box } from '@mui/material';

function WalletTotalCreditWidget() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    const { wallet_total_credit } = useSelector((state) => state.WalletReducer);

    /** Redux reducer actions */
    const getWalletTotalCredit = (params) => dispatch(WalletAction.getWalletTotalCredit(params));

    useEffect(() => {
        getWalletTotalCredit({ type: "total" });
        //eslint-disable-next-line
    }, [])

    return (
        <Box>
            <StatisticsWidget
                color="dark"
                icon={<CurrencySymbol />}
                title="Total Credit"
                count={wallet_total_credit}
                percentage={{
                    label: <> Your total credit is &nbsp; <span style={{ whiteSpace: 'nowrap' }}> <CurrencySymbol /> &nbsp; {wallet_total_credit}</span> </>
                }}
            />
        </Box>
    )
}

export { WalletTotalCreditWidget }