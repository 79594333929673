import { FormControlLabel, Switch } from "@mui/material";
import { FormHelperText } from "@mui/material";
import { FormSwitchStyled } from './';
import React from "react";
import { useState } from "react";


const FormSwitch = ({ name, label, placeholder, type = 'text', className, id, value, onChange, isReadOnly = false, maskType, tabIndex, error, info, ...props }) => {

    const [val, setVal] = useState((typeof value === 'undefined' || value === true) ? true : false);

    const handleChange = (e) => {
        setVal(e.target.checked);
        onChange(e);
    };

    return (
        <FormSwitchStyled fullWidth>
            <FormControlLabel labelPlacement='start' control={<Switch name={name} checked={val} onChange={handleChange} />} label={`${label} `} />
            {(info) && <FormHelperText id={`helper_${name}`} >{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error>{error}</FormHelperText>}
        </FormSwitchStyled>
    );
};

export { FormSwitch };
