import React, { useEffect, useRef, useState } from "react";
import { PDFDownloadLink, StyleSheet} from "@react-pdf/renderer";
import { Download } from "@mui/icons-material";
import { Fragment } from "react";
import { GeneralButton } from "../../../../../../../_components";
import { useSelector } from "react-redux";
import { ItemPdf } from "./ItemPdf";

function TodaysItemQuantityPdf({ ...props }) {

    const { total, todays_item_quantity_loading: isLoading, todays_item_quantity } = useSelector((state) => state.RestaurantOrdersReducer);

    return (
        <Fragment>
            {(todays_item_quantity && todays_item_quantity.length > 0) && <div>
                <PDFDownloadLink document={<ItemPdf todays_item_quantity={todays_item_quantity}/>} fileName="item-quantity.pdf">
                    {({ blob, url, loading, error }) =>
                        (loading || isLoading) ? <GeneralButton disabled={true} startIcon={<Download />} label='Download PDF' /> : <GeneralButton startIcon={<Download />} label='Download PDF' />
                    }
                </PDFDownloadLink>
            </div>}
        </Fragment>
    );
}

export { TodaysItemQuantityPdf }