import { Card, CardContent, CardHeader } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useDispatch } from 'react-redux';
import { InfluencerAction } from '../../../../../redux/actions';

function InfluencerWithMostFollowersGraph() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux reducer actions */
    const getInfluencersWithMostFollowers = (params) => dispatch(InfluencerAction.getInfluencersWithMostFollowers(params));

    /** Initialize and declare state */
    const [option, setOption] = useState({
        chart: {
            type: 'column',
            renderTo: 'container',
            backgroundColor: 'rgba(0,0,0,0)',
            style: {
                fontFamily: 'unset'
            }
        },
        title: {
            text: ''
        },
        accessibility: {
            announceNewData: {
                enabled: true
            },
            enabled: false
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: {
                text: 'Followers'
            }

        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y:.0f}'
                }
            }
        },
        tooltip: {
            formatter: function () {
                return '<span style="font-size:11px">' + this.series.name + ': ' + this.point.influencer_name + '</span><br> <span style="color:' + this.point.color + '">' + this.point.name + '</span>: <b>' + this.point.y + '</b> followers<br/>'
            }
        },
        credits: {
            enabled: false
        }
    });

    useEffect(() => {
        getInfluencersWithMostFollowers().then(result => {
            if (result.status)
                setOption({
                    series: [
                        {
                            name: "Influencer",
                            colorByPoint: true,
                            data: result.data
                        }
                    ],
                })
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <Card>
            <CardHeader title="Influencer's with most followers" />
            <CardContent>
                <HighchartsReact highcharts={Highcharts} options={option} />
            </CardContent>
        </Card>
    )
}

export { InfluencerWithMostFollowersGraph }