
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from "react-router-dom";
import { cookieServices } from '../../_helpers';
import { preAuthRoutes } from '../../routes';
import { Header } from './'
import { useStyles } from '.'
import { SideBar } from './sidebar';
import ability from '../../_helpers/permission/UserAbility';
import { Box } from '@mui/material';

const PostSignIn = () => {

    const navigate = useNavigate();

    /** Redux actions and state */
    const { isDrawerOpen } = useSelector((state) => state.GlobalReducer);
    const { user } = useSelector((state) => state.UserReducer);


    useEffect(() => {

        if (!cookieServices.get('accessToken'))
            navigate({ to: preAuthRoutes('login').path });

    }, [navigate])

    const classes = useStyles();

    return (
        <Box>
            <Header />
            <SideBar />
            <Box component="main" className={`${classes.mainComponent} ${isDrawerOpen ? classes.drawerOpen : ``}`}>
                <Outlet />
            </Box>
        </Box>
    );
}

export { PostSignIn };
