import { Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { handleInputChange, validateForm } from "../../../../../_helpers";
import { validate } from ".";
import { GiveawayAction } from "../../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import { Fragment } from "react";
import { Dialog, DialogActionCloseBtn, DialogActionSaveBtn, DialogActions, DialogContent, DialogTitle, FormInput } from "../../../../../_components";

// Initialize form input values to null
const inputs = { giveaway_id: '', giveaway_message: '' };

function GiveawayGenerateWinner({ ...props }) {

    /** Initialize plugins and variables */
    let { search } = useLocation();
    const dispatch = useDispatch();

    const params = new URLSearchParams(search);
    const giveawayId = params.get('gId'); // story id from url

    /** Initialize props values */
    const { show, closeModal } = props;

    /** Redux actions and state */
    const generateGiveawayWinner = (params) => dispatch(GiveawayAction.generateGiveawayWinner(params));
    const getGiveawayParticipantsCount = (params) => dispatch(GiveawayAction.getGiveawayParticipantsCount(params));

    const { generate_giveaway_winner_loading: isLoading, giveaway_participants_count_loading: isParticipantsLoading, giveaway_participants_count: participants } = useSelector((state) => state.GiveawayReducer);

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {
        setData({ ...inputs, giveaway_id: giveawayId });
    }, [giveawayId])

    useEffect(() => {
        getGiveawayParticipantsCount({ giveaway_id: giveawayId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [giveawayId])

    /**
     * function to close generate giveaway modal
     * @param {string} giveaway_message Entered giveaway message
     * @return view
     * @author Jasin
     * @created_at 17 Jan 2023
     */
    const onClose = () => {
        setData({ ...inputs });
        setErrors({ ...inputs });
        closeModal(false);
    }

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        formData.append("giveaway_id", giveawayId);
        formData.append("giveaway_message", data.giveaway_message);
        dispatchGenerateGiveawayWinnerAction(data);

    }

    /**
     * function to dispatch genetaye giveaway winner api
     * @param {Object} formData - The params which are used for the api
     * @param {string} formData.giveaway_id
     * @param {string} formData.giveaway_message
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const dispatchGenerateGiveawayWinnerAction = async (formData) => {
        generateGiveawayWinner(formData).then((result) => {
            closeModal(false);
        });
    }

    return (
        <Fragment>
            <Dialog isDialogOpen={show} onClose={onClose} maxWidth='md'>
                <form onSubmit={handleSubmit} noValidate >
                    <DialogTitle id="generateWinner" onClose={onClose} title='Do you want to generate the winner?' />
                    <DialogContent >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormInput
                                    tabIndex={1}
                                    label='Giveaway message'
                                    name='giveaway_message'
                                    value={data.giveaway_message}
                                    error={action.isSubmitted && (errors.giveaway_message ? errors.giveaway_message : '')}
                                    onChange={handleChange}
                                    multiline
                                    maxLength={250}
                                    minRows={2}
                                />
                                <Divider />
                                <Box>
                                    <Typography variant='subtitle2' textAlign={'left'}>{participants} {participants > 1 ? 'contestants' : 'contestant'} found.</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogActionSaveBtn tabIndex={2} text='Generate' loading={isLoading || isParticipantsLoading} disabled={!participants || participants === 0} />
                        <DialogActionCloseBtn tabIndex={3} onClose={onClose} />
                    </DialogActions>
                </form>
            </Dialog>
        </Fragment>
    );
}

export { GiveawayGenerateWinner }