import React, { useState } from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { FormHelperText } from '@mui/material';
import { FormTimePickerStyled } from '.';

function FormTimePicker({label, name, placeholder, className, onChange, tabIndex, id, error, isReadOnly = false, pattern, maxLength, maskType, value, info, ...props}) {

    const [val, setVal] = useState((value && value !== '')  ? dayjs(value) : null);

    const handleTimeChange = (newTime) => {
        onChange({ target: { type: 'time', name: name, value: newTime.toISOString() } });
        setVal(newTime);
    };

    return (
        <FormTimePickerStyled fullWidth className={`${className}`} >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker 
                    value={val} 
                    onChange={handleTimeChange} 
                    label={label}
                />
            </LocalizationProvider>
            {info && <FormHelperText id={`helper_${name}`}>{info}</FormHelperText>}
            {error && <FormHelperText id={`error_${name}`} error>{error}</FormHelperText>}
        </FormTimePickerStyled>
    );
}

export { FormTimePicker };
