import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FilterAlt } from '@mui/icons-material';
import { Box, Grid, Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { postAuthRoutes } from '../../../../../routes';
import { Card, CardContent, CardHeader } from '../../../../../_components/card';
import { GeneralButton } from '../../../../../_components/controls';
import { FormSearchInput } from '../../../../../_components/form';
import { RestaurantOrderDataTable, OrderHistoryFilter } from '.';
import { MonthlyCollectionWidget, DailyCollectionWidget, TotalCollectionWidget } from '../widgets';
import { AbilityCan } from '../../../../../_helpers/permission/AbilityCan';

function RestaurantOrderHistory() {
	/** Initialize plugins and variables */
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	/** Redux reducer state */
	const { user } = useSelector((state) => state.UserReducer);

	const [showOrderHistoryFilterModal, setShowOrderHistoryFilterModal] = useState(false);
	const [payload, setPayload] = useState({ limit: 100, page: 1, filter: { status: searchParams.get('status') ?? '', order_created_at: searchParams.get('order_created_at') ?? '' }, search: searchParams.get('q') ?? '' });

	/**
	 * function to open/close Order History filter modal
	 * @param {string} null
	 * @return view
	 * @author Naveen
	 * @created_at 11 Dec 2023
	 */
	const orderHistoryFilterModal = () => {
		setShowOrderHistoryFilterModal(!showOrderHistoryFilterModal);
	};

	/**
	 * function to apply the selected filter options on the Order History
	 * @param {Object} filterData
	 * @param {String} filterData.status Selected status from the Order History filter modal
	 * @return view
	 * @author
	 * @created_at 11 Dec 2023
	 */
	const applyFilter = (filterData) => {
		setPayload({ ...payload, page: 1, filter: filterData });
	};

	/**
	 * function to apply the search key words on the Order History
	 * @param {String} text Search keyword used
	 * @return view
	 * @author Naveen
	 * @created_at 11 Dec 2023
	 */
	const applySearch = (text) => {
		setPayload({ ...payload, page: 1, search: text });
	};

	return (
		<React.Fragment>
			<Box>
				<CardHeader
					title={postAuthRoutes('restaurant_order_history').name}
					action={
						<Stack spacing={1} direction="row">
						<AbilityCan I="brand_restaurant_order_history_search" passThrough={user.role_slug === 'developer' ? true : false}>
							<FormSearchInput getSearchText={applySearch} searchText={payload.search} />
						</AbilityCan>
						<AbilityCan I="brand_restaurant_order_history_filter" passThrough={user.role_slug === 'developer' ? true : false}>
							<GeneralButton label={<FilterAlt />} onClick={() => orderHistoryFilterModal()} />
						</AbilityCan>
						</Stack>
					}
				/>
			</Box>
			<OrderHistoryFilter show={showOrderHistoryFilterModal} closeModal={orderHistoryFilterModal} applyFilter={applyFilter} orderHistoryFilter={payload.filter} />
			<Grid container spacing={3} style={{ paddingBottom: '15px' }}>
				<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
					<DailyCollectionWidget filter={payload.filter}/>
				</Grid>
				<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
					<MonthlyCollectionWidget filter={payload.filter}/>
				</Grid>
				<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
					<TotalCollectionWidget filter={payload.filter}/>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Card>
						<CardContent>
							<RestaurantOrderDataTable filter={payload.filter} search={payload.search} limit={payload.limit} page={payload.page}/>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

export { RestaurantOrderHistory };
