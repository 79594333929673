import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { UserAction, CurrencyAction } from '../../../../redux/actions';
import { Divider, IconButton, MenuItem, Menu } from '@mui/material';
import { CurrencyExchange } from '@mui/icons-material';
import { useStyles } from '.';
import { Fragment } from "react";

function SelectCurrency() {

    /** Initialize props values */
    const classes = useStyles();
    const dispatch = useDispatch();

    /** Redux reducer state */
    const { currency } = useSelector((state) => state.CurrencyReducer);

    /** Redux reducer actions*/
    const [anchorEl, setAnchorEl] = useState(null);

    const changeCurrency = (params) => dispatch(UserAction.changeCurrency(params));
    const listCurrency = (params) => dispatch(CurrencyAction.listCurrency(params));

    const openMenu = Boolean(anchorEl);

    useEffect(() => {
        listCurrency();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectCurrency = (currencyValue) => {
        changeCurrency({currency: currencyValue._id});
        handleClose();
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Fragment>
            <IconButton onClick={handleClick}>
                <CurrencyExchange />
            </IconButton>
            <Menu anchorEl={anchorEl} open={openMenu} onClose={handleClose} onClick={handleClose} PaperProps={{ elevation: 0, className: classes.menuPaper }} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            { currency && currency.currency_values && currency.currency_values.length > 0 && currency.currency_values.map((currency, i) => {
                return (
                    <>
                        <MenuItem onClick={() => selectCurrency(currency)}>
                            <div style={{ marginRight: '8px' }} dangerouslySetInnerHTML={{ __html: currency.html }} />
                            {currency.name}
                        </MenuItem>
                        <Divider />
                    </>
                )
            })}
            </Menu>
        </Fragment>
    );
}

export { SelectCurrency }