import { CheckCircle, Instagram, LocalPhone, MailOutline } from '@mui/icons-material';
import { Avatar, Box, Card, CardContent, CardMedia, Chip, CircularProgress, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { CouponCodeCard } from '../../../coupon-codes';
import { postAuthRoutes } from '../../../../../../routes'
import { Fragment } from 'react';
import { StoryAction } from '../../../../../../redux/actions';

function StoryDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();
    const navigate = useNavigate();

    const params = new URLSearchParams(search);
    const storyId = params.get('sId'); // story id from url

    /** Redux reducer state */
    const { story_loading: isLoading, story } = useSelector((state) => state.StoryReducer);

    /** Redux reducer actions */
    const getStoryDetails = (params) => dispatch(StoryAction.getStoryDetails(params));

    useEffect(() => {
        getStoryDetails({ story_id: storyId });
        //eslint-disable-next-line
    }, [storyId])

    /**
     * function direct to influencer details page
     * @author
     * @created_at 30 May 2022
     */
    function handleInfluencerDetailsClick() {
        const pathname = postAuthRoutes('influencer_details').path;
        navigate({ pathname, search: createSearchParams(`?influencerId=${story.influencer_id}`).toString() });
    }

    if (isLoading)
        return ''

    return (
        <Card sx={{ padding: 6 }}>
            <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} display="flex" alignItems="center"  >
                    {isLoading ? (
                        <Skeleton variant="rectangular" width={180} height={320} />
                    ) : (
                        <CardMedia onError={e => { e.target.src = story.story_url_error }} component="img" image={story.story_url} style={{ position: "relative", padding: 0, margin: 0, width: "100%" }} alt={story.ig_id} />
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} display="flex" alignItems="center"  >
                    <CardContent style={{ width: `100%` }} >
                        {story.influencer &&
                            (
                                <Fragment>
                                    <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                <Avatar sx={{ width: 100, height: 100 }} src={story.influencer.insta_profile.profile_picture_url}>{story.influencer.short_name}</Avatar>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                <Typography variant="h4" color="primary" onClick={handleInfluencerDetailsClick} sx={{ cursor: `pointer` }}>{story.influencer.name}</Typography>
                                                <Chip label={story.influencer.insta_profile.is_private ? 'Private' : 'Public'} color={story.influencer.insta_profile.is_private ? 'error' : 'success'} />
                                            </Stack>
                                            <Typography variant="h6" display={`flex`} alignItems='center'><Instagram /> &nbsp;{`@${story.influencer.insta_handle}`}</Typography>
                                            {story.influencer.email && <Typography variant="h6" display={`flex`} alignItems='center'><MailOutline />&nbsp;{story.influencer.email}&nbsp;{story.influencer.email_verify_status ? <CheckCircle color='success' size='small' /> : <CheckCircle color='secondary' size='small' />}</Typography>}
                                            {story.influencer.mobile_number && <Typography variant="h6" display={`flex`} alignItems='center'><LocalPhone /> &nbsp;{story.influencer.mobile_number}&nbsp;{story.influencer.mobile_verify_status ? <CheckCircle color='success' size='small' /> : <CheckCircle color='secondary' size='small' />}</Typography>}
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                </Fragment>
                            )
                        }
                        <Box>
                            <Typography variant={`body2`} sx={{ fontWeight: 500 }}>{`Posted on - ${story.created_at}`}</Typography>
                            <Typography variant={`body2`} sx={{ fontWeight: 500 }}>{`Status - ${story.status.toUpperCase()}`}</Typography>
                            <Divider />
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} display="flex" alignItems="center" justifyContent={`center`}>
                                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                        <CircularProgress color="success" variant="determinate" value={story.current_checkpoint ? story.current_checkpoint.percentage : 0} style={{ width: "75px", height: "75px" }} />
                                        <Box sx={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center' }}  >
                                            <Typography variant="h6" > {`${(story.current_checkpoint ? story.current_checkpoint.percentage : 0)}%`}  </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                    <CouponCodeCard height='100px' coupon_code={story.coupon.coupon_code} status={story.coupon.status} _id={story.coupon._id} story={story} description={story.coupon.description} completed_at={story.coupon.completed_at} hasAction={false} />
                                </Grid>
                            </Grid>
                            <Divider />
                            {
                                story.tags.map((tag, i) => {
                                    return <Chip key={i} label={`#${tag}`} color="primary" style={{ marginRight: 3, borderRadius: 4 }} />
                                })
                            }
                        </Box>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    )
}

export { StoryDetails }