import {
    TICKETS_REQUEST, TICKETS_SUCCESS, TICKETS_FAILURE,
    TICKETS_CREATE_REQUEST, TICKETS_CREATE_SUCCESS, TICKETS_CREATE_FAILURE,
    UPDATE_TICKETS_REQUEST, UPDATE_TICKETS_SUCCESS, UPDATE_TICKETS_FAILURE,
    TICKETS_DETAILS_REQUEST, TICKETS_DETAILS_SUCCESS, TICKETS_DETAILS_FAILURE,
    CHANGE_TICKETS_STATUS_REQUEST, CHANGE_TICKETS_STATUS_SUCCESS, CHANGE_TICKETS_STATUS_FAILURE,
    ADD_REPLY_REQUEST, ADD_REPLY_SUCCESS, ADD_REPLY_FAILURE,

} from "../actions";

const initialState = {
    tickets_loading: false, tickets: [], tickets_errors: {},
    create_ticket_loading: false, create_ticket_errors: {}, create_ticket_request: {},
    ticket_details_loading: false, ticket_details: {}, ticket_details_errors: {},
    change_ticket_status_loading: false, change_ticket_status_errors: {},
    page: 1, per_page: 18, total: 0, total_pages: 0,
    add_reply_loading: false, add_reply_errors: [],
};

export function TicketReducer(state = initialState, action) {
    switch (action.type) {

        case TICKETS_REQUEST: {
            return {
                ...state,
                tickets_loading: true,
                tickets_errors: {}
            };
        }

        case TICKETS_SUCCESS: {

            let newArray = [...state.tickets]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }

            action.data.tickets.forEach((payload) => {
                const brandIndex = newArray.findIndex(ticket => ticket._id === payload._id);
                if (brandIndex !== -1) {
                    newArray[brandIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                tickets_loading: false,
                tickets: newArray,
                tickets_errors: {},
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };

        }

        case TICKETS_FAILURE: {
            return {
                ...state,
                tickets_loading: false,
                tickets_errors: action.errors,
            };
        }
        /** end::List support center redux */

        /** begin::Create support center redux */

        case TICKETS_CREATE_REQUEST: {
            return {
                ...state,
                create_ticket_loading: true,
                create_ticket_errors: {},
                create_ticket_request: {}
            };
        }
        case TICKETS_CREATE_SUCCESS: {

            let ticketsArray = [...state.tickets]; //making a new array
            const ticketIndex = ticketsArray.findIndex(ticket => ticket._id === action.data._id);

            if (ticketIndex !== -1) {
                ticketsArray[ticketIndex].status = action.data.status;
            }

            return {
                ...state,
                create_ticket_loading: false,
                tickets: ticketsArray,
                create_ticket_errors: {},
                create_ticket_request: action
            };

        }
        case TICKETS_CREATE_FAILURE: {
            return {
                ...state,
                create_ticket_loading: false,
                create_ticket_errors: action.errors
            };
        }

        /** end::Create support center redux */

        /** begin::Update support center redux */
        case UPDATE_TICKETS_REQUEST: {
            return {
                ...state,
                update_ticket_loading: true,
                update_ticket_errors: {}
            };
        }
        case UPDATE_TICKETS_SUCCESS: {
            let ticketsArray = [...state.tickets];
            const ticketIndex = ticketsArray.findIndex(ticket => ticket._id === action.data._id);
            if (ticketIndex !== -1) {
                ticketsArray[ticketIndex] = action.data;
            }

            return {
                ...state,
                tickets: ticketsArray,
                update_ticket_loading: false,
                update_ticket: action.data,
                update_ticket_errors: {}
            };
        }
        case UPDATE_TICKETS_FAILURE: {
            return {
                ...state,
                update_ticket_loading: false,
                update_ticket_errors: action.errors
            };
        }
        /** end::Update Support Center redux */

        /** begin::ticket details redux */
        case TICKETS_DETAILS_REQUEST: {
            return {
                ...state,
                ticket_details_loading: true,
                ticket_details_errors: {}
            };
        }
        case TICKETS_DETAILS_SUCCESS: {
            return {
                ...state,
                ticket_details_loading: false,
                ticket_details: action.data,
                ticket_details_errors: {}
            };
        }
        case TICKETS_DETAILS_FAILURE: {
            return {
                ...state,
                ticket_details_loading: false,
                ticket_details_errors: action.errors
            };
        }
        /** end::ticket details redux */

        /** begin::Change ticket status redux */
        case CHANGE_TICKETS_STATUS_REQUEST: {
            return {
                ...state,
                change_ticket_status_loading: true,
                create_ticket_errors: {}
            };
        }
        case CHANGE_TICKETS_STATUS_SUCCESS: {
            let ticketArray = [...state.tickets];
            let ticketDetails = state.ticket_details

            const ticketIndex = ticketArray.findIndex(ticket => ticket._id === action.data._id);
            if (ticketIndex !== -1) {
                ticketArray[ticketIndex].status = action.data.status;
            }

            if (ticketDetails && ticketDetails._id === action.data._id) {
                ticketDetails.status = action.data.status;
            }
            return {
                ...state,
                change_ticket_status_loading: false,
                change_ticket_status_errors: {},
                tickets: ticketArray,
                ticket_details: ticketDetails
            };
        }
        case CHANGE_TICKETS_STATUS_FAILURE: {
            return {
                ...state,
                change_ticket_status_loading: false,
                change_ticket_status_errors: action.errors
            };
        }
        /** end::Change ticket status redux */

        /** begin::Add reply redux */
        case ADD_REPLY_REQUEST: {
            return {
                ...state,
                add_reply_loading: true,
                add_reply_errors: {}
            };
        }
        case ADD_REPLY_SUCCESS: {
            let ticketArray = state.ticket_details;

            let replies = ticketArray.replies;
            replies = replies.concat(action.data);
            ticketArray.replies = replies

            return {
                ...state,
                add_reply_loading: false,
                add_reply_errors: {},
                ticket_details: ticketArray,

            };
        }
        case ADD_REPLY_FAILURE: {
            return {
                ...state,
                add_reply_loading: false,
                add_reply_errors: action.errors
            };
        }
        /** end::Add reply redux */
        default:
            return state;
    }
}