import React from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Stack } from '@mui/material';
import { Card, CardContent, CardHeader } from '../../../../_components/card'
import { Calendar, GeneralButton, RouteLink } from '../../../../_components';
import { SchedulePostAction } from '../../../../redux/actions';
import { Add } from '@mui/icons-material';
import { AbilityCan } from '../../../../_helpers/permission/AbilityCan';
import { Fragment } from 'react';
import { postAuthRoutes } from '../../../../routes';

function SchedulePost() {

	/** Initialize plugins and variables */
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { schedule_post_calendar } = useSelector((state) => state.SchedulePostReducer);
	const { user } = useSelector((state) => state.UserReducer);

	const getSchedulePostCalendar = (params) => dispatch(SchedulePostAction.getSchedulePostCalendar(params));

	const handleViewRender = async ({ start, end }) => {
		await getSchedulePostCalendar({
			start: new Date(start).toISOString(),
			end: new Date(end).toISOString()
		});
	};

	function handleClick(e) {

		let id = e.event.extendedProps._id;

		const pathname = postAuthRoutes('schedule_post_details').path;
        navigate({ pathname, search: createSearchParams(`?spId=${id}`).toString() });
	}

	return (
		<Fragment>
			<Box>
				<CardHeader
					title={postAuthRoutes('schedule_post').name}
					action={
						<Stack spacing={1} direction="row" alignItems={`center`}>
							<AbilityCan I="brand_schedule_post_create" passThrough={user.role_slug === 'developer' ? true : false}>
								<RouteLink to={postAuthRoutes('create_schedule_post').path} label={<GeneralButton startIcon={<Add />} label={postAuthRoutes('create_schedule_post').name} />} />
							</AbilityCan>
						</Stack>
					}
				/>
			</Box>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Card>
						<CardContent>
							<Calendar events={schedule_post_calendar} handleViewRender={handleViewRender} handleClick={handleClick} />
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Fragment>
	);
}

export { SchedulePost }