import { apiServices } from '../../_helpers';


export const SearchServices = {

    /**
     * Service to get login activity list
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    search: (params) => {
        return apiServices.post('/brand/coupon/search', params)
            .then((response) => { return response; })
    },

    /**
     * Service to get login activity list
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    redeemCoupon: (params) => {
        return apiServices.post('/brand/coupon/redeem/promo', params)
            .then((response) => { return response; })
    },

    /**
     * Service to get login activity list
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    giveawayRedeemCoupon: (params) => {
        return apiServices.post('/brand/coupon/redeem/giveaway', params)
            .then((response) => { return response; })
    }

};
