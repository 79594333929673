import { Ability, AbilityBuilder } from '@casl/ability';
import { store } from '../../redux/store';

const ability = new Ability([], { subjectName });

function subjectName(item) {
	return 'all';
}

store.subscribe(() => {
	let userReducer = store.getState().UserReducer;
	UserAbility(userReducer.user_permission);
});

const UserAbility = (user_permission) => {
	const permission = user_permission ? user_permission : [];
	ability.update(defineRulesFor(permission));
	return ability;
};

function defineRulesFor(permission) {
	const { can, rules } = new AbilityBuilder(Ability);

	for (var i = 0; i < permission.length; i++) {
		can(permission[i], 'all');
	}
	return rules;
}

export default ability;
