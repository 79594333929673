import React from 'react'
import { styled, CardHeader } from '@mui/material';

const StyledPageHeader = styled(({ ...props }) => (<CardHeader {...props} />))`
        padding: ${props => props.theme.spacing(1)};
        margin-bottom: ${props => props.theme.spacing(3)};
        padding-left:0;
        & .MuiTypography-root {
            ${'' /* font-weight: 600; */}
            position: relative;
            display: flex;
            align-items: center;
        }
`;

export { StyledPageHeader }