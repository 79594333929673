import {
    INFLUENCER_COUNT_REQUEST, INFLUENCER_COUNT_SUCCESS, INFLUENCER_COUNT_FAILURE,
    INFLUENCER_REQUEST, INFLUENCER_SUCCESS, INFLUENCER_FAILURE,
    INFLUENCER_DETAILS_REQUEST, INFLUENCER_DETAILS_SUCCESS, INFLUENCER_DETAILS_FAILURE,
    EXPORT_INFLUENCER_REQUEST, EXPORT_INFLUENCER_SUCCESS, EXPORT_INFLUENCER_FAILURE,
    DELETE_INFLUENCER_PROFILE_REQUEST, DELETE_INFLUENCER_PROFILE_SUCCESS, DELETE_INFLUENCER_PROFILE_FAILURE,
} from "../actions";

const initialState = {
    influencer_count_loading: false, influencer_count: { count: 0, percent: 0 }, influencer_count_errors: {},
    influencers_loading: false, influencers: [], influencers_errors: {},
    influencer_details_loading: false, influencer_details: {}, influencer_details_errors: {},
    export_influencer_loading: false, export_influencer: [], export_influencer_errors: {}, export_headers: [],
    delete_influencer_profile_loading: false, delete_influencer_profile_errors: {},
};

export function InfluencerReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::influencer count redux */
        case INFLUENCER_COUNT_REQUEST: {
            return {
                ...state,
                influencer_count_loading: true,
                influencer_count_errors: {}
            };
        }
        case INFLUENCER_COUNT_SUCCESS: {
            return {
                ...state,
                influencer_count_loading: false,
                influencer_count: action.data,
                influencer_count_errors: {}
            };
        }
        case INFLUENCER_COUNT_FAILURE: {
            return {
                ...state,
                influencer_count_loading: false,
                influencer_count_errors: action.errors
            };
        }
        /** end::influencer count redux */

        case INFLUENCER_REQUEST: {
            return {
                ...state,
                influencers_loading: true,
                influencers_errors: {}
            };
        }
        case INFLUENCER_SUCCESS: {

            let newArray = [...state.influencers]; //making a new array
            if (action.data.page === 1) {
                newArray = []
            }
            action.data.influencers.forEach((payload) => {
                const influencerIndex = newArray.findIndex(influencer => influencer._id === payload._id);
                if (influencerIndex !== -1) {
                    newArray[influencerIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                influencers_loading: false,
                influencers: newArray,
                influencers_errors: {}
            };

        }
        case INFLUENCER_FAILURE: {
            return {
                ...state,
                influencers_loading: false,
                influencers_errors: action.errors,
                influencers: []
            };
        }

        /** end::List influencer coupon codes redux */

        /** begin::influencer details redux */
        case INFLUENCER_DETAILS_REQUEST: {
            return {
                ...state,
                influencer_details_loading: true,
                influencer_details_errors: {}
            };
        }
        case INFLUENCER_DETAILS_SUCCESS: {
            return {
                ...state,
                influencer_details_loading: false,
                influencer_details: action.data,
                influencer_details_errors: {}
            };
        }
        case INFLUENCER_DETAILS_FAILURE: {
            return {
                ...state,
                influencer_details_loading: false,
                influencer_details_errors: action.errors
            };
        }
        /** end::influencer details redux */

        /** begin::Export influencers redux*/
        case EXPORT_INFLUENCER_REQUEST: {
            return {
                ...state,
                export_influencer_loading: true,
                export_influencer_errors: {}
            };
        }
        case EXPORT_INFLUENCER_SUCCESS: {

            return {
                ...state,
                export_influencer_loading: false,
                export_influencer: action.data.influencers,
                export_headers: action.data.headers,
                export_influencer_errors: {}
            };

        }
        case EXPORT_INFLUENCER_FAILURE: {
            return {
                ...state,
                export_influencer_loading: false,
                export_influencer: [],
                export_influencer_errors: action.errors,
                export_headers: action.data.headers,
            };
        }
        /** end::Export story redux*/

        /** begin::delete influencer redux */
        case DELETE_INFLUENCER_PROFILE_REQUEST: {
            return {
                ...state,
                delete_influencer_profile_loading: true,
                delete_influencer_profile_errors: {}
            };
        }
        case DELETE_INFLUENCER_PROFILE_SUCCESS: {

            return {
                ...state,
                delete_influencer_profile_loading: false,
                delete_influencer_profile_errors: {}
            };
        }
        case DELETE_INFLUENCER_PROFILE_FAILURE: {
            return {
                ...state,
                delete_influencer_profile_loading: false,
                delete_influencer_profile_errors: action.errors
            };
        }
        /** end::delete influencer redux */

        default:
            return state;
    }
}