import {
	CREATE_ACCOUNT_REQUEST, CREATE_ACCOUNT_SUCCESS, CREATE_ACCOUNT_FAILURE,
	ADD_BILLING_ADDRESS_REQUEST, ADD_BILLING_ADDRESS_SUCCESS, ADD_BILLING_ADDRESS_FAILURE,
} from "../actions";

const initialState = {
	request: {}, errors: {}, insta_register_request: {}, insta_register_errors: {},
	add_billing_address_loading: false, add_billing_address_errors: {},
};

export function RegisterReducer(state = initialState, action) {
	switch (action.type) {


		/** begin::intagram Sign up redux */
		case CREATE_ACCOUNT_REQUEST: {
			return {
				CREATE_ACCOUNT_loading: true,
				insta_register_request: action.request,
			};
		}
		case CREATE_ACCOUNT_SUCCESS: {
			return {
				CREATE_ACCOUNT_loading: false,
			};
		}
		case CREATE_ACCOUNT_FAILURE: {
			return {
				CREATE_ACCOUNT_loading: false,
				insta_register_errors: action.errors
			};
		}
		/** end::intagram Sign up redux */


		/** begin::Add billing address redux */
		case ADD_BILLING_ADDRESS_REQUEST: {
			return {
				...state,
				add_billing_address_loading: true,
				add_billing_address_errors: {}
			};
		}
		case ADD_BILLING_ADDRESS_SUCCESS: {
			return {
				...state,
				add_billing_address_loading: false,
				add_billing_address_errors: {},
			};
		}
		case ADD_BILLING_ADDRESS_FAILURE: {
			return {
				...state,
				add_billing_address_loading: false,
				add_billing_address_errors: action.errors
			};
		}
		/** end::Add billing address redux */

		default:
			return state;
	}
}
