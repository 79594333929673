import React, { useEffect } from "react";
import { CardHeader as PageHeader } from "../../../../../_components/card";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { NoData } from "../../../../../_components";
import { RestaurantOrdersCard, RestaurantOrdersCardLoader } from "../restaurant-orders-card";
import { Fragment } from "react";
import { RestaurantOrdersAction } from "../../../../../redux/actions";
import { postAuthRoutes } from "../../../../../routes";

function RestaurantOrdersDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const orderId = params.get('oId'); // plan promo from url

    /** Redux actions and state */
    const { restaurant_order_details_loading: isLoading, restaurant_order_details: order_details } = useSelector((state) => state.RestaurantOrdersReducer);
    const restaurantOrderDetails = (params) => dispatch(RestaurantOrdersAction.restaurantOrderDetails(params));


    useEffect(() => {
        restaurantOrderDetails({ order_id: orderId });

        //eslint-disable-next-line
    }, [orderId])



    return (
        <Fragment>
            {!isLoading && Object.keys(order_details).length === 0 && <NoData content1={`No`} content2={`Restaurant order available`} minHeight={`55vh`} ></NoData>}
            {(!isLoading && Object.keys(order_details).length > 0) &&
                <Fragment>
                    <PageHeader title={postAuthRoutes('restaurant_order_details').name} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <RestaurantOrdersCard restaurant_order={order_details} height="80vh" hasAction={{ status: true, cooking_status: true, ready_to_deliver: true, details: false, generate_bill: true }} />
                        </Grid>
                    </Grid>

                </Fragment>}
            {isLoading && <RestaurantOrdersCardLoader count={1} />}

        </Fragment>
    );
}

export { RestaurantOrdersDetails }