import { GlobalAction } from ".";
import { TicketServices } from "../services";

export const TICKETS_REQUEST = 'TICKETS_REQUEST';
export const TICKETS_SUCCESS = 'TICKETS_SUCCESS';
export const TICKETS_FAILURE = 'TICKETS_FAILURE';

export const TICKETS_CREATE_REQUEST = 'TICKETS_CREATE_REQUEST';
export const TICKETS_CREATE_SUCCESS = 'TICKETS_CREATE_SUCCESS';
export const TICKETS_CREATE_FAILURE = 'TICKETS_CREATE_FAILURE';

export const UPDATE_TICKETS_REQUEST = 'UPDATE_TICKETS_REQUEST';
export const UPDATE_TICKETS_SUCCESS = 'UPDATE_TICKETS_SUCCESS';
export const UPDATE_TICKETS_FAILURE = 'UPDATE_TICKETS_FAILURE';

export const TICKETS_DETAILS_REQUEST = 'TICKETS_DETAILS_REQUEST';
export const TICKETS_DETAILS_SUCCESS = 'TICKETS_DETAILS_SUCCESS';
export const TICKETS_DETAILS_FAILURE = 'TICKETS_DETAILS_FAILURE';

export const CHANGE_TICKETS_STATUS_REQUEST = 'CHANGE_TICKETS_STATUS_REQUEST';
export const CHANGE_TICKETS_STATUS_SUCCESS = 'CHANGE_TICKETS_STATUS_SUCCESS';
export const CHANGE_TICKETS_STATUS_FAILURE = 'CHANGE_TICKETS_STATUS_FAILURE';

export const ADD_REPLY_REQUEST = 'ADD_REPLY_REQUEST';
export const ADD_REPLY_SUCCESS = 'ADD_REPLY_SUCCESS';
export const ADD_REPLY_FAILURE = 'ADD_REPLY_FAILURE';

export const TicketAction = {

    /**
     * Action for list Support center
     * @param {Object} params - The params which are used for the api
     * @author 
     * @created_at 30 May 2022
     */
    listTickets: (params) => {
        return dispatch => {
            dispatch(request(params));
            return TicketServices.listTickets(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: TICKETS_REQUEST, request: request } }
        function success(request, response) { return { type: TICKETS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: TICKETS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for Creating Support Center
     * @param {Object} params - The params which are used for the api
     * @author 
     * @created_at 30 May 2022
     */
    createTicket: (params) => {
        return dispatch => {
            dispatch(request(params));
            return TicketServices.createTicket(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        }

        function request(request) { return { type: TICKETS_CREATE_REQUEST, request: request } }
        function success(request, response) { return { type: TICKETS_CREATE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: TICKETS_CREATE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action for update Support Center
     * @param {Object} params - The params which are used for the api
     * @author 
     * @created_at 30 May 2022
    */
    UpdateTicket: (params) => {
        return dispatch => {
            dispatch(request(params));
            return TicketServices.updateTicket(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_TICKETS_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_TICKETS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_TICKETS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get ticket details
     * @param {Object} params - The params which are used for the api
     * @author 
     * @created_at 30 May 2022
    */
    ticketDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return TicketServices.ticketDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: TICKETS_DETAILS_REQUEST, request: request } }
        function success(request, response) { return { type: TICKETS_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: TICKETS_DETAILS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for change ticket status
     * @param {Object} params - The params which are used for the api
     * @author 
     * @created_at 30 May 2022
     */
    changeStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return TicketServices.changeStatus(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_TICKETS_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_TICKETS_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_TICKETS_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to add ticket reply
     * @param {Object} params - The params which are used for the api
     * @author 
     * @created_at 30 May 2022
    */
    addReply: (params) => {
        return dispatch => {
            dispatch(request(params));
            return TicketServices.addReply(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ADD_REPLY_REQUEST, request: request } }
        function success(request, response) { return { type: ADD_REPLY_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ADD_REPLY_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

}