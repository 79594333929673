import { apiServices } from '../../_helpers';


export const WalletServices = {

    /**
     * Service to create wallet
     * @param {Object} params - The params which are used for change password api
     * @author Jasin N
     * @created_at 30 May 2022
     */
    createBrandWallet: (params) => {
        return apiServices.post('/brand/wallet/add/success', params)
            .then((response) => { return response; })

    },

        
    /**
     * Service for wallet checkout session
     *
     * @author Jasin 
     */
    createWalletCheckoutSession: (params) => {
        return apiServices.post('/brand/wallet/add/payment', params)
        .then((response) => { return response; })
    },

    /**
     * Service for wallet history
     *
     * @author Jasin 
     */
    listWalletHistory: (params) => {
        return apiServices.post('/brand/wallet/list', params)
        .then((response) => { return response; })
    },

    /**
     * Service for wallet history
     *
     * @author Jasin 
     */
    getWalletChart: (params) => {
        return apiServices.post('/brand/wallet/chart', params)
        .then((response) => { return response; })
    },

    /**
     * Service for wallet balance
     *
     * @author Jasin 
     */
    getWalletBalance: (params) => {
        return apiServices.post('/brand/wallet/balance', params)
        .then((response) => { return response; })
    },

    /**
     * Service for wallet balance
     *
     * @author Jasin 
     */
    getWalletTotalCredit: (params) => {
        return apiServices.post('/brand/wallet/total-credit', params)
        .then((response) => { return response; })
    },

    /**
     * Service for wallet statements
     *
     * @author Jasin 
     */
    getWalletStatements: (params) => {
        return apiServices.post('/brand/wallet/statements', params)
        .then((response) => { return response; })
    },

};
