import { SearchServices } from "../services";

export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';

export const REDEEM_COUPON_REQUEST = 'REDEEM_COUPON_REQUEST';
export const REDEEM_COUPON_SUCCESS = 'REDEEM_COUPON_SUCCESS';
export const REDEEM_COUPON_FAILURE = 'REDEEM_COUPON_FAILURE';

export const GIVEAWAY_REDEEM_COUPON_REQUEST = 'GIVEAWAY_REDEEM_COUPON_REQUEST';
export const GIVEAWAY_REDEEM_COUPON_SUCCESS = 'GIVEAWAY_REDEEM_COUPON_SUCCESS';
export const GIVEAWAY_REDEEM_COUPON_FAILURE = 'GIVEAWAY_REDEEM_COUPON_FAILURE';

export const SearchAction = {

    /**
    * Action for search
    * @param {Object} params - The params which are used for registration api.
    * @author  
    * @created_at 30 May 2022
    */
    search: (params) => {
        return dispatch => {
            dispatch(request(params));
            return SearchServices.search(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: SEARCH_REQUEST, request: request } }
        function success(request, response) { return { type: SEARCH_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: SEARCH_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
    * Action for redeem coupon
    * @param {Object} params - The params which are used for registration api.
    * @author  
    * @created_at 30 May 2022
    */
    redeemCoupon: (params) => {
        return dispatch => {
            dispatch(request(params));
            return SearchServices.redeemCoupon(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: REDEEM_COUPON_REQUEST, request: request } }
        function success(request, response) { return { type: REDEEM_COUPON_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: REDEEM_COUPON_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
    * Action for giveaway redeem coupon
    * @param {Object} params - The params which are used for registration api.
    * @author  
    * @created_at 30 May 2022
    */
    giveawayRedeemCoupon: (params) => {
        return dispatch => {
            dispatch(request(params));
            return SearchServices.giveawayRedeemCoupon(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GIVEAWAY_REDEEM_COUPON_REQUEST, request: request } }
        function success(request, response) { return { type: GIVEAWAY_REDEEM_COUPON_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GIVEAWAY_REDEEM_COUPON_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },
};





