import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { postAuthRoutes } from '../../../../../routes';
import { handleInputChange, validateForm, APP_NAME } from '../../../../../_helpers';
import { CancelButton, SubmitButton } from '../../../../../_components/controls';
import { FormBrowseFile, FormInput, FormRadio, FormSwitch, FormFreeSoloAutocomplete, FormSelect, FormTimePicker } from '../../../../../_components/form';
import { Card, CardContent, CardHeader } from '../../../../../_components/card';
import { CurrencySymbol } from '../../../../../_components';
import { Grid, IconButton, Stack, Box, InputAdornment } from '@mui/material';
import { validate } from './validate';
import { AddCircleRounded, DeleteOutline, Percent } from '@mui/icons-material';
import { Fragment } from 'react';
import { RestaurantMenuAction } from '../../../../../redux/actions';

const inputs = { restaurant_menu_id: '', item_type: '', title: '', description: '', category: '', image: '', is_non: 'false', price: '', available_time_start: '', available_time_end: '', have_portions: false, has_add_ons: false, add_ons: [{ add_on_id: '', name: '', price: '', is_non: '' }], discount: '', portions: [{ portion: '', price: '', slug: '', discount: '' }], };
const IsNon = [{ value: 'false', label: 'No' }, { value: 'true', label: 'Yes' }];
const addAddOns = [{ value: 'false', label: 'No' }, { value: 'true', label: 'Yes' }];
const itemTypes = [{ value: 'main', label: 'Main' }, { value: 'add-ons', label: 'Add-ons' }];
const portionCategories = [{ value: 'quarter_portion', label: 'Quarter Portion' }, { value: 'half_portion', label: 'Half Portion' }, { value: 'full_portion', label: 'Full Portion' }];

function CreateRestaurantMenu() {

	/** Initialize plugins and variables */
	const dispatch = useDispatch();
	const navigate = useNavigate();

	/** Plan id and details on update page */
	const location = useLocation();
	const { restaurantMenu } = location.state ?? {};

	/** Redux actions and state */
	const createRestaurantMenu = (params) => dispatch(RestaurantMenuAction.createRestaurantMenu(params));
	const updateRestaurantMenu = (params) => dispatch(RestaurantMenuAction.updateRestaurantMenu(params));
	const itemTypeSelectList = (params) => dispatch(RestaurantMenuAction.itemTypeSelectList(params));
	const categorySelectList = (params) => dispatch(RestaurantMenuAction.categorySelectList(params));
	const addOnSelectList = (params) => dispatch(RestaurantMenuAction.addOnSelectList(params));
	const { create_restaurant_menu_loading: isLoading, create_restaurant_menu_errors: createRestaurantMenuErrors, restaurant_item_type_list, restaurant_item_type_list_loading: isItemTypeLoading, create_restaurant_menu_requests } = useSelector((state) => state.RestaurantMenuReducer);
	const { restaurant_menu_select_list_loading: isRestaurantMenuSelectListLoading, restaurant_menu_select_list, add_on_select_list_loading: isAddOnSelectLoading, add_on_select_list } = useSelector((state) => state.RestaurantMenuReducer);

	/** Initialize and declare state */
	const [data, setData] = useState({ ...inputs });
	const [errors, setErrors] = useState({ ...inputs });
	const [action, setAction] = useState({ isSubmitted: false });
	const [response, setResponse] = useState({});
	const [isMenuLoading, setIsMenuLoading] = useState(true);
	const [addOns, setAddOns] = useState([{ name: '', price: '', is_non: '' }]);
	const [portions, setPortions] = useState([{ portion: '', price: '', slug: '', discount: '' }]);

	useEffect(() => {
		if (restaurantMenu && restaurantMenu._id) {
			setData({
				restaurant_menu_id: restaurantMenu._id,
				title: restaurantMenu.title,
				category: restaurantMenu.category,
				description: restaurantMenu.description,
				is_non: restaurantMenu.is_non,
				price: restaurantMenu.price,
				available_time_start: restaurantMenu.available_time_start ? restaurantMenu.available_time_start : '',
				available_time_end: restaurantMenu.available_time_end ? restaurantMenu.available_time_end : '',
				image: restaurantMenu.image,
				have_portions: (typeof restaurantMenu.have_portions === 'undefined') ? true : restaurantMenu.have_portions,
				has_add_ons: restaurantMenu.has_add_ons ? 'true' : 'false',
				add_ons: (restaurantMenu.add_ons && restaurantMenu.add_ons.length > 0) ? restaurantMenu.add_ons : [{ add_on_id: '', name: '', price: '', is_non: '' }],
				portions: (restaurantMenu.portions && restaurantMenu.portions.length > 0) ? restaurantMenu.portions : [{ portion: '', price: '', slug: '', discount: '' }],
				discount: restaurantMenu.discount ? restaurantMenu.discount : '',
				item_type: restaurantMenu.item_type,
			});
			setAddOns(restaurantMenu.add_ons && restaurantMenu.add_ons.length > 0 ? restaurantMenu.add_ons : [{ add_on_id: '', name: '', price: '', is_non: '' }]);
			setPortions((restaurantMenu.portions && restaurantMenu.portions.length > 0) ? restaurantMenu.portions : [{ portion: '', price: '', slug: '', discount: '' }]);
			setIsMenuLoading(false);
		} else {
			setIsMenuLoading(false);
		}
	}, []);


	useEffect(() => {
		itemTypeSelectList();
		categorySelectList();
		addOnSelectList();
	}, []);

	useEffect(() => {
		setErrors({ ...createRestaurantMenuErrors });
	}, [createRestaurantMenuErrors]);

	/**
	 * function to handle input changes and modify the value
	 * @param {string} null
	 * @author Naveen K
	 * @created_at 10 Oct 2023
	 */
	function handleChange(e) {
		const { name, value } = handleInputChange(e);
		setErrors({ ...errors, ...validate(name, value, data) });
		data[name] = value;
		setData({ ...data });
	}

	const handleAddOnAdd = () => {
		const formErrors = {};
		const formErrorsModified = {};
		const inputError = validate('add_ons', data['add_ons'], data);

		formErrors['add_ons'] = inputError['add_ons'];
		formErrorsModified['add_ons'] = inputError['add_ons'].toString();
		let valid = validateForm(formErrorsModified);

		if (!valid) {
			setErrors({ ...errors, add_ons: formErrors.add_ons });
			setAction({ isSubmitted: true });
			return;
		}

		setAddOns([...addOns, { add_on_id: '', name: '', price: '', is_non: '' }]);
		data['add_ons'] = [...addOns, { add_on_id: '', name: '', price: '', is_non: '' }];
		setData({ ...data });
	};

	const handleAddOnChange = (index, field, value) => {
		const updatedAddOns = [...addOns];
		updatedAddOns[index][field] = value;
		setAddOns(updatedAddOns);
		const targetAddOn = add_on_select_list.find(obj => obj.value === value);
		updatedAddOns[index]['price'] = value ? targetAddOn.price : '';
		updatedAddOns[index]['is_non'] = value ? targetAddOn.is_non : '';
		updatedAddOns[index]['name'] = value ? targetAddOn.label : '';
		setErrors({ ...errors, ...validate('add_ons', updatedAddOns, data) });
		data['add_ons'] = updatedAddOns;
		setData({ ...data });
	};

	const handleAddOnRemove = (index) => {
		if (addOns.length === 1) {
			setAddOns([{ add_on_id: '', name: '', price: '', is_non: '' }]);
			data['add_ons'] = [{ add_on_id: '', name: '', price: '', is_non: '' }];
			setData({ ...data });
		} else {
			const updatedAddOns = [...addOns];
			updatedAddOns.splice(index, 1);
			setAddOns(updatedAddOns);
			data['add_ons'] = updatedAddOns;
			setData({ ...data });
		}
	};

	const handlePortionAdd = () => {
		const formErrors = {};
		const formErrorsModified = {};
		const inputError = validate('portions', data['portions'], data);

		formErrors['portions'] = inputError['portions'];
		formErrorsModified['portions'] = inputError['portions'].toString();
		let valid = validateForm(formErrorsModified);

		if (!valid) {
			setErrors({ ...errors, portions: formErrors.portions });
			setAction({ isSubmitted: true });
			return;
		}

		setPortions([...portions, { portion: '', price: '', slug: '', discount: '' }]);
		data['portions'] = [...portions, { portion: '', price: '', slug: '', discount: '' }];
		setData({ ...data });
	};

	const handlePortionsChange = (index, field, value) => {
		const updatedPortions = [...portions];
		updatedPortions[index][field] = value;
		setPortions(updatedPortions);
		setErrors({ ...errors, ...validate('portions', updatedPortions, data) });
		data['portions'] = updatedPortions;
		setData({ ...data });
	};

	const handlePortionRemove = (index) => {
		if (portions.length === 1) {
			setPortions([{ portion: '', price: '', slug: '', discount: '' }]);
			data['portions'] = [{ portion: '', price: '', slug: '', discount: '' }];
			setData({ ...data });
		} else {
			const updatedPortions = [...portions];
			updatedPortions.splice(index, 1);
			setPortions(updatedPortions);
			data['portions'] = updatedPortions;
			setData({ ...data });
		}
	};

	/**
	 * function to handle submit entered values
	 * @param {object} e form object
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	function handleSubmit(e) {
		e.preventDefault();
		const formData = new FormData();
		setAction({ isSubmitted: true });

		const formErrors = {};
		const formErrorsModified = {};

		for (const [name, value] of Object.entries(data)) {
			const inputError = validate(name, value, data);
			formErrors[name] = inputError[name];
			formErrorsModified[name] = (name === 'add_ons' || name === 'portions') ? inputError[name].toString() : inputError[name];
		}
		let valid = validateForm(formErrorsModified);
		if (!valid) {
			setErrors(formErrors);
			setAction({ isSubmitted: true });
			return;
		}
		formData.append('restaurant_menu_id', data.restaurant_menu_id);
		formData.append('description', data.description);
		formData.append('category', typeof data.category === 'object' && data.category !== null ? data.category.name : data.category);
		formData.append('title', data.title);
		formData.append('price', data.price ?? 0);
		formData.append('discount', data.discount ?? 0);
		formData.append('is_non', data.is_non);
		formData.append('available_time_start', data.available_time_start);
		formData.append('available_time_end', data.available_time_end);
		formData.append('menu_image', data.image);
		formData.append('have_portions', data.have_portions);
		formData.append('has_add_ons', data.has_add_ons);
		formData.append('item_type', data.item_type);
		data.add_ons.forEach(item => { formData.append(`add_ons[]`, JSON.stringify(item)); });
		data.portions.forEach(item => { formData.append(`portions[]`, JSON.stringify(item)); });
		addRestaurantMenu(formData);
	}

	/**
	 * function to create/update restaurant menu
	 * @param {object} formData form object
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	async function addRestaurantMenu(formData) {
		const isCreated = restaurantMenu && restaurantMenu._id ? await updateRestaurantMenu(formData) : await createRestaurantMenu(formData);
		setResponse(isCreated);
	}

	useEffect(() => {
		if (response.status === 1)
			navigate(postAuthRoutes('restaurant_menu').path);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [response]);


	/**
	 * Function to cancel create/update restaurant menu
	 * @param {object} formData form object
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	function cancelMenuCreate() {
		let redirectPath = postAuthRoutes('restaurant_menu').path;
		navigate(redirectPath);
	}

	return (
		<Fragment>
			<Helmet>
				<title>{`${APP_NAME} | ${restaurantMenu && restaurantMenu._id ? postAuthRoutes('update_restaurant_menu').name : postAuthRoutes('create_restaurant_menu').name}`}</title>
			</Helmet>
			<Box>
				<CardHeader title={restaurantMenu && restaurantMenu._id ? postAuthRoutes('update_restaurant_menu').name : postAuthRoutes('create_restaurant_menu').name} />
				<form onSubmit={handleSubmit} noValidate>
					<Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
						<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
							<Card>
								{!isMenuLoading && !isRestaurantMenuSelectListLoading && (
									<CardContent>
										<Grid container spacing={3} display={'flex'} alignItems={'flex-end'}>
											<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
												<FormSelect tabIndex={1} label={`Item Type`} name={`item_type`} placeholder={`Select item type`} onChange={handleChange} data={itemTypes} value={data.item_type} error={action.isSubmitted && (errors.item_type ? errors.item_type : ' ')}/>
											</Grid>
											<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
												<FormFreeSoloAutocomplete name="category" label="Category" value={data.category} onChange={handleChange} type="text" maxLength={255} tabIndex={3} error={action.isSubmitted && (errors.category ? errors.category : ' ')} inputOptions={restaurant_menu_select_list} />
											</Grid>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<FormInput tabIndex={2} label="Title" name="title" value={data.title} error={action.isSubmitted && errors.title ? errors.title : ''} onChange={handleChange} />
											</Grid>

											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<FormInput tabIndex={4} label="Description" name="description" value={data.description} error={action.isSubmitted && errors.description ? errors.description : ''} onChange={handleChange} multiline maxLength={250} minRows={3} />
											</Grid>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<FormBrowseFile tabIndex={10} name="image" placeholder="menu image" label="Menu image" value={data.image} error={action.isSubmitted && errors.image ? errors.image : ''} onChange={handleChange} acceptType=".png,.jpg,.jpeg" info="Allowed Format: png, jpg, jpeg | Allowed Maximum Size: 2 MB | Suggested Aspect Ratio: 1080 x 566" />
											</Grid>
											<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
												<FormRadio tabIndex={3} label={`Non Veg`} name={`is_non`} placeholder={`Select Veg or Non-Veg`} onChange={handleChange} data={IsNon} error={action.isSubmitted && errors.is_non ? errors.is_non : ''} className="col-md-6" value={data.is_non} />
											</Grid>
											<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
												<FormSwitch name="have_portions" value={data.have_portions} label="Does the item have portions?" error={action.isSubmitted && errors.have_portions ? errors.have_portions : ''} onChange={handleChange} />
											</Grid>
											{(!data.have_portions) &&
												<Fragment>
												<Grid container item xs={12} sm={12} md={12} lg={12} xl={12} spacing={3} display={'flex'} alignItems={'flex-end'}>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<FormInput tabIndex={2} label={<> Price (<CurrencySymbol />) </>} name="price" placeholder="Price" value={data.price} error={action.isSubmitted && errors.price ? errors.price : ''} onChange={handleChange} maskType={`digit-with-one-dot`} />
													</Grid>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<FormInput tabIndex={2} label="Discount (%)" name="discount" value={data.discount} error={action.isSubmitted && errors.discount ? errors.discount : ''} onChange={handleChange} maskType={`digit-with-one-dot`} endAdornment={<InputAdornment position="end"><Percent /></InputAdornment>} />
													</Grid>
												</Grid>
												</Fragment>
											}
											{(data.have_portions && !isItemTypeLoading) &&
												<Grid container item xs={12} sm={12} md={12} lg={12} xl={12} spacing={3}>
													<Grid item container xs={12} sm={12} md={12} lg={12} xl={12} spacing={3} display={'flex'} alignItems={'flex-end'}>
														{portions.map((portion, index) => (
															<Grid item container xs={12} sm={12} md={11} lg={11} xl={11} spacing={3} key={index} display={'flex'} alignItems={'flex-end'}>

																<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
																	<FormSelect tabIndex={1} label={`Portion Category`} name={`slug`} placeholder={`Select portion category`} onChange={(e) => handlePortionsChange(index, 'slug', e.target.value)} data={portionCategories} value={portion.slug} error={action.isSubmitted && errors.portions && errors.portions[index] && errors.portions[index].slug ? errors.portions[index].slug : ''} />
																</Grid>
																<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
																	<FormInput
																		tabIndex={2}
																		label="Portion"
																		name="portion"
																		value={portion.portion}
																		error={action.isSubmitted && errors.portions && errors.portions[index] && errors.portions[index].portion ? errors.portions[index].portion : ''}
																		onChange={(e) => handlePortionsChange(index, 'portion', e.target.value)}
																	/>
																</Grid>
																<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
																	<FormInput
																		tabIndex={2}
																		label={<> Portion price (<CurrencySymbol />) </>}
																		name="portion_price"
																		maskType={`digit-with-one-dot`}
																		placeholder="Portion Price"
																		value={portion.price}
																		error={action.isSubmitted && errors.portions && errors.portions[index] && errors.portions[index].price ? errors.portions[index].price : ''}
																		onChange={(e) => handlePortionsChange(index, 'price', e.target.value)}
																	/>
																</Grid>
																<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
																	<FormInput tabIndex={2} label="Discount (%)" name="discount" value={portion.discount} error={action.isSubmitted && errors.portions && errors.portions[index] && errors.portions[index].discount ? errors.portions[index].discount : ''} onChange={(e) => handlePortionsChange(index, 'discount', e.target.value)} maskType={`digit-with-one-dot`} />
																</Grid>
																<Grid item xs={12} sm={12} md={1} lg={1} xl={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
																	<IconButton
																		aria-label="Delete"
																		onClick={() => handlePortionRemove(index)}
																	>
																		<DeleteOutline />
																	</IconButton>
																</Grid>
															</Grid>
														))}
														<Grid item xs={12} sm={12} md={1} lg={1} xl={1} container>
															<IconButton aria-label="Add" onClick={handlePortionAdd} > <AddCircleRounded /> </IconButton>
														</Grid>
													</Grid>
												</Grid>
											}
											<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
												<FormTimePicker tabIndex={3} label="Item Available from" name="available_time_start" value={data.available_time_start} placeholder="dd/mm/yyyy" error={action.isSubmitted && errors.available_time_start ? errors.available_time_start : ''} onChange={handleChange} minDate={new Date(data.start_date) < new Date() ? data.start_date : new Date()} />
											</Grid>
											<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
												<FormTimePicker tabIndex={3} label="Item Available till" name="available_time_end" value={data.available_time_end} placeholder="dd/mm/yyyy" error={action.isSubmitted && errors.available_time_end ? errors.available_time_end : ''} onChange={handleChange} minDate={new Date(data.start_date) < new Date() ? data.start_date : new Date()} />
											</Grid>

											{data.item_type === 'main' && (
												<React.Fragment>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<FormRadio tabIndex={3} label={`Do you want to add add-ons?`} name={`has_add_ons`} onChange={handleChange} data={addAddOns} error={action.isSubmitted && errors.has_add_ons ? errors.has_add_ons : ''} className="col-md-6" value={data.has_add_ons} />
													</Grid>

													{data.has_add_ons === 'true' && !isAddOnSelectLoading && (
														<Grid item container xs={12} sm={12} md={12} lg={12} xl={12} spacing={3} display={'flex'} alignItems={'flex-end'}>
															{addOns.map((addOn, index) => (
																<Grid item container xs={12} sm={12} md={11} lg={11} xl={11} spacing={3} key={index} display={'flex'} alignItems={'flex-end'}>
																	<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
																		<FormSelect tabIndex={2} label="Add-on name" name="add_on_name" value={addOn.add_on_id} error={action.isSubmitted && errors.add_ons && errors.add_ons[index] && errors.add_ons[index].add_on_id ? errors.add_ons[index].add_on_id : ''} onChange={(e) => handleAddOnChange(index, 'add_on_id', e.target.value)} data={add_on_select_list} />
																	</Grid>
																	<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
																		<FormInput tabIndex={2} label={<>Add-on price (<CurrencySymbol />)</>} name="add_on_price" maskType={`digit-with-one-dot`} value={addOn.price} error={action.isSubmitted && errors.add_ons && errors.add_ons[index] && errors.add_ons[index].price ? errors.add_ons[index].price : ''} onChange={(e) => handleAddOnChange(index, 'price', e.target.value)} readOnly={true} />
																	</Grid>
																	<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
																		<FormRadio tabIndex={3} label={`Non Veg`} name={`is_non`} placeholder={`Select Veg or Non-Veg`} data={IsNon} onChange={(e) => handleAddOnChange(index, 'is_non', e.target.value)} error={action.isSubmitted && errors.add_ons && errors.add_ons[index] && errors.add_ons[index].is_non ? errors.add_ons[index].is_non : ''} className="col-md-6" value={addOn.is_non} isReadOnly={true} />
																	</Grid>
																	<Grid item xs={12} sm={12} md={1} lg={1} xl={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
																		<IconButton aria-label="Delete" onClick={() => handleAddOnRemove(index)}>
																			<DeleteOutline />
																		</IconButton>
																	</Grid>
																</Grid>
															))}
															<Grid item xs={12} sm={12} md={1} lg={1} xl={1} container>
																<IconButton aria-label="Delete" onClick={handleAddOnAdd}> <AddCircleRounded /> </IconButton>
															</Grid>
														</Grid>
													)}
												</React.Fragment>
											)}

											<Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
												<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
													<SubmitButton label={restaurantMenu && restaurantMenu._id ? 'Update' : 'Create'} loading={isLoading} tabIndex={6} />
													<CancelButton onClick={cancelMenuCreate} tabIndex={12} />
												</Stack>
											</Grid>
										</Grid>
									</CardContent>
								)}
							</Card>
						</Grid>
					</Grid>
				</form>
			</Box>
		</Fragment>
	);
}

export { CreateRestaurantMenu };