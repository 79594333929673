import { GlobalAction } from ".";
import { RestaurantOrdersServices } from '../services';

export const RESTAURANT_ORDERS_REQUEST = 'RESTAURANT_ORDERS_REQUEST';
export const RESTAURANT_ORDERS_SUCCESS = 'RESTAURANT_ORDERS_SUCCESS';
export const RESTAURANT_ORDERS_FAILURE = 'RESTAURANT_ORDERS_FAILURE';

export const CHANGE_RESTAURANT_ORDER_STATUS_REQUEST = 'CHANGE_RESTAURANT_ORDER_STATUS_REQUEST';
export const CHANGE_RESTAURANT_ORDER_STATUS_SUCCESS = 'CHANGE_RESTAURANT_ORDER_STATUS_SUCCESS';
export const CHANGE_RESTAURANT_ORDER_STATUS_FAILURE = 'CHANGE_RESTAURANT_ORDER_STATUS_FAILURE';

export const CHANGE_RESTAURANT_PRE_ORDER_STATUS_REQUEST = 'CHANGE_RESTAURANT_PRE_ORDER_STATUS_REQUEST';
export const CHANGE_RESTAURANT_PRE_ORDER_STATUS_SUCCESS = 'CHANGE_RESTAURANT_PRE_ORDER_STATUS_SUCCESS';
export const CHANGE_RESTAURANT_PRE_ORDER_STATUS_FAILURE = 'CHANGE_RESTAURANT_PRE_ORDER_STATUS_FAILURE';

export const RESTAURANT_ORDER_DETAILS_REQUEST = 'RESTAURANT_ORDER_DETAILS_REQUEST';
export const RESTAURANT_ORDER_DETAILS_SUCCESS = 'RESTAURANT_ORDER_DETAILS_SUCCESS';
export const RESTAURANT_ORDER_DETAILS_FAILURE = 'RESTAURANT_ORDER_DETAILS_FAILURE';

export const CHANGE_RESTAURANT_ITEM_STATUS_REQUEST = 'CHANGE_RESTAURANT_ITEM_STATUS_REQUEST';
export const CHANGE_RESTAURANT_ITEM_STATUS_SUCCESS = 'CHANGE_RESTAURANT_ITEM_STATUS_SUCCESS';
export const CHANGE_RESTAURANT_ITEM_STATUS_FAILURE = 'CHANGE_RESTAURANT_ITEM_STATUS_FAILURE';

export const GENERATE_RESTAURANT_ORDER_BILL_REQUEST = 'GENERATE_RESTAURANT_ORDER_BILL_REQUEST';
export const GENERATE_RESTAURANT_ORDER_BILL_SUCCESS = 'GENERATE_RESTAURANT_ORDER_BILL_SUCCESS';
export const GENERATE_RESTAURANT_ORDER_BILL_FAILURE = 'GENERATE_RESTAURANT_ORDER_BILL_FAILURE';

export const GENERATE_RESTAURANT_PRE_ORDER_BILL_REQUEST = 'GENERATE_RESTAURANT_PRE_ORDER_BILL_REQUEST';
export const GENERATE_RESTAURANT_PRE_ORDER_BILL_SUCCESS = 'GENERATE_RESTAURANT_PRE_ORDER_BILL_SUCCESS';
export const GENERATE_RESTAURANT_PRE_ORDER_BILL_FAILURE = 'GENERATE_RESTAURANT_PRE_ORDER_BILL_FAILURE';

export const GET_RESTAURANT_ORDER_BILL_REQUEST = 'GET_RESTAURANT_ORDER_BILL_REQUEST';
export const GET_RESTAURANT_ORDER_BILL_SUCCESS = 'GET_RESTAURANT_ORDER_BILL_SUCCESS';
export const GET_RESTAURANT_ORDER_BILL_FAILURE = 'GET_RESTAURANT_ORDER_BILL_FAILURE';

export const MONTHLY_ORDER_COLLECTION_REQUEST = 'MONTHLY_ORDER_COLLECTION_REQUEST';
export const MONTHLY_ORDER_COLLECTION_SUCCESS = 'MONTHLY_ORDER_COLLECTION_SUCCESS';
export const MONTHLY_ORDER_COLLECTION_FAILURE = 'MONTHLY_ORDER_COLLECTION_FAILURE';

export const DAILY_ORDER_COLLECTION_REQUEST = 'DAILY_ORDER_COLLECTION_REQUEST';
export const DAILY_ORDER_COLLECTION_SUCCESS = 'DAILY_ORDER_COLLECTION_SUCCESS';
export const DAILY_ORDER_COLLECTION_FAILURE = 'DAILY_ORDER_COLLECTION_FAILURE';

export const TOTAL_ORDER_COLLECTION_REQUEST = 'TOTAL_ORDER_COLLECTION_REQUEST';
export const TOTAL_ORDER_COLLECTION_SUCCESS = 'TOTAL_ORDER_COLLECTION_SUCCESS';
export const TOTAL_ORDER_COLLECTION_FAILURE = 'TOTAL_ORDER_COLLECTION_FAILURE';

export const RESTAURANT_ORDERS_HISTORY_REQUEST = 'RESTAURANT_ORDERS_HISTORY_REQUEST';
export const RESTAURANT_ORDERS_HISTORY_SUCCESS = 'RESTAURANT_ORDERS_HISTORY_SUCCESS';
export const RESTAURANT_ORDERS_HISTORY_FAILURE = 'RESTAURANT_ORDERS_HISTORY_FAILURE';

export const TODAYS_RESTAURANT_ORDERS_REQUEST = 'TODAYS_RESTAURANT_ORDERS_REQUEST';
export const TODAYS_RESTAURANT_ORDERS_SUCCESS = 'TODAYS_RESTAURANT_ORDERS_SUCCESS';
export const TODAYS_RESTAURANT_ORDERS_FAILURE = 'TODAYS_RESTAURANT_ORDERS_FAILURE';

export const RESTAURANT_ORDERS_MOBILE_SELECT_LIST_REQUEST = 'RESTAURANT_ORDERS_MOBILE_SELECT_LIST_REQUEST';
export const RESTAURANT_ORDERS_MOBILE_SELECT_LIST_SUCCESS = 'RESTAURANT_ORDERS_MOBILE_SELECT_LIST_SUCCESS';
export const RESTAURANT_ORDERS_MOBILE_SELECT_LIST_FAILURE = 'RESTAURANT_ORDERS_MOBILE_SELECT_LIST_FAILURE';

export const TODAYS_ITEM_QUANTITY_REQUEST = 'TODAYS_ITEM_QUANTITY_REQUEST';
export const TODAYS_ITEM_QUANTITY_SUCCESS = 'TODAYS_ITEM_QUANTITY_SUCCESS';
export const TODAYS_ITEM_QUANTITY_FAILURE = 'TODAYS_ITEM_QUANTITY_FAILURE';

export const TODAYS_ITEM_CHANGE_STATUS_REQUEST = 'TODAYS_ITEM_CHANGE_STATUS_REQUEST';
export const TODAYS_ITEM_CHANGE_STATUS_SUCCESS = 'TODAYS_ITEM_CHANGE_STATUS_SUCCESS';
export const TODAYS_ITEM_CHANGE_STATUS_FAILURE = 'TODAYS_ITEM_CHANGE_STATUS_FAILURE';

export const  BRAND_CREATE_ORDER_REQUEST = ' BRAND_CREATE_ORDER_REQUEST';
export const  BRAND_CREATE_ORDER_SUCCESS = ' BRAND_CREATE_ORDER_SUCCESS';
export const  BRAND_CREATE_ORDER_FAILURE = ' BRAND_CREATE_ORDER_FAILURE';

export const ADD_ITEM_TO_CART_REQUEST = 'ADD_ITEM_TO_CART_REQUEST';
export const ADD_ITEM_TO_CART_SUCCESS = 'ADD_ITEM_TO_CART_SUCCESS';
export const ADD_ITEM_TO_CART_FAILURE = 'ADD_ITEM_TO_CART_FAILURE';

export const UPDATE_CART_ITEM_QUANTITY_REQUEST = 'UPDATE_CART_ITEM_QUANTITY_REQUEST';
export const UPDATE_CART_ITEM_QUANTITY_SUCCESS = 'UPDATE_CART_ITEM_QUANTITY_SUCCESS';
export const UPDATE_CART_ITEM_QUANTITY_FAILURE = 'UPDATE_CART_ITEM_QUANTITY_FAILURE';

export const REMOVE_CART_ITEM_REQUEST = 'REMOVE_CART_ITEM_REQUEST';
export const REMOVE_CART_ITEM_SUCCESS = 'REMOVE_CART_ITEM_SUCCESS';
export const REMOVE_CART_ITEM_FAILURE = 'REMOVE_CART_ITEM_FAILURE';

export const CART_REQUEST = 'CART_REQUEST';
export const CART_SUCCESS = 'CART_SUCCESS';
export const CART_FAILURE = 'CART_FAILURE';

export const PLACE_ORDER_REQUEST = 'PLACE_ORDER_REQUEST';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE';

export const RESTAURANT_DELIVERY_ORDERS_REQUEST = 'RESTAURANT_DELIVERY_ORDERS_REQUEST';
export const RESTAURANT_DELIVERY_ORDERS_SUCCESS = 'RESTAURANT_DELIVERY_ORDERS_SUCCESS';
export const RESTAURANT_DELIVERY_ORDERS_FAILURE = 'RESTAURANT_DELIVERY_ORDERS_FAILURE';

export const RestaurantOrdersAction = {

    /**
     * Action for list restaurant orders
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 09 Oct 2023
     */
    listRestaurantOrders: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.listRestaurantOrders(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: RESTAURANT_ORDERS_REQUEST, request: request }; }
        function success(request, response) { return { type: RESTAURANT_ORDERS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code }; }
        function failure(request, error) { return { type: RESTAURANT_ORDERS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code }; };
    },

    /**
     * Action for change order status
     * @param {Object} params - The params which are used for the api
     * @param {String} params.giveaway_id
     * @author Akshay N
     * @created_at 13 Jan 2022
     */
    changeStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.changeStatus(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_RESTAURANT_ORDER_STATUS_REQUEST, request: request }; }
        function success(request, response) { return { type: CHANGE_RESTAURANT_ORDER_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code }; }
        function failure(request, error) { return { type: CHANGE_RESTAURANT_ORDER_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code }; };
    },

    /**
     * Action for change order status
     * @param {Object} params - The params which are used for the api
     * @param {String} params.giveaway_id
     * @author Akshay N
     * @created_at 13 Jan 2022
     */
    changePreOrderStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.changePreOrderStatus(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_RESTAURANT_PRE_ORDER_STATUS_REQUEST, request: request }; }
        function success(request, response) { return { type: CHANGE_RESTAURANT_PRE_ORDER_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code }; }
        function failure(request, error) { return { type: CHANGE_RESTAURANT_PRE_ORDER_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code }; };
    },


    /**
     * Action to get promo details
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.influencer_id
     * @author Jasin
    */
    restaurantOrderDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.restaurantOrderDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: RESTAURANT_ORDER_DETAILS_REQUEST, request: request }; }
        function success(request, response) { return { type: RESTAURANT_ORDER_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code }; }
        function failure(request, error) { return { type: RESTAURANT_ORDER_DETAILS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code }; };
    },

    /**
     * Action for change order status
     * @param {Object} params - The params which are used for the api
     * @param {String} params.giveaway_id
     * @author Akshay N
     * @created_at 13 Jan 2022
     */
    changeItemStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.changeItemStatus(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_RESTAURANT_ITEM_STATUS_REQUEST, request: request }; }
        function success(request, response) { return { type: CHANGE_RESTAURANT_ITEM_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code }; }
        function failure(request, error) { return { type: CHANGE_RESTAURANT_ITEM_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code }; };
    },

    /**
     * Action for change order status
     * @param {Object} params - The params which are used for the api
     * @param {String} params.giveaway_id
     * @author Akshay N
     * @created_at 13 Jan 2022
     */
    generateRestaurantBill: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.generateRestaurantBill(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GENERATE_RESTAURANT_ORDER_BILL_REQUEST, request: request }; }
        function success(request, response) { return { type: GENERATE_RESTAURANT_ORDER_BILL_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code }; }
        function failure(request, error) { return { type: GENERATE_RESTAURANT_ORDER_BILL_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code }; };
    },

    /**
     * Action for change order status
     * @param {Object} params - The params which are used for the api
     * @param {String} params.giveaway_id
     * @author Akshay N
     * @created_at 13 Jan 2022
     */
    generateRestaurantPreOrderBill: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.generateRestaurantPreOrderBill(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GENERATE_RESTAURANT_PRE_ORDER_BILL_REQUEST, request: request }; }
        function success(request, response) { return { type: GENERATE_RESTAURANT_PRE_ORDER_BILL_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code }; }
        function failure(request, error) { return { type: GENERATE_RESTAURANT_PRE_ORDER_BILL_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code }; };
    },

    /**
 * Action for change order status
 * @param {Object} params - The params which are used for the api
 * @param {String} params.giveaway_id
 * @author Akshay N
 * @created_at 13 Jan 2022
 */
    getRestaurantOrderBill: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.getRestaurantOrderBill(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_RESTAURANT_ORDER_BILL_REQUEST, request: request }; }
        function success(request, response) { return { type: GET_RESTAURANT_ORDER_BILL_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code }; }
        function failure(request, error) { return { type: GET_RESTAURANT_ORDER_BILL_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code }; };
    },

    /**
     * Action to get monthly order collection
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.influencer_id
     * @author Jasin
    */
    monthlyOrderCollection: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.monthlyOrderCollection(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: MONTHLY_ORDER_COLLECTION_REQUEST, request: request }; }
        function success(request, response) { return { type: MONTHLY_ORDER_COLLECTION_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code }; }
        function failure(request, error) { return { type: MONTHLY_ORDER_COLLECTION_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code }; };
    },

    /**
     * Action to get daily order collection
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.influencer_id
     * @author Jasin
    */
    dailyOrderCollection: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.dailyOrderCollection(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: DAILY_ORDER_COLLECTION_REQUEST, request: request }; }
        function success(request, response) { return { type: DAILY_ORDER_COLLECTION_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code }; }
        function failure(request, error) { return { type: DAILY_ORDER_COLLECTION_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code }; };
    },

    /**
     * Action for get total order collection
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 12 Dec 2023
     */
    totalOrderCollection: (params) => {
        console.log("PARAMS: ", params)
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.totalOrderCollection(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: TOTAL_ORDER_COLLECTION_REQUEST, request: request }; }
        function success(request, response) { return { type: TOTAL_ORDER_COLLECTION_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code }; }
        function failure(request, error) { return { type: TOTAL_ORDER_COLLECTION_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code }; };
    },

    /**
     * Action for list restaurant orders history
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 11 Dec 2023
     */
    restaurantOrdersHistory: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.restaurantOrdersHistory(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: RESTAURANT_ORDERS_HISTORY_REQUEST, request: request }; }
        function success(request, response) { return { type: RESTAURANT_ORDERS_HISTORY_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code }; }
        function failure(request, error) { return { type: RESTAURANT_ORDERS_HISTORY_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code }; };
    },

    /**
     * Action for list todays restaurant orders
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 18 Dec 2023
     */
    todaysRestaurantOrders: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.todaysRestaurantOrders(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: TODAYS_RESTAURANT_ORDERS_REQUEST, request: request }; }
        function success(request, response) { return { type: TODAYS_RESTAURANT_ORDERS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code }; }
        function failure(request, error) { return { type: TODAYS_RESTAURANT_ORDERS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code }; };
    },

    /**
     * Action for mobile number select list
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 18 Dec 2023
    */
    mobileSelectList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.mobileSelectList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: RESTAURANT_ORDERS_MOBILE_SELECT_LIST_REQUEST, request: request } }
        function success(request, response) { return { type: RESTAURANT_ORDERS_MOBILE_SELECT_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: RESTAURANT_ORDERS_MOBILE_SELECT_LIST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for list todays restaurant orders
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 18 Dec 2023
     */
    todaysItemQuantity: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.todaysItemQuantity(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: TODAYS_ITEM_QUANTITY_REQUEST, request: request }; }
        function success(request, response) { return { type: TODAYS_ITEM_QUANTITY_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code }; }
        function failure(request, error) { return { type: TODAYS_ITEM_QUANTITY_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code }; };
    },

    /**
     * Action for list todays restaurant orders
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 18 Dec 2023
     */
    todaysItemChangeStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.todaysItemChangeStatus(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: TODAYS_ITEM_CHANGE_STATUS_REQUEST, request: request }; }
        function success(request, response) { return { type: TODAYS_ITEM_CHANGE_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code }; }
        function failure(request, error) { return { type: TODAYS_ITEM_CHANGE_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code }; };
    },

    /**
     * Action for create order
     * @param {Object} params - The params which are used for the api
     * @param {String} params.giveaway_id
     * @author Akshay N
     * @created_at 13 Jan 2022
     */
    createOrder: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.createOrder(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: BRAND_CREATE_ORDER_REQUEST, request: request } }
        function success(request, response) { return { type: BRAND_CREATE_ORDER_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: BRAND_CREATE_ORDER_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for add to cart
     * @param {Object} params - The params which are used for the api
     * @param {String} params.giveaway_id
     * @author Akshay N
     * @created_at 13 Jan 2022
     */
    addToCart: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.addToCart(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: ADD_ITEM_TO_CART_REQUEST, request: request } }
        function success(request, response) { return { type: ADD_ITEM_TO_CART_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ADD_ITEM_TO_CART_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for update cart quantity
     * @param {Object} params - The params which are used for the api
     * @param {String} params.giveaway_id
     * @author Akshay N
     * @created_at 13 Jan 2022
     */
    updateCartItemQuantity: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.updateCartItemQuantity(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: UPDATE_CART_ITEM_QUANTITY_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_CART_ITEM_QUANTITY_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_CART_ITEM_QUANTITY_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for update cart quantity
     * @param {Object} params - The params which are used for the api
     * @param {String} params.giveaway_id
     * @author Akshay N
     * @created_at 13 Jan 2022
     */
    removeItemFromCart: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.removeItemFromCart(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: REMOVE_CART_ITEM_REQUEST, request: request } }
        function success(request, response) { return { type: REMOVE_CART_ITEM_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: REMOVE_CART_ITEM_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for update cart quantity
     * @param {Object} params - The params which are used for the api
     * @param {String} params.giveaway_id
     * @author Akshay N
     * @created_at 13 Jan 2022
     */
    getCartList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.getCartList(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: CART_REQUEST, request: request } }
        function success(request, response) { return { type: CART_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CART_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for update cart quantity
     * @param {Object} params - The params which are used for the api
     * @param {String} params.giveaway_id
     * @author Akshay N
     * @created_at 13 Jan 2022
     */
    placeOrder: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.placeOrder(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: PLACE_ORDER_REQUEST, request: request } }
        function success(request, response) { return { type: PLACE_ORDER_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: PLACE_ORDER_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for list restaurant delivery orders
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 06 Jun 2024
     */
    listRestaurantDeliveryOrders: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantOrdersServices.listRestaurantDeliveryOrders(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: RESTAURANT_DELIVERY_ORDERS_REQUEST, request: request }; }
        function success(request, response) { return { type: RESTAURANT_DELIVERY_ORDERS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code }; }
        function failure(request, error) { return { type: RESTAURANT_DELIVERY_ORDERS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code }; };
    },
};
