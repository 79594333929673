import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FormControl, FormHelperText } from '@mui/material';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { FormDateTimePickerStyled } from '.';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useState } from 'react';


function FormDateTimePicker({ label, name, placeholder, className, onChange, tabIndex, id, error, isReadOnly = false, pattern, maxLength, maskType, minDate = null, maxDate = null, value, info, disablePast = false, minDateTime = null, ...props }) {

    const [val, setVal] = useState((value && value !== '') ? dayjs(value) : null);

    const handleChange = (e) => {
        onChange({ target: { type: `date_time`, name: name, value: e.toISOString() } });
        setVal(e);
    };

    return (

        <FormDateTimePickerStyled fullWidth className={`${className}`} >
            <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DateTimePicker
                    label={label}
                    value={(val)}
                    onChange={handleChange}
                    onError={console.log}
                    format="DD/MM/YYYY hh:mm A"
                    viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                        textField: { variant: 'outlined' },
                        toolbar: {
                            toolbarPlaceholder: '__',
                            toolbarFormat: 'DD / MM / YYYY',
                            hidden: false,
                        }
                    }}
                />
            </LocalizationProvider>
            {(info) && <FormHelperText id={`helper_${name}`} >{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error >{error}</FormHelperText>}

        </FormDateTimePickerStyled>

    );
}
export { FormDateTimePicker };
