import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { postAuthRoutes } from "../../../../routes";
import { handleInputChange, validateForm } from "../../../../_helpers";
import { CancelButton, SubmitButton } from "../../../../_components/controls";
import { FormInput } from "../../../../_components/form";
import { Card, CardContent, CardHeader } from "../../../../_components/card";
import { Grid, Stack, Box } from "@mui/material";
import { TicketAction } from "../../../../redux/actions";
import { validate } from ".";

const inputs = { subject: '', description: '' };

function CreateOrUpdateTicket() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /** Support center id and details on update page */
    const location = useLocation();
    const { ticket } = location.state ?? {};

    /** Redux actions and state */
    const createTicket = (params) => dispatch(TicketAction.createTicket(params));
    const UpdateTicket = (params) => dispatch(TicketAction.UpdateTicket(params));
    const { create_ticket_loading: isLoading, create_ticket_errors: createTicketErrors, create_ticket_request } = useSelector((state) => state.TicketReducer);

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {
        setErrors({ ...createTicketErrors });
    }, [createTicketErrors])

    /**
    * function to handle input changes and modify the value
    * @author
    * @created_at 30 May 2022
    */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    useEffect(() => {
        if (ticket && ticket._id) {
            setData({
                ticket_id: ticket._id,
                subject: ticket.subject,
                description: ticket.description,
            });
        }
    }, [ticket])

    useEffect(() => {
        if (create_ticket_request && create_ticket_request.status === 1) {
            navigate(postAuthRoutes('tickets').path);

        }
    }, [create_ticket_request])

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        addTicket(data);
    }

    /**
     * function to update/create ticket
     * @param {Object} filterData
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    async function addTicket(formData) {
        const isCreated = ticket && ticket._id ? await UpdateTicket(formData) : await createTicket(formData)
        if (isCreated && isCreated.status === 1) {
            navigate(postAuthRoutes('tickets').path);
        }

    }

    /**
     * function to cancel update/create ticket
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function cancelTicket() {
        navigate(postAuthRoutes('tickets').path);
    }

    return (
        <Box>
            <CardHeader title={ticket && ticket._id ? postAuthRoutes('update_ticket').name : postAuthRoutes('create_ticket').name} />
            <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Card >
                            <CardContent >
                                <Grid container spacing={3} justifyContent="center" alignItems="center" >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormInput
                                                    tabIndex={4}
                                                    label='Subject'
                                                    name='subject'
                                                    value={data.subject}
                                                    error={(action.isSubmitted && (errors.subject)) ? (errors.subject) : ''}
                                                    onChange={handleChange}
                                                    maxLength={250}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                <FormInput
                                                    tabIndex={4}
                                                    label='Description'
                                                    name='description'
                                                    value={data.description}
                                                    error={(action.isSubmitted && (errors.description)) ? (errors.description) : ''}
                                                    onChange={handleChange}
                                                    multiline
                                                    maxLength={2000}
                                                    minRows={20}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={8} sm={8} md={8} lg={8} xl={12}>
                                        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                            <SubmitButton label={ticket && ticket._id ? 'Update' : 'Create'} loading={isLoading} tabIndex={6} />
                                            <CancelButton onClick={cancelTicket} tabIndex={12} />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )

}

export { CreateOrUpdateTicket };