module.exports = {
    /**
     * function to validate the registration form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author 
     * @created_at 30 May 2022
     */
    validate: (name, value, data = {}) => {
        const errors = {};
        switch (name) {
            case 'mobile_number': {
                errors.mobile_number = '';
                if (value === 'undefined') {
                    errors.mobile_number = 'Mobile number is invalid. '
                }
                if (value === '')
                    errors.mobile_number = 'Enter mobile number. ';
                break;
            } case 'table_id': {
                errors.table_id = '';
                if (value === '')
                    errors.table_id = 'Select table';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}