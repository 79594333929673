import { makeStyles } from "@mui/styles";
import { DRAWER_WIDTH } from "../../_helpers";

const useStyles = makeStyles((theme) => ({
    appLayout: {
        display: `flex`
    },
    mainComponent: {
        flexGrow: 1,
        height: '90vh',
        marginLeft: `60px`,
        padding: `25px`,

        [theme.breakpoints.down('md')]: {
            marginLeft: `0px`,
        },
    },
    drawerOpen: {
        marginLeft: `${DRAWER_WIDTH}px !important`,
    }
}))

export { useStyles };
