import { Grid, Stack, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CardHeader } from '../../../../../../_components/card';
import { useNavigate, useLocation } from 'react-router-dom';
import { PromoStoriesFilter } from '.';
import { FormSearchInput } from '../../../../../../_components/form';
import { GeneralButton } from '../../../../../../_components/controls';
import { NoData } from '../../../../../../_components/layout';
import { FilterAlt } from '@mui/icons-material';
import queryString from 'query-string';
import { StoryCard, StoryCardLoader } from '../../../stories';
import { PromoAction } from '../../../../../../redux/actions';

function PromoStories() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const promoId = searchParams.get('pId'); // promo id from url

    /** Initialize and declare state */
    const [payload, setPayload] = useState({ promo_id: promoId, limit: 100, page: 1, filter: { status: searchParams.get('storiesStatus') ?? '', promo_date: searchParams.get('storiesPromoDate') ?? '' }, search: searchParams.get('storiesQuery') ?? '' });
    const [showStoriesFilterModal, setShowStoriesFilterModal] = useState(false);

    /** Redux reducer states */
    const { stories_loading: isLoading, stories } = useSelector((state) => state.PromoReducer);

    /** Redux reducer actions */
    const listPromoStories = (params) => dispatch(PromoAction.listPromoStories(params));

    useEffect(() => {

        const queryParam = {};
        for (var key of searchParams.keys()) {
            const element = searchParams.get(key);
            if (element === "" || element === null) {
                delete queryParam[key];
            }
            else {
                queryParam[key] = element;
            }
        }

        queryParam.pId = promoId;
        if (payload.search) queryParam.storiesQuery = payload.search; else delete queryParam.storiesQuery;
        if (payload.filter.status) queryParam.storiesStatus = payload.filter.status; else delete queryParam.storiesStatus;
        if (payload.filter.promo_date) queryParam.storiesPromoDate = payload.filter.promo_date; else delete queryParam.storiesPromoDate;

        navigate({ search: `?${queryString.stringify(queryParam)}` });

        getNext();
        //eslint-disable-next-line
    }, [promoId, payload.filter, payload.search]);

    /**
     * function to fetch promo list
     * @param {Object} payload - The params which are used for the api
     * @param {string} payload.page - Page number to load
     * @param {string} payload.limit -  Count of the documents per page
     * @param {string} payload.filter - Filter data
     * @param {string} payload.search - Search key
     * @return json
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const getNext = async () => {
        const result = await listPromoStories(payload);
        if (result.status)
            setPayload({ ...payload, page: payload.page + 1 });
    };

    /**
     * function to open/close stories filter modal
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const storiesFilterModal = () => {
        setShowStoriesFilterModal(!showStoriesFilterModal);
    };

    /**
     * function to apply the selected filter options on the promo stories list
     * @param {Object} filterData
     * @param {String} filterData.status Selected status from the promo filter modal
     * @param {String} filterData.promo_start_date Selected start date form the promo filter modal
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData });
    };

    /**
     * function to apply the selected filter options on the promo stories list
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    };

    return (
        <Box>
            <CardHeader
                action={(
                    <Stack spacing={1} direction="row">
                        <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                        <GeneralButton label={<FilterAlt />} onClick={() => storiesFilterModal()} />
                    </Stack>
                )}
            />
            <PromoStoriesFilter show={showStoriesFilterModal} closeModal={storiesFilterModal} applyFilter={applyFilter} storiesFilter={payload.filter} />
            <InfiniteScroll dataLength={stories.length} next={getNext} hasMore={true}>
                <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center" >
                    {
                        stories.length > 0 && stories.map((story, index) => {
                            return <Grid item xs={12} sm={6} md={3} lg={2} xl={2} key={index} ><StoryCard story={story} /></Grid>;
                        })
                    }
                    {isLoading && (<StoryCardLoader />)}
                    {!isLoading && stories.length === 0 && <NoData content1={`No`} content2={`Stories available`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} minHeight={`55vh`} />}
                </Grid>
            </InfiniteScroll>
        </Box>
    );
}

export { PromoStories };