import {
    STORIES_REQUEST, STORIES_SUCCESS, STORIES_FAILURE,
    STORY_COUNT_REQUEST, STORY_COUNT_SUCCESS, STORY_COUNT_FAILURE,
    PROMO_DETAILS_REQUEST, PROMO_DETAILS_SUCCESS, PROMO_DETAILS_FAILURE,
    STORY_DETAIL_REQUEST, STORY_DETAIL_SUCCESS, STORY_DETAIL_FAILURE,
    STORY_TIMELINE_REQUEST, STORY_TIMELINE_SUCCESS, STORY_TIMELINE_FAILURE,
    INFLUENCER_STORIES_REQUEST, INFLUENCER_STORIES_SUCCESS, INFLUENCER_STORIES_FAILURE,
    EXPORT_STORY_REQUEST, EXPORT_STORY_SUCCESS, EXPORT_STORY_FAILURE,

} from "../actions";

const initialState = {
    stories_loading: false, stories: [], stories_errors: {},
    story_count_loading: false, story_count: { count: 0, percent: 0 }, story_count_errors: {},
    promo_loading: false, promo: {}, promo_errors: {},
    story_loading: true, story: {}, story_errors: {},
    story_timeline_loading: true, story_timeline: {}, story_timeline_errors: {},
    influencer_stories_loading: false, influencer_stories: [], influencer_stories_errors: {},
    export_story_loading: false, export_story: [], export_story_errors: {}, export_headers: []

};

export function StoryReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::List story redux */
        case STORIES_REQUEST: {
            return {
                ...state,
                stories_loading: true,
                stories_errors: {}
            };
        }
        case STORIES_SUCCESS: {

            let newArray = [...state.stories]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.stories.forEach((payload) => {
                const storyIndex = newArray.findIndex(stories => stories._id === payload._id);
                if (storyIndex !== -1) {
                    newArray[storyIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                stories_loading: false,
                stories: newArray,
                stories_errors: {}
            };

        }
        case STORIES_FAILURE: {
            return {
                ...state,
                stories_loading: false,
                stories_errors: action.errors,
                stories: []
            };
        }
        /** end::List story redux */

        /** begin::stories count redux */
        case STORY_COUNT_REQUEST: {
            return {
                ...state,
                story_count_loading: true,
                story_count_errors: {}
            };
        }
        case STORY_COUNT_SUCCESS: {
            return {
                ...state,
                story_count_loading: false,
                story_count: action.data,
                story_count_errors: {}
            };
        }
        case STORY_COUNT_FAILURE: {
            return {
                ...state,
                story_count_loading: false,
                story_count_errors: action.errors
            };
        }
        /** end::stories count redux */

        /** begin::Promo details redux of stories*/
        case PROMO_DETAILS_REQUEST: {
            return {
                ...state,
                promo_loading: true,
                promo_errors: {}
            };
        }
        case PROMO_DETAILS_SUCCESS: {

            return {
                ...state,
                promo_loading: false,
                promo: action.data,
                promo_errors: {}
            };

        }
        case PROMO_DETAILS_FAILURE: {
            return {
                ...state,
                promo_loading: false,
                promo_errors: action.errors,
            };
        }
        /** end::Promo details redux of stories */

        /** begin::List brand stories redux */
        case STORY_DETAIL_REQUEST: {
            return {
                ...state,
                story_loading: true,
                story_errors: {}
            };
        }
        case STORY_DETAIL_SUCCESS: {

            return {
                ...state,
                story_loading: false,
                story: action.data,
                story_errors: {}
            };

        }
        case STORY_DETAIL_FAILURE: {
            return {
                ...state,
                story_loading: false,
                story_errors: action.errors
            };
        }
        /** end::List brand stories redux */

        /** begin::List brand stories redux */
        case STORY_TIMELINE_REQUEST: {
            return {
                ...state,
                story_timeline_loading: true,
                story_timeline_errors: {}
            };
        }
        case STORY_TIMELINE_SUCCESS: {

            return {
                ...state,
                story_timeline_loading: false,
                story_timeline: action.data,
                story_timeline_errors: {}
            };

        }
        case STORY_TIMELINE_FAILURE: {
            return {
                ...state,
                story_timeline_loading: false,
                story_timeline_errors: action.errors
            };
        }
        /** end::List brand stories redux */

        /** begin::List brand influencer stories redux */
        case INFLUENCER_STORIES_REQUEST: {
            return {
                ...state,
                influencer_stories_loading: true,
                influencer_stories_errors: {}
            };
        }
        case INFLUENCER_STORIES_SUCCESS: {
            let newArray = [...state.influencer_stories]; //making a new array

            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.stories.forEach((payload) => {
                const storyIndex = newArray.findIndex(stories => stories._id === payload._id);
                if (storyIndex !== -1) {
                    newArray[storyIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                influencer_stories_loading: false,
                influencer_stories: newArray,
                influencer_stories_errors: {}
            };

        }
        case INFLUENCER_STORIES_FAILURE: {
            return {
                ...state,
                influencer_stories_loading: false,
                influencer_stories_errors: action.errors,
                influencer_stories: []
            };
        }
        /** end::List brand influencer stories redux */

        /** begin::Export story redux*/
        case EXPORT_STORY_REQUEST: {
            return {
                ...state,
                export_story_loading: true,
                export_story_errors: {}
            };
        }
        case EXPORT_STORY_SUCCESS: {

            return {
                ...state,
                export_story_loading: false,
                export_story: action.data.stories,
                export_headers: action.data.headers,
                export_story_errors: {}
            };

        }
        case EXPORT_STORY_FAILURE: {
            return {
                ...state,
                export_story_loading: false,
                export_story: [],
                export_story_errors: action.errors,
            };
        }
        /** end::Export story redux*/

        default:
            return state;
    }
}
