import {
    USER_REQUEST, USER_SUCCESS, USER_FAILURE,
    CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE,
    ADD_PASSWORD_REQUEST, ADD_PASSWORD_SUCCESS, ADD_PASSWORD_FAILURE,
    UPDATE_BILLING_ADDRESS_REQUEST, UPDATE_BILLING_ADDRESS_SUCCESS, UPDATE_BILLING_ADDRESS_FAILURE,
    GET_BILLING_ADDRESS_REQUEST, GET_BILLING_ADDRESS_SUCCESS, GET_BILLING_ADDRESS_FAILURE,
    GET_ACTIVITY_LOG_REQUEST, GET_ACTIVITY_LOG_SUCCESS, GET_ACTIVITY_LOG_FAILURE,
    USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_FAILURE,
    CHECK_BRAND_ACCESS_TOKEN_REQUEST, CHECK_BRAND_ACCESS_TOKEN_SUCCESS, CHECK_BRAND_ACCESS_TOKEN_FAILURE,
    UPDATE_LOGO_REQUEST, UPDATE_LOGO_SUCCESS, UPDATE_LOGO_FAILURE,
    BRAND_CATEGORY_SELECT_LIST_REQUEST, BRAND_CATEGORY_SELECT_LIST_SUCCESS, BRAND_CATEGORY_SELECT_LIST_FAILURE,
    USER_PERMISSION_REQUEST, USER_PERMISSION_SUCCESS, USER_PERMISSION_FAILURE,
    USER_CHANGE_CURRENCY_REQUEST, USER_CHANGE_CURRENCY_SUCCESS, USER_CHANGE_CURRENCY_FAILURE,
    CHANGE_BRAND_RESTAURANT_CONFIGURATION_REQUEST, CHANGE_BRAND_RESTAURANT_CONFIGURATION_SUCCESS, CHANGE_BRAND_RESTAURANT_CONFIGURATION_FAILURE,

} from "../actions";

const initialState = {
    user_loading: true, user: {}, user_errors: [],
    change_password_loading: false, change_password: {}, change_password_errors: [],
    add_password_loading: false, add_password: {}, add_password_errors: [],
    billing_address_loading: true, billing_address: {}, billing_address_errors: {},
    update_billing_address_loading: false, update_billing_address_errors: {}, update_billing_address_requests: {},
    activity_logs_loading: false, activity_logs: [], activity_logs_errors: {},
    check_brand_access_token_loading: false, check_brand_access: '', check_brand_access_token_errors: {},
    update_logo_loading: false, update_logo_errors: {},
    brand_category_select_list_loading: false, brand_category_select_list: [], brand_category_select_list_errors: {},
    user_permission_loading: true, user_permission: [], user_permission_errors: [],
    user_change_currency_loading: true, user_change_currency: [], user_change_currency_errors: [],
    change_brand_restaurant_configuration_loading: false, change_brand_restaurant_configuration_errors: {},
};

export function UserReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::User details redux */
        case USER_REQUEST: {
            return {
                ...state,
                user_loading: true,
                user_errors: {}
            };
        }
        case USER_SUCCESS: {
            return {
                ...state,
                user_loading: false,
                user: action.data,
                user_errors: {}
            };
        }
        case USER_FAILURE: {
            return {
                ...state,
                user_loading: false,
                user_errors: action.errors
            };
        }
        /** end::User details redux */

        /** begin::Change password redux */
        case CHANGE_PASSWORD_REQUEST: {
            return {
                ...state,
                change_password_loading: true,
                change_password_errors: {}
            };
        }
        case CHANGE_PASSWORD_SUCCESS: {
            return {
                ...state,
                change_password_loading: false,
                change_password: action.data,
                change_password_errors: {}
            };
        }
        case CHANGE_PASSWORD_FAILURE: {
            return {
                ...state,
                change_password_loading: false,
                change_password_errors: action.errors
            };
        }
        /** end::Change passwor redux */

        /** begin::Add password redux */
        case ADD_PASSWORD_REQUEST: {
            return {
                ...state,
                add_password_loading: true,
                add_password_errors: {}
            };
        }
        case ADD_PASSWORD_SUCCESS: {
            return {
                ...state,
                add_password_loading: false,
                add_password: action.data,
                add_password_errors: {}
            };
        }
        case ADD_PASSWORD_FAILURE: {
            return {
                ...state,
                add_password_loading: false,
                add_password_errors: action.errors
            };
        }
        /** end::Add passwor redux */


        /** begin::Add billing address redux */
        case UPDATE_BILLING_ADDRESS_REQUEST: {
            return {
                ...state,
                update_billing_address_loading: true,
                update_billing_address_errors: {}
            };
        }
        case UPDATE_BILLING_ADDRESS_SUCCESS: {
            let newArray = state.user; //making a new array
            newArray.billing_address = action.data;
            return {
                ...state,
                update_billing_address_loading: false,
                billing_address: action.data,
                update_billing_address_errors: {},
                user: newArray,
                update_billing_address_requests: action
            };
        }
        case UPDATE_BILLING_ADDRESS_FAILURE: {
            return {
                ...state,
                update_billing_address_loading: false,
                update_billing_address_errors: action.errors
            };
        }
        /** end::Add billing address redux */

        /** begin::Get billing address redux */
        case GET_BILLING_ADDRESS_REQUEST: {
            return {
                ...state,
                billing_address_loading: true,
                billing_address_errors: {},
                update_billing_address_requests: {}
            };
        }
        case GET_BILLING_ADDRESS_SUCCESS: {
            return {
                ...state,
                billing_address_loading: false,
                billing_address: action.data,
                billing_address_errors: {}
            };
        }
        case GET_BILLING_ADDRESS_FAILURE: {
            return {
                ...state,
                billing_address_loading: false,
                billing_address_errors: action.errors
            };
        }
        /** end::Get billing address redux */

        /** begin::List login activity redux */
        case GET_ACTIVITY_LOG_REQUEST: {
            return {
                ...state,
                activity_logs_loading: true,
                activity_logs_errors: {}
            };
        }
        case GET_ACTIVITY_LOG_SUCCESS: {

            let newArray = [...state.activity_logs]; //making a new array
            if (action.data.page === 1) {
                newArray = []
            }
            action.data.activities.forEach((payload) => {
                const loginActivityIndex = newArray.findIndex(activityLog => activityLog._id === payload._id);
                if (loginActivityIndex !== -1) {
                    newArray[loginActivityIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                activity_logs_loading: false,
                activity_logs_errors: {},
                activity_logs: newArray
            };

        }
        case GET_ACTIVITY_LOG_FAILURE: {
            return {
                ...state,
                activity_logs_loading: false,
                activity_logs_errors: action.errors,
                activity_logs: []
            };
        }

        /** end::List login activity redux */


        /** begin::User details redux */
        case USER_UPDATE_REQUEST: {
            return {
                ...state,
                user_loading: true,
                user_errors: {}
            };
        }
        case USER_UPDATE_SUCCESS: {
            return {
                ...state,
                user_loading: false,
                user_errors: {}
            };
        }
        case USER_UPDATE_FAILURE: {
            return {
                ...state,
                user_loading: false,
                user_errors: action.errors
            };
        }
        /** end::User details redux */

        /** begin::User details redux */
        case CHECK_BRAND_ACCESS_TOKEN_REQUEST: {
            return {
                ...state,
                check_brand_access_token_loading: true,
                check_brand_access_token_errors: {},
            };
        }
        case CHECK_BRAND_ACCESS_TOKEN_SUCCESS: {
            return {
                ...state,
                check_brand_access_token_loading: false,
                check_brand_access: action.data.valid,
                check_brand_access_token_errors: {}
            };
        }
        case CHECK_BRAND_ACCESS_TOKEN_FAILURE: {
            return {
                ...state,
                check_brand_access_token_loading: false,
                check_brand_access_token_errors: action.errors,
                check_brand_access: 'invalid'
            };
        }
        /** end::User details redux */


        /** begin::Update logo redux */
        case UPDATE_LOGO_REQUEST: {
            return {
                ...state,
                update_logo_loading: true,
                update_logo_errors: {}
            };
        }
        case UPDATE_LOGO_SUCCESS: {
            let newArray = state.user; //making a new array

            if (newArray._id === action.data._id) {
                newArray.insta_profile.profile_picture_url = action.data.insta_profile.profile_picture_url;
            }

            return {
                ...state,
                update_logo_loading: false,
                update_logo_errors: {},
                user: newArray
            };
        }
        case UPDATE_LOGO_FAILURE: {
            return {
                ...state,
                update_logo_loading: false,
                update_logo_errors: action.errors
            };
        }
        /** end::Update logo redux */

        /** begin::Brand category select list redux */
        case BRAND_CATEGORY_SELECT_LIST_REQUEST: {
            return {
                ...state,
                brand_category_select_list_loading: true,
                brand_category_select_list_errors: {}
            };
        }
        case BRAND_CATEGORY_SELECT_LIST_SUCCESS: {

            return {
                ...state,
                brand_category_select_list_loading: false,
                brand_category_select_list_errors: {},
                brand_category_select_list: action.data

            };

        }
        case BRAND_CATEGORY_SELECT_LIST_FAILURE: {
            return {
                ...state,
                brand_category_select_list_loading: false,
                brand_category_select_list_errors: action.errors,
                brand_category_select_list: []
            };
        }
        /** end::Brand category select list redux */

        /** begin::User permission redux */
        case USER_PERMISSION_REQUEST: {
            return {
                ...state,
                user_permission_loading: true,
                user_permission_errors: {}
            };
        }
        case USER_PERMISSION_SUCCESS: {
            return {
                ...state,
                user_permission_loading: false,
                user_permission: action.data.permissions,
                user_permission_errors: {}
            };
        }
        case USER_PERMISSION_FAILURE: {
            return {
                ...state,
                user_permission_loading: false,
                user_permission_errors: action.errors,
                user_permission: []
            };
        }
        /** end::User permission redux */

        /** begin::User Change currency redux */
        case USER_CHANGE_CURRENCY_REQUEST: {
            return {
                ...state,
                user_change_currency_loading: true,
                user_change_currency_errors: {}
            };
        }
        case USER_CHANGE_CURRENCY_SUCCESS: {

            return {
                ...state,
                user_change_currency_loading: false,
                user_change_currency_errors: {},
                user_change_currency: action.data

            };

        }
        case USER_CHANGE_CURRENCY_FAILURE: {
            return {
                ...state,
                user_change_currency_loading: false,
                user_change_currency_errors: action.errors,
                user_change_currency: []
            };
        }
        /** end::User change currency redux */

        /** begin::Change restaurant configuration redux */
		case CHANGE_BRAND_RESTAURANT_CONFIGURATION_REQUEST: {
			return {
				...state,
				change_brand_restaurant_configuration_loading: true,
				change_brand_restaurant_configuration_errors: {},
			};
		}
		case CHANGE_BRAND_RESTAURANT_CONFIGURATION_SUCCESS: {

            let newArray = state.user; //making a new array

            if (newArray._id === action.data._id) {
                newArray.restaurant_configuration = action.data.restaurant_configuration;
            }
			return {
				...state,
				change_brand_restaurant_configuration_loading: false,
				change_brand_restaurant_configuration_errors: {},
				user: newArray,
			};
		}
		case CHANGE_BRAND_RESTAURANT_CONFIGURATION_FAILURE: {
			return {
				...state,
				change_brand_restaurant_configuration_loading: false,
				change_brand_restaurant_configuration_errors: action.errors,
			};
		}
		/** end::Change restaurant configuration redux */
        default:
            return state;
    }
}
