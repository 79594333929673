import {
    CREATE_PROMO_REQUEST, CREATE_PROMO_SUCCESS, CREATE_PROMO_FAILURE,
    UPDATE_PROMO_REQUEST, UPDATE_PROMO_SUCCESS, UPDATE_PROMO_FAILURE,
    PROMOS_REQUEST, PROMOS_SUCCESS, PROMOS_FAILURE,
    PROMO_COUNT_REQUEST, PROMO_COUNT_SUCCESS, PROMO_COUNT_FAILURE,
    PROMO_COUPON_CODES_REQUEST, PROMO_COUPON_CODES_SUCCESS, PROMO_COUPON_CODES_FAILURE,
    CHANGE_PROMO_STATUS_REQUEST, CHANGE_PROMO_STATUS_SUCCESS, CHANGE_PROMO_STATUS_FAILURE,
    CHANGE_COUPON_STATUS_REQUEST, CHANGE_COUPON_STATUS_SUCCESS, CHANGE_COUPON_STATUS_FAILURE,
    CREATE_COUPON_CODE_REQUEST, CREATE_COUPON_CODE_SUCCESS, CREATE_COUPON_CODE_FAILURE,
    PROMO_STORIES_REQUEST, PROMO_STORIES_SUCCESS, PROMO_STORIES_FAILURE,
    EXPORT_PROMO_REQUEST, EXPORT_PROMO_SUCCESS, EXPORT_PROMO_FAILURE,
    BRAND_PROMO_DETAILS_REQUEST, BRAND_PROMO_DETAILS_SUCCESS, BRAND_PROMO_DETAILS_FAILURE,
    BRAND_PROMO_STORIES_REQUEST, BRAND_PROMO_STORIES_SUCCESS, BRAND_PROMO_STORIES_FAILURE,
    BRAND_PROMO_COUPON_CODES_REQUEST, BRAND_PROMO_COUPON_CODES_SUCCESS, BRAND_PROMO_COUPON_CODES_FAILURE,
    GENERATE_COUPON_CODES_REQUEST, GENERATE_COUPON_CODES_SUCCESS, GENERATE_COUPON_CODES_FAILURE,
    GET_RUNNING_PROMOS_REQUEST, GET_RUNNING_PROMOS_SUCCESS, GET_RUNNING_PROMOS_FAILURE,
} from "../actions";

const initialState = {
    create_promos_loading: false, create_promos: {}, create_promos_errors: {}, c: {},
    promo_count_loading: false, promo_count: { count: 0, percent: 0 }, promo_count_errors: {},
    promos_loading: false, promos: [], promos_errors: {},
    coupon_codes_loading: false, coupon_codes: [], coupon_codes_errors: {},
    change_promo_status_loading: false, change_promo_status_errors: {},
    change_coupon_status_loading: false, change_coupon_status_errors: {},
    stories_loading: false, stories: [], stories_errors: {},
    export_promo_loading: false, export_promo: [], export_promo_errors: {}, export_headers: [],
    brand_promo_details_loading: false, brand_promo_details: {}, brand_promo_details_errors: {},
    brand_promo_stories_loading: false, brand_promo_stories: [], brand_promo_stories_errors: {},
    brand_promo_coupon_codes_loading: false, brand_promo_coupon_codes: [], brand_promo_coupon_codes_errors: {},
    generate_coupon_codes_loading: false, generate_coupon_codes: [], generate_coupon_codes_csv: [], generate_codes_errors: {},
    running_promos_loading: false, running_promos: [], running_promos_errors: {},
};

export function PromoReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Create promo redux */
        case CREATE_PROMO_REQUEST: {
            return {
                ...state,
                create_promos_loading: true,
                create_promos_errors: {}
            };
        }
        case CREATE_PROMO_SUCCESS: {

            const newData = [action.data];
            return {
                ...state,
                promos: (state.promos.length > 0) ? (newData.concat(state.promos)) : newData,
                create_promos_loading: false,
                create_promos: action.data,
                create_promos_errors: {},
                create_promos_requests: action
            };
        }
        case CREATE_PROMO_FAILURE: {
            return {
                ...state,
                create_promos_loading: false,
                create_promos_errors: action.errors
            };
        }
        /** end::Create promo redux */

        /** begin::Update promo redux */
        case UPDATE_PROMO_REQUEST: {
            return {
                ...state,
                create_promos_loading: true,
                create_promos_errors: {}
            };
        }
        case UPDATE_PROMO_SUCCESS: {
            let promoArray = [...state.promos];
            const promoIndex = promoArray.findIndex(promos => promos._id === action.data._id);
            if (promoIndex !== -1) {
                promoArray[promoIndex] = action.data;
            }

            return {
                ...state,
                promos: promoArray,
                create_promos_loading: false,
                create_promos: action.data,
                create_promos_errors: {},
                create_promos_requests: action
            };
        }
        case UPDATE_PROMO_FAILURE: {
            return {
                ...state,
                create_promos_loading: false,
                create_promos_errors: action.errors
            };
        }
        /** end::Update promo redux */

        /** begin::List brand promos redux */
        case PROMOS_REQUEST: {
            return {
                ...state,
                promos_loading: true,
                promos_errors: {},
                create_promos_requests: {}
            };
        }
        case PROMOS_SUCCESS: {

            let newArray = [...state.promos]; //making a new array
            let runningPromosArray = [...state.running_promos];

            if (action.data.page === 1) {
                newArray = [];
            }

            action.data.promos.forEach((payload) => {

                let activeToday = false;                
                
                if(runningPromosArray && runningPromosArray.length > 0) {
                    const promoIndex = runningPromosArray.findIndex(promo => (promo._id === payload._id && (promo.category === 'promo')) );
                    activeToday = (promoIndex !== -1) ? true : false;
                }

                const promoIndex = newArray.findIndex(promos => promos._id === payload._id);
                if (promoIndex !== -1) {
                    newArray[promoIndex] = payload;
                    newArray[promoIndex].active_today = activeToday;
                } else {
                    newArray = newArray.concat({ ...payload, active_today: activeToday });
                }
            });

            return {
                ...state,
                promos_loading: false,
                promos: newArray,
                promos_errors: {}
            };

        }
        case PROMOS_FAILURE: {
            return {
                ...state,
                promos_loading: false,
                promos_errors: action.errors,
                promos: []
            };
        }
        /** end::List brand promos redux */

        /** begin::promo count redux */
        case PROMO_COUNT_REQUEST: {
            return {
                ...state,
                promo_count_loading: true,
                promo_count_errors: {}
            };
        }
        case PROMO_COUNT_SUCCESS: {
            return {
                ...state,
                promo_count_loading: false,
                promo_count: action.data,
                promo_count_errors: {}
            };
        }
        case PROMO_COUNT_FAILURE: {
            return {
                ...state,
                promo_count_loading: false,
                promo_count_errors: action.errors
            };
        }
        /** end::promo count redux */


        /** begin::List brand promos coupon codes redux */
        case PROMO_COUPON_CODES_REQUEST: {
            return {
                ...state,
                coupon_codes_loading: true,
                coupon_codes_errors: {}
            };
        }
        case PROMO_COUPON_CODES_SUCCESS: {

            let newArray = [...state.coupon_codes]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }

            action.data.coupon_codes.forEach((payload) => {
                const couponCodeIndex = newArray.findIndex(coupon_code_data => (coupon_code_data.coupon_code === payload.coupon_code && (coupon_code_data._id === payload._id)));
                if (couponCodeIndex !== -1) {
                    newArray[couponCodeIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                coupon_codes_loading: false,
                coupon_codes: newArray,
                coupon_codes_errors: {}
            };

        }
        case PROMO_COUPON_CODES_FAILURE: {
            return {
                ...state,
                coupon_codes: [],
                coupon_codes_loading: false,
                coupon_codes_errors: action.errors
            };
        }
        /** end::List brand promos coupon codes redux */

        /** begin::Change promo status redux */
        case CHANGE_PROMO_STATUS_REQUEST: {
            return {
                ...state,
                change_promo_status_loading: true,
                create_promos_errors: {}
            };
        }
        case CHANGE_PROMO_STATUS_SUCCESS: {
            let promoArray = [...state.promos];
            const promoIndex = promoArray.findIndex(promos => promos._id === action.data._id);
            if (promoIndex !== -1) {
                promoArray[promoIndex].status = action.data.status;
            }
            return {
                ...state,
                change_promo_status_loading: false,
                change_promo_status_errors: {},
                promos: promoArray
            };
        }
        case CHANGE_PROMO_STATUS_FAILURE: {
            return {
                ...state,
                change_promo_status_loading: false,
                change_promo_status_errors: action.errors
            };
        }
        /** end::Change promo status redux */

        /** begin::Change coupon status redux */
        case CHANGE_COUPON_STATUS_REQUEST: {
            return {
                ...state,
                change_coupon_status_loading: true,
                create_coupon_errors: {}
            };
        }
        case CHANGE_COUPON_STATUS_SUCCESS: {
            let couponArray = [...state.coupon_codes];
            const couponIndex = couponArray.findIndex(coupons => coupons._id === action.data._id);
            if (couponIndex !== -1) {
                couponArray[couponIndex].status = action.data.status;
            }

            let couponArray1 = [...state.brand_promo_coupon_codes];
            const couponIndex1 = couponArray1.findIndex(coupons => coupons._id === action.data._id);
            if (couponIndex1 !== -1) {
                couponArray1[couponIndex1].status = action.data.status;
            }
            
            return {
                ...state,
                change_coupon_status_loading: false,
                change_coupon_status_errors: {},
                couponArray: couponArray,
                brand_promo_coupon_codes: couponArray1
            };
        }
        case CHANGE_COUPON_STATUS_FAILURE: {
            return {
                ...state,
                change_coupon_status_loading: false,
                change_coupon_status_errors: action.errors
            };
        }
        /** end::Change coupon status redux */

        /** begin::Create coupon code redux */
        case CREATE_COUPON_CODE_REQUEST: {
            return {
                ...state,
                create_coupon_codes_loading: true,
                create_coupon_Codes_errors: {}
            };
        }
        case CREATE_COUPON_CODE_SUCCESS: {
            const newData = [action.data];
            return {
                ...state,
                coupon_codes: (state.coupon_codes.length > 0) ? (newData.concat(state.coupon_codes)) : newData,
                brand_promo_coupon_codes: (state.brand_promo_coupon_codes.length > 0) ? (newData.concat(state.brand_promo_coupon_codes)) : newData,
                create_coupon_codes_loading: false,
                create_coupons: action.data,
                create_coupon_Codes_errors: {}
            };
        }
        case CREATE_COUPON_CODE_FAILURE: {
            return {
                ...state,
                create_coupon_codes_loading: false,
                create_coupon_Codes_errors: action.errors
            };
        }
        /** end::Create coupon code redux */

        /** begin::List promo stories redux */
        case PROMO_STORIES_REQUEST: {
            return {
                ...state,
                stories_loading: true,
                stories_errors: {}
            };
        }
        case PROMO_STORIES_SUCCESS: {

            let newArray = [...state.stories]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }

            action.data.stories.forEach((payload) => {
                const couponCodeIndex = newArray.findIndex(coupon_code_data => coupon_code_data.coupon_code === payload.coupon_code);
                if (couponCodeIndex !== -1) {
                    newArray[couponCodeIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                stories_loading: false,
                stories: newArray,
                stories_errors: {}
            };

        }
        case PROMO_STORIES_FAILURE: {
            return {
                ...state,
                stories_loading: false,
                stories_errors: action.errors
            };
        }
        /** end::List promo stories redux */
        
        /** begin::Export story redux*/
        case EXPORT_PROMO_REQUEST: {
            return {
                ...state,
                export_promo_loading: true,
                export_promo_errors: {}
            };
        }
        case EXPORT_PROMO_SUCCESS: {

            return {
                ...state,
                export_promo_loading: false,
                export_promo: action.data.promos,
                export_headers: action.data.headers,
                export_promo_errors: {}
            };

        }
        case EXPORT_PROMO_FAILURE: {
            return {
                ...state,
                export_promo_loading: false,
                export_promo: [],
                export_promo_errors: action.errors,
                export_headers: action.data.headers,
            };
        }
        /** end::Export story redux*/

            /** begin::promo details redux */
        case BRAND_PROMO_DETAILS_REQUEST: {
            return {
                ...state,
                brand_promo_details_loading: true,
                brand_promo_details_errors: {}
            };
        }
        case BRAND_PROMO_DETAILS_SUCCESS: {

            let runningPromosArray = [...state.running_promos];

            if (runningPromosArray && runningPromosArray.length > 0 && action.data) {
                const promoIndex = runningPromosArray.findIndex(promo => (promo._id === action.data._id && (promo.category === 'promo')) );
                action.data.active_today = (promoIndex !== -1) ? true : false;

            }
            
            return {
                ...state,
                brand_promo_details_loading: false,
                brand_promo_details: action.data,
                brand_promo_details_errors: {}
            };
        }
        case BRAND_PROMO_DETAILS_FAILURE: {
            return {
                ...state,
                brand_promo_details_loading: false,
                brand_promo_details_errors: action.errors
            };
        }
        /** end::promo details redux */

        /** begin::List promo stories redux */
        case BRAND_PROMO_STORIES_REQUEST: {
            return {
                ...state,
                brand_promo_stories_loading: true,
                brand_promo_stories_errors: {}
            };
        }
        case BRAND_PROMO_STORIES_SUCCESS: {

            let newArray = [...state.brand_promo_stories]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }

            action.data.stories.forEach((payload) => {
                const couponCodeIndex = newArray.findIndex(coupon_code_data => coupon_code_data.coupon_code === payload.coupon_code);
                if (couponCodeIndex !== -1) {
                    newArray[couponCodeIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                brand_promo_stories_loading: false,
                brand_promo_stories: newArray,
                brand_promo_stories_errors: {}
            };

        }
        case BRAND_PROMO_STORIES_FAILURE: {
            return {
                ...state,
                brand_promo_stories_loading: false,
                brand_promo_stories_errors: action.errors
            };
        }
        /** end::List promo stories redux */

         /** begin::List brand promos coupon codes redux */
        case BRAND_PROMO_COUPON_CODES_REQUEST: {
            return {
                ...state,
                brand_promo_coupon_codes_loading: true,
                brand_promo_coupon_codes_errors: {}
            };
        }
        case BRAND_PROMO_COUPON_CODES_SUCCESS: {

            let newArray = [...state.brand_promo_coupon_codes]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }

            action.data.coupon_codes.forEach((payload) => {
                const couponCodeIndex = newArray.findIndex(coupon_code_data => ((coupon_code_data.coupon_code === payload.coupon_code) && (coupon_code_data._id === payload._id)));
                if (couponCodeIndex !== -1) {
                    newArray[couponCodeIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                brand_promo_coupon_codes_loading: false,
                brand_promo_coupon_codes: newArray,
                brand_promo_coupon_codes_errors: {}
            };

        }
        case BRAND_PROMO_COUPON_CODES_FAILURE: {
            return {
                ...state,
                brand_promo_coupon_codes: [],
                brand_promo_coupon_codes_loading: false,
                brand_promo_coupon_codes_errors: action.errors
            };
        }
        /** end::List brand promos coupon codes redux */

        case "PROMO_REDEEM_COUPON": {

            let couponArray = [...state.coupon_codes];
            let couponArray1 = [...state.brand_promo_coupon_codes];

            
            if (couponArray.length > 0) {
                const couponIndex = couponArray.findIndex(coupons => (coupons.coupon_code === action.data.coupon_code && coupons._id === action.data._id));
                if (couponIndex !== -1) {
                    couponArray[couponIndex].status = action.data.status;
                    couponArray[couponIndex].redeemed_at = action.data.redeemed_at;
                }
            }

            const couponIndex1 = couponArray1.findIndex(coupons => coupons.coupon_code === action.data.coupon_code && coupons._id === action.data._id);
            if (couponIndex1 !== -1) {
                couponArray1[couponIndex1].status = action.data.status;
                couponArray1[couponIndex1].redeemed_at = action.data.redeemed_at;
            }

			return {
                ...state,
				coupon_codes: couponArray,
				brand_promo_coupon_codes: couponArray1,

			};
		}

        
         /** begin::generate coupon codes redux*/
         case GENERATE_COUPON_CODES_REQUEST: {
            return {
                ...state,
                generate_coupon_codes_loading: true,
                generate_coupon_codes_errors: {}
            };
        }
        case GENERATE_COUPON_CODES_SUCCESS: {

            let csvData = [];
            if (action.data && action.data.length > 0) {
                csvData = action.data.map(item => [item.coupon_code, item.description]);
            }

            return {
                ...state,
                generate_coupon_codes_loading: false,
                generate_coupon_codes: action.data,
                generate_coupon_codes_csv: csvData,
                generate_coupon_codes_errors: {}
            };

        }
        case GENERATE_COUPON_CODES_FAILURE: {
            return {
                ...state,
                generate_coupon_codes: [],
                generate_coupon_codes_csv: [],
                generate_coupon_codes_loading: false,
                generate_coupon_codes_errors: action.errors
            };
        }
        /** end::generate coupon codes redux */

        /** begin::running promos redux*/
        case GET_RUNNING_PROMOS_REQUEST: {
            return {
                ...state,
                running_promos_loading: true,
                running_promos_errors: {}
            };
        }
        case GET_RUNNING_PROMOS_SUCCESS: {

            let newArray = [...state.promos]; //making a new array
            let newDetailsArray = state.brand_promo_details; //making a new array

            newArray.forEach(payload => {
                const promoIndex = action.data.findIndex(promo => ((promo._id === payload._id) && (promo.category === 'promo')));

                if (promoIndex !== -1) {
                    payload.active_today = true;
                } else {
                    payload.active_today = false;
                }
            });

            if (newDetailsArray && Object.keys(newDetailsArray).length > 0) {

                const promoIndex = action.data.findIndex(promo => ((promo._id === newDetailsArray._id) && (promo.category === 'promo')));

                if (promoIndex !== -1) {
                    newDetailsArray.active_today = true;
                } else {
                    newDetailsArray.active_today = false;
                }
            }


            return {
                ...state,
                running_promos_loading: false,
                running_promos: action.data,
                running_promos_errors: {},
                promos: newArray,
                brand_promo_details: newDetailsArray
            };

        }
        case GET_RUNNING_PROMOS_FAILURE: {
            return {
                ...state,
                running_promos: [],
                running_promos_loading: false,
                running_promos_errors: action.errors
            };
        }
        /** end::running promos redux */
        default:
            return state;
    }
}
