import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { DataTable } from '../../../../../_components/data-table/DataTable';
import { CurrencySymbol } from '../../../../../_components/currency-symbol';
import { useDispatch, useSelector } from 'react-redux';
import { RestaurantOrdersAction } from '../../../../../redux/actions';
import { Chip } from '@mui/material';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { postAuthRoutes } from '../../../../../routes';

function RestaurantOrderDataTable({ filter, search, limit, page, ...props }) {
	/** Initialize plugins and variables */
	const dispatch = useDispatch();
    let navigate = useNavigate();

    /** Redux actions and state */
    const { per_page, total, restaurant_orders_history_loading: isLoading, restaurant_orders_history } = useSelector((state) => state.RestaurantOrdersReducer);
    const restaurantOrdersHistory = (params) => dispatch(RestaurantOrdersAction.restaurantOrdersHistory(params));

	/** Initialize and declare state */
	const [sort, setSort] = useState({ _id: 'asc' });
	const [payload, setPayload] = useState({ filter, search, page, limit });

	const columns = [
		{ name: '#', selector: (row, i) => row._id, sortField: '_id', center: true, width: '6%', cell: (row, i) => per_page * (page - 1) + (i + 1) },
        { name: 'Mobile Number', selector: (row, i) => row.influencers.mobile_number, sortField: 'influencers.mobile_number', center: true, sortable: true, width: '18%' },
		{ name: 'Table Name', selector: (row, i) => (row.table && row.table[0] && row.table[0].name) ? row.table[0].name : 'null', sortField: 'table.name', sortable: true, width: '32%' },
		{ name: 'Total GST Price', selector: (row, i) => row.bill.total_gst_price, sortField: 'bill.total_gst_price', center: true, sortable: true, width: '13%', cell: row => (<TotalGstRow row={row} />) },
		{ name: 'Status', selector: (row, i) => row.status_text, sortField: 'status_text', center: true, sortable: true, width: '16%', cell: row => (<StatusRow row={row} />) },
        { name: 'Created At', selector: (row, i) => row.created_at, sortField: 'created_at', sortable: true, center: true, width: '15%'},
	];

    /**
	 * function to display Total GST Price on rows
	 * @param {Object} row
	 * @param {string} row.bill.total_gst_price - description on the row
	 * @return json
	 * @author Naveen K
	 * @created_at 11 Dec 2023
	 */
	const TotalGstRow = ({ row }) => {
		return <p><CurrencySymbol/>{(row.status === 'paid' && row.bill && row.bill.total_gst_price)? row.bill.total_gst_price: '0'}</p>;
	};

    /**
     * function to display total GST Price on the row
     * @return json
     * @author Naveen
     * @created_at 11 Dec 2023
     */
    function StatusRow({row}) {

        let status = <Chip label={row.status_text} color="secondary" className="badge-status capitalize" style={{ textTransform: `capitalize` }}/>
        if (row.status === 'paid')
            status = <Chip label={row.status_text} color="success" className="badge-status capitalize" style={{ textTransform: `capitalize` }}/>

        return (
			<React.Fragment>
				{status}
			</React.Fragment>
		);
    }

    useEffect(() => {
        setPayload({ filter, search, page, limit, sort });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, search, page, limit])

    useEffect(() => {

        const queryParam = { ...filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = page;
        if (search) queryParam.q = search; else delete queryParam.q;
        if (filter.status) queryParam.status = filter.status; else delete queryParam.status;
        if (filter.order_created_at) queryParam.order_created_at = filter.order_created_at;
		else delete queryParam.order_created_at;

        const pathname = postAuthRoutes('restaurant_order_history').path;
		navigate({ pathname, search: createSearchParams(`?${queryString.stringify(queryParam)}`).toString() });
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search])

    /**
     * function to fetch restaurant history
     * @param {Object} payload - The params which are used for the api
     * @return json
     */
    const getData = async () => {
        await restaurantOrdersHistory(payload);
    }

    /**
     * function to sort columns
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setSort({ [sortField]: sortDirection })
    }

	/**
	 * function to select columns
	 * @return view
	 * @author
	 * @created_at 14 June 2022
	 */
	const handleSelect = (data) => {
		// console.log(data.selectedRows)
	};

	/**
	 * function to handle page
	 * @param {String} page - Page number to load
	 * @return view
	 * @author
	 * @created_at 14 June 2022
	 */

	const handlePage = (page) => {
		setPayload({ ...payload, page: page });
	};

	return <DataTable loading={isLoading} columns={columns} data={restaurant_orders_history} total={total} per_page={per_page} setPage={(page) => handlePage(page)} handleSort={handleSort} handleSelect={handleSelect} />;
}

export { RestaurantOrderDataTable };
