import {
    GET_APP_USERS_REQUEST, GET_APP_USERS_SUCCESS, GET_APP_USERS_FAILURE,
    ADD_APP_USER_REQUEST, ADD_APP_USER_SUCCESS, ADD_APP_USER_FAILURE,
    UPDATE_APP_USER_REQUEST, UPDATE_APP_USER_SUCCESS, UPDATE_APP_USER_FAILURE,
    CHANGE_APP_USER_STATUS_REQUEST, CHANGE_APP_USER_STATUS_SUCCESS, CHANGE_APP_USER_STATUS_FAILURE,
	USER_ACCOUNT_TYPE_SELECT_LIST_REQUEST, USER_ACCOUNT_TYPE_SELECT_LIST_SUCCESS, USER_ACCOUNT_TYPE_SELECT_LIST_FAILURE,

} from "../actions";

const initialState = {
    app_users_loading: false, app_users: [], app_users_errors: [],
    add_app_user_loading: false, add_app_user_errors: [], add_app_user_requests: {},
    change_app_user_status_loading: false, change_app_user_status_errors: {},
    account_type_select_list_loading: false, account_type_select_list: [], account_type_select_list_errors: {},
};

export function AppUserReducer(state = initialState, action) {
    switch (action.type) {
        
        /** begin::Users list redux */
        case GET_APP_USERS_REQUEST: {
            return {
                ...state,
                app_users_loading: true,
                app_users_errors: {},
                add_app_user_requests: {}
            };
        }
        case GET_APP_USERS_SUCCESS: {
            let newArray = [...state.app_users]; //making a new array
            if (action.data.page === 1) {
                newArray = []
            }
            action.data.users.forEach((payload) => {
                const influencerIndex = newArray.findIndex(influencer => influencer._id === payload._id);
                if (influencerIndex !== -1) {
                    newArray[influencerIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                app_users_loading: false,
                app_users: newArray,
                app_users_errors: {}
            };
        }
        case GET_APP_USERS_FAILURE: {
            return {
                ...state,
                app_users_loading: false,
                app_users: [],
                app_users_errors: action.errors
            };
        }
        /** end::Users list redux */

        /** begin::Create Users redux */
        case ADD_APP_USER_REQUEST: {
            return {
                ...state,
                add_app_user_loading: true,
                add_app_user_errors: {}
            };
        }
        case ADD_APP_USER_SUCCESS: {
            return {
                ...state,
                add_app_user_loading: false,
                add_app_user_errors: {},
                add_app_user_requests: action
            };
        }
        case ADD_APP_USER_FAILURE: {
            return {
                ...state,
                add_app_user_loading: false,
                add_app_user_errors: action.errors
            };
        }
        /** end::Create Users redux */

        /** begin::Update Users redux */
        case UPDATE_APP_USER_REQUEST: {
            return {
                ...state,
                add_app_user_loading: true,
                add_app_user_errors: {}
            };
        }
        case UPDATE_APP_USER_SUCCESS: {
            return {
                ...state,
                add_app_user_loading: false,
                add_app_user_errors: {},
                add_app_user_requests: action
            };
        }
        case UPDATE_APP_USER_FAILURE: {
            return {
                ...state,
                add_app_user_loading: false,
                add_app_user_errors: action.errors
            };
        }
        /** end::Update Users redux */

        /** begin::Change User status redux */
        case CHANGE_APP_USER_STATUS_REQUEST: {
            return {
                ...state,
                change_app_user_status_loading: true,
                change_app_user_status_errors: {}
            };
        }
        case CHANGE_APP_USER_STATUS_SUCCESS: {
            let usersArray = [...state.app_users];
            const userIndex = usersArray.findIndex(users => users._id === action.data._id);

            if (userIndex !== -1) {
                usersArray[userIndex].status = action.data.status;
            }

            return {
                ...state,
                change_app_user_status_loading: false,
                change_app_user_status_errors: {},
                users: usersArray
            };
        }
        case CHANGE_APP_USER_STATUS_FAILURE: {
            return {
                ...state,
                change_app_user_status_loading: false,
                change_app_user_status_errors: action.errors
            };
        }
        /** end::Change User status redux */

        
		/** begin::Account type select list redux */
		case USER_ACCOUNT_TYPE_SELECT_LIST_REQUEST: {
			return {
				...state,
				account_type_select_list_loading: true,
				account_type_select_list_errors: {},
			};
		}
		case USER_ACCOUNT_TYPE_SELECT_LIST_SUCCESS: {
			return {
				...state,
				account_type_select_list_loading: false,
				account_type_select_list_errors: {},
				account_type_select_list: action.data,
			};
		}
		case USER_ACCOUNT_TYPE_SELECT_LIST_FAILURE: {
			return {
				...state,
				account_type_select_list_loading: false,
				account_type_select_list_errors: action.errors,
				account_type_select_list: [],
			};
		}
		/** end::Account type select list redux */

        default:
            return state;
    }
}
