import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { StatisticsWidget } from '../../../../../../_components/widgets';
import { useDispatch, useSelector } from 'react-redux';
import { StoryAction } from '../../../../../../redux/actions';
import { DynamicFeed } from '@mui/icons-material';

function StoriesCountWidget() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux reducer actions */
    const getStoryCount = (params) => dispatch(StoryAction.getStoryCount(params));

    /** Redux reducer states */
    const { story_count } = useSelector((state) => state.StoryReducer);

    useEffect(() => {
        getStoryCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <Box>
            <StatisticsWidget
                color="secondary"
                icon={<DynamicFeed />}
                title="Stories"
                count={story_count.count}
                percentage={{
                    color: `${story_count.percent > 0 ? 'success' : 'error'}`,
                    amount: `${story_count.percent}%`,
                    label: "than last month",
                }}
            />
        </Box>
    )
}

export { StoriesCountWidget }