import { Avatar, Box, Grid, Link, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ConversationDetailLoader } from '..';
import { MessagesAction } from '../../../../../../redux/actions';
import { Fragment } from 'react';

function ConversationDetail() {


    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();
    const params = new URLSearchParams(search);

    /** Initialize and declare state */

    const cId = params.get('cId');

    /** Redux actions and state */
    const { conversation_details_loading: isLoading, conversation_details } = useSelector((state) => state.MessagesReducer);
    const getConversationDetails = (params) => dispatch(MessagesAction.getConversationDetails(params));

    useEffect(() => {

        if (cId)
            getConversationDetails({ cId: cId });
        //eslint-disable-next-line
    }, [cId])


    const replyEndRef = useRef(null)

    const scrollToBottom = () => {
        replyEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [conversation_details]);




    if (isLoading)
        return <ConversationDetailLoader />

    const displayStory = (reply) => {
        if (reply.data.story) {

            if (reply.data.story.mention) {
                return (
                    <Fragment>
                        { reply.data.story.mention.link && <Grid container justifyContent="flex-end" direction="column">
                            <Grid item md={12} xs={12} style={{ ...reply.from_user_type === 'shopper' ? { textAlign: 'right' } : {} }}>
                                <img src={`${reply.data.story.mention.link}`} height="100px" alt="Story" />
                            </Grid>
                            <Grid item md={12} xs={12} style={{ ...reply.from_user_type === 'shopper' ? { textAlign: 'right' } : {} }}>
                                <Link href={reply.data.story.mention.link} target="_blank" rel="noreferrer">
                                    View story
                                </Link>
                            </Grid>
                        </Grid> }
                        {!reply.data.story.mention.link && <Grid container justifyContent="flex-end" direction="column">
                            <Grid item md={12} xs={12} style={{ ...reply.from_user_type === 'shopper' ? { textAlign: 'right' } : {} }}>
                                <Typography variant='caption'>
                                { reply.from_user_type === 'brand' ? 'Mentioned them in your story' : 'Mentioned you in their story'}
                                </Typography>
                            </Grid>
                        </Grid> }
                    </Fragment>
                )
            } else if (reply.data.story.reply_to) {
                return (
                    <Fragment>
                        <Grid container justifyContent="flex-end" direction="column">
                            {reply.data.story.reply_to.link && <Grid item md={12} xs={12} style={{ ...reply.from_user_type === 'shopper' ? { textAlign: 'right' } : {} }}>
                                <img src={`${reply.data.story.reply_to.link}`} height="100px" alt="Story" />
                            </Grid>}
                            <Grid item md={12} xs={12} style={{ ...reply.from_user_type === 'shopper' ? { textAlign: 'right' } : {} }}>
                                <Typography variant='caption'>
                                    { reply.from_user_type === 'brand' ? 'You replied to their story' : 'Replied to your story'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Fragment>
                )
            }
        }
    }


    return (
        <Fragment >
            {!isLoading && Object.keys(conversation_details).length > 0 &&
                <Box sx={{ height: `735px`, overflow: `scroll`, marginBottom: `10px` }} >
                    <Grid container spacing={1} display={`flex`} alignItems={`center`}>
                        {conversation_details && conversation_details.length > 0 && conversation_details.map((reply, i) => {

                            return (reply.from_user_type === 'shopper') ?
                                (
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={i}>
                                        <Box display={`flex`} alignItems={`center`} justifyContent={`right`} style={{ padding: '2', backgroundColor: `#d9d9d9`, borderRadius: '2', minHeight: `80px` }}>
                                            <Box mr={2} pl={2} >
                                                <Typography variant="body2" style={{ textAlign: 'right' }}>{reply.data.message}</Typography>
                                                {displayStory(reply)}
                                                <Typography variant="body2" textAlign={`right`} sx={{ fontSize: `9px` }}> {reply.created_time}</Typography>

                                            </Box>
                                            <Avatar src={reply.replied_by.insta_profile.profile_picture_url} />
                                        </Box>
                                    </Grid>
                                ) :
                                (
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={i}>
                                        <Box display={`flex`} alignItems={`center`} justifyContent={`left`} sx={{ p: 2, backgroundColor: `#1b1b1b`, borderRadius: 2, minHeight: `80px` }}>
                                            <Avatar />
                                            <Box ml={2} sx={{ width: `100%` }}>
                                                <Typography variant="body2">{reply.data.message}</Typography>
                                                <Typography variant="body2" sx={{ fontSize: `9px` }}> {reply.created_time}</Typography>
                                                {displayStory(reply)}
                                            </Box>
                                        </Box>
                                    </Grid>
                                )
                        })}
                    </Grid>
                    <Box ref={replyEndRef}></Box>
                </Box>
            }
        </Fragment>
    )




}

export { ConversationDetail }