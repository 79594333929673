import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { FilterAlt, Download, Add, DeliveryDining } from '@mui/icons-material';
import { Grid, Stack } from '@mui/material';
import { postAuthRoutes } from '../../../../routes';
import { RestaurantMenuAction, UserAction } from '../../../../redux//actions';
import { CardHeader } from '../../../../_components/card';
import { GeneralButton } from '../../../../_components/controls';
import { FormSearchInput } from '../../../../_components/form';
import { UpdateDataStatusDialog } from '../../../../_components/dialog';
import { RestaurantTablesCard, RestaurantTablesCardLoader, RestaurantTablesFilter, RestaurantConfigureModal } from '.';
import { NoData } from '../../../../_components';
import { RestaurantTablePdf } from './restaurant-tables-card/restaurant-table-pdf/RestaurantTablesPdf';
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AbilityCan } from '../../../../_helpers/permission/AbilityCan';

function RestaurantTables() {
	/** Initialize plugins and variables */
	const dispatch = useDispatch();
	let navigate = useNavigate();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	/** Redux actions and state */
	const { restaurant_tables_loading: isLoading, restaurant_tables } = useSelector((state) => state.RestaurantMenuReducer);
	const { get_future_orders_details_loading: isFutureOrdersLoading, get_future_orders_details } = useSelector((state) => state.RestaurantMenuReducer);

	const { user } = useSelector((state) => state.UserReducer);
	const changeStatus = (params) => dispatch(RestaurantMenuAction.changeRestaurantTablesStatus(params));
	const listRestaurantTables = (params) => dispatch(RestaurantMenuAction.listRestaurantTables(params));
    const getFutureOrdersDetails = (params) => dispatch(RestaurantMenuAction.getFutureOrdersDetails(params));
	const changeRestaurantConfiguration = (params) => dispatch(UserAction.changeRestaurantConfiguration(params));

	/** Initialize and declare state */
	const [showRestaurantTablesFilterModal, setShowRestaurantTablesFilterModal] = useState(false);
	const [showRestaurantConfigureModal, setShowRestaurantConfigureModal] = useState(false);
	const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
	const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });
	const [payload, setPayload] = useState({ limit: 100, page: 1, search: searchParams.get('q') ?? '', filter: { status: searchParams.get('status') ?? '', }, });


    useEffect(() => {
        getFutureOrdersDetails();
    }, [])

	useEffect(() => {
		/* Read the payload and append the parameters to the url for proper reload */
		const queryParam = { ...payload.filter };
		Object.keys(queryParam).forEach(element => {
			if (queryParam[element] === "" || queryParam[element] === null) {
				delete queryParam[element];
			}
		});
		queryParam.p = payload.page;
		if (payload.search) queryParam.q = payload.search; else delete queryParam.q;
		if (payload.filter.status) queryParam.status = payload.filter.status; else delete queryParam.status;
		const pathname = postAuthRoutes('restaurant_tables').path;
		navigate({ pathname, search: createSearchParams(`?${queryString.stringify(queryParam)}`).toString() });
		getNext();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payload.filter, payload.search]);

	/**
	 * function to fetch promo list
	 * @param {Object} payload - The params which are used for the api
	 * @param {string} payload.page - Page number to load
	 * @param {string} payload.limit -  Count of the documents per page
	 * @param {string} payload.filter - Filter data
	 * @param {string} payload.search - Search key
	 * @return json
	 * @author Akshay N
	 * @created_at 28 May 2022
	 */
	const getNext = async () => {
		const result = await listRestaurantTables(payload);
		if (result.status) {
			setPayload({ ...payload, page: payload.page + 1 }); // If result success, update payload state
		}
	};

	/**
	 * function to create Restaurant table
	 * @param {string} null
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	function createRestaurantTable() {
		navigate(postAuthRoutes('create_restaurant_tables').path);
	}

	/**
	 * function to open/close update restaurant table Filter model
	 * @param {string} null
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	const statusUpdateModal = ({ id, status, item }) => {
		setStatusData({ id: id, status: status, item: '' });
		setShowStatusUpdateModal(!showStatusUpdateModal);
	};

	/**
	 * function to update status
	 * @param {string} null
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	const updateStatusAction = async (id) => {
		setShowStatusUpdateModal(!showStatusUpdateModal);
		await changeStatus({ restaurantTableId: id });
	};

	/**
	 * function to open/close restaurant table filter modal
	 * @param {string} null
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	const restaurantTablesFilterModal = () => {
		setShowRestaurantTablesFilterModal(!showRestaurantTablesFilterModal);
	};

	/**
	 * function to apply the selected filter options on the restaurant table list
	 * @param {Object} filterData
	 * @param {String} filterData.category Selected category from the restaurant table filter modal
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	const applyFilter = (filterData) => {
		setPayload({ ...payload, page: 1, filter: filterData });
	};

	/**
	 * function to apply the search key words on the restaurant table list
	 * @param {String} text Search keyword used
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	const applySearch = (text) => {
		setPayload({ ...payload, page: 1, search: text });
	};

	/**
	 * function to open/close restaurant configure modal
	 * @param {string} null
	 * @return view
	 * @author Naveen K
	 * @created_at 09 July 2024
	 */
	const restaurantConfigureModal = () => {
		setShowRestaurantConfigureModal(!showRestaurantConfigureModal);
	};

	/**
	 * function to apply restaurant configure
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Jul 2024
	 */
	const applyRestaurantConfigure = (configureData) => {
		changeRestaurantConfiguration({ restaurant_configurations: configureData });
	};

	return (
		<Fragment>
			<CardHeader
				title={postAuthRoutes('restaurant_tables').name}
				action={
					<Stack spacing={1} direction="row">
						<AbilityCan I="brand_restaurant_table_search" passThrough={user.role_slug === 'developer' ? true : false}>
							<FormSearchInput getSearchText={applySearch} searchText={payload.search} />
						</AbilityCan>
						<AbilityCan I="brand_restaurant_table_create" passThrough={user.role_slug === 'developer' ? true : false}>
							<GeneralButton startIcon={<Add />} label={postAuthRoutes('create_restaurant_tables').name} onClick={() => createRestaurantTable()} />
						</AbilityCan>
						<AbilityCan I="brand_restaurant_table_pdf_qrcode" passThrough={user.role_slug === 'developer' ? true : false}>
							<PDFDownloadLink document={<RestaurantTablePdf restaurant_tables={restaurant_tables} />} fileName="tablesQrCode.pdf">
								{({ loading }) => (loading ? <GeneralButton disabled={true} startIcon={<Download />} label={<Fragment>QR Code Loading...</Fragment>} /> : <GeneralButton startIcon={<Download />} label={<Fragment>QR Code Download</Fragment>} />)}
							</PDFDownloadLink>
						</AbilityCan>
						<AbilityCan I="brand_restaurant_table_filter" passThrough={user.role_slug === 'developer' ? true : false}>
							<GeneralButton label={<FilterAlt />} onClick={() => restaurantTablesFilterModal()} />
						</AbilityCan>
						<AbilityCan I="brand_restaurant_configure" passThrough={user.role_slug === 'developer' ? true : false}>
							<GeneralButton label={<DeliveryDining />} onClick={() => restaurantConfigureModal()} />
						</AbilityCan>
					</Stack>
				}
			/>
			<RestaurantTablesFilter show={showRestaurantTablesFilterModal} closeModal={restaurantTablesFilterModal} applyFilter={applyFilter} tablesFilter={payload.filter} />
			<RestaurantConfigureModal show={showRestaurantConfigureModal} closeModal={restaurantConfigureModal} applyRestaurantConfigure={applyRestaurantConfigure} restaurantConfigure={user.restaurant_configuration} />
			<InfiniteScroll dataLength={restaurant_tables.length} next={getNext} hasMore={true}>
				<Grid container spacing={2} direction="row" justifyContent="left" alignItems="stretch">
					{restaurant_tables.length > 0 &&
						restaurant_tables.map((restaurant_table, i) => {
							return (
								<Grid item xs={12} sm={12} md={12} lg={3} xl={3} key={i}>
									<RestaurantTablesCard restaurant_table={restaurant_table} statusUpdateModal={statusUpdateModal} />
								</Grid>
							);
						})}
					{isLoading && <RestaurantTablesCardLoader />}
					{!isLoading && restaurant_tables.length === 0 && <NoData content1={`Tables`} content2={`Not Found`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} minHeight={`75vh`} />}
				</Grid>
			</InfiniteScroll>
			<Grid container spacing={2} direction="row" justifyContent="left" alignItems="stretch" mt={2} >
				{(user && user._id) &&
					<Fragment>
						<Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
							<RestaurantTablesCard restaurant_table={{brand_id: user._id, booking_type_url: 'order-by-vehicle', short_code: 'ORDER BY CAR NUMBER'}} statusUpdateModal={statusUpdateModal} />
						</Grid>
						{(!isFutureOrdersLoading && get_future_orders_details && get_future_orders_details.future_orders && get_future_orders_details.future_orders === true) &&
							<Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
								<RestaurantTablesCard restaurant_table={{brand_id: user._id, booking_type_url: 'pre-booking', short_code: 'PRE-BOOKING'}} statusUpdateModal={statusUpdateModal} />
							</Grid>
						}
					</Fragment>
				}
			</Grid>
			<UpdateDataStatusDialog show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />
		</Fragment>
	);
}
export { RestaurantTables };