import React from 'react'
import { IconButton, Stack, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { DialogTitleStyled } from './DialogTitleStyled'



function DialogTitle({ id, title, onClose }) {
    return (
        <DialogTitleStyled >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant={`h5`}>{title}</Typography>
                <IconButton aria-label="close" onClick={() => onClose()}>
                    <Close />
                </IconButton>
            </Stack>
        </DialogTitleStyled>
    )
}

export { DialogTitle }
