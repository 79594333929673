
import { Container, Typography, Link, Grid, Box } from "@mui/material";
import { StyledTypography, } from "../../../_components";

import { PRODUCT_NAME } from "../../../_helpers";

function CopyRight({ light = "dark" }) {

  return (
    <Box position="unset" width="100%" bottom={0} py={3} sx={({ breakpoints }) => ({ [breakpoints.up("md")]: { position: 'absolute', py: 4 } })} height={'10%'}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" pl={'20px'} pr={'20px'}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" color={light ? "white" : "text"} fontSize={`14px`} sx={{ justifyContent: { md: 'left' } }}>
              &copy; {new Date().getFullYear()} Made by <Link href="https://www.adrundigital.com/" target="_blank"><StyledTypography variant="button" color={light ? "white" : "dark"}>&nbsp;{PRODUCT_NAME}&nbsp;  </StyledTypography>  </Link> for a better web.
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
            <Box component="ul" sx={({ breakpoints }) => ({ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", listStyle: "none", mt: 0, mb: 0, p: 0, [breakpoints.up("md")]: { justifyContent: 'right' } })} >
              <Box component="li" pr={2} lineHeight={1}>
                <Link href="https://adrundigital.com/featured-promos?p=1" target="_blank">
                  <StyledTypography variant="button" fontWeight="regular" color={light ? "white" : "dark"}> Promos </StyledTypography>
                </Link>
              </Box>
              <Box component="li" pr={2} lineHeight={1}>
                <Link href="https://www.adrundigital.com/about-us" target="_blank">
                  <StyledTypography variant="button" fontWeight="regular" color={light ? "white" : "dark"}> About Us </StyledTypography>
                </Link>
              </Box>
              <Box component="li" pr={2} lineHeight={1}>
                <Link href="https://www.adrundigital.com/contact-us" target="_blank">
                  <StyledTypography variant="button" fontWeight="regular" color={light ? "white" : "dark"}> Support Center </StyledTypography>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
    </Box>
  );
}

export { CopyRight };
