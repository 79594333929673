import { cookieServices } from '../../_helpers';
import { INITIAL_LOADER, PAGE_LOADER, SET_DRAWER, SET_TOAST, SET_THEME, SET_THEME_LAYOUT } from '../actions';

/**
 * This is defining the initial state of the Redux store for the application.
 * It contains several properties with their initial values:
 * */
const initialState = {
    initialLoader: true,
    pageLoader: false,
    isDrawerOpen: ((localStorage.getItem('is_drawer_open') && (localStorage.getItem('is_drawer_open') === 'true')) ? true : false),
    toastMessage: null,
    theme: (cookieServices.get('isDarkMode') === 'true') ? 'dark' : ((cookieServices.get('isDarkMode') === 'false') ? ('light') : (null)),
    themeLayout: (cookieServices.get('layout')) ? cookieServices.get('layout') : ('vertical')
};

export function GlobalReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Initial loader redux */
        case INITIAL_LOADER:
            return {
                ...state,
                initialLoader: action.loader,
            };
        /** end::Initial loader redux */

        /** begin::Page loader redux */
        case PAGE_LOADER:
            return {
                ...state,
                pageLoader: action.loader,
            };
        /** end::Page loader redux */

        /** begin::Set drawer redux */
        case SET_DRAWER:
            return {
                ...state,
                isDrawerOpen: action.isOpen,
            };
        /** end::Set drawer redux */

        /** begin::Set toast redux */
        case SET_TOAST:
            return {
                ...state,
                toastMessage: action.message,
            };
        /** end::Set drawer redux */

        /** begin::Set theme redux */
        case SET_THEME: {
            return {
                ...state,
                theme: (action.mode) ? 'dark' : 'light',
            };
        }
        /** end::Set theme redux */

        /** begin::Set theme navigation menu redux */
        case SET_THEME_LAYOUT: {
            return {
                ...state,
                themeLayout: (cookieServices.get('layout') === 'vertical') ? 'horizontal' : 'vertical',
            };
        }
        /** end::Set theme navigation menu redux */

        default:
            return state;
    }
}
