module.exports = {
    /**
     * function to validate the registration form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author 
     * @created_at 30 May 2022
     */
    validate: (name, value, data = {}) => {
        const errors = {};
        switch (name) {
            case 'cgst': {
                errors.cgst = '';
                if (value === '' && data.configure_gst === 'true')
                    errors.cgst = 'Enter CSGT';
                break;
            } case 'sgst': {
                errors.sgst = '';
                if (value === '' && data.configure_gst === 'true')
                    errors.sgst = 'Enter SSGT';
                break;
            } case 'configure_gst': {
                errors.configure_gst = '';
                if (value === '')
                    errors.configure_gst = 'Select Configure GST';
                break;
            } case 'gstin': {
                errors.gstin = '';
                if (value === '' && data.configure_gst === 'true')
                    errors.gstin = 'Enter GSTIN';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}