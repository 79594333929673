import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { postAuthRoutes } from '../../../../routes';
import { handleInputChange, validateForm } from '../../../../_helpers';

import { CancelButton, SubmitButton } from '../../../../_components/controls';
import { FormInput, FormPassword, FormSelect } from '../../../../_components/form';
import { Grid, Stack, Box } from '@mui/material';
import { validate } from './validate';
import { Fragment } from 'react';
import { AppUserAction } from '../../../../redux/actions';
import { Card, CardContent, CardHeader } from '../../../../_components';

// Initialize form input values to null
const inputs = { user_id: '', name: '', mobile_number: '', account_type: '', password: '', confirm_password: '' };


function CreateUser() {
	/** Initialize plugins and variables */
	const dispatch = useDispatch();
	const navigate = useNavigate();

	/** Plan id and details on update page */
	const location = useLocation();
	const { user } = location.state ?? {};

	/** Redux actions and state */
	const addUser = (params) => dispatch(AppUserAction.addUser(params));
	const updateUser = (params) => dispatch(AppUserAction.updateUser(params));
	const accountTypeSelectList = (params) => dispatch(AppUserAction.accountTypeSelectList(params));

	const { add_app_user_loading: isLoading, add_app_user_errors: createUserErrors, account_type_select_list, account_type_select_list_loading: isAccountTypeLoading, add_app_user_requests } = useSelector((state) => state.AppUserReducer);

	/** Initialize and declare state */
	const [data, setData] = useState({ ...inputs });
	const [errors, setErrors] = useState({ ...inputs });
	const [action, setAction] = useState({ isSubmitted: false });

	useEffect(() => {
		setErrors({ ...createUserErrors });
	}, [createUserErrors]);

	/**
	 * function to handle input changes and modify the value
	 * @param {string} null
	 * @author Naveen K
	 * @created_at 10 Oct 2023
	 */
	function handleChange(e) {
		const { name, value } = handleInputChange(e);
		setErrors({ ...errors, ...validate(name, value, data) });
		data[name] = value;
		setData({ ...data });
	}
	useEffect(() => {
		accountTypeSelectList();
	}, []);


	useEffect(() => {
		if (user && user._id) {
			setData({
				user_id: user._id,
				name: user.name,
				account_type: user.account_type,
				mobile_number: user.mobile_number,
			});
		} else {
			if (!isAccountTypeLoading && account_type_select_list.length > 0) {
				if (data && data.account_type === '') {
					const selectedValue = account_type_select_list.find(item => item.slug === 'customer')?.value;
					setData({
						...data,
						account_type: selectedValue,
					});
				}
			}
		}
	}, [user, isAccountTypeLoading, account_type_select_list]);


	/**
	 * function to handle submit entered values
	 * @param {object} e form object
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	function handleSubmit(e) {
		e.preventDefault();
		setAction({ isSubmitted: true });

		const formErrors = {};
		for (const [name, value] of Object.entries(data)) {
			const inputError = validate(name, value, data);
			formErrors[name] = inputError[name];
		}
		let valid = validateForm(formErrors);
		if (!valid) {
			setErrors(formErrors);
			setAction({ isSubmitted: true });
			return;
		}

		addRestaurantMenu(data);
	}

	/**
	 * function to create/update restaurant menu
	 * @param {object} formData form object
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	async function addRestaurantMenu(formData) {
		const isCreated = user && user._id ? await updateUser(formData) : await addUser(formData);
		// if (isCreated && isCreated.status === 1) {
		// 	let redirectPath = postAuthRoutes('users').path;
		// 	navigate(redirectPath);
		// }
	}

	/**
	 * Function to cancel create/update restaurant menu
	 * @param {object} formData form object
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	function cancelUserCreate() {
		let redirectPath = postAuthRoutes('users').path;
		navigate(redirectPath);
	}

	useEffect(() => {
		if (add_app_user_requests && add_app_user_requests.status === 1) {
			navigate(postAuthRoutes('users').path);

		}
	}, [add_app_user_requests]);

	return (
		<Box py={3}>
			<CardHeader title={user && user._id ? postAuthRoutes('update_users').name : postAuthRoutes('create_users').name} />
			<form onSubmit={handleSubmit} noValidate>
				<Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
					<Grid item xs={12} md={6} lg={6}>
						<Card >
							<CardContent >
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<FormInput
											tabIndex={2}
											label='Name'
											name='name'
											value={data.name}
											error={action.isSubmitted && (errors.name ? errors.name : '')}
											onChange={handleChange}
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<FormInput
											tabIndex={3}
											label='Mobile Number'
											name='mobile_number'
											value={data.mobile_number}
											error={action.isSubmitted && errors.mobile_number ? errors.mobile_number : ''}
											onChange={handleChange}
											maskType={`digit-only`}
											inputProps={{ maxLength: 12 }}
											autoComplete={`mobile_number`}
										/>
									</Grid>
									{!isAccountTypeLoading && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<FormSelect
											label="Account Type"
											name="account_type"
											placeholder="Account Type"
											onChange={handleChange}
											value={data.account_type}
											error={(action.isSubmitted && (errors.account_type)) ? (errors.account_type) : ''}
											data={account_type_select_list}
											tabIndex={12}
										/>
									</Grid>}
									{!(user && user._id) && <Fragment>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<FormPassword
												tabIndex={3}
												label='Password'
												name='password'
												value={data.password}
												error={action.isSubmitted && errors.password ? errors.password : ''}
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<FormPassword
												tabIndex={4}
												label='Re-enter password'
												name='confirm_password'
												value={data.confirm_password}
												error={action.isSubmitted && errors.confirm_password ? errors.confirm_password : ''}
												onChange={handleChange}
											/>
										</Grid>
									</Fragment>}
									<Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
										<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
											<SubmitButton label={user && user._id ? 'Update' : 'Create'} loading={isLoading} tabIndex={5} />
											<CancelButton onClick={cancelUserCreate} tabIndex={6} />
										</Stack>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</form>
		</Box>
	);
}

export { CreateUser };
