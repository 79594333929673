import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { Download } from "@mui/icons-material";
import { ListItemIcon, MenuItem } from "@mui/material";
import { PromoAction } from "../../../../../redux/actions";
import { Fragment } from "react";


function PromoExport({ ...props }) {

    /** Initialize props values */
    const { filter } = props;

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const csvLink = useRef();

    /** Redux reducer state */
    const { export_promo_loading: isLoading, export_promo, export_headers } = useSelector((state) => state.PromoReducer);

    /** Redux reducer actions */
    const exportPromoCsv = (params) => dispatch(PromoAction.exportPromoCsv(params));

    /** Initialize and declare state */
    const [isExporting, setIsExporting] = useState(false)
    const [exportDisabled, setExportDisabled] = useState(true)

    /**
     * function to export csv file for promos
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const getPromos = () => {
        if (!exportDisabled) {
            setExportDisabled(true)
            setIsExporting(true);
            exportPromoCsv({ filter: filter });

        }
    }

    useEffect(() => {
        if (isExporting && !isLoading) {
            csvLink.current.link.click();
            setIsExporting(false);
            setExportDisabled(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [export_promo])

    useEffect(() => {
        if (csvLink.current) {
            setExportDisabled(false)
        }
    }, [csvLink])

    return (
        <Fragment>
            <MenuItem onClick={(e) => { e.stopPropagation(); getPromos() }} disabled={exportDisabled} key={1}>
                <ListItemIcon>
                    <Download fontSize="small" />
                </ListItemIcon>
                Export to CSV
            </MenuItem>
            <MenuItem key={2} style={{ display: `none` }}>
                <CSVLink data={export_promo} filename="promos.csv" asyncOnClick={true} headers={export_headers} ref={csvLink}>Download</CSVLink>
            </MenuItem>
        </Fragment>
    )
}

export { PromoExport }