import { FormControl, styled } from '@mui/material';

const FormSwitchStyled = styled(({ ...props }) => <FormControl {...props} />)`
	& .MuiFormControlLabel-root {
		display: flex;
		justify-content: left;
	}
`;

export { FormSwitchStyled };
