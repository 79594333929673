module.exports = {
	/**
	 * function to validate the registration form inputs
	 * @param {string} name Input name
	 * @param {string} value Input value
	 * @param {object} [data={}] All input values stored in the state
	 * @author
	 * @created_at 09 Oct 2023
	 */
	validate: (name, value, data = {}) => {
		const errors = {};
		switch (name) {
			case 'short_code': {
				errors.short_code = '';
				if (value === '')
					errors.short_code = 'Enter short code';
				break;
			} case 'name': {
				errors.name = '';
				if (value === '')
					errors.name = 'Enter name';
				break;
			} case 'category': {
				errors.category = '';
				if (value === '')
					errors.category = 'Enter/Select category';
				break;
			} case 'description': {
				errors.description = '';
				if (value === '')
					errors.description = 'Enter description';
				break;
			}
			default: {
				errors[name] = '';
				break;
			}
		}
		return errors;
	},
};
