import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Box } from '@mui/material';
import { RestaurantOrdersAction } from '../../../../../../redux/actions';
import { StatisticsWidget, CurrencySymbol } from '../../../../../../_components';

function MonthlyCollectionWidget({filter, ...props}) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    const { monthly_order_collection_loading: isLoading, monthly_order_collection } = useSelector((state) => state.RestaurantOrdersReducer);

    /** Redux reducer actions */
    const monthlyOrderCollection = (params) => dispatch(RestaurantOrdersAction.monthlyOrderCollection(params));

    /** Initialize and declare state */
	const [payload, setPayload] = useState({ filter });

    useEffect(() => {
		setPayload({ filter });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

    useEffect(() => {
        getData();
        //eslint-disable-next-line
    }, [payload.filter]);

    /**
     * function to fetch monthly order collection
     * @param {Object} payload - The params which are used for the api
     * @return json
     */
    const getData = async () => {
        await monthlyOrderCollection(payload);
    }

    return (
        <Box>
            <StatisticsWidget
                color="dark"
                icon={<CurrencySymbol />}
                title="Monthly Collection"
                count={monthly_order_collection}
                percentage={{
                    // color: `${wallet_chart.balanceAmount > 0 ? 'success' : 'error'}`,
                    // amount: `${wallet_chart.balanceAmount}%`,
                    label: <> Your monthly collection is &nbsp; <span style={{ whiteSpace: 'nowrap' }}> <CurrencySymbol /> &nbsp; {monthly_order_collection} </span> </>
                }}
            />
        </Box>
    );
}

export { MonthlyCollectionWidget };