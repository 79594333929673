import React, {useState} from 'react'
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Instagram, Email, Phone, CheckCircle, MoreVert } from '@mui/icons-material'
import { Box, CardActionArea, CardHeader, CardMedia, Chip, Grid, Typography, IconButton, Menu, MenuItem } from '@mui/material'
import { postAuthRoutes } from '../../../../routes'
import { Card, CardContent } from '../../../../_components/card'
import { Fragment } from 'react'
import { AbilityCan } from '../../../../_helpers/permission/AbilityCan'
import { DeleteInfluencer } from "./influencer-delete";

function InfluencerCard({ influencer, height = `100%` }) {

    /** Initialize plugins and variables */
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [showDeleteInfluencerModal, setShowDeleteInfluencerModal] = useState(false);

    /** Initialize form values from influencer */
    const { _id, status, insta_profile, email, insta_handle, email_verify_status, mobile_number, mobile_verify_status } = influencer;

    /** Redux actions and state */
    const { user } = useSelector((state) => state.UserReducer);

    /**
     * function to handle click menu
     * @param {object} event form object
     * @author
     * @created_at 11 Mar 2022
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * function to handle close menu
     * @param {string} null
     * @author
     * @created_at 11 Mar 2022
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * function to handle click to direct to influencer details page
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleDetailsClick() {
        const pathname = postAuthRoutes('influencer_details').path;
        navigate({ pathname, search: createSearchParams(`?influencerId=${_id}`).toString() });
    }

    /**
     * function to delete influencer
     * @return view
     * @author
     * @created_at 08 Mar 2024
     */
    const deleteInfluencerModal = () => {
        setShowDeleteInfluencerModal(!showDeleteInfluencerModal);
    }

    return (
        <Card >
            <CardHeader action={
                <Fragment>
                    <Chip size='small' label={status === 'active' ? 'Active' : 'Inactive'} color={status === 'active' ? 'success' : 'error'} />
                    <IconButton aria-label="more" id="long-button" aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} ><MoreVert /></IconButton>
                    <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button' }} anchorEl={anchorEl} open={open} onClose={handleClose}  >
                        <AbilityCan I='brand_influencer_delete' passThrough={(user.role_slug === 'developer') ? true : false}>
                            <MenuItem onClick={deleteInfluencerModal}>Delete Influencer</MenuItem>
                        </AbilityCan>
                    </Menu>
                </Fragment>
            }>
            </CardHeader>
            <CardActionArea onClick={handleDetailsClick} sx={{ height: height, alignItems: `center`, justifyContent: `center` }}>
                <CardContent>
                    <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 0 }}>
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: `200px` }}>
                                <CardMedia height={`100%`} component={"img"} src={insta_profile.profile_picture_url ? insta_profile.profile_picture_url : 'IMAGE_NOT_AVAILABLE'} onError={e => { e.target.src = insta_profile.profile_picture_url_error }} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} alignItems={`center`} justifyContent={`center`} sx={{ minHeight: `137px` }}>
                            <Typography variant="h5" align='center' mb={1}>{`${insta_profile.name ? insta_profile.name : '-'}`}</Typography>
                            <Typography variant="h6" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}><Instagram />&nbsp;{`@${insta_handle}`}</Typography>
                            {email && <Typography variant="h6" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}><Email />&nbsp;{`${email}`}&nbsp;{email_verify_status ? <CheckCircle color='success' size='small' /> : <CheckCircle color='light' size='small' />}</Typography>}
                            {mobile_number && <Typography variant="h6" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}><Phone />&nbsp;{`${mobile_number}`}&nbsp;{mobile_verify_status ? <CheckCircle color='success' size='small' /> : <CheckCircle color='light' size='small' />}</Typography>}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} display={`flex`} alignItems={`center`} justifyContent={`center`} mt={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{ backgroundColor: 'info.main', width: `100%`, p: 1 }}  >
                                <Typography variant="h6" color={`white`} align={`center`}>{insta_profile.followers_count}</Typography>
                                <Typography variant="body2" color={`white`} align={`center`}>{`Followers`}</Typography>
                            </Box>
                        </Grid>
                        <DeleteInfluencer show={showDeleteInfluencerModal} closeModal={deleteInfluencerModal} influencer_details={influencer}/>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    )

}

export { InfluencerCard }