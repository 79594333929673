import React from 'react';
import { styled, Card } from '@mui/material';

const RestaurantTablesCardStyled = styled(({ ...props }) => <Card {...props} />)`
    height: 100%;
    &  .MuiCardHeader-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
	& .MuiCardMedia-root {
		margin:0;
	}
	& .MuiCardContent-root {
        & .MuiTypography-body2{
            height: 80px;
        }
        & .MuiChip-root{
            margin-top:10px;
        }
	}
`;

export { RestaurantTablesCardStyled };
