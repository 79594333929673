import { MessagesServices } from "../services";

export const CONVERSATIONS_LIST_REQUEST = 'CONVERSATIONS_LIST_REQUEST';
export const CONVERSATIONS_LIST_SUCCESS = 'CONVERSATIONS_LIST_SUCCESS';
export const CONVERSATIONS_LIST_FAILURE = 'CONVERSATIONS_LIST_FAILURE';

export const CONVERSATION_DETAILS_REQUEST = 'CONVERSATION_DETAILS_REQUEST';
export const CONVERSATION_DETAILS_SUCCESS = 'CONVERSATION_DETAILS_SUCCESS';
export const CONVERSATION_DETAILS_FAILURE = 'CONVERSATION_DETAILS_FAILURE';

export const SEND_DIRECT_MESSAGE_REQUEST = 'SEND_DIRECT_MESSAGE_REQUEST';
export const SEND_DIRECT_MESSAGE_SUCCESS = 'SEND_DIRECT_MESSAGE_SUCCESS';
export const SEND_DIRECT_MESSAGE_FAILURE = 'SEND_DIRECT_MESSAGE_FAILURE';


export const MessagesAction = {

    getConversationsList: (params) => {
        return dispatch => {
            dispatch(request());
            return MessagesServices.getConversationsList()
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); });
        };

        function request() { return { type: CONVERSATIONS_LIST_REQUEST } }
        function success(response) { return { type: CONVERSATIONS_LIST_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: CONVERSATIONS_LIST_SUCCESS, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    getConversationDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return MessagesServices.getConversationDetails(params)
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); });
        };

        function request(params) { return { type: CONVERSATION_DETAILS_REQUEST, request: params } }
        function success(response) { return { type: CONVERSATION_DETAILS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: CONVERSATION_DETAILS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    sendDirectMessage: (params) => {
        return dispatch => {
            dispatch(request());
            return MessagesServices.sendDirectMessage(params)
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); });
        };

        function request(params) { return { type: SEND_DIRECT_MESSAGE_REQUEST, request: params } }
        function success(response) { return { type: SEND_DIRECT_MESSAGE_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: SEND_DIRECT_MESSAGE_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    }

}
