import { apiServices } from '../../_helpers';


export const RestaurantMenuServices = {
	/**
	 * Service to list restaurant menu
	 * @param {Object} params - The params which are used for change password api
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	listRestaurantMenu: (params) => {
		return apiServices.post('/brand/restaurant-menu', params).then((response) => {
			return response;
		});
	},

	/**
	 * Service for Creating Restaurant Menu
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	createRestaurantMenu: (params) => {
		return apiServices.post('/brand/restaurant-menu/create', params, { headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {
			return response;
		});
	},

	/**
	 * Service for update Restaurant Menu
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	updateRestaurantMenu: (params) => {
		return apiServices.put('/brand/restaurant-menu/update', params, { headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {
			return response;
		});
	},

	/**
	 * Service for change Restaurant Menu
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	changeRestaurantMenuStatus: (params) => {
		return apiServices.put('/brand/restaurant-menu/change-status', params).then((response) => {
			return response;
		});
	},

	/**
	 * Service for deleting an Restaurant Menu
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	deleteRestaurantMenu: (params) => {
		return apiServices.put('/brand/restaurant-menu/delete', params).then((response) => {
			return response;
		});
	},

	/**
	 * Service for Category select list
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 11 Oct 2023
	 */
	categorySelectList: (params) => {
		return apiServices.post('/brand/restaurant-menu/category-select-list', params).then((response) => {
			return response;
		});
	},
	/**
	 * Service for Category select list
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 11 Oct 2023
	 */
	itemTypeSelectList: (params) => {
		return apiServices.post('/brand/restaurant-menu/item-type-select-list', params).then((response) => {
			return response;
		});
	},

	/**
	 * Service for add-on select list
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 11 Oct 2023
	 */
	addOnSelectList: (params) => {
		return apiServices.post('/brand/restaurant-menu/add-on-select-list', params).then((response) => {
			return response;
		});
	},

	/**
	 * Service to list restaurant menu
	 * @param {Object} params - The params which are used for change password api
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	listRestaurantTables: (params) => {
		return apiServices.post('/brand/restaurant-table', params).then((response) => {
			return response;
		});
	},

	/**
	 * Service for Creating Restaurant Menu
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	createRestaurantTables: (params) => {
		return apiServices.post('/brand/restaurant-table/create', params).then((response) => {
			return response;
		});
	},

	/**
	 * Service for update Restaurant Menu
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	updateRestaurantTables: (params) => {
		return apiServices.put('/brand/restaurant-table/update', params).then((response) => {
			return response;
		});
	},

	/**
	 * Service for change Restaurant Menu
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	changeRestaurantTablesStatus: (params) => {
		return apiServices.put('/brand/restaurant-table/change-status', params).then((response) => {
			return response;
		});
	},

	/**
	 * Service for change Restaurant table categories
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	getRestaurantTableCategories: (params) => {
		return apiServices.post('/brand/restaurant-table/categories', params).then((response) => {
			return response;
		});
	},

	/**
     * Service to get restaurant menu details
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.brand_id
     * @author Naveen
     * @created_at 21 Nov 2023
    */
    restaurantMenuDetails: (params) => {
        return apiServices.post('brand/restaurant-menu/details', params)
            .then((response) => { return response; })
    },


	/**
     * Service to get restaurant menu details
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.brand_id
     * @author Naveen
     * @created_at 21 Nov 2023
    */
    configureGst: (params) => {
        return apiServices.post('brand/restaurant-menu/gst/configure', params)
            .then((response) => { return response; })
    },


	/**
     * Service to get restaurant menu details
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.brand_id
     * @author Naveen
     * @created_at 21 Nov 2023
    */
    getGstDetails: (params) => {
        return apiServices.post('brand/restaurant-menu/gst/details', params)
            .then((response) => { return response; })
    },

	/**
	 * Service for Menu Category select list
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 14 Dec 2023
	 */
	menuCategorySelectList: (params) => {
		return apiServices.post('/brand/restaurant-menu/menu-category-select-list', params).then((response) => {
			return response;
		});
	},

	/**
     * Service to set future orders
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.brand_id
     * @author Naveen
     * @created_at 21 Nov 2023
    */
    setFutureOrders: (params) => {
        return apiServices.post('brand/restaurant-menu/future-orders', params)
            .then((response) => { return response; })
    },

	/**
     * Service to set future orders
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.brand_id
     * @author Naveen
     * @created_at 21 Nov 2023
    */
    getFutureOrdersDetails: (params) => {
        return apiServices.post('brand/restaurant-menu/future-orders/details', params)
            .then((response) => { return response; })
    },

	/**
	 * Service for change Restaurant table select list
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	getRestaurantTableSelectList: (params) => {
		return apiServices.post('/brand/restaurant-table/select-list', params).then((response) => {
			return response;
		});
	},

	/**
     * Service to get restaurant menu list for order
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.brand_id
     * @author Naveen
     * @created_at 21 Nov 2023
    */
    getOrderMenuList: (params) => {
        return apiServices.post('brand/restaurant-menu/order-menu-list', params)
            .then((response) => { return response; })
    },

	/**
     * Service to get restaurant menu item details
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.brand_id
     * @author Naveen
     * @created_at 21 Nov 2023
    */
    getOrderMenuItemDetails: (params) => {
        return apiServices.post('brand/restaurant-menu/order-menu-item', params)
            .then((response) => { return response; })
    },
};