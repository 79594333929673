import React, { useState } from 'react'
import { MoreVert } from '@mui/icons-material';
import { Button, Menu, Box } from '@mui/material';

function FilterMenu({ children, ...props }) {

    /* Initializing the state with the default values. */
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    /**
     * The handleClick function sets the anchor element to the current target of the event.
     * @param {Object} event - The event parameter in the handleClick function is an object that represents the
     * event that triggered the function. It contains information about the event, such as the type of
     * event, the target element, and any additional data related to the event. In this case, the event
     * parameter is used to get the current target
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * The function handleClose sets the value of AnchorEl to null.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <Box>
            <Button onClick={handleClick} size='large' variant="contained" color="primary">
                <MoreVert />
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{ elevation: 0, sx: { overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 32, ml: -0.5, mr: 1 }, '&:before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 } } }}
            >
                {children}
            </Menu>
        </Box>
    )
}

export { FilterMenu }