import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MoreVert, Phone } from '@mui/icons-material'
import { Box, CardActionArea, CardHeader, CardMedia, Chip, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { Card, CardContent } from '../../../../_components/card'
import { postAuthRoutes } from '../../../../routes'
import { Fragment } from 'react'
import { useState } from 'react'

function UserCard({ user, height = `100%`, statusUpdateModal }) {

    /** Initialize form values from influencer */
    const { _id, status, mobile_number, account_type_name, profile_picture, profile_picture_url_error, name } = user;
    const statusData = { id: _id, status: status, item: '' };


    /** Initialize plugins and variables */
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    /**
     * function to handle click menu
     * @param {object} event form object
     * @author
     * @created_at 14 June 2022
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * function to handle close menu
     * @param {string} null
     * @author
     * @created_at 14 June 2022
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * function to direct to update usrt
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleUpdateClick() {
        setAnchorEl(null);
        navigate(postAuthRoutes('update_users').path, { state: { user: user }, });
    }

    /**
     * function to change giveaway status
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleStatusChangeClick() {
        setAnchorEl(null);
        setTimeout(() => {
            statusUpdateModal(statusData);
        }, 100);
    }

    return (
        <Card >
            <CardActionArea sx={{ height: height, alignItems: `center`, justifyContent: `center` }}>
                <CardHeader action={
                    <Fragment>
                        <Chip size='small' label={status === 'active' ? 'Active' : 'Inactive'} color={status === 'active' ? 'success' : 'error'} />
                        <IconButton aria-label="more" id="long-button" aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} ><MoreVert /></IconButton>
                        <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button' }} anchorEl={anchorEl} open={open} onClose={handleClose}  >
                            <MenuItem onClick={handleStatusChangeClick}>{status === 'active' ? 'Deactivate' : 'Activate'}</MenuItem>
                            <MenuItem onClick={handleUpdateClick}>Update</MenuItem>
                        </Menu>
                    </Fragment>
                }>
                </CardHeader>
                <CardContent>
                    <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 0 }}>
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: `200px` }}>
                                <CardMedia height={`100%`} component={"img"} src={profile_picture ? profile_picture : 'IMAGE_NOT_AVAILABLE'} onError={e => { e.target.src = profile_picture_url_error }} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} alignItems={`center`} justifyContent={`center`} sx={{ minHeight: `137px` }}>
                            <Typography variant="h5" align='center' mb={1}>{`${name}`}</Typography>
                            {mobile_number && <Typography variant="h6" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>{`${account_type_name}`}&nbsp;</Typography>}
                            {mobile_number && <Typography variant="h6" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}><Phone />&nbsp;{`${mobile_number}`}&nbsp;</Typography>}
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    )

}

export { UserCard }