import { GlobalAction } from ".";
import { WalletServices } from "../services";

export const CREATE_WALLET_REQUEST = 'CREATE_WALLET_REQUEST';
export const CREATE_WALLET_SUCCESS = 'CREATE_WALLET_SUCCESS';
export const CREATE_WALLET_FAILURE = 'CREATE_WALLET_FAILURE';

export const CREATE_WALLET_CHECKOUT_SESSION_REQUEST = 'CREATE_WALLET_CHECKOUT_SESSION_REQUEST';
export const CREATE_WALLET_CHECKOUT_SESSION_SUCCESS = 'CREATE_WALLET_CHECKOUT_SESSION_SUCCESS';
export const CREATE_WALLET_CHECKOUT_SESSION_FAILURE = 'CREATE_WALLET_CHECKOUT_SESSION_FAILURE';

export const WALLET_HISTORY_REQUEST = 'WALLET_HISTORY_REQUEST';
export const WALLET_HISTORY_SUCCESS = 'WALLET_HISTORY_SUCCESS';
export const WALLET_HISTORY_FAILURE = 'WALLET_HISTORY_FAILURE';

export const WALLET_CHART_REQUEST = 'WALLET_CHART_REQUEST';
export const WALLET_CHART_SUCCESS = 'WALLET_CHART_SUCCESS';
export const WALLET_CHART_FAILURE = 'WALLET_CHART_FAILURE';

export const WALLET_BALANCE_REQUEST = 'WALLET_BALANCE_REQUEST';
export const WALLET_BALANCE_SUCCESS = 'WALLET_BALANCE_SUCCESS';
export const WALLET_BALANCE_FAILURE = 'WALLET_BALANCE_FAILURE';

export const WALLET_TOTAL_CREDIT_REQUEST = 'WALLET_TOTAL_CREDIT_REQUEST';
export const WALLET_TOTAL_CREDIT_SUCCESS = 'WALLET_TOTAL_CREDIT_SUCCESS';
export const WALLET_TOTAL_CREDIT_FAILURE = 'WALLET_TOTAL_CREDIT_FAILURE';

export const WALLET_MONTHLY_CREDIT_REQUEST = 'WALLET_MONTHLY_CREDIT_REQUEST';
export const WALLET_MONTHLY_CREDIT_SUCCESS = 'WALLET_MONTHLY_CREDIT_SUCCESS';
export const WALLET_MONTHLY_CREDIT_FAILURE = 'WALLET_MONTHLY_CREDIT_FAILURE';

export const WALLET_STATEMENTS_REQUEST = 'WALLET_STATEMENTS_REQUEST';
export const WALLET_STATEMENTS_SUCCESS = 'WALLET_STATEMENTS_SUCCESS';
export const WALLET_STATEMENTS_FAILURE = 'WALLET_STATEMENTS_FAILURE';

export const WalletAction = {

    /**
     * Action for list plans
     * @param {Object} params - The params which are used for the api
     * @author Akshay N
     * @created_at 30 May 2022
     */
    createBrandWallet: (params) => {
        return dispatch => {
            dispatch(request(params));
            return WalletServices.createBrandWallet(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: CREATE_WALLET_REQUEST, request: request } }
        function success(request, response) { return { type: CREATE_WALLET_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CREATE_WALLET_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    createWalletCheckoutSession: (params) => {
        return dispatch => {
            dispatch(request(params));
            return WalletServices.createWalletCheckoutSession(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(request, response)); },
                    error => { return dispatch(failure(error)); }
                );
        };


        function request(params) { return { type: CREATE_WALLET_CHECKOUT_SESSION_REQUEST, request: params } }
        function success(params, response) { return { type: CREATE_WALLET_CHECKOUT_SESSION_SUCCESS, request: params, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: CREATE_WALLET_CHECKOUT_SESSION_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action for list wallet history
     * @param {Object} params - The params which are used for the api
     * @author Akshay N
     * @created_at 30 May 2022
     */
    listWalletHistory: (params) => {
        return dispatch => {
            dispatch(request(params));
            return WalletServices.listWalletHistory(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: WALLET_HISTORY_REQUEST, request: request } }
        function success(request, response) { return { type: WALLET_HISTORY_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: WALLET_HISTORY_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for list wallet chart
     * @param {Object} params - The params which are used for the api
     * @author Akshay N
     * @created_at 30 May 2022
     */
    getWalletChart: (params) => {
        return dispatch => {
            dispatch(request(params));
            return WalletServices.getWalletChart(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: WALLET_CHART_REQUEST, request: request } }
        function success(request, response) { return { type: WALLET_CHART_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: WALLET_CHART_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for list wallet balance
     * @param {Object} params - The params which are used for the api
     * @author Akshay N
     * @created_at 30 May 2022
     */
    getWalletBalance: (params) => {
        return dispatch => {
            dispatch(request(params));
            return WalletServices.getWalletBalance(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: WALLET_BALANCE_REQUEST, request: request } }
        function success(request, response) { return { type: WALLET_BALANCE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: WALLET_BALANCE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    
    /**
     * Action for total credit
     * @param {Object} params - The params which are used for the api
     * @author Akshay N
     * @created_at 30 May 2022
     */
    getWalletTotalCredit: (params) => {
        return dispatch => {
            dispatch(request(params));
            return WalletServices.getWalletTotalCredit(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: WALLET_TOTAL_CREDIT_REQUEST, request: request } }
        function success(request, response) { return { type: WALLET_TOTAL_CREDIT_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: WALLET_TOTAL_CREDIT_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

        
    /**
     * Action for monthy credit
     * @param {Object} params - The params which are used for the api
     * @author Akshay N
     * @created_at 30 May 2022
     */
    getWalletMonthlyCredit: (params) => {
        return dispatch => {
            dispatch(request(params));
            return WalletServices.getWalletTotalCredit(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: WALLET_MONTHLY_CREDIT_REQUEST, request: request } }
        function success(request, response) { return { type: WALLET_MONTHLY_CREDIT_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: WALLET_MONTHLY_CREDIT_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
            
    /**
     * Action for statements
     * @param {Object} params - The params which are used for the api
     * @author Akshay
     * @created_at 30 May 2022
     */
    getWalletStatements: (params) => {
        return dispatch => {
            dispatch(request(params));
            return WalletServices.getWalletStatements(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: WALLET_STATEMENTS_REQUEST, request: request } }
        function success(request, response) { return { type: WALLET_STATEMENTS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: WALLET_STATEMENTS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
};
