import React from 'react'
import { Button } from '@mui/material'

function CancelButton({ text = 'Cancel', className, tabIndex, onClick, props }) {
    return (
        <Button className={className} type="button" variant="contained" color="secondary" tabIndex={tabIndex} onClick={() => onClick()} {...props} >
            {text}
        </Button>
    )
}

export { CancelButton }
