const returnInstaHandle = (e) => {
    var regex = new RegExp("^[a-z0-9._]{1,30}");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }
    e.preventDefault();
    return false;
};

export { returnInstaHandle }
