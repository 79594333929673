import { Close, Search } from '@mui/icons-material';
import { IconButton, InputAdornment, InputBase } from '@mui/material';
import React, { useState } from 'react'
import { FormSearchInputStyled } from './FormSearchInputStyled';

const FormSearchInput = ({ ...props }) => {

    const { searchText, getSearchText } = props;

    const [searchKey, setSearchKey] = useState(searchText)

    const checkKeyPress = (e) => {
        if (e.key === 'Enter')
            isEnterPressed()
    };

    const handleChange = (e) => {
        let value = e.target.value;
        setSearchKey(value);
    }

    const isEnterPressed = () => {
        getSearchText(searchKey);
    }

    const clearSearch = () => {
        setSearchKey('');
        getSearchText('');
    }

    return (
        <FormSearchInputStyled sx={{ ...props.sx }}>
            <InputBase
                fullWidth
                type="text"
                placeholder="Search…"
                value={searchKey}
                onKeyPress={checkKeyPress}
                onChange={handleChange}
                endAdornment={
                    searchKey ? (
                        <InputAdornment position="end" >
                            <IconButton aria-label="toggle password visibility" onClick={clearSearch} size='small' sx={{ paddingRight: `0.75rem` }}>
                                <Close />
                            </IconButton>
                        </InputAdornment>
                    ) : (
                        <InputAdornment position="end" sx={{ visibility: `hidden` }}>
                            <IconButton aria-label="toggle password visibility" size='small' sx={{ paddingRight: `0.75rem` }}>
                                <Close />
                            </IconButton>
                        </InputAdornment>
                    )
                }
                startAdornment={
                    <InputAdornment position="end" >
                        <IconButton aria-label="toggle password visibility" size='small'>
                            <Search />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormSearchInputStyled>
    )
}

export { FormSearchInput }