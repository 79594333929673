import React from 'react';
import { styled, Card } from '@mui/material';

const RestaurantMenuCardStyled = styled(({ ...props }) => <Card {...props} />)`
	& .MuiCardMedia-root {
		border-radius: ${(props) => props.theme.borders.borderRadius.lg};
	}
`;

export { RestaurantMenuCardStyled };
