import { Box, alpha, styled } from '@mui/material';

const FormSearchInputStyled = styled(({ ...props }) => <Box {...props} />)`
	position: relative;
	border-radius: 0.375rem;
	background-color: ${(props) => alpha(props.theme.palette.common.white, 0.15)};
	&:hover {
		background-color: ${(props) => alpha(props.theme.palette.common.white, 0.25)};
	}
	margin-left: 0;
	width: '100%';
	${(props) => props.theme.breakpoints.up('sm')} {
		margin-left: ${(props) => props.theme.spacing(3)};
		width: 'auto';
	}

	& .MuiInputBase-root {
		color: inherit;
		min-height: 2.75rem;
		border-radius: ${(props) => `${props.theme.borders.borderRadius.md}`};
		border:  ${(props) => `${props.theme.borders.borderWidth[1]}  solid ${props.theme.borders.borderColor}`};
		& .MuiInputBase-input {
			padding: ${(props) => props.theme.spacing(1)};
			padding-left: ${(props) => props.theme.spacing(1)};
			/* transition: theme.transitions.create('width'); */
			width: 100%;
		}
	}
`;

export { FormSearchInputStyled };
