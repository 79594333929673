import React from 'react';
import { styled, Card } from '@mui/material';

const MenuItemStyled = styled(({ ...props }) => <Card {...props} />)`
	border-radius: ${(props) => `${props.theme.spacing(2.5)}`};
	display: flex;
	align-items: center;
	justify-content: center;
	padding: ${(props) => `${props.theme.spacing(2.5)}`};
	min-height: ${(props) => `${props.theme.functions.pxToRem(300)}`};
	& .item-image {
		border-radius: 50%;
		height: ${(props) => `${props.theme.functions.pxToRem(110)}`};
		width: ${(props) => `${props.theme.functions.pxToRem(110)}`};
		margin: 0;
	}
	& .MuiCardContent-root {
		padding: ${(props) => `${props.theme.spacing(2)} ${props.theme.spacing(0)}`};
		width: 100%;
		& .item-description {
			font-size: ${(props) => `${props.theme.typography.size.xs}`};
		}
	}
	& .MuiCardActions-root {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		padding: ${(props) => `${props.theme.spacing(2)} ${props.theme.spacing(0)} ${props.theme.spacing(0)} ${props.theme.spacing(2)}`};
		& .price-box {
			display: flex;
			align-items: center;
			justify-content: left;
			& .item-price {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			& .item-discount-price {
				display: flex;
				align-items: center;
				justify-content: center;
				text-decoration: line-through;
				font-size: 12px;
				margin-left: 8px;
				& .MuiSvgIcon-root {
					color: ${(props) => props.theme.palette.error.main};
				}
			}
		}
		& .item-add-to-cart-btn {
			min-width: unset;
			font-size: ${(props) => `${props.theme.typography.size.xs}`};
			border-radius: ${(props) => `${props.theme.spacing(2.5)} ${props.theme.spacing(0)} ${props.theme.spacing(2.5)} ${props.theme.spacing(0)}`};
			height: ${(props) => `${props.theme.spacing(5)}`};
			padding: ${(props) => `${props.theme.spacing(0)} ${props.theme.spacing(2)}`};
		}
	}
`;

export { MenuItemStyled };
