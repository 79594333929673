import React from 'react'
import { DialogContentStyled } from '.'

function DialogContent({ className = "", children, ...props }) {
    return (
        <DialogContentStyled className={`${className}`} {...props}>
            {children}
        </DialogContentStyled>
    )
}

export { DialogContent }
