import {
    CONVERSATIONS_LIST_REQUEST, CONVERSATIONS_LIST_SUCCESS, CONVERSATIONS_LIST_FAILURE,
    CONVERSATION_DETAILS_REQUEST, CONVERSATION_DETAILS_SUCCESS, CONVERSATION_DETAILS_FAILURE,
    SEND_DIRECT_MESSAGE_REQUEST, SEND_DIRECT_MESSAGE_SUCCESS, SEND_DIRECT_MESSAGE_FAILURE

} from "../actions";

const initialState = {
    conversations_list_loading: false, conversations_list: [], conversations_list_error: {},
    conversation_details_loading: false, conversation_details: [], conversation_details_string: "",conversation_details_error: {},
    send_direct_message_loading: false, send_direct_message: {}, send_direct_message_error: {},
};

export function MessagesReducer(state = initialState, action) {

    switch (action.type) {

        /** begin::Ig conversation list redux */
        case CONVERSATIONS_LIST_REQUEST: {
            return {
                ...state,
                conversations_list_loading: true,
                conversations_list_error: {}
            };
        } case CONVERSATIONS_LIST_SUCCESS: {

            return {
                ...state,
                conversations_list_loading: false,
                conversations_list: (action.data) ? (action.data) : (state.conversations_list),
                conversations_list_error: {}
            };
        } case CONVERSATIONS_LIST_FAILURE: {
            return {
                ...state,
                conversations_list_loading: false,
                conversations_list_error: action.error
            };
        }
        /** end::Ig conversation list redux */

        /** begin::Ig conversation details redux */
        case CONVERSATION_DETAILS_REQUEST: {
            return {
                ...state,
                conversation_details_loading: true,
                conversation_details_error: {}
            };
        } case CONVERSATION_DETAILS_SUCCESS: {
            return {
                ...state,
                conversation_details_loading: false,
                conversation_details: action.data,
                conversation_details_string: JSON.stringify(action.data),
                conversation_details_error: {}
            };
        } case CONVERSATION_DETAILS_FAILURE: {
            return {
                ...state,
                conversation_details_loading: false,
                conversation_details_error: action.error,
                conversation_details: []
            };
        }
        /** end::Ig conversation list redux */


        /** begin::Ig send dm redux */
        case SEND_DIRECT_MESSAGE_REQUEST: {
            return {
                ...state,
                send_direct_message_loading: true,
                send_direct_message_error: {}
            };
        } case SEND_DIRECT_MESSAGE_SUCCESS: {
            let convString = state.conversation_details_string;
            let newArray = JSON.parse(convString);
            newArray = newArray.concat(action.data);

            return {
                ...state,
                send_direct_message_loading: false,
                conversation_details: newArray,
                send_direct_message_error: {}
            };
        } case SEND_DIRECT_MESSAGE_FAILURE: {
            return {
                ...state,
                send_direct_message_loading: false,
                send_direct_message_error: action.error,
            };
        }
        /** end::Ig conversation list redux */

        default:
            return state;
    }
}