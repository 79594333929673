import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component';
import { Grid, Stack, Box } from '@mui/material'
import { GeneralButton } from '../../../../../_components/controls/general-button/GeneralButton'
import { CardHeader } from '../../../../../_components/card'
import { UpdateDataStatusDialog } from '../../../../../_components/dialog'
import { postAuthRoutes } from '../../../../../routes'
import { Add, FilterAlt } from '@mui/icons-material'
import { FormSearchInput } from '../../../../../_components/form';
import { NoData } from '../../../../../_components/layout'
import queryString from 'query-string';
import { CouponCodeCard, CouponCodeCardLoader } from '../../coupon-codes';
import { GiveawayAction } from '../../../../../redux/actions';
import { GiveawaysCouponCodesFilter } from './giveaways-coupon-codes-filter';
import { CreateOrUpdateGiveawaysCouponCode } from './giveaways-coupon-codes-create-or-update';
import { Fragment } from 'react';

function GiveawaysCouponCodes() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const giveawayId = searchParams.get('gId'); // giveaway id from url

    /** Initialize and declare state */
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });
    const [showCreateCouponModal, setShowCreateCouponModal] = useState(false);
    const [showCouponFilterModal, setShowCouponFilterModal] = useState(false);
    const [payload, setPayload] = useState({ giveaway_id: giveawayId, limit: 100, page: 1, filter: { status: searchParams.get('status') ?? '' }, search: searchParams.get('q') ?? '' });


    /** Redux actions and state */
    const { giveaway_coupon_codes_loading: isLoading, giveaway_coupon_codes: coupon_codes } = useSelector((state) => state.GiveawayReducer);
    const listGiveawayCouponCodes = (params) => dispatch(GiveawayAction.listGiveawayCouponCodes(params));
    const changeCouponStatus = (params) => dispatch(GiveawayAction.changeCouponStatus(params));

    useEffect(() => {

        const queryParam = { ...payload.filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.gId = giveawayId;
        queryParam.p = payload.page;
        if (payload.search) queryParam.q = payload.search; else delete queryParam.q;
        if (payload.filter.status) queryParam.status = payload.filter.status; else delete queryParam.status;

        const pathname = postAuthRoutes('giveaways_coupon_codes').path;
        navigate({ pathname, search: createSearchParams(`?${queryString.stringify(queryParam)}`).toString() });

        getNext();
        //eslint-disable-next-line
    }, [giveawayId, payload.filter, payload.search])

    /**
     * function to fetch giveaway coupon codes list
     * @param {Object} payload - The params which are used for the api
     * @param {string} payload.page - Page number to load
     * @param {string} payload.limit -  Count of the documents per page
     * @param {string} payload.filter - Filter data
     * @return json
     * @author
     * @created_at 30 May 2022
     */
    const getNext = async () => {
        const result = await listGiveawayCouponCodes(payload);
        if (result.status)
            setPayload({ ...payload, page: payload.page + 1 });
    }

    /**
     * function to open/close status update modal
     * @param {string} id
     * @param {string} status
     * @return view
     * @author
     * @created_at 30 May 2022
     */
    const statusUpdateModal = ({ id, status, item }) => {
        setStatusData({ id: id, status: status, item: item });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    /**
     * function to change coupon code status
     * @param {string} id
     * @return view
     * @author
     * @created_at 30 May 2022
     */
    const updateStatusAction = async (id) => {
        setShowStatusUpdateModal(!showStatusUpdateModal);
        await changeCouponStatus({ 'coupon_id': id });
    }

    /**
     * function to open/close create coupon code modal
     * @param {string} null
     * @return view
     * @author
     * @created_at 30 May 2022
     */
    const createCouponCodesModal = () => {
        setShowCreateCouponModal(!showCreateCouponModal);
    }

    /**
     * function to open/close filter modal
     * @param {string} null
     * @return view
     * @author
     * @created_at 30 May 2022
     */
    const couponFilterModal = () => {
        setShowCouponFilterModal(!showCouponFilterModal);
    }

    /**
     * function to apply filter data to payload
     * @param {string} null
     * @return view
     * @author
     * @created_at 30 May 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData });
    }

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    return (
        <Fragment>
            <Box >
                <Fragment>
                    <CardHeader
                        title={postAuthRoutes('giveaways_coupon_codes').name}
                        action={(
                            <Stack spacing={1} direction="row">
                                <GeneralButton startIcon={<Add />} label='Create Coupon Codes' onClick={() => createCouponCodesModal()} />
                                <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                                <GeneralButton label={<FilterAlt />} onClick={() => couponFilterModal()} />
                            </Stack>
                        )}
                    />
                    <GiveawaysCouponCodesFilter show={showCouponFilterModal} closeModal={couponFilterModal} applyFilter={applyFilter} couponFilter={payload.filter} />
                    <InfiniteScroll dataLength={coupon_codes.length} next={getNext} hasMore={true} height={700}>
                        <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center" >
                            {
                                coupon_codes.length > 0 && coupon_codes.map((coupon_code, i) => {
                                    return <Grid key={i} item xs={12} sm={12} md={3} lg={3} xl={3} ><CouponCodeCard category={'giveaway'} promo={{ '_id': giveawayId }} {...coupon_code} isClickable={true} statusUpdateModal={statusUpdateModal} canRedeem={true} /></Grid>;
                                })
                            }
                            {isLoading && (<CouponCodeCardLoader />)}
                            {!isLoading && coupon_codes.length === 0 && <NoData content1={`No`} content2={`Coupon codes  available`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} />}
                        </Grid>
                    </InfiniteScroll>
                </Fragment>
            </Box>
            <UpdateDataStatusDialog show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />
            <CreateOrUpdateGiveawaysCouponCode show={showCreateCouponModal} closeModal={createCouponCodesModal} giveaway_id={giveawayId} />
        </Fragment>
    )
}

export { GiveawaysCouponCodes }
