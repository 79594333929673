import {
	SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_IN_FAILURE,
	INSTAGRAM_SIGN_IN_REQUEST, INSTAGRAM_SIGN_IN_SUCCESS, INSTAGRAM_SIGN_IN_FAILURE,
} from "../actions";

let user = localStorage.getItem('accessToken') ? (localStorage.getItem('accessToken')) : '';

// const initialState = user ? { user, errors: {}, password_errors: {}, instagram_sign_in_loading: false, instagram_sign_in_request: {} } : { request: {}, user: {}, errors: {}, instagram_sign_in_loading: false, instagram_sign_in_request: {} };
const initialState = {
	sign_in_loading: false, sign_in_request: {}, sign_in_errors: {},
	instagram_sign_in_loading: false, instagram_sign_in_request: {}, instagram_sign_in_errors: {},
	user: user
};

export function SignInReducer(state = initialState, action) {
	switch (action.type) {

		/** begin::Sign in redux */
		case SIGN_IN_REQUEST: {
			return {
				sign_in_loading: true,
				sign_in_request: action.request,
				user: {}
			};
		}
		case SIGN_IN_SUCCESS: {
			return {
				sign_in_loading: false,
				sign_in: action.data,
				user: action.data,
			};
		}
		case SIGN_IN_FAILURE: {
			return {
				sign_in_loading: false,
				sign_in_errors: action.errors,
				user: {},
			};
		}
		/** end::Sign in redux */

		/** begin::intagram Sign in redux */
		case INSTAGRAM_SIGN_IN_REQUEST: {
			return {
				instagram_sign_in_loading: true,
				instagram_sign_in_request: action.request,
				user: {}
			};
		}
		case INSTAGRAM_SIGN_IN_SUCCESS: {
			return {
				instagram_sign_in_loading: false,
				instagram_sign_in: action.data,
				user: action.data,
			};
		}
		case INSTAGRAM_SIGN_IN_FAILURE: {
			return {
				instagram_sign_in_loading: false,
				user: {},
			};
		}
		/** end::intagram Sign in redux */

		case "RESET_USER": {
			return {
				user: {},
				check_brand_access: ''
			};
		}

		default:
			return state;
	}
}
