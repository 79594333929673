import { apiServices } from "../../_helpers";


export const CalendarServices = {

    getCalendar: (params) => {

		return apiServices.post('/brand/calendar', params)
			.then((response) => { return response; })
	},
}
