import {
    CURRENCY_REQUEST, CURRENCY_SUCCESS, CURRENCY_FAILURE,
    CREATE_CURRENCY_REQUEST, CREATE_CURRENCY_SUCCESS, CREATE_CURRENCY_FAILURE,
	UPDATE_CURRENCY_REQUEST, UPDATE_CURRENCY_SUCCESS, UPDATE_CURRENCY_FAILURE,
	DELETE_CURRENCY_REQUEST, DELETE_CURRENCY_SUCCESS, DELETE_CURRENCY_FAILURE,
	CURRENCY_SELECT_LIST_REQUEST, CURRENCY_SELECT_LIST_SUCCESS, CURRENCY_SELECT_LIST_FAILURE,
} from "../actions";

const initialState = {
    page: 1, per_page: 18, total: 0, total_pages: 0,
    currency_loading: true, currency: [], currency_errors: [],
    create_currency_loading: false, create_currency_errors: {},
	update_currency_loading: false, update_currency_errors: {},
	currency_select_list_loading: false, currency_select_list: [], currency_select_list_errors: {},
};

export function CurrencyReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::List currency redux */
        case CURRENCY_REQUEST: {
            return {
                ...state,
                currency_loading: true,
                currency_errors: {}
            };
        }
        case CURRENCY_SUCCESS: {

            return {
                ...state,
                currency_loading: false,
                currency_errors: {},
                currency: action.data

            };

        }
        case CURRENCY_FAILURE: {
            return {
                ...state,
                currency_loading: false,
                currency_errors: action.errors,
                currency: []
            };
        }
        /** end::List currency redux */

        /** begin::Create currency redux */
		case CREATE_CURRENCY_REQUEST: {
			return {
				...state,
				create_currency_loading: true,
				create_currency_errors: {},
			};
		}
		case CREATE_CURRENCY_SUCCESS: {
			let currencyArray = [...state.currency.currency_values]; //making a new array
			const currencyIndex = currencyArray.findIndex((currencyMenu) => currencyMenu._id === action.data._id);

			if (currencyIndex !== -1) {
				currencyArray[currencyIndex].status = action.data.status;
			}

			return {
				...state,
				create_currency_loading: false,
				currency: currencyArray,
				create_currency_errors: {},
			};
		}
		case CREATE_CURRENCY_FAILURE: {
			return {
				...state,
				create_currency_loading: false,
				create_currency_errors: action.errors,
			};
		}

		/** end::Create currency redux */

		/** begin::Update currency redux */
		case UPDATE_CURRENCY_REQUEST: {
			return {
				...state,
				update_currency_loading: true,
				update_currency_errors: {},
			};
		}
		case UPDATE_CURRENCY_SUCCESS: {
			let currencyArray = [...state.currency.currency_values];
			const currencyIndex = currencyArray.findIndex((currencyValue) => currencyValue._id === action.data._id);
			if (currencyIndex !== -1) {
				currencyArray[currencyIndex] = action.data;
			}

			return {
				...state,
				currency: currencyArray,
				update_currency_loading: false,
				update_currency: action.data,
				update_currency_errors: {},
			};
		}
		case UPDATE_CURRENCY_FAILURE: {
			return {
				...state,
				update_currency_loading: false,
				update_currency_errors: action.errors,
			};
		}
		/** end::Update currency redux */

        /** begin::Delete currency redux */
		case DELETE_CURRENCY_REQUEST: {
			return {
				...state,
				delete_currency_loading: true,
				delete_currency_errors: {},
			};
		}
		case DELETE_CURRENCY_SUCCESS: {
			let currencyArray = [...state.currency.currency_values];

			const CurrencyFilter = currencyArray.filter((currencyValue) => currencyValue._id !== action.data._id);

			return {
				...state,
				currency: {currency_values: CurrencyFilter},
				delete_currency_loading: false,
				delete_currency: action.data,
				delete_currency_errors: {},
			};
		}
		case DELETE_CURRENCY_FAILURE: {
			return {
				...state,
				delete_currency_loading: false,
				delete_currency_errors: action.errors,
			};
		}
		/** end::Delete currency redux */

		/** begin::Currency select list redux */
        case CURRENCY_SELECT_LIST_REQUEST: {
            return {
                ...state,
                currency_select_list_loading: true,
                currency_select_list_errors: {}
            };
        }
        case CURRENCY_SELECT_LIST_SUCCESS: {

            return {
                ...state,
                currency_select_list_loading: false,
                currency_select_list_errors: {},
                currency_select_list: action.data

            };

        }
        case CURRENCY_SELECT_LIST_FAILURE: {
            return {
                ...state,
                currency_select_list_loading: false,
                currency_select_list_errors: action.errors,
                currency_select_list: []
            };
        }
        /** end::Currency select list redux */
        default:
            return state;
    }
}