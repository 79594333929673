import { apiServices } from '../../_helpers';


export const PromoServices = {

    /**
     * Service for get user details
     * @param {Object} params - The params which are used for the api
     * @param {String} params.name
     * @param {String} params.insta_handle
     * @param {String} params.description
     * @param {String} params.promo_type
     * @param {String} params.promo_start_date
     * @param {String} params.promo_end_date
     * @param {String} params.number_of_promos
     * @param {String} params.influencer_minimum_followers
     * @param {String} params.promo_cost_per_influencer
     * @param {Object} params.file
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    createPromo: (params) => {
        return apiServices.post('/brand/promo/create', params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => { return response; })

    },
    /**
     * Service for update promo
     * @param {Object} params - The params which are used for the api
     * @param {String} params.promo_id
     * @param {String} params.name
     * @param {String} params.insta_handle
     * @param {String} params.description
     * @param {String} params.promo_type
     * @param {String} params.promo_start_date
     * @param {String} params.promo_end_date
     * @param {String} params.number_of_promos
     * @param {String} params.influencer_minimum_followers
     * @param {String} params.promo_cost_per_influencer
     * @param {Object} params.file
     * @author Akshay N 
     * @created_at 30 May 2022
    */
    updatePromo: (params) => {
        return apiServices.put('/brand/promo/update', params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => { return response; })

    },
    /**
     * Service for list brand promos
     * @param {Object} params - The params which are used for change password api
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    listBrandPromos: (params) => {
        return apiServices.post('/brand/promo/list', params)
            .then((response) => { return response; })

    },
    /**
     * Service to get promo count
     * @param {Object} params - The params which are used for change password api
     * @author 
     * @created_at 30 May 2022
     */
    getPromoCount: (params) => {
        return apiServices.post('/brand/promo/count', params)
            .then((response) => { return response; })
    },

    /**
     * Service for list promo coupon codes
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    listPromoCouponCodes: (params) => {
        return apiServices.post('/brand/promo/coupon-codes', params)
            .then((response) => { return response; })

    },

    /**
     * Service for change promo status
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    changeStatus: (params) => {
        return apiServices.put('/brand/promo/change-status', params)
            .then((response) => { return response; })

    },

    /**
     * Service for change coupon status
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    changeCouponStatus: (params) => {
        return apiServices.put('/brand/promo/change-coupon-status', params)
            .then((response) => { return response; })

    },

    /**
     * Service for create coupon code
     * @param {Object} params - The params which are used for the api
     * @param {String} params.promo_id
     * @param {String} params.coupon_code
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    createCouponCode: (params) => {
        return apiServices.put('/brand/promo/create-coupon-code', params)
            .then((response) => { return response; })
    },
    /**
     * Service for list promo stories
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    listPromoStories: (params) => {
        return apiServices.post('/brand/promo/stories', params)
            .then((response) => { return response; })

    },

    /**
     * Service to export promo CSV
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 30 May 2022
    */
    exportPromoCsv: (params) => {
        return apiServices.post('/brand/promo/export', params)
            .then((response) => { return response; })

    },

    /**
     * Service for promo details
     * @author Jasin 
     */
    promoDetails: (params) => {
        return apiServices.post('/brand/promo/details', params)
            .then((response) => { return response; })

    },

    /**
     * Service for list promo stories
     * @author Akshay N 
     */
    listBrandPromoStories: (params) => {
        return apiServices.post('/brand/promo/stories', params)
            .then((response) => { return response; })

    },

    /**
     * Service for list promo coupon codes
     * @author Akshay N 
     */
    listBrandPromoCouponCodes: (params) => {
        return apiServices.post('/brand/promo/coupon-codes', params)
            .then((response) => { return response; })

    },

    /**
     * Service for list promo coupon codes
     * @author Akshay N 
     */
    generateCouponCodes: (params) => {
        return apiServices.post('/brand/promo/coupon-codes/generate', params)
            .then((response) => { return response; })

    },

    /**
     * Service for list promo coupon codes
     * @author Akshay N 
     */
    getRunningPromos: (params) => {
        return apiServices.post('/brand/promo/running', params)
            .then((response) => { return response; })

    },
};
