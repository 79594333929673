import { apiServices } from "../../_helpers";

export const PaymentServices = { 
    

    /**
     * Service for get designations 
     *
     * @author Akshay 
     */
    getPaymentDetails: (params) => {
        return apiServices.get('/brand/payments/details', params)
                .then((response) => { return response; })
    },

    /**
     * Service for get designations 
     *
     * @author Akshay 
     */
    deleteCreditCard: () => {
        return apiServices.post('/brand/payments/delete-card')
        .then((response) => { return response; })
    },

    /**
     * Service for get designations 
     *
     * @author Akshay 
     */
    addUpdateCard: (params) => {
        return apiServices.post('/brand/payments/save-card', params)
        .then((response) => { return response; })
    },

    /**
     * Service for get designations 
     *
     * @author Akshay 
     */
    addUpdateBankDetails: (params) => {
        return apiServices.post('/brand/payments/save-bank-account', params)
        .then((response) => { return response; })
    },

    /**
     * Service for get designations 
     *
     * @author Akshay 
     */
    deleteBankDetails: (params) => {
        return apiServices.get('/brand/payments/delete-bank-account', params)
        .then((response) => { return response; })
    },

    /**
     * Service for get designations 
     *
     * @author Akshay 
     */
    verifyPaymentDetails: (params) => {
        return apiServices.post('/brand/payments/verify-bank-account', params)
        .then((response) => { return response; })
    },

    /**
     * Service for get designations 
     *
     * @author Akshay 
     */
    setDefaultPaymentMethod: (params) => {
        return apiServices.post('/brand/payments/default-payment', params)
        .then((response) => { return response; })
    }
}

