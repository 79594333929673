import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterAlt } from '@mui/icons-material';
import { Box, Grid, Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { postAuthRoutes } from '../../../../../routes';
import { Card, CardContent, CardHeader } from '../../../../../_components/card';
import { GeneralButton } from '../../../../../_components/controls';
import { FormSearchInput } from '../../../../../_components/form';
import { TodaysOrderFilter, TodaysOrderStatusChangeModal, TodaysRestaurantOrderDataTable } from '.';
import { AbilityCan } from '../../../../../_helpers/permission/AbilityCan';
import { RestaurantOrdersAction } from '../../../../../redux/actions';

const statusLabels = {
	'active': { text: 'Active', color: 'info' },
	'open': { text: 'Open', color: 'info' },
	'order_placed': { text: 'Order Placed', color: 'info' },
	'cooking_in_progress': { text: 'Cooking In progress', color: 'warning' },
	'ready_to_deliver': { text: 'Ready To Deliver', color: 'warning' },
	'delivered': { text: 'Delivered', color: 'warning' },
	'bill_issued': { text: 'Bill issued', color: 'info' },
	'paid': { text: 'Paid', color: 'success' },
	'cancelled': { text: 'Order Cancelled', color: 'error' },
};


function TodaysRestaurantOrder() {

    /** Initialize plugins and variables */
	const { search } = useLocation();
	const dispatch = useDispatch();

	const searchParams = new URLSearchParams(search);

	const changePreOrderStatus = (params) => dispatch(RestaurantOrdersAction.changePreOrderStatus(params));
    const generateRestaurantPreOrderBill = (params) => dispatch(RestaurantOrdersAction.generateRestaurantPreOrderBill(params));

	/** Redux reducer state */
	const { user } = useSelector((state) => state.UserReducer);
    const { total, todays_restaurant_orders_loading: isLoading, todays_restaurant_orders } = useSelector((state) => state.RestaurantOrdersReducer);

	const [showTodaysOrderFilterModal, setShowTodaysOrderFilterModal] = useState(false);
	const [payload, setPayload] = useState({ limit: 100, page: 1, per_page: 10, filter: { mobile: searchParams.get('mobile') ?? ''}, search: searchParams.get('q') ?? '' });
	const [orderStatus, setOrderStatus] = useState('');
	const [anchorEl, setAnchorEl] = useState(null);
	const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
	const [statusData, setStatusData] = useState({  status: '' });
    const [response, setResponse] = useState({});

    /**
	 * function to open/close todays Order filter modal
	 * @param {string} null
	 * @return view
	 * @author Naveen
	 * @created_at 18 Dec 2023
	 */
	const todaysOrderFilterModal = () => {
		setShowTodaysOrderFilterModal(!showTodaysOrderFilterModal);
	};

    /**
	 * function to apply the selected filter options on the todays order
	 * @param {Object} filterData
	 * @param {String} filterData.status Selected status from the todays order filter modal
	 * @return view
	 * @author Naveen
	 * @created_at 18 Dec 2023
	 */
	const applyFilter = (filterData) => {
		setPayload({ ...payload, page: 1, filter: filterData });
	};

    /**
	 * function to apply the search key words on the todays order mobile
	 * @param {String} text Search keyword used
	 * @return view
	 * @author Naveen
	 * @created_at 18 Dec 2023
	 */
	const applySearch = (text) => {
		setPayload({ ...payload, page: 1, search: text });
	};

	
	function handleStatusChangeClick(status) {
		setAnchorEl(null);
		setTimeout(() => {
			statusUpdateModal({ status });
		}, 100);
	}

	const isButtonDisabled = (order_status, status = 'bill_issued', main = false) => {
		const statusIndex = Object.keys(statusLabels).indexOf(status);
		const orderStatusIndex = Object.keys(statusLabels).indexOf(order_status);
		if (main) {
			return statusIndex <= orderStatusIndex;
		}
		return orderStatusIndex >= statusIndex;
	};

	useEffect(() => {
		if (!isLoading) {
			if (todays_restaurant_orders && todays_restaurant_orders.length > 0) {
				setOrderStatus(todays_restaurant_orders[0].order_status)
			} else {
				setOrderStatus('')
			}
		}

	}, [todays_restaurant_orders, isLoading])
	


    const OrderActionButtons = () => {
		let buttons = Object.keys(statusLabels).map((status) => {
			if (status !== 'order_placed' && status !== 'open') {
				let isItemDisabled = isButtonDisabled(orderStatus, status, true);
				let statusData = statusLabels[status];
				if (status === 'bill_issued')
					statusData.text = 'Requested for Bill';
                return <GeneralButton size='medium' label={statusData.text} color={statusData.color} onClick={() => updateOrderStatus( status)} disabled={isLoading || isItemDisabled || (!todays_restaurant_orders || (todays_restaurant_orders && todays_restaurant_orders.length === 0))} style={{ marginLeft: 5 }}/>;
			}
			return '';
		});
		return <Box>{buttons}</Box>;
	};

    
	const updateOrderStatus = async (status) => {

		if (status === 'paid' || status === 'cancelled' || status === 'bill_issued')
			handleStatusChangeClick(status);
		else
			await changePreOrderStatus({ status });
	};

	
	const statusUpdateModal = ({ status }) => {
		setStatusData({ status: status });
		setShowStatusUpdateModal(!showStatusUpdateModal);
	};

	const updateStatusAction = async (id) => {
		setShowStatusUpdateModal(!showStatusUpdateModal);
		await changePreOrderStatus({ status: statusData.status });
	};

	const generateBill = async () => {
		let result = await generateRestaurantPreOrderBill({ status: statusData.status });
        setResponse(result);

	};

    useEffect(() => {
        if (response.status === 1) {
			setShowStatusUpdateModal(!showStatusUpdateModal);
			setResponse({})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    return (
		<React.Fragment>
				<Box>
					<CardHeader
						action={
							<Stack spacing={1} direction="row">
							<AbilityCan I="brand_todays_restaurant_pre_order_search" passThrough={user.role_slug === 'developer' ? true : false}>
								<FormSearchInput getSearchText={applySearch} searchText={payload.search} />
							</AbilityCan>
							<AbilityCan I="brand_todays_restaurant_pre_order_filter" passThrough={user.role_slug === 'developer' ? true : false}>
								<GeneralButton label={<FilterAlt />} onClick={() => todaysOrderFilterModal()} />
							</AbilityCan>
							</Stack>
						}
					/>
				</Box>
				<TodaysOrderFilter show={showTodaysOrderFilterModal} closeModal={todaysOrderFilterModal} applyFilter={applyFilter} todaysOrderFilter={payload.filter} />
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Box display="flex" justifyContent="flex-end">
						<OrderActionButtons />
					</Box>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Card>
							<CardContent>
							
								<TodaysRestaurantOrderDataTable filter={payload.filter} search={payload.search} limit={payload.limit} page={payload.page} per_page={payload.per_page}/>
							</CardContent>
						</Card>
					</Grid>
				</Grid>

				<TodaysOrderStatusChangeModal show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} generateBill={generateBill} />
		</React.Fragment>

	)
}

export { TodaysRestaurantOrder };