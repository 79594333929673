import { apiServices } from '../../_helpers';


export const NotificationServices = {

    /**
     * Service to get notification list
     * @param {Object} params - The params which are used for change password api
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    getNotificationList: (params) => {
        return apiServices.post('/brand/notification/list', params)
            .then((response) => { return response; })
    },

     /**
     * Service to mark all notifications as read
     * @param {Object} params - The params which are used for change password api
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    markAllRead: () => {
        return apiServices.put('/brand/notification/mark-all-read')
            .then((response) => { return response; })

    },

    /**
     * Service to mark notification as read
     * @param {Object} params - The params which are used for change password api
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    markRead: (params) => {
        return apiServices.put('/brand/notification/mark-read', params)
            .then((response) => { return response; })

    },

};
