import React, { Fragment, useEffect, useState } from 'react';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup, Chip, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { RestaurantOrdersAction } from '../../../../../../redux/actions';
import { postAuthRoutes } from '../../../../../../routes';
import { DataTable } from '../../../../../../_components';
import { MoreVert } from '@mui/icons-material';


const statusLabels = {
	'active': { text: 'Active', color: 'info' },
	'open': { text: 'Open', color: 'info' },
	'order_placed': { text: 'Order Placed', color: 'info' },
	'cooking_in_progress': { text: 'Cooking In progress', color: 'warning' },
	'ready_to_deliver': { text: 'Ready To Deliver', color: 'warning' },
	'delivered': { text: 'Delivered', color: 'warning' },
	'bill_issued': { text: 'Bill issued', color: 'info' },
	'paid': { text: 'Paid', color: 'success' },
	'cancelled': { text: 'Order Cancelled', color: 'error' },
};


function TodaysItemQuantityDataTable({ filter, search, limit, page, per_page, ...props }) {

	/** Initialize plugins and variables */
	const dispatch = useDispatch();
    let navigate = useNavigate();

    /** Redux actions and state */
    const { total, todays_item_quantity_loading: isLoading, todays_item_quantity } = useSelector((state) => state.RestaurantOrdersReducer);
    const todaysItemQuantity = (params) => dispatch(RestaurantOrdersAction.todaysItemQuantity(params));
    const todaysItemChangeStatus = (params) => dispatch(RestaurantOrdersAction.todaysItemChangeStatus(params));

	/** Initialize and declare state */
	const [sort, setSort] = useState({ _id: 'asc' });
	const [payload, setPayload] = useState({ filter, search, page, per_page, limit });

    const columns = [
		{ name: '#', selector: (row, i) => row.item_id, sortField: 'item_id', center: true, width: '6%', cell: (row, i) => per_page * (page - 1) + (i + 1) },
		{ name: 'Item', selector: (row, i) => row.title, sortField: 'title', sortable: true, width: '38%', cell: row => (<ItemRow row={row} />) },
        { name: 'Item Quantity', selector: (row, i) => row.item_quantity, sortField: 'item_quantity', center: true, sortable: true, width: '12%' },
		{ name: 'Status', selector: (row, i) => row.item_status_text, sortField: 'item_status_text', center: true, sortable: true, width: '25%', cell: row => (<StatusRow row={row} />) },
        { name: 'Action', selector: (row, i) => row.item_id, sortField: 'item_id', center: true, sortable: true, width: '25%', cell: row => (<ActionRow row={row} />) },
	];

    /**
     * function to display Item on the row
     * @return json
     * @author Naveen
     * @created_at 20 Dec 2023
     */
    function ItemRow({row}) {

        let item = row.title

        if (row.portion){
            item = <p>{(row.portion)? `${row.title} (${row.portion})` : row.title}</p>;
        }
        return (
			<React.Fragment>
				{item}
			</React.Fragment>
		);
    }

        /**
     * function to display Status on the row
     * @return json
     * @author Naveen
     * @created_at 18 Dec 2023
     */
    function StatusRow({row}) {

        let status = <Chip label={row.item_status_text} className="badge-status capitalize" style={{ textTransform: `capitalize` }}/>;
        if (row.item_status === 'paid')
            status = <Chip label={row.item_status_text} color="success" className="badge-status capitalize" style={{ textTransform: `capitalize` }}/>;
        else if (row.item_status === 'open')
            status = <Chip label={row.item_status_text} color="info" className="badge-status capitalize" style={{ textTransform: `capitalize` }}/>;
        else if (row.item_status === 'order_placed')
            status = <Chip label={row.item_status_text} color="info" className="badge-status capitalize" style={{ textTransform: `capitalize` }}/>;
        else if (row.item_status === 'cooking_in_progress')
            status = <Chip label={row.item_status_text} color="warning" className="badge-status capitalize" style={{ textTransform: `capitalize` }}/>;
        else if (row.item_status === 'ready_to_deliver')
            status = <Chip label={row.item_status_text} color="warning" className="badge-status capitalize" style={{ textTransform: `capitalize` }}/>;
        else if (row.item_status === 'delivered')
            status = <Chip label={row.item_status_text} color="warning" className="badge-status capitalize" style={{ textTransform: `capitalize` }}/>;
        else if (row.item_status === 'bill_issued')
            status = <Chip label={row.item_status_text} color="info" className="badge-status capitalize" style={{ textTransform: `capitalize` }}/>;
        else if (row.item_status === 'cancelled')
            status = <Chip label={row.item_status_text} color="error" className="badge-status capitalize" style={{ textTransform: `capitalize` }}/>;
        else
            status = <Chip label={row.item_status_text} color="secondary" className="badge-status capitalize" style={{ textTransform: `capitalize` }}/>;

        return (
            <React.Fragment>
                {status}
            </React.Fragment>
        );
    }

    /**
     * function to display Status on the row
     * @return json
     * @author Naveen
     * @created_at 18 Dec 2023
     */
    function ActionRow({row}) {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
    

        const isButtonDisabled = (order_status, status = 'bill_issued', main = false) => {
            const statusIndex = Object.keys(statusLabels).indexOf(status);
            const orderStatusIndex = Object.keys(statusLabels).indexOf(order_status);
            if (main) {
                return statusIndex <= orderStatusIndex;
            }
            return orderStatusIndex >= statusIndex;
        };
    
            
        const updateOrderStatus = async (status, item_id, portion) => {
            await todaysItemChangeStatus({ status, item_id, portion });
        };
      
        const handleClick = (e) => {
          setAnchorEl(e.currentTarget);
        }
      
        const handleClose = () => {
          setAnchorEl(null);
        }
      


        let buttons = Object.keys(statusLabels).map((status) => {
            if (status !== 'order_placed' && status !== 'open' && status !== 'paid' && status !== 'bill_issued') {
                let isItemDisabled = isButtonDisabled(row.item_status, status, true);
                let statusData = statusLabels[status];
                return <MenuItem onClick={() => updateOrderStatus( status, row.item_id, row.portion_slug)} disabled={isLoading || isItemDisabled}>{statusData.text}</MenuItem>
    
            }
            return '';
        });
        // return <Box>{buttons}</Box>;
        return (
            <Fragment>
                <IconButton aria-label="more" id="long-button" aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} ><MoreVert /></IconButton>
                <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button' }} anchorEl={anchorEl} open={open} onClose={handleClose}>
                    {buttons}
                </Menu>
            </Fragment>
        )
    }




    useEffect(() => {
        setPayload({ filter, search, page, per_page, limit, sort });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, search, page, per_page, limit])

    useEffect(() => {

        const queryParam = { ...filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = page;
        if (search) queryParam.q = search; else delete queryParam.q;
        if (filter.mobile) queryParam.mobile = filter.mobile; else delete queryParam.mobile;

        const pathname = postAuthRoutes('todays_restaurant_pre_order').path;
		navigate({ pathname, search: createSearchParams(`?${queryString.stringify(queryParam)}`).toString() });
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search])

    /**
     * function to fetch todays restaurant orders
     * @param {Object} payload - The params which are used for the api
     * @return json
     */
    const getData = async () => {
        await todaysItemQuantity(payload);
    }

    /**
     * function to sort columns
     * @return view
     * @author Naveen
     * @created_at 18 Dec 2023
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setSort({ [sortField]: sortDirection })
    }

	/**
	 * function to select columns
	 * @return view
	 * @author Naveen
	 * @created_at 18 Dec 2023
	 */
	const handleSelect = (data) => {
		// console.log(data.selectedRows)
	};

	/**
	 * function to handle page
	 * @param {String} page - Page number to load
	 * @return view
	 * @author Naveen
	 * @created_at 18 Dec 2023
	 */

	const handlePage = (page) => {
		setPayload({ ...payload, page: page });
	};

	return <DataTable loading={isLoading} columns={columns} data={todays_item_quantity} total={total} per_page={per_page} setPage={(page) => handlePage(page)} handleSort={handleSort} handleSelect={handleSelect} />;
}

export { TodaysItemQuantityDataTable };