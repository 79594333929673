import { apiServices } from "../../_helpers";


export const IgInsightsServices = {

    /**
     * Service for get deals 
     *
     * @author Jasin 
     */
    getIgInsights: () => {
        return apiServices.post('/brand/insights/latest')
            .then((response) => { return response; })
    },

    /**
     * Service for import deals
     *
     * @author Jasin 
     */
    getInsightChart: (params) => {
        return apiServices.post('/brand/insights/chart', params)
            .then((response) => { return response; })
    },

    /**
     * Service for import deals
     *
     * @author Jasin 
     */
    getDailyCouponCount: (params) => {
        return apiServices.post('/brand/insights/daily-count/coupon')
            .then((response) => { return response; })
    },

    /**
     * Service for import deals
     *
     * @author Jasin 
     */
    getDcInsightChart: (params) => {
        return apiServices.post('/brand/insights/daily-count/chart', params)
            .then((response) => { return response; })
    }

}
