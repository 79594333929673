import {
    GET_PAYMENT_DETAILS_REQUEST, GET_PAYMENT_DETAILS_SUCCESS, GET_PAYMENT_DETAILS_FAILURE
} from "../actions";

const initialState = {
    payment_details_loading: true, payment_details: { stripe_card_response: null, stripe_bank_response: null, default_payment: null }, payment_details_error: {}
};

export function PaymentReducer(state = initialState, action) {
    switch (action.type) {
        /** begin::Designation redux */
        case GET_PAYMENT_DETAILS_REQUEST: {
            return {
                ...state,
                payment_details_loading: true,
            };
        } case GET_PAYMENT_DETAILS_SUCCESS: {

            return {
                ...state,
                payment_details_loading: false,
                payment_details: {
                    stripe_card_response: action.data.stripe_card_response ?? null,
                    stripe_bank_response: action.data.stripe_bank_response ?? null,
                    default_payment: action.data.default_payment ?? null,
                }

            };
        } case GET_PAYMENT_DETAILS_FAILURE: {
            return {
                ...state,
                payment_details_loading: false,
                payment_details: { stripe_card_response: null },
                payment_details_error: action.error
            };
        }
        /** end::Designation redux */

        default:
            return state;
    }
}