//     intlTelInputs: {
//         height: `47px`,
//         width: '100%',
//         '& .iti-flag': {
//             // marginTop: '10px',
//         },
//         '& .selected-dial-code': {
//             paddingLeft: '10px !important',
//         },
//         '& .country-list':{
//             color: 'inherit',
//             backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]
//         }
//     },

//     inputClass: {
//         width: '100%',
//         height: '100%',
//         borderRadius: '.25rem',
//         border: '1px solid #ced4da',
//         color: 'inherit',
//         backgroundColor: 'inherit'
//     },

import { FormControl, styled } from '@mui/material';

const FormPhoneInputStyled = styled(({ ...props }) => <FormControl {...props} />)``;

export { FormPhoneInputStyled };
