import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Grid } from '@mui/material';
import { validate } from ".";
import { GiveawayAction } from '../../../../../../redux/actions';
import { handleInputChange, validateForm } from '../../../../../../_helpers';
import { Dialog, DialogActionCloseBtn, DialogActionSaveBtn, DialogActions, DialogContent, DialogTitle, FormInput } from '../../../../../../_components';

// Initialize form input values to null
const inputs = { giveaway_id: '', coupon_code: '', description: '' };

function CreateOrUpdateGiveawaysCouponCode({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Initialize and declare state */
    const { show, closeModal, giveaway_id } = props;
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    /** Redux reducer states */
    const { create_giveaway_coupon_codes_loading: isLoading, create_giveaway_coupon_codes_errors: createCouponCodesErrors } = useSelector((state) => state.GiveawayReducer);

    /** Redux actions */
    const createCouponCode = (params) => dispatch(GiveawayAction.createCouponCode(params));

    useEffect(() => {
        data['giveaway_id'] = giveaway_id;
        setData({ ...data });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);


    useEffect(() => {
        setErrors({ ...createCouponCodesErrors });
    }, [createCouponCodesErrors]);

    /**
     * function to close giveaway Coupon code model
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const onClose = () => {
        setData({ ...inputs });
        setErrors({ ...inputs });
        closeModal(false);
    };

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle create coupon code form submit action
     * @param {object} e form object
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchCreateCouponCodeAction(data);
    }

    /**
     * function to dispatch create coupon code api
     * @author Akshay N
     * @created_at 30 May 2022
    */
    async function dispatchCreateCouponCodeAction(data) {
        const isCreated = await createCouponCode(data);
        if (isCreated && isCreated.status === 1) {
            setData({ ...inputs });
            setErrors({ ...inputs });
            closeModal(false);
        }
    }

    return (
        <Dialog isDialogOpen={show} onClose={onClose} maxWidth="xs" loader={isLoading}>
            <form onSubmit={handleSubmit} noValidate >
                <DialogTitle id="createCouponCode" title='Create Coupon Code' onClose={onClose} />
                <DialogContent >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormInput
                                tabIndex={1}
                                label='Coupon Code'
                                name='coupon_code'
                                value={data.coupon_code}
                                autoFocus={true}
                                onChange={handleChange}
                                maxLength={100}
                                error={(action.isSubmitted && (errors.coupon_code)) ? (errors.coupon_code) : ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormInput
                                tabIndex={1}
                                label='Description'
                                name='description'
                                value={data.description}
                                onChange={handleChange}
                                multiline
                                maxLength={250}
                                minRows={2}
                                error={(action.isSubmitted && (errors.description)) ? (errors.description) : ''}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <DialogActionSaveBtn tabIndex={3} />
                    <DialogActionCloseBtn tabIndex={2} onClose={onClose} />
                </DialogActions>
            </form>
        </Dialog>
    );
}

export { CreateOrUpdateGiveawaysCouponCode };
