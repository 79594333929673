import React from 'react';
import { Box, Card, CardContent, Divider, Grid, Skeleton } from '@mui/material';
import { Fragment } from 'react';

function RestaurantTablesCardLoader({ ...props }) {
    var rows = [];
    for (var i = 0; i < (props.count ?? 6); i++) {
        rows.push(
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} key={i}  {...props}>
                <Card>
                    <Grid container spacing={2} display="flex" alignItems="center">
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <CardContent>
                                <Skeleton animation="wave" height={40} width="60%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={10} width="90%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={10} width="70%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={10} width="100%" style={{ marginBottom: 5 }} />
                                <Divider />
                                <Skeleton animation="wave" height={10} width="90%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={10} width="70%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={10} width="100%" style={{ marginBottom: 5 }} />
                            </CardContent>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        )
    }

    return <Fragment> {rows} </Fragment>
}

export { RestaurantTablesCardLoader };
