import { apiServices } from '../../_helpers';


export const ConnectAccountServices = {

    /**
     * Service to add new fb user token
     * @param {Object} params - The params which are used for the api
     * @author
	 * @created_at 13 July 2022
     */
    addNewUserToken: (params) => {
        return apiServices.post('/brand/ig-api/user-token/add', params)
            .then((response) => { return response; })

    },

    /**
     * Service to get connected ig account
     * @param {Object} params - The params which are used for the api
     * @author
	 * @created_at 13 July 2022
     */
    getConnectedAccount: (params) => {
        return apiServices.post('/brand/ig-api/connected-account', params)
            .then((response) => { return response; })

    },
}