import { CardHeader, styled } from '@mui/material';
import React from 'react'

const CardHeaderStyled = styled(({ ...props }) => (<CardHeader {...props} />))`
        padding: ${props => props.theme.spacing(1)};
        margin-bottom: 25px;
        padding-left:0;
        & .MuiTypography-root {
            font-weight: 600;
            position: relative;
            &:after {
                content: "";
                display: block;
                position: absolute;
                z-index: 1;
                width: 75px;
                height: 2px;
                background: #1242de;
                margin-top: 7px;
            }
        }
`;

export { CardHeaderStyled }