
import { forwardRef } from "react";
import PropTypes from "prop-types";
import StyledTypographyStyled from "./StyledTypographyStyled";

const StyledTypography = forwardRef(
    ({ color, fontWeight, textTransform, verticalAlign, textGradient, opacity, children, ...rest }, ref) => {
        return (<StyledTypographyStyled {...rest} ref={ref} ownerState={{ color, textTransform, verticalAlign, fontWeight, opacity, textGradient, }} > {children} </StyledTypographyStyled>);
    }
);

// Setting default values for the props of Typography
StyledTypography.defaultProps = {
    color: "dark",
    fontWeight: false,
    textTransform: "none",
    verticalAlign: "unset",
    textGradient: false,
    opacity: 1,
};

// Typechecking props for the Typography
StyledTypography.propTypes = {
    color: PropTypes.oneOf(["inherit", "primary", "secondary", "info", "success", "warning", "error", "light", "dark", "text", "white",]),
    fontWeight: PropTypes.oneOf([false, "light", "regular", "medium", "bold"]),
    textTransform: PropTypes.oneOf(["none", "capitalize", "uppercase", "lowercase"]),
    verticalAlign: PropTypes.oneOf(["unset", "baseline", "sub", "super", "text-top", "text-bottom", "middle", "top", "bottom",]),
    textGradient: PropTypes.bool,
    children: PropTypes.node.isRequired,
    opacity: PropTypes.number,
};

export { StyledTypography };
