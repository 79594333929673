import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { DataTable } from '../../../../../_components/data-table/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { RestaurantOrdersAction } from '../../../../../redux/actions';
import { Chip, Typography } from '@mui/material';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { postAuthRoutes } from '../../../../../routes';

function TodaysRestaurantOrderDataTable({ filter, search, limit, page, per_page, ...props }) {
	/** Initialize plugins and variables */
	const dispatch = useDispatch();
    let navigate = useNavigate();

    /** Redux actions and state */
    const { total, todays_restaurant_orders_loading: isLoading, todays_restaurant_orders } = useSelector((state) => state.RestaurantOrdersReducer);
    const todaysRestaurantOrders = (params) => dispatch(RestaurantOrdersAction.todaysRestaurantOrders(params));

	/** Initialize and declare state */
	const [sort, setSort] = useState({ _id: 'asc' });
	const [payload, setPayload] = useState({ filter, search, page, per_page, limit });

    const columns = [
		{ name: '#', selector: (row, i) => row.order_id, sortField: 'order_id', center: true, width: '6%', cell: (row, i) => per_page * (page - 1) + (i + 1) },
        { name: 'Mobile Number', selector: (row, i) => row.mobile_number, sortField: 'mobile_number', center: true, sortable: true, width: '19%' },
		{ name: 'Item', selector: (row, i) => row.title, sortField: 'title', sortable: true, width: '38%', cell: row => (<ItemRow row={row} />) },
        { name: 'Item Quantity', selector: (row, i) => row.item_quantity, sortField: 'item_quantity', center: true, sortable: true, width: '12%' },
		{ name: 'Status', selector: (row, i) => row.item_status_text, sortField: 'item_status_text', center: true, sortable: true, width: '25%', cell: row => (<StatusRow row={row} />) },
	];

    /**
     * function to display Item on the row
     * @return json
     * @author Naveen
     * @created_at 20 Dec 2023
     */
    function ItemRow({row}) {

        let item = row.title

        if (row.portion){
            item = <p>{(row.portion.portion)? `${row.title} (${row.portion.portion})` : row.title}</p>;
        }
        return (
			<React.Fragment>
				{item}
			</React.Fragment>
		);
    }
    /**
     * function to display Status on the row
     * @return json
     * @author Naveen
     * @created_at 18 Dec 2023
     */
    function StatusRow({row}) {

        let status = <Chip label={row.item_status_text} className="badge-status capitalize" style={{ textTransform: `capitalize` }}/>;
        if (row.item_status === 'paid')
            status = <Chip label={row.item_status_text} color="success" className="badge-status capitalize" style={{ textTransform: `capitalize` }}/>;
        else if (row.item_status === 'open')
            status = <Chip label={row.item_status_text} color="info" className="badge-status capitalize" style={{ textTransform: `capitalize` }}/>;
        else if (row.item_status === 'order_placed')
            status = <Chip label={row.item_status_text} color="info" className="badge-status capitalize" style={{ textTransform: `capitalize` }}/>;
        else if (row.item_status === 'cooking_in_progress')
            status = <Chip label={row.item_status_text} color="warning" className="badge-status capitalize" style={{ textTransform: `capitalize` }}/>;
        else if (row.item_status === 'ready_to_deliver')
            status = <Chip label={row.item_status_text} color="warning" className="badge-status capitalize" style={{ textTransform: `capitalize` }}/>;
        else if (row.item_status === 'delivered')
            status = <Chip label={row.item_status_text} color="warning" className="badge-status capitalize" style={{ textTransform: `capitalize` }}/>;
        else if (row.item_status === 'bill_issued')
            status = <Chip label={row.item_status_text} color="info" className="badge-status capitalize" style={{ textTransform: `capitalize` }}/>;
        else if (row.item_status === 'cancelled')
            status = <Chip label={row.item_status_text} color="error" className="badge-status capitalize" style={{ textTransform: `capitalize` }}/>;
        else
            status = <Chip label={row.item_status_text} color="secondary" className="badge-status capitalize" style={{ textTransform: `capitalize` }}/>;

        return (
			<React.Fragment>
				{status}
			</React.Fragment>
		);
    }

    useEffect(() => {
        setPayload({ filter, search, page, per_page, limit, sort });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, search, page, per_page, limit])

    useEffect(() => {

        const queryParam = { ...filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = page;
        if (search) queryParam.q = search; else delete queryParam.q;
        if (filter.mobile) queryParam.mobile = filter.mobile; else delete queryParam.mobile;

        const pathname = postAuthRoutes('todays_restaurant_pre_order').path;
		navigate({ pathname, search: createSearchParams(`?${queryString.stringify(queryParam)}`).toString() });
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search])

    /**
     * function to fetch todays restaurant orders
     * @param {Object} payload - The params which are used for the api
     * @return json
     */
    const getData = async () => {
        await todaysRestaurantOrders(payload);
    }

    /**
     * function to sort columns
     * @return view
     * @author Naveen
     * @created_at 18 Dec 2023
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setSort({ [sortField]: sortDirection })
    }

	/**
	 * function to select columns
	 * @return view
	 * @author Naveen
	 * @created_at 18 Dec 2023
	 */
	const handleSelect = (data) => {
		// console.log(data.selectedRows)
	};

	/**
	 * function to handle page
	 * @param {String} page - Page number to load
	 * @return view
	 * @author Naveen
	 * @created_at 18 Dec 2023
	 */

	const handlePage = (page) => {
		setPayload({ ...payload, page: page });
	};

	return <DataTable loading={isLoading} columns={columns} data={todays_restaurant_orders} total={total} per_page={per_page} setPage={(page) => handlePage(page)} handleSort={handleSort} handleSelect={handleSelect} />;
}

export { TodaysRestaurantOrderDataTable };