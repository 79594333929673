import React from 'react'
import { Stack } from '@mui/material'
import { DialogActionsStyled } from '.'

function DialogActions({ className = "", children, justifyContent = 'flex-start' }) {
    return (
        <DialogActionsStyled className={`${className}`} sx={{ justifyContent: justifyContent }}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                {children}
            </Stack>
        </DialogActionsStyled>
    )
}

export { DialogActions }
