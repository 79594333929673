import { apiServices } from '../../_helpers';


export const TicketServices = {

    /**
     * Service for list tickets
     * @param {Object} params - The params which are used for the api
     * @author
	 * @created_at 30 May 2022
     */
    listTickets: (params) => {
        return apiServices.post('/brand/tickets/list', params)
            .then((response) => { return response; })

    },

    /**
     * Service for Creating ticket
     * @param {Object} params - The params which are used for the api
     * @author
	 * @created_at 30 May 2022
     */
    createTicket: (params) => {
        return apiServices.post('/brand/tickets/create', params)
            .then((response) => { return response; })

    },

    /**
     * Service for update Support center
     * @param {Object} params - The params which are used for the api
     * @author
	 * @created_at 30 May 2022
    */
    updateTicket: (params) => {
        return apiServices.put('brand/tickets/update', params)
            .then((response) => { return response; })
    },

    /**
     * Service for display tickets details
     * @param {Object} params - The params which are used for the api
     * @author
	 * @created_at 30 May 2022
     */
    ticketDetails: (params) => {
        return apiServices.post('/brand/tickets/details', params)
            .then((response) => { return response; })

    },

    /**
     * Service for change ticket status
     * @param {Object} params - The params which are used for the api
     * @author
	 * @created_at 30 May 2022
     */
    changeStatus: (params) => {
        return apiServices.put('/brand/tickets/change-status', params)
            .then((response) => { return response; })

    },

    /**
     * Service to add reply
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 30 May 2022
     */
    addReply: (params) => {
        return apiServices.put('brand/tickets/reply/add', params)
            .then((response) => { return response; })
    },   
}