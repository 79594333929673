export const PRODUCT_NAME = process.env.REACT_APP_PRODUCT_NAME;
export const APP_NAME = process.env.REACT_APP_APP_NAME;

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const API_REQUEST_TIMEOUT = 50000;

export const URL_PRIVACY_POLICY = 'https://google.com';
export const URL_TERMS_OF_SERVICE = 'https://google.com';

export const DRAWER_WIDTH = 260;

export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const FACEBOOK_API_VERSION = process.env.REACT_APP_FACEBOOK_API_VERSION || `14.0`;

export const INFLUENCER_APP_URL = process.env.REACT_APP_INFLUENCER_APP_URL;