import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { postAuthRoutes } from "../../../../routes";
import { handleInputChange, validateForm, APP_NAME } from "../../../../_helpers";

import { CancelButton, SubmitButton } from "../../../../_components/controls";
import { FormInput } from "../../../../_components/form";
import { Card, CardContent, CardHeader } from "../../../../_components/card";
import { Grid, Stack, Box } from "@mui/material";
import { CurrencyAction } from "../../../../redux/actions";
import { validate } from "./validate";

// Initialize form input values to null
const inputs = { name: '', html: '' };

function CreateCurrency() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let navigate = useNavigate();

    /** Role id and details on update page */
    const location = useLocation();
    const { cId } = location.state ?? {};

    /** Redux actions and state */
    const createCurrency = (params) => dispatch(CurrencyAction.createCurrency(params));
    const UpdateCurrency = (params) => dispatch(CurrencyAction.updateCurrency(params));
    const { create_currency_loading: isLoading, create_currency_errors: createCurrencyErrors } = useSelector((state) => state.CurrencyReducer);

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {
        setErrors({ ...createCurrencyErrors });
    }, [createCurrencyErrors])

    /**
     * function to handle input changes and modify the value
     * @author Naveen
     * @created_at 23 Apr 2024
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    useEffect(() => {
        if (cId && cId._id) {
            setData({
                currency_id: cId._id,
                name: cId.name,
                html: cId.html,
            });
        }
    }, [cId])

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author  Naveen K
     * @created_at 14 June 2022
     */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        const formData = {
            "currencyId": data.currency_id,
            "name": data.name,
            "html": data.html,
        }

        addCurrency(formData);
    }

    /**
     * function to create/update Currency
     * @author Naveen
     * @created_at 23 Apr 2024
     */
    async function addCurrency(formData) {
        const isCreated = cId && cId._id ? await UpdateCurrency(formData) : await createCurrency(formData)
        if (isCreated && isCreated.status === 1) {
            let redirectPath = postAuthRoutes('currency').path;
            navigate(redirectPath);
        }

    }

    /**
     * function to cancel create/update Currency
     * @author Naveen
     * @created_at 23 Apr 2024
     */
    function cancelCurrency() {
        let redirectPath = postAuthRoutes('currency').path;
        navigate(redirectPath);
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${cId && cId._id ? postAuthRoutes('update_currency').name : postAuthRoutes('create_currency').name}`}</title>
            </Helmet>
            <CardHeader title={cId && cId._id ? postAuthRoutes('update_currency').name : postAuthRoutes('create_currency').name} />
            <Box py={3} paddingLeft='350px'>
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" style={{ width: '70%' }}>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <Card >
                                <CardContent >
                                    <Grid container spacing={2} justifyContent="flex-start" alignItems="center" >
                                        <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                                            <Grid container spacing={3}>

                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <FormInput
                                                        tabIndex={4}
                                                        label='Name'
                                                        name='name'
                                                        value={data.name}
                                                        error={(action.isSubmitted && (errors.name)) ? (errors.name) : ''}
                                                        onChange={handleChange}
                                                        maxLength={250}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <FormInput
                                                        tabIndex={4}
                                                        label='Html'
                                                        name='html'
                                                        value={data.html}
                                                        error={(action.isSubmitted && (errors.html)) ? (errors.html) : ''}
                                                        onChange={handleChange}
                                                        multiline
                                                        maxLength={1000}
                                                        minRows={3}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                                <SubmitButton label={cId && cId._id ? 'Update' : 'Create'} loading={isLoading} tabIndex={6} />
                                                <CancelButton onClick={cancelCurrency} tabIndex={12} />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </React.Fragment>
    )
}

export { CreateCurrency };