import { Badge, Box, Grid, IconButton, MenuItem, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { postAuthRoutes } from '../../../../../../routes';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { RestaurantMenuAction } from '../../../../../../redux/actions';
import queryString from 'query-string';
import { cookieServices } from '../../../../../../_helpers';
import { MenuCategory, MenuCategoryLoader } from './menu-category';
import { MenuModal } from './_component/menu-modal';
import { CardHeader, NoData } from '../../../../../../_components';
import { RestaurantCart } from '..';

function OrderMenu() {

    /** Declare hooks and plugins and constants */
	let orderId = cookieServices.get('orderId');
	let tableId = cookieServices.get('tableId');

    /** Initialize plugins and variables */
    const dispatch = useDispatch();    
    const navigate = useNavigate();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

    
    /** Redux actions and state */
	const getMenuList = (params) => dispatch(RestaurantMenuAction.getOrderMenuList(params));

    const { get_order_menu_list_loading: isLoading, get_order_menu_list } = useSelector((state) => state.RestaurantMenuReducer);
    const { cart } = useSelector((state) => state.RestaurantOrdersReducer);


    const [payload, setPayload] = useState({
		search: searchParams.get('q') ?? '' // Search key to check for word anywhere in the record
	});
	const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
		/* Read the payload and append the parameters to the url for proper reload */
		const queryParam = {};
		if (payload.search) queryParam.q = payload.search;
		else delete queryParam.q;
		let redirectPath = postAuthRoutes('order_menu').path;
		navigate(redirectPath, { search: `?${queryString.stringify(queryParam)}` });
		getNext(); // Initially load participants list
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payload.search]);

	useEffect (() =>{
		setIsLoaded(true);
	}, []);

	const getNext = async () => {
		const result = await getMenuList({ table_id: tableId, order_id: orderId, ...payload });
		if (result.status) {
			setPayload({ ...payload });
		}
	};
    
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
				<Box >
					<CardHeader
						title={postAuthRoutes('order_menu').name} 
					/>

					<Grid container spacing={2} direction="row" justifyContent="left" alignItems="center">
						{
							get_order_menu_list.length > 0 && get_order_menu_list.map((menu, index) => {
								return <MenuCategory menu={menu} key={index} />;
							})
						}
						{!isLoading && (isLoaded && get_order_menu_list.length === 0) && <NoData content1={`Menu`} content2={`Not Found`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} minHeight={`80vh`} />}
						{(isLoading || !isLoaded) && <MenuCategoryLoader category={2} item={4} />}
					</Grid>				
					<MenuModal />
				</Box>
			</Grid>
			<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
				<Box>
					<RestaurantCart/>
				</Box>
			</Grid>
		</Grid>  

    )
}
export { OrderMenu };