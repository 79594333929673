import { apiServices } from '../../_helpers';


export const PlanServices = {

    /**
     * Service to list plans
     * @param {Object} params - The params which are used for change password api
     * @author Akshay N
     * @created_at 30 May 2022
     */
    listPlans: (params) => {
        return apiServices.post('/brand/plan/list', params)
            .then((response) => { return response; })

    },
    /**
     * Service to list plans
     * @param {Object} params - The params which are used for change password api
     * @author Akshay N
     * @created_at 30 May 2022
     */
    updateBrandPlan: (params) => {
        return apiServices.post('/brand/plan/update/success', params)
            .then((response) => { return response; })

    },

        
    /**
     * Service for get designations 
     *
     * @author Akshay 
     */
    createCheckoutSession: (params) => {
        return apiServices.post('/brand/plan/update/payment', params)
        .then((response) => { return response; })
    },

    /**
     * Service for list giveaway plans
     *
     * @author Akshay 
     */
    listGiveawayPlans: (params) => {
        return apiServices.post('/brand/plan/giveaway/list', params)
        .then((response) => { return response; })
    },

    /**
     * Service for get designations 
     *
     * @author Akshay 
     */
    createGiveawayCheckoutSession: (params) => {
        return apiServices.post('/brand/plan/giveaway/update/payment', params)
        .then((response) => { return response; })
    },

};
