module.exports = {
	/**
	 * function to validate the registration form inputs
	 * @param {string} name Input name
	 * @param {string} value Input value
	 * @param {object} [data={}] All input values stored in the state
	 * @author
	 * @created_at 09 Oct 2023
	 */
	validate: (name, value, data = {}) => {
		const errors = {};
		switch (name) {
			case 'name': {
				errors.name = '';
				if (value === '') 
					errors.name = 'Enter name';
				break;
			} case 'account_type': {
				errors.account_type = '';
				if (value === '') 
					errors.account_type = 'Select account type';
				break;
			} case 'mobile_number': {
				errors.mobile_number = '';
				if (value === '') 
					errors.mobile_number = 'Enter mobile number';
				break;
			} case 'password': {
                const confirmPassword = data.confirm_password ?? '';
                errors.password = value.length < 6 ? 'Password must be 6 characters long.' : '';
                if (confirmPassword !== '' && confirmPassword === value)
                    errors.confirm_password = '';
                if (value === '')
                    errors.password = 'Enter password.';
                break;
            } case 'confirm_password': {
                const password = data.password ?? '';
                errors.confirm_password = value.length < 6 ? 'Confirm password must be 6 characters long.' : '';
                if (password !== '' && password !== value)
                    errors.confirm_password = 'Confirm password should match with the password.';
                if (value === '')
                    errors.confirm_password = 'Re-enter your password.';
                break;
            } 
			default: {
				errors[name] = '';
				break;
			}
		}
		return errors;
	},
};
