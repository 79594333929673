import { GlobalAction } from ".";
import { RestaurantMenuServices } from '../services';

export const RESTAURANT_MENU_REQUEST = 'RESTAURANT_MENU_REQUEST';
export const RESTAURANT_MENU_SUCCESS = 'RESTAURANT_MENU_SUCCESS';
export const RESTAURANT_MENU_FAILURE = 'RESTAURANT_MENU_FAILURE';

export const RESTAURANT_MENU_CREATE_REQUEST = 'RESTAURANT_MENU_CREATE_REQUEST';
export const RESTAURANT_MENU_CREATE_SUCCESS = 'RESTAURANT_MENU_CREATE_SUCCESS';
export const RESTAURANT_MENU_CREATE_FAILURE = 'RESTAURANT_MENU_CREATE_FAILURE';

export const CHANGE_RESTAURANT_MENU_STATUS_REQUEST = 'CHANGE_RESTAURANT_MENU_STATUS_REQUEST';
export const CHANGE_RESTAURANT_MENU_STATUS_SUCCESS = 'CHANGE_RESTAURANT_MENU_STATUS_SUCCESS';
export const CHANGE_RESTAURANT_MENU_STATUS_FAILURE = 'CHANGE_RESTAURANT_MENU_STATUS_FAILURE';

export const UPDATE_RESTAURANT_MENU_REQUEST = 'UPDATE_RESTAURANT_MENU_REQUEST';
export const UPDATE_RESTAURANT_MENU_SUCCESS = 'UPDATE_RESTAURANT_MENU_SUCCESS';
export const UPDATE_RESTAURANT_MENU_FAILURE = 'UPDATE_RESTAURANT_MENU_FAILURE';

export const DELETE_RESTAURANT_MENU_REQUEST = 'DELETE_RESTAURANT_MENU_REQUEST';
export const DELETE_RESTAURANT_MENU_SUCCESS = 'DELETE_RESTAURANT_MENU_SUCCESS';
export const DELETE_RESTAURANT_MENU_FAILURE = 'DELETE_RESTAURANT_MENU_FAILURE';

export const RESTAURANT_MENU_SELECT_LIST_REQUEST = 'RESTAURANT_MENU_SELECT_LIST_REQUEST';
export const RESTAURANT_MENU_SELECT_LIST_SUCCESS = 'RESTAURANT_MENU_SELECT_LIST_SUCCESS';
export const RESTAURANT_MENU_SELECT_LIST_FAILURE = 'RESTAURANT_MENU_SELECT_LIST_FAILURE';

export const RESTAURANT_TABLES_REQUEST = 'RESTAURANT_TABLES_REQUEST';
export const RESTAURANT_TABLES_SUCCESS = 'RESTAURANT_TABLES_SUCCESS';
export const RESTAURANT_TABLES_FAILURE = 'RESTAURANT_TABLES_FAILURE';

export const RESTAURANT_TABLES_CREATE_REQUEST = 'RESTAURANT_TABLES_CREATE_REQUEST';
export const RESTAURANT_TABLES_CREATE_SUCCESS = 'RESTAURANT_TABLES_CREATE_SUCCESS';
export const RESTAURANT_TABLES_CREATE_FAILURE = 'RESTAURANT_TABLES_CREATE_FAILURE';

export const CHANGE_RESTAURANT_TABLES_STATUS_REQUEST = 'CHANGE_RESTAURANT_TABLES_STATUS_REQUEST';
export const CHANGE_RESTAURANT_TABLES_STATUS_SUCCESS = 'CHANGE_RESTAURANT_TABLES_STATUS_SUCCESS';
export const CHANGE_RESTAURANT_TABLES_STATUS_FAILURE = 'CHANGE_RESTAURANT_TABLES_STATUS_FAILURE';

export const UPDATE_RESTAURANT_TABLES_REQUEST = 'UPDATE_RESTAURANT_TABLES_REQUEST';
export const UPDATE_RESTAURANT_TABLES_SUCCESS = 'UPDATE_RESTAURANT_TABLES_SUCCESS';
export const UPDATE_RESTAURANT_TABLES_FAILURE = 'UPDATE_RESTAURANT_TABLES_FAILURE';

export const RESTAURANT_TABLE_CATEGORIES_REQUEST = 'RESTAURANT_TABLE_CATEGORIES_REQUEST';
export const RESTAURANT_TABLE_CATEGORIES_SUCCESS = 'RESTAURANT_TABLE_CATEGORIES_SUCCESS';
export const RESTAURANT_TABLE_CATEGORIES_FAILURE = 'RESTAURANT_TABLE_CATEGORIES_FAILURE';

export const ITEM_TYPE_SELECT_LIST_REQUEST = 'ITEM_TYPE_SELECT_LIST_REQUEST';
export const ITEM_TYPE_SELECT_LIST_SUCCESS = 'ITEM_TYPE_SELECT_LIST_SUCCESS';
export const ITEM_TYPE_SELECT_LIST_FAILURE = 'ITEM_TYPE_SELECT_LIST_FAILURE';

export const ADD_ON_SELECT_LIST_REQUEST = 'ADD_ON_SELECT_LIST_REQUEST';
export const ADD_ON_SELECT_LIST_SUCCESS = 'ADD_ON_SELECT_LIST_SUCCESS';
export const ADD_ON_SELECT_LIST_FAILURE = 'ADD_ON_SELECT_LIST_FAILURE';

export const RESTAURANT_MENU_DETAILS_REQUEST = 'RESTAURANT_MENU_DETAILS_REQUEST';
export const RESTAURANT_MENU_DETAILS_SUCCESS = 'RESTAURANT_MENU_DETAILS_SUCCESS';
export const RESTAURANT_MENU_DETAILS_FAILURE = 'RESTAURANT_MENU_DETAILS_FAILURE';


export const CONFIGURE_GST_REQUEST = 'CONFIGURE_GST_REQUEST';
export const CONFIGURE_GST_SUCCESS = 'CONFIGURE_GST_SUCCESS';
export const CONFIGURE_GST_FAILURE = 'CONFIGURE_GST_FAILURE';

export const GST_DETAILS_REQUEST = 'GST_DETAILS_REQUEST';
export const GST_DETAILS_SUCCESS = 'GST_DETAILS_SUCCESS';
export const GST_DETAILS_FAILURE = 'GST_DETAILS_FAILURE';

export const RESTAURANT_MENU_CATEGORY_SELECT_LIST_REQUEST = 'RESTAURANT_MENU_CATEGORY_SELECT_LIST_REQUEST';
export const RESTAURANT_MENU_CATEGORY_SELECT_LIST_SUCCESS = 'RESTAURANT_MENU_CATEGORY_SELECT_LIST_SUCCESS';
export const RESTAURANT_MENU_CATEGORY_SELECT_LIST_FAILURE = 'RESTAURANT_MENU_CATEGORY_SELECT_LIST_FAILURE';

export const SET_FUTURE_ORDERS_REQUEST = 'SET_FUTURE_ORDERS_REQUEST';
export const SET_FUTURE_ORDERS_SUCCESS = 'SET_FUTURE_ORDERS_SUCCESS';
export const SET_FUTURE_ORDERS_FAILURE = 'SET_FUTURE_ORDERS_FAILURE';

export const GET_FUTURE_ORDERS_DETAILS_REQUEST = 'GET_FUTURE_ORDERS_DETAILS_REQUEST';
export const GET_FUTURE_ORDERS_DETAILS_SUCCESS = 'GET_FUTURE_ORDERS_DETAILS_SUCCESS';
export const GET_FUTURE_ORDERS_DETAILS_FAILURE = 'GET_FUTURE_ORDERS_DETAILS_FAILURE';

export const RESTAURANT_TABLE_SELECT_LIST_REQUEST = 'RESTAURANT_TABLE_SELECT_LIST_REQUEST';
export const RESTAURANT_TABLE_SELECT_LIST_SUCCESS = 'RESTAURANT_TABLE_SELECT_LIST_SUCCESS';
export const RESTAURANT_TABLE_SELECT_LIST_FAILURE = 'RESTAURANT_TABLE_SELECT_LIST_FAILURE';

export const GET_ORDER_MENU_LIST_REQUEST = 'GET_ORDER_MENU_LIST_REQUEST';
export const GET_ORDER_MENU_LIST_SUCCESS = 'GET_ORDER_MENU_LIST_SUCCESS';
export const GET_ORDER_MENU_LIST_FAILURE = 'GET_ORDER_MENU_LIST_FAILURE';

export const SELECT_MENU_ITEM_REQUEST = 'SELECT_MENU_ITEM_REQUEST';
export const SELECT_MENU_ITEM_SUCCESS = 'SELECT_MENU_ITEM_SUCCESS';
export const SELECT_MENU_ITEM_FAILURE = 'SELECT_MENU_ITEM_FAILURE';

export const UNSELECT_MENU_ITEM = 'UNSELECT_MENU_ITEM';

export const RestaurantMenuAction = {

    /**
     * Action for list restaurant menu
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 09 Oct 2023
     */
    listRestaurantMenu: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantMenuServices.listRestaurantMenu(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: RESTAURANT_MENU_REQUEST, request: request } }
        function success(request, response) { return { type: RESTAURANT_MENU_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: RESTAURANT_MENU_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for Creating Restaurant menu
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 09 Oct 2023
     */
    createRestaurantMenu: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantMenuServices.createRestaurantMenu(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        }

        function request(request) { return { type: RESTAURANT_MENU_CREATE_REQUEST, request: request }; }
        function success(request, response) { return { type: RESTAURANT_MENU_CREATE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: RESTAURANT_MENU_CREATE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action for update Restaurant menu
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 09 Oct 2023
    */
    updateRestaurantMenu: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantMenuServices.updateRestaurantMenu(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_RESTAURANT_MENU_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_RESTAURANT_MENU_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_RESTAURANT_MENU_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for change Restaurant menu status
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 09 Oct 2023
     */
    changeRestaurantMenuStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantMenuServices.changeRestaurantMenuStatus(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_RESTAURANT_MENU_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_RESTAURANT_MENU_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_RESTAURANT_MENU_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for deleting an Restaurant menu
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 09 Oct 2023
    */
    deleteRestaurantMenu: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantMenuServices.deleteRestaurantMenu(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: DELETE_RESTAURANT_MENU_REQUEST, request: request } }
        function success(request, response) { return { type: DELETE_RESTAURANT_MENU_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: DELETE_RESTAURANT_MENU_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for Category select list
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 11 Oct 2023
    */
    categorySelectList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantMenuServices.categorySelectList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: RESTAURANT_MENU_SELECT_LIST_REQUEST, request: request } }
        function success(request, response) { return { type: RESTAURANT_MENU_SELECT_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: RESTAURANT_MENU_SELECT_LIST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },


    /**
     * Action for getRestaurantTableCategories
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 09 Oct 2023
     */
    itemTypeSelectList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantMenuServices.itemTypeSelectList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ITEM_TYPE_SELECT_LIST_REQUEST, request: request } }
        function success(request, response) { return { type: ITEM_TYPE_SELECT_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ITEM_TYPE_SELECT_LIST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },



    /**
     * Action for addOnSelectList
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 09 Oct 2023
     */
    addOnSelectList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantMenuServices.addOnSelectList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ADD_ON_SELECT_LIST_REQUEST, request: request } }
        function success(request, response) { return { type: ADD_ON_SELECT_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ADD_ON_SELECT_LIST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },


    /**
     * Action for list restaurant tables
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 09 Oct 2023
     */
    listRestaurantTables: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantMenuServices.listRestaurantTables(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: RESTAURANT_TABLES_REQUEST, request: request } }
        function success(request, response) { return { type: RESTAURANT_TABLES_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: RESTAURANT_TABLES_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for Creating Restaurant menu
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 09 Oct 2023
     */
    createRestaurantTables: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantMenuServices.createRestaurantTables(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        }

        function request(request) { return { type: RESTAURANT_TABLES_CREATE_REQUEST, request: request }; }
        function success(request, response) { return { type: RESTAURANT_TABLES_CREATE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: RESTAURANT_TABLES_CREATE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action for update Restaurant menu
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 09 Oct 2023
    */
    updateRestaurantTables: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantMenuServices.updateRestaurantTables(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_RESTAURANT_TABLES_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_RESTAURANT_TABLES_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_RESTAURANT_TABLES_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for change Restaurant menu status
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 09 Oct 2023
     */
    changeRestaurantTablesStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantMenuServices.changeRestaurantTablesStatus(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_RESTAURANT_TABLES_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_RESTAURANT_TABLES_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_RESTAURANT_TABLES_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for getRestaurantTableCategories
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 09 Oct 2023
     */
    getRestaurantTableCategories: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantMenuServices.getRestaurantTableCategories(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: RESTAURANT_TABLE_CATEGORIES_REQUEST, request: request } }
        function success(request, response) { return { type: RESTAURANT_TABLE_CATEGORIES_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: RESTAURANT_TABLE_CATEGORIES_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get restaurant menu details
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.brand_id
     * @author Naveen
     * @created_at 21 Nov 2023
    */
    restaurantMenuDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantMenuServices.restaurantMenuDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: RESTAURANT_MENU_DETAILS_REQUEST, request: request } }
        function success(request, response) { return { type: RESTAURANT_MENU_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: RESTAURANT_MENU_DETAILS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for configure gst
     * @param {Object} params - The params which are used for the api
     * @param {String} params.giveaway_id
     * @author Akshay N
     * @created_at 13 Jan 2022
     */
    configureGst: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantMenuServices.configureGst(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CONFIGURE_GST_REQUEST, request: request } }
        function success(request, response) { return { type: CONFIGURE_GST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CONFIGURE_GST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },


    /**
     * Action to get gst details
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.influencer_id
     * @author Jasin
    */
    getGstDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantMenuServices.getGstDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type:GST_DETAILS_REQUEST, request: request } }
        function success(request, response) { return { type:GST_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type:GST_DETAILS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for Menu Category select list
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 14 Dec 2023
    */
    menuCategorySelectList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantMenuServices.menuCategorySelectList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: RESTAURANT_MENU_CATEGORY_SELECT_LIST_REQUEST, request: request } }
        function success(request, response) { return { type: RESTAURANT_MENU_CATEGORY_SELECT_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: RESTAURANT_MENU_CATEGORY_SELECT_LIST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for set future orders
     * @param {Object} params - The params which are used for the api
     * @param {String} params.giveaway_id
     * @author Akshay N
     * @created_at 13 Jan 2022
     */
    setFutureOrders: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantMenuServices.setFutureOrders(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: SET_FUTURE_ORDERS_REQUEST, request: request } }
        function success(request, response) { return { type: SET_FUTURE_ORDERS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: SET_FUTURE_ORDERS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for set future orders
     * @param {Object} params - The params which are used for the api
     * @param {String} params.giveaway_id
     * @author Akshay N
     * @created_at 13 Jan 2022
     */
    getFutureOrdersDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantMenuServices.getFutureOrdersDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_FUTURE_ORDERS_DETAILS_REQUEST, request: request } }
        function success(request, response) { return { type: GET_FUTURE_ORDERS_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_FUTURE_ORDERS_DETAILS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for getRestaurantTableSelect
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 09 Oct 2023
     */
    getRestaurantTableSelectList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantMenuServices.getRestaurantTableSelectList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: RESTAURANT_TABLE_SELECT_LIST_REQUEST, request: request } }
        function success(request, response) { return { type: RESTAURANT_TABLE_SELECT_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: RESTAURANT_TABLE_SELECT_LIST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get order menu list
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.influencer_id
     * @author Jasin
    */
    getOrderMenuList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantMenuServices.getOrderMenuList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type:GET_ORDER_MENU_LIST_REQUEST, request: request } }
        function success(request, response) { return { type:GET_ORDER_MENU_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type:GET_ORDER_MENU_LIST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get order menu item details
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.influencer_id
     * @author Jasin
    */
    selectMenuItem: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantMenuServices.getOrderMenuItemDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type:SELECT_MENU_ITEM_REQUEST, request: request } }
        function success(request, response) { return { type:SELECT_MENU_ITEM_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type:SELECT_MENU_ITEM_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    unselectMenuItem: (params) => {
        return dispatch => {
            dispatch(request(params));
        };

        function request(request) { return { type:SELECT_MENU_ITEM_REQUEST, request: request } }
    },
};
