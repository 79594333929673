module.exports = {
    /**
     * function to validate the change password component
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author Akshay N
     * @created_at 30 May 2022
     */
    validate: (name, value, data = {}) => {
        const errors = {};
        switch (name) {
            case 'address': {
                errors.address = '';
                if (value === '')
                    errors.address = 'Enter address.';
                break;
            } case 'city': {
                errors.city = '';
                if (value === '')
                    errors.city = 'Enter city.';
                break;
            } case 'state': {
                errors.state = '';
                if (value === '')
                    errors.state = 'Enter state.';
                break;
            } case 'country': {
                errors.country = '';
                if (value === '')
                    errors.country = 'Enter country.';
                break;
            } case 'pincode': {
                errors.pincode = '';
                if (value === '')
                    errors.pincode = 'Enter pincode.';
                break;
            } case 'brand_category': {
                errors.brand_category = '';
                if (value === '')
                    errors.brand_category = 'Select brand category.';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}