import React, { useEffect } from "react";
import { CardHeader as PageHeader } from "../../../../../_components/card";
import { Box, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { NoData } from "../../../../../_components";
import { Tabs } from "../../../../../_components/tabs";
import { FilterMenu } from "../../../../../_components/controls";
import { PromoPdf, PromosCouponCodes, PromoShare, PromoStories } from ".";
import { Share } from "@mui/icons-material";
import { Fragment } from "react";
import { PromoAction } from "../../../../../redux/actions";
import { postAuthRoutes } from "../../../../../routes";
import { PromoCard, PromoCardLoader } from "../promos-card";


function PromoDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);

    const promoId = params.get('pId'); // plan promo from url

    /** Redux actions and state */
    const { brand_promo_details_loading: isLoading, brand_promo_details: promo_details } = useSelector((state) => state.PromoReducer);
    const promoDetails = (params) => dispatch(PromoAction.promoDetails(params));
    const getRunningPromos = (params) => dispatch(PromoAction.getRunningPromos(params));

    /** Initialize and declare state */


    useEffect(() => {
        promoDetails({ promo_id: promoId });
        getRunningPromos();
        //eslint-disable-next-line
    }, [promoId])


    function PromoDetailTabPanel() {
        return (
            <Fragment>
                {
                    (!isLoading && Object.keys(promo_details).length > 0) &&
                    <Box sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>
                        <Box sx={{ maxWidth: `1000px` }}>
                            {!isLoading && <PromoCard promo={promo_details} hasAction={false} />}
                            {isLoading && <PromoCardLoader count={1} />}
                        </Box>
                    </Box>
                }
                {(isLoading) && (
                    <Box sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>
                        <Box sx={{ maxWidth: `1000px` }}>
                            <PromoCardLoader count={1} />
                        </Box>
                    </Box>
                )}
            </Fragment>
        )
    }

    return (
        <Fragment>
            <PageHeader title={postAuthRoutes('promo_details').name} action={(
                <Stack spacing={1} direction="row">
                    <PromoPdf promoId={promoId} promo_details={promo_details} />
                    <FilterMenu label='Share' startIcon={<Share />}>
                        <PromoShare promoId={promoId} promo_details={promo_details} />
                    </FilterMenu>
                </Stack>
            )} />
            {!isLoading && Object.keys(promo_details).length === 0 && <NoData content1={`No`} content2={`Promo available`} minHeight={`55vh`} ></NoData>}
            {/* {(!isLoading && Object.keys(promo_details).length > 0) && */}
            <Tabs
                groupName={'pdTab'}
                data={[
                    { slug: "promo", label: "Promo", component: <PromoDetailTabPanel /> },
                    { slug: "stories", label: "Stories", component: <PromoStories /> },
                    { slug: "coupons", label: "Coupon Codes", component: <PromosCouponCodes /> }
                ]}
            />
            {/* } */}
        </Fragment>
    );
}

export { PromoDetails }