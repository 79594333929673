module.exports = {
    /**
     * function to validate the registration form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author Akshay N
     * @created_at 30 May 2022
     */
    validate: (name, value, data = {}) => {
        //eslint-disable-next-line
        const errors = {};
        switch (name) {

            case 'message_content': {
                errors.message_content = '';
                if (value === '')
                    errors.message_content = 'Reply is required. ';
                break;

            } default:
                errors[name] = '';
                break;
        }
        return errors;
    }
}