import { apiServices } from '../../_helpers';


export const InvoiceServices = {

    /**
     * Service to list plans
     * @param {Object} params - The params which are used for change password api
     * @author Akshay N
     * @created_at 30 May 2022
     */
    listInvoices: (params) => {
        return apiServices.post('/brand/invoice/list', params)
            .then((response) => { return response; })

    },

    /**
     * Service to list plans
     * @author Akshay N
     */
     invoiceDetails: (params) => {
        return apiServices.post('/brand/invoice/details', params)
            .then((response) => { return response; })

    },

};
