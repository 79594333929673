import { Card, CardContent, CardMedia, Stack, Typography, Box, Table, TableHead, TableRow, TableCell, TableBody, Grid, CircularProgress } from '@mui/material';
import { WhatsApp } from '@mui/icons-material';
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { DialogActionCloseBtn, DialogActionSaveBtn, DialogActions, DialogContent, CurrencySymbol } from '../../../../../_components';
import { RestaurantOrdersAction } from '../../../../../redux/actions';
import { RestaurantOrderBillModalStyled } from './RestaurantOrderBillModalStyled';

function RestaurantOrderBillModal({ show, closeModal, orderId }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Initialize and declare state */
    const [response, setResponse] = useState({});

    const { get_restaurant_order_bill_loading, get_restaurant_order_bill } = useSelector((state) => state.RestaurantOrdersReducer);
    const { generate_restaurant_order_bill_loading, generate_restaurant_order_bill } = useSelector((state) => state.RestaurantOrdersReducer);

    const getRestaurantOrderBill = (params) => dispatch(RestaurantOrdersAction.getRestaurantOrderBill(params));
    const generateRestaurantBill = (params) => dispatch(RestaurantOrdersAction.generateRestaurantBill(params));


    /**
     * function to close update status modal
     * @param {string} status Entered status on ticket update status modal
     * @return view
     * @author
     * @created_at 30 May 2022
     */
    const onClose = () => { closeModal(); };

    useEffect(() => {
        if (orderId && show) {
            getRestaurantOrderBill({ order_id: orderId });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId, show]);

    const generateBill = async () => {
        let result = await generateRestaurantBill({ order_id: orderId });
        setResponse(result);
    };

    useEffect(() => {
        if (response.status === 1) {
            closeModal(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);


    return (
        <RestaurantOrderBillModalStyled isDialogOpen={show} onClose={onClose} >
            {get_restaurant_order_bill_loading && <CircularProgress />}
            {!get_restaurant_order_bill_loading &&
                <Card style={{ boxShadow: `none` }}>
                    <DialogContent style={{ padding: 0 }}>
                        <Box className={`content`}>
                            <Fragment>
                                <Typography variant="h5" textAlign={`left`} >Bill Details</Typography>
                                {get_restaurant_order_bill.gst && Object.keys(get_restaurant_order_bill.gst).length && <Typography variant="caption" textAlign={`left`} >{`GSTIN: ${get_restaurant_order_bill.gst.gst_number}`}</Typography>}
                                <Box className='items'>
                                    <Table>
                                        <TableHead style={{ display: `table-header-group` }}>
                                            <TableRow>
                                                <TableCell width={`50%`} align="left">Item </TableCell>
                                                <TableCell width={`20%`} align="right">Price </TableCell>
                                                <TableCell width={`10%`} align="right">Qty </TableCell>
                                                <TableCell width={`20%`} align="right">Amount </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {get_restaurant_order_bill.items && get_restaurant_order_bill.items.map((row, i) => (
                                                <TableRow key={row.order_id} >
                                                    <TableCell align="left">
                                                        <Typography variant='body2' style={{ textTransform: 'uppercase' }}> {`${row.title}`}</Typography>
                                                        <Typography variant='caption' color="secondary" style={{ display: `flex`, alignItems: `center`, justifyContent: `left` }}>
                                                            {row.have_portions ? <Box style={{ marginRight: `5px`, texTransform: 'uppercase' }}>{row.portion}</Box> : ``}
                                                            <Box><CurrencySymbol />{`${row.price}`}</Box>
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right"><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `right` }}><CurrencySymbol />{`${row.item_actual_discounted_price} (${row.price})`}</Box></TableCell>
                                                    <TableCell align="right"><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `right` }}>{row.item_quantity}</Box></TableCell>
                                                    <TableCell align="right"><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `right` }}><CurrencySymbol />{row.item_discounted_price}</Box></TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                                {(get_restaurant_order_bill.total_actual_price !== get_restaurant_order_bill.total_discounted_price) && (
                                    <Box className='content'>
                                        <Grid container>
                                            <Grid item xs={8}> <Typography variant="h6" component={'span'}>Item total</Typography> </Grid>
                                            <Grid item xs={4}> <Typography variant="h6" component={'span'}><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `right` }}><CurrencySymbol />{get_restaurant_order_bill.total_actual_price}</Box></Typography> </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={8}>Discount</Grid>
                                            <Grid item xs={4}><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `right` }}><CurrencySymbol />{get_restaurant_order_bill.total_discount}</Box></Grid>
                                        </Grid>
                                    </Box>
                                )}
                                <Box className='total-amount'>
                                    <Grid container>
                                        <Grid item xs={8}>AMOUNT</Grid>
                                        <Grid item xs={4}><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `right` }}><CurrencySymbol />{get_restaurant_order_bill.total_discounted_price}</Box></Grid>
                                    </Grid>
                                    {get_restaurant_order_bill.gst && Object.keys(get_restaurant_order_bill.gst).length && (
                                        <Box className='gst-amount'>
                                            <Grid container>
                                                <Grid item xs={8}>CGST ({get_restaurant_order_bill.gst.cgst.percent}%)</Grid>
                                                <Grid item xs={4}><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `right` }}><CurrencySymbol />{get_restaurant_order_bill.gst.cgst.amount}</Box></Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={8}>SGST ({get_restaurant_order_bill.gst.sgst.percent}%)</Grid>
                                                <Grid item xs={4}><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `right` }}><CurrencySymbol />{get_restaurant_order_bill.gst.sgst.amount}</Box></Grid>
                                            </Grid>
                                        </Box>
                                    )}
                                    <Grid container style={{ border: `none` }}>
                                        <Grid item xs={8}>AMOUNT</Grid>
                                        <Grid item xs={4}><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `right` }}><CurrencySymbol />{get_restaurant_order_bill.total_gst_price}</Box></Grid>
                                    </Grid>
                                </Box>
                            </Fragment>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Stack style={{ width: `100%` }} direction="row" justifyContent="center" alignItems="center" spacing={1}>
                            <DialogActionSaveBtn tabIndex={3} text={`Whatsapp Bill`} startIcon={<WhatsApp />} onClick={generateBill} loading={generate_restaurant_order_bill_loading} />
                            <DialogActionCloseBtn tabIndex={4} text={`Cancel`} onClose={onClose} />
                        </Stack>
                    </DialogActions>
                </Card>
            }
        </RestaurantOrderBillModalStyled>
    );
}

export { RestaurantOrderBillModal };
