import { Adjust, AdUnits, Article, AttachFile, Event, FileDownload, Instagram, MoreVert, Numbers, PostAdd } from '@mui/icons-material'
import { Box, CardMedia, Chip, Divider, Grid, IconButton, Menu, MenuItem, Stack, Card, CardContent, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';
import { postAuthRoutes } from '../../../../../routes'
import { AbilityCan } from '../../../../../_helpers/permission/AbilityCan';
import { CurrencySymbol } from '../../../../../_components/currency-symbol';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { Fragment } from 'react';
import { useState } from 'react';

const timeBetweenPostOptions = [
	{ value: 'one_time', label: 'One time' },
	{ value: 'once_in_a_day', label: 'Once in a day' },
	{ value: 'once_in_a_week', label: 'Once in a week' },
	{ value: 'once_in_a_month', label: 'Once in a monh' },
]

function PromoCard({ promo, statusUpdateModal, hasAction = true }) {

	/** Initialize form statusData values from promo */
	const statusData = { id: promo._id, status: promo.status, item: '' };

	/** Initialize and declare state */
	const { user } = useSelector((state) => state.UserReducer);
	const [anchorEl, setAnchorEl] = useState(null);

	/** Initialize plugins and variables */
	const open = Boolean(anchorEl);
	let navigate = useNavigate();

	/**
	 * function to handle click icon menu
	 * @param {object} event form object
	 * @author Akshay N
	 * @created_at 30 May 2022
	 */
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	/**
	 * function to handle close icon menu
	 * @author Akshay N
	 * @created_at 30 May 2022
	 */
	const handleClose = () => {
		setAnchorEl(null);
	};

	/**
	 * function to direct to update promo page
	 * @return view
	 * @author Akshay N
	 * @created_at 30 May 2022
	 */
	function handleUpdateClick() {
		setAnchorEl(null);
		navigate(postAuthRoutes('update_promos').path, { state: { promo: promo }, });
	}

	/**
	 * function to direct to update promo page
	 * @return view
	 * @author Akshay N
	 * @created_at 30 May 2022
	 */
	function handleDetailsClick() {
		setAnchorEl(null);
		const pathname = postAuthRoutes('promo_details').path;
        navigate({ pathname, search: createSearchParams(`?pId=${promo._id}`).toString() });
	}

	/**
	 * function to change promo status
	 * @return view
	 * @author Akshay N
	 * @created_at 30 May 2022
	 */
	function handleStatusChangeClick() {
		setAnchorEl(null);
		setTimeout(() => {
			statusUpdateModal(statusData);
		}, 100);
	}

	const howOften = (value) => {
		const option = timeBetweenPostOptions.find(option => option.value === value);
		return option ? option.label : '';
	}

	return (
		<Card style={{ height: '100%' }}>
			<Grid container spacing={2} display="flex" alignItems="center">
				<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
					<Box style={{ width: `100%` }}>
						<CardMedia
							component={'img'}
							src={promo.file}
							onError={(e) => {
								e.target.src = promo.file_error;
							}}
							sx={{ position: 'relative', padding: 0, margin: 0, width: '100%', borderTopRightRadius: 0, borderBottomRightRadius: { xs: 0, sm: 0, md: 4 }, borderBottomLeftRadius: { xs: 0, sm: 0, md: 4 } }}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
					<CardContent>
						<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} mb={1} mt={2}>
							<Typography variant="h5" onClick={handleDetailsClick} sx={{ cursor: `pointer` }}>{`${promo.name}`}</Typography>
							<Box>
								{promo.active_today && <Chip size="small" label="Active Today" color="primary" sx={{ mr: 1 }} />}

								<Chip size="small" label={promo.status === 'active' ? 'Active' : 'Inactive'} color={promo.status === 'active' ? 'success' : 'error'} />
								{hasAction && (
									<Fragment>
										<IconButton aria-label="more" id="long-button" aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={handleClick}>
											<MoreVert />
										</IconButton>
										<Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button' }} anchorEl={anchorEl} open={open} onClose={handleClose}>
											<AbilityCan I="brand_promos_details" passThrough={user.role_slug === 'developer' ? true : false}>
												<MenuItem onClick={handleDetailsClick}>{`Details`}</MenuItem>
											</AbilityCan>
											<AbilityCan I="brand_promos_change_status" passThrough={user.role_slug === 'developer' ? true : false}>
												<MenuItem onClick={handleStatusChangeClick}>{promo.status === 'active' ? 'Deactivate' : 'Activate'}</MenuItem>
											</AbilityCan>
											<AbilityCan I="brand_promos_update" passThrough={user.role_slug === 'developer' ? true : false}>
												<MenuItem onClick={handleUpdateClick}>{`Update`}</MenuItem>
											</AbilityCan>
										</Menu>
									</Fragment>
								)}
							</Box>
						</Box>
						<Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>{`${promo.description}`}</Typography>
						<Divider />
						<Box display="flex" justifyContent="space-between" alignItems="center">
							<Typography variant="body2">
								<Instagram /> {`Instagram handle : `}
							</Typography>
							<Typography variant="h6">{`@${promo.insta_handle}`}</Typography>
						</Box>
						<Box display="flex" justifyContent="space-between" alignItems="center">
							<Typography variant="body2">
								<Event /> {`Start date : `}
							</Typography>
							<Typography variant="h6">{`${promo.promo_start_date}`}</Typography>
						</Box>
						<Box display="flex" justifyContent="space-between" alignItems="center">
							<Typography variant="body2">
								<Event /> {`End date : `}
							</Typography>
							<Typography variant="h6">{`${promo.promo_end_date}`}</Typography>
						</Box>
						<Divider />
						<Stack spacing={1}>
							<Box display="flex" justifyContent="space-between" alignItems="center">
								<Typography variant="body2">{`Promo type : `}</Typography>
								<Chip label={`${promo.promo_type}`} size="small" icon={<AttachFile />} />
							</Box>
							<Box display="flex" justifyContent="space-between" alignItems="center">
								<Typography variant="body2">{`Promo payment method : `}</Typography>
								<Chip label={`${promo.payment_method}`} size="small" icon={<Adjust />} />
							</Box>
							{promo.issue_coupons_at_name && (
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<Typography variant="body2">{`Issue coupons : `}</Typography>
									<Chip label={`${promo.issue_coupons_at_name}`} size="small" icon={<AdUnits />} />
								</Box>
							)}
							{promo.time_between_post && (
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<Typography variant="body2">{`How often you can post : `}</Typography>
									<Chip label={`${howOften(promo.time_between_post)}`} size="small" icon={<PostAdd />} />
								</Box>
							)}
							{promo.payment_method === 'price' && (
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<Typography variant="body2">{`Promo cost per post : `}</Typography>
									<Chip label={`${promo.promo_cost_per_influencer}/-`} size="small" icon={<CurrencySymbol />} />
								</Box>
							)}
							{promo.payment_method === 'coupon' && (
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<Typography variant="body2">{`Coupon file uploaded : `}</Typography>
									<Chip component="a" href={promo.coupon_code_file} label={`Download`} clickable size="small" icon={<FileDownload />} />
								</Box>
							)}
							{promo.payment_method === 'coupon' && (
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<Typography variant="body2">{`Coupon codes : `}</Typography>
									<Chip component="a" href={`${postAuthRoutes('promo_details').path}?pId=${promo._id}&pdTab=coupons`} clickable label={`${promo.used_coupon_count}/${promo.coupon_codes}`} size="small" icon={<Numbers />} />
								</Box>
							)}
							<Box display="flex" justifyContent="space-between" alignItems="center">
								<Typography variant="body2">{`Maximum promos : `}</Typography>
								<Chip component="a" href={`${postAuthRoutes('promo_details').path}?pId=${promo._id}&pdTab=stories`} clickable label={`${promo.used_coupon_count}/${promo.number_of_promos}`} size="small" icon={<Article />} />
							</Box>
							<Divider />
							<Box display="flex" justifyContent="space-between">
								<Typography variant="body2" align="left" style={{ minWidth: '35%' }}>{`Coupon message : `}</Typography>
								<Typography variant="body2" align="right" style={{ whiteSpace: 'pre-line' }}>{`${promo.coupon_message}`}</Typography>
							</Box>
							{promo.issue_coupons_at === 'after_24_hours' && (
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<Typography variant="body2" align="left" style={{ minWidth: '35%' }}>{`Thank you message : `}</Typography>
									<Typography variant="body2" align="right" style={{ whiteSpace: 'pre-line' }}>{`${promo.thank_you_message}`}</Typography>
								</Box>
							)}
						</Stack>
					</CardContent>
				</Grid>
			</Grid>
		</Card>
	);
}

export { PromoCard }