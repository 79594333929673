import { Chip, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormSelect } from "../../../../../../_components/form";
import { Dialog, DialogContent, DialogActions, DialogActionCloseBtn, DialogActionSaveBtn, DialogTitle } from "../../../../../../_components/dialog";
import { handleInputChange } from "../../../../../../_helpers";
import { Fragment } from "react";

// Initialize form input values to null
const inputs = { status: '' };

// Declare filter status select options
const filterStatus = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
    { value: 'done', label: 'Done' },
];

function GiveawaysCouponCodesFilter({ ...props }) {

    /** Initialize props values */
    const { show, closeModal, applyFilter, couponFilter } = props;

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [filteredData, setFilteredData] = useState({});

    useEffect(() => {

        setData({ status: couponFilter.status });

        let filtered = {};
        Object.keys(couponFilter).forEach(function (item, i) {
            if (couponFilter[item])
                filtered[item] = couponFilter[item];
        });
        setFilteredData({ ...filtered });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [couponFilter]);

    /**
     * function to close giveaway coupon code filter modal
     * @param {string} status Entered status on giveaway coupon code filter modal
     * @return view
     * @author
     * @created_at 30 May 2022
     */
    const onClose = () => {
        setData({ status: '' });
        closeModal(false);
    };

    /**
     * function to clear giveaway coupon code filter modal
     * @param {string} status Entered status on giveaway coupon code filter modal
     * @return view
     * @author
     * @created_at 30 May 2022
     */
    const onClear = () => {
        setData({ status: '' });
        applyFilter({ status: '' });
        closeModal(false);
    };

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author
     * @created_at 30 May 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        applyFilter(data);
        closeModal(false);

    }

    /**
     * function to clear all data entered on giveaway coupon code filter modal
     * @return view
     * @author
     * @created_at 30 May 2022
     */
    const clearFilterItem = (item) => {
        const clearItem = { ...data };
        clearItem[item] = '';
        setData({ ...clearItem });
        applyFilter({ ...clearItem });
    };

    return (
        <Fragment>
            {
                Object.keys(filteredData).length > 0 &&
                (
                    <Grid container mb={2} >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack direction="row" spacing={1}>
                                {
                                    Object.keys(filteredData).map((item, i) => {
                                        if (item === 'status') {
                                            const status = filterStatus.find(function (status) { return status.value === filteredData[item]; });
                                            return <Chip key={i} label={status.label} onDelete={() => clearFilterItem(item)} />;
                                        } else {
                                            return <Chip key={i} label={filteredData[item]} onDelete={() => clearFilterItem(item)} style={{ textTransform: `capitalize` }} />;
                                        }
                                    })
                                }
                            </Stack>
                        </Grid>
                    </Grid>
                )
            }
            <Dialog isDialogOpen={show} onClose={onClose} maxWidth='xs'>
                <form onSubmit={handleSubmit} noValidate >
                    <DialogTitle id="couponFilter" onClose={onClose} title='Filter' />
                    <DialogContent >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormSelect
                                    tabIndex={1}
                                    label={`Status`}
                                    name={`status`}
                                    placeholder={`Select status`}
                                    onChange={handleChange}
                                    data={filterStatus}
                                    value={data.status}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent >
                    <DialogActions >
                        <DialogActionSaveBtn tabIndex={3} text='Filter' />
                        <DialogActionCloseBtn tabIndex={2} onClose={onClear} text='Clear' />
                    </DialogActions>
                </form>
            </Dialog>
        </Fragment>
    );
}

export { GiveawaysCouponCodesFilter };