import { styled } from "@mui/material";
import { Link } from 'react-router-dom';

const RouteLinkStyled = styled(({ ...props }) => (<Link {...props} />))`
    font-size : ${props => props.theme.typography.size.sm};
    text-decoration: none;
    display:flex;
    align-items:center;
    width: fit-content;
`;

export { RouteLinkStyled }