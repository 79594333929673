import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { Add, FilterAlt, TableRestaurant } from '@mui/icons-material';
import { Grid, Stack } from '@mui/material';
import queryString from 'query-string';
import { postAuthRoutes } from '../../../../routes';
import { CardHeader } from '../../../../_components/card';
import { GeneralButton } from '../../../../_components/controls';
import { FormSearchInput } from '../../../../_components/form';
import { UpdateDataStatusDialog } from '../../../../_components/dialog';
import { DeleteDataDialog } from '../../../../_components/dialog';
import { AbilityCan } from '../../../../_helpers/permission/AbilityCan';
import { RestaurantMenuFilter } from '.';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NoData } from '../../../../_components/layout';
import { RestaurantMenuCard, RestaurantMenuCardLoader } from './restaurant-menu-card';
import { Fragment } from 'react';
import { RestaurantMenuAction } from '../../../../redux//actions';
import { ConfigureGst } from '../configure-gst';
import { FutureOrders } from '../future-orders';

function RestaurantMenu() {
	/** Initialize plugins and variables */
	const dispatch = useDispatch();
	let navigate = useNavigate();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	/** Redux reducer state */
	const { per_page, page, restaurant_menu_loading: isLoading, restaurant_menu } = useSelector((state) => state.RestaurantMenuReducer);
	const { user } = useSelector((state) => state.UserReducer);
	const changeStatus = (params) => dispatch(RestaurantMenuAction.changeRestaurantMenuStatus(params));
	const deleteRestaurantMenu = (params) => dispatch(RestaurantMenuAction.deleteRestaurantMenu(params));
	const listRestaurantMenu = (params) => dispatch(RestaurantMenuAction.listRestaurantMenu(params));

	/** Initialize and declare state */
	const [payload, setPayload] = useState({ limit: per_page, page: page, filter: { category: searchParams.get('category') ?? '' }, search: searchParams.get('q') ?? '' });
	const [showRestaurantMenuFilterModal, setShowRestaurantMenuFilterModal] = useState(false);
	const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
	const [statusData, setStatusData] = useState({ id: '', status: '', title: '' });
	const [restaurantMenuDeleteData, setRestaurantMenuDeleteData] = useState({ id: '' });
	const [showDeleteModal, setShowDeleteModal] = useState(false);



	/**
	 * function to create Restaurant Menu
	 * @param {string} null
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	function createRestaurantMenu() {
		navigate(postAuthRoutes('create_restaurant_menu').path,);
	}

	/**
	 * function to open/close update restaurant menu Filter model
	 * @param {string} null
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	const statusUpdateModal = ({ id, status, title }) => {
		setStatusData({ id: id, status: status, title: '' });
		setShowStatusUpdateModal(!showStatusUpdateModal);
	};

	/**
	 * function to update status
	 * @param {string} null
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	const updateStatusAction = async (id) => {
		setShowStatusUpdateModal(!showStatusUpdateModal);
		await changeStatus({ restaurantMenuId: id });
	};

	/**
	 * function to open/close restaurant menu delete modal
	 * @return view
	 * @author Naveen K
	 * @created_at 14 June 2022
	 */
	const restaurantMenuDeleteModal = async ({ id }) => {
		setRestaurantMenuDeleteData({ id: id });
		setShowDeleteModal(!showDeleteModal);
	};

	/**
	 * function to delete restaurant menu
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	const restaurantMenuDeleteAction = async (id) => {
		setShowDeleteModal(!showDeleteModal);
		await deleteRestaurantMenu({ restaurantMenuId: id });
	};

	/**
	 * function to open/close restaurant menu filter modal
	 * @param {string} null
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	const restaurantMenuFilterModal = () => {
		setShowRestaurantMenuFilterModal(!showRestaurantMenuFilterModal);
	};

	/**
	 * function to apply the selected filter options on the restaurant menu list
	 * @param {Object} filterData
	 * @param {String} filterData.category Selected category from the restaurant menu filter modal
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	const applyFilter = (filterData) => {
		setPayload({ ...payload, page: 1, filter: filterData });
	};

	/**
	 * function to apply the search key words on the restaurant menu list
	 * @param {String} text Search keyword used
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	const applySearch = (text) => {
		setPayload({ ...payload, page: 1, search: text });
	};

	useEffect(() => {

		/* Read the payload and append the parameters to the url for proper reload */
		const queryParam = { ...payload.filter };
		Object.keys(queryParam).forEach(element => {
			if (queryParam[element] === "" || queryParam[element] === null)
				delete queryParam[element];
		});

		queryParam.p = payload.page;
		if (payload.search) queryParam.q = payload.search; else delete queryParam.q;
		if (payload.filter.category) queryParam.category = payload.filter.category; else delete queryParam.category;

		const pathname = postAuthRoutes('restaurant_menu').path;
		navigate({ pathname, search: createSearchParams(`?${queryString.stringify(queryParam)}`).toString() });
		getNext();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payload.filter, payload.search]);

	const getNext = async () => {
		const result = await listRestaurantMenu(payload);
		if (result.status) {
			setPayload({ ...payload, page: payload.page + 1 }); // If result success, update payload state
		}
	};

	return (
		<Fragment>
			<CardHeader
				title={postAuthRoutes('restaurant_menu').name}
				action={
					<Stack spacing={1} direction="row">
						<AbilityCan I="brand_restaurant_menu_search" passThrough={user.role_slug === 'developer' ? true : false}>
							<FormSearchInput getSearchText={applySearch} searchText={payload.search} />
						</AbilityCan>
						<AbilityCan I="brand_restaurant_menu_create" passThrough={user.role_slug === 'developer' ? true : false}>
							<GeneralButton startIcon={<Add />} label={postAuthRoutes('create_restaurant_menu').name} onClick={() => createRestaurantMenu()} />
						</AbilityCan>
						<AbilityCan I="brand_restaurant_menu_configure_gst" passThrough={user.role_slug === 'developer' ? true : false}>
							<ConfigureGst />
						</AbilityCan>
						<AbilityCan I="brand_restaurant_menu_future_orders" passThrough={user.role_slug === 'developer' ? true : false}>
							<FutureOrders />
						</AbilityCan>
						<AbilityCan I="brand_restaurant_menu_filter" passThrough={user.role_slug === 'developer' ? true : false}>
							<GeneralButton label={<FilterAlt />} onClick={() => restaurantMenuFilterModal()} />
						</AbilityCan>
					</Stack>
				}
			/>

			<RestaurantMenuFilter show={showRestaurantMenuFilterModal} closeModal={restaurantMenuFilterModal} applyFilter={applyFilter} restaurantMenuFilter={payload.filter} />
			<InfiniteScroll dataLength={restaurant_menu.length} next={getNext} hasMore={true}>
				<Grid container spacing={2} direction="row" justifyContent="left" alignItems="stretch">
					{restaurant_menu.length > 0 &&
						restaurant_menu.map((restaurantMenu, i) => {
							return (
								<Grid style={{ display: 'flex'}} item xs={12} sm={12} md={3} lg={3} xl={3} key={i}>
									<RestaurantMenuCard restaurantMenu={restaurantMenu} restaurantMenuDeleteModal={restaurantMenuDeleteModal} statusUpdateModal={statusUpdateModal} />
								</Grid>
							);
						})}
					{isLoading && <RestaurantMenuCardLoader />}
					{!isLoading && restaurant_menu.length === 0 && <NoData content1={`Restaurant Menu`} content2={`Not Found`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} minHeight={`75vh`} />}
				</Grid>
			</InfiniteScroll>
			<UpdateDataStatusDialog show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />
			<DeleteDataDialog show={showDeleteModal} closeModal={restaurantMenuDeleteModal} data={restaurantMenuDeleteData} deleteModal={restaurantMenuDeleteAction} />
		</Fragment>
	);
}
export { RestaurantMenu };
