import { apiServices } from '../../_helpers';


export const StoryServices = {

    /**
     * Service for list stories
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
	 * @created_at 30 May 2022 
     */
    listStories: (params) => {
        return apiServices.post('/brand/story/list', params)
            .then((response) => { return response; })

    },

    /**
    * Service to get story count
    * @param {Object} params - The params which are used for the api
    * @author
	* @created_at 30 May 2022
    */
    getStoryCount: (params) => {
        return apiServices.post('/brand/story/count', params)
            .then((response) => { return response; })
    },


    /**
     * Service to get promo details from stories
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     * @created_at 30 May 2022
    */
    getPromoDetails: (params) => {
        return apiServices.post('/brand/story/promo-details', params)
            .then((response) => { return response; })

    },

    /**
     * Service for get story details
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    getStoryDetails: (params) => {
        return apiServices.post('/brand/story/detail', params)
            .then((response) => { return response; })
    },

    /**
     * Service for get story timeline
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    getStoryTimeline: (params) => {
        return apiServices.post('/brand/story/timeline', params)
            .then((response) => { return response; })
    },

    /**
     * Service for list influencer
     * @param {Object} params - The params which are used for the api
     * @author
	 * @created_at 30 May 2022
     */
    listInfluencerStories: (params) => {
        return apiServices.post('/brand/influencer/stories', params)
            .then((response) => { return response; })
    },

    /**
     * Service to export story CSV
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     * @created_at 30 May 2022
   */
    exportStoryCsv: (params) => {
        return apiServices.post('/brand/story/export', params)
            .then((response) => { return response; })

    },
};

