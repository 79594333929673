import {
    GET_IG_INSIGHTS_REQUEST, GET_IG_INSIGHTS_SUCCESS, GET_IG_INSIGHTS_FAILURE,
    GET_IG_INSIGHTS_DAILY_COUPON_REQUEST, GET_IG_INSIGHTS_DAILY_COUPON_SUCCESS, GET_IG_INSIGHTS_DAILY_COUPON_FAILURE,
    GET_INSIGHT_CHART_REQUEST, GET_INSIGHT_CHART_SUCCESS, GET_INSIGHT_CHART_FAILURE,
    
} from "../actions";

const initialState = {
    ig_insights_loading: false, ig_insights: {}, ig_insights_error: {},
    insight_chart_loading: false, insight_chart: {}, insight_chart_error: {},
    ig_insights_daily_coupons_loading: false, ig_insights_daily_coupons: 0, ig_insights_daily_coupons_error: {},
};

export function IgInsightsReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Ig insights redux */
        case GET_IG_INSIGHTS_REQUEST: {
            return {
                ...state,
                ig_insights_loading: true,
                ig_insights_error: {}
            };
        } case GET_IG_INSIGHTS_SUCCESS: {
            return {
                ...state,
                ig_insights_loading: false,
                ig_insights: action.data,
                ig_insights_error: {}
            };
        } case GET_IG_INSIGHTS_FAILURE: {
            return {
                ...state,
                ig_insights_loading: false,
                ig_insights_error: action.error
            };
        }
        /** end::Ig Insights redux */

        /** begin::Insight chart redux */
        case GET_INSIGHT_CHART_REQUEST: {
            return {
                ...state,
                insight_chart_loading: true,
                insight_chart: {},
                insight_chart_error: []
            };
        } case GET_INSIGHT_CHART_SUCCESS: {
            return {
                ...state,
                insight_chart_loading: false,
                insight_chart: action.data,
                insight_chart_error: [],
            };
        } case GET_INSIGHT_CHART_FAILURE: {
            return {
                ...state,
                insight_chart_loading: false,
                insight_chart: {},
                insight_chart_error: []
            };
        }
        /** end::Insight chart redux */

        /** begin::Ig insights daily coupons redux */
        case GET_IG_INSIGHTS_DAILY_COUPON_REQUEST: {
            return {
                ...state,
                ig_insights_daily_coupons_loading: true,
                ig_insights_daily_coupons_error: {}
            };
        } case GET_IG_INSIGHTS_DAILY_COUPON_SUCCESS: {
            return {
                ...state,
                ig_insights_daily_coupons_loading: false,
                ig_insights_daily_coupons: action.data.total ? action.data.total : 0,
                ig_insights_daily_coupons_error: {}
            };
        } case GET_IG_INSIGHTS_DAILY_COUPON_FAILURE: {
            return {
                ...state,
                ig_insights_daily_coupons_loading: false,
                ig_insights_daily_coupons_error: action.error
            };
        }
        /** end::Ig Insights daily coupons redux */
        default:
            return state;
    }
}