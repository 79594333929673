import React, { useState } from 'react'
import { FormHelperText, InputLabel, OutlinedInput, styled } from '@mui/material';
import { FormDateRangePickerStyled } from '.';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';


function FormDateRangePicker({ label, name, value, placeholder, onChange, error, info }) {

    const [val, setVal] = useState((value && value !== '') ? value : '');

    const DATE_FORMAT = 'DD/MM/yyyy';

    const handleApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format(DATE_FORMAT) + ' - ' + picker.endDate.format(DATE_FORMAT)
        );
        onChange({ target: { type: `date_time`, name: name, value: event.target.value } });
        setVal(event.target.value);
    };

    const handleCancel = (event, picker) => {
        picker.element.val('');
        onChange({ target: { type: `date_time`, name: name, value: null } });
        setVal(event.target.value);
    };

    const handleChange = (event) => {
        setVal(event.target.value);
    };

    return (
        <FormDateRangePickerStyled fullWidth  >
            <InputLabel htmlFor={name} >{label}</InputLabel>
            <DateRangePicker
                onApply={handleApply}
                onCancel={handleCancel}
                autoApply={true}
                initialSettings={{
                    autoUpdateInput: false,
                    locale: {
                        format: DATE_FORMAT,
                    },
                }}>
                <OutlinedInput
                    type="text"
                    placeholder={placeholder ? placeholder : label}
                    name={name}
                    label={label}
                    error={error ? true : false}
                    value={val}
                    onChange={handleChange}
                    readOnly={true}
                />
            </DateRangePicker>
            {(info) && <FormHelperText id={`helper_${name}`} >{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error >{error}</FormHelperText>}
        </FormDateRangePickerStyled>
    )
}

export { FormDateRangePicker }
