import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { validate } from ".";
import { CancelButton, SubmitButton } from "../../../../../_components/controls";
import { Card, CardContent, CardHeader } from "../../../../../_components/card";
import { Box, Grid, Stack, Alert } from "@mui/material";
import { GiveawayAction } from "../../../../../redux/actions";
import { Info } from "@mui/icons-material";
import sampleCouponFile from "./sample-coupon-file.csv"
import { Fragment } from "react";
import { postAuthRoutes } from "../../../../../routes";
import { FormBrowseFile, FormInput, FormMultipleDatePicker, FormRadio } from "../../../../../_components";
import { APP_NAME, handleInputChange, validateForm } from "../../../../../_helpers";

// Initialize form input values to null
const inputs = { giveaway_id: '', name: '', insta_handle: '', description: '', sample_story: '', coupon_file: '', start_date: '', end_date: '', thank_you_message: '', send_coupon_code: 'no', giveaway_date: [], time_between_post: '' };

// Declare send coupon code select options
const sendCouponCode = [{ value: 'yes', label: 'Yes' }, { value: 'no', label: 'No' }]

// // Declare time between post select options
// const timeBetweenPostOptions = [
//     { value: 'one_time', label: 'One time' },
//     { value: 'once_in_a_day', label: 'Once in a day' },
//     { value: 'once_in_a_week', label: 'Once in a week' },
//     { value: 'once_in_a_month', label: 'Once in a month' },
// ]

function CreateOrUpdateGiveaway() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { giveaway } = location.state ?? {};

    /** Redux actions */
    const createGiveaway = (params) => dispatch(GiveawayAction.createGiveaway(params));
    const updateGiveaway = (params) => dispatch(GiveawayAction.updateGiveaway(params));
    const getGiveawayDates = (params) => dispatch(GiveawayAction.getGiveawayDates(params));

    /** Redux reducer states */
    const { create_giveaway_loading: isLoading, giveaway_dates: existing_giveaway_dates, create_giveaway_request } = useSelector((state) => state.GiveawayReducer);
    const { user_loading: isUserLoading, user } = useSelector((state) => state.UserReducer);

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, send_coupon_code: '', });
    const [action, setAction] = useState({ isSubmitted: false });
    const [isGiveawayLoading, setIsGiveawayLoading] = useState(true);

    useEffect(() => {
        if (giveaway && giveaway._id) {
            setData({
                giveaway_id: giveaway._id,
                name: giveaway.name,
                insta_handle: giveaway.insta_handle,
                description: giveaway.description,
                sample_story: giveaway.sample_story,
                start_date: (giveaway.start_date ? (new Date(giveaway.start_date.split(" ").map(part => part.split("/").reverse().join("/")).join(" ")).toISOString()) : ''),
                end_date: (giveaway.end_date ? (new Date(giveaway.end_date.split(" ").map(part => part.split("/").reverse().join("/")).join(" ")).toISOString()) : ''),
                thank_you_message: giveaway.thank_you_message ?? '',
                send_coupon_code: giveaway.send_coupon_code ?? '',
                coupon_file: giveaway.coupon_code_file,
                giveaway_date: giveaway.giveaway_dates,
                time_between_post: giveaway.time_between_post ?? ''

            });
            setIsGiveawayLoading(false);
        } else {
            setIsGiveawayLoading(false);
        }
    }, [giveaway])

    useEffect(() => {

        getGiveawayDates({ giveaway_id: (giveaway && giveaway._id) ? giveaway._id : null });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [giveaway])

    useEffect(() => {
        if (create_giveaway_request && create_giveaway_request.status === 1) {
            navigate(postAuthRoutes('giveaways').path);

        }
    }, [create_giveaway_request])

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const handleChange = async (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle create/update giveaway form submit action
     * @param {object} e form object
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        formData.append("giveaway_id", data.giveaway_id);
        formData.append("name", data.name);
        formData.append("insta_handle", data.insta_handle);
        formData.append("description", data.description);
        formData.append("sample_story", data.sample_story);
        formData.append("start_date", data.start_date);
        formData.append("end_date", data.end_date);
        formData.append("thank_you_message", data.thank_you_message);
        formData.append("send_coupon_code", data.send_coupon_code);
        formData.append("coupon_file", data.coupon_file);
        formData.append("giveaway_date", data.giveaway_date);
        formData.append("time_between_post", data.time_between_post);

        dispatchCreateOrUpdateGiveawayAction(formData);
    }

    /**
     * function to dispatch create/update giveaway api
     * @param {Object} formData - The params which are used for the api
     * @param {string} formData.giveaway_id - Giveaway id which used for update giveaway
     * @param {string} formData.name - Giveaway name
     * @param {string} formData.insta_handle - Giveaway instagram handle
     * @param {string} formData.description - Giveaway description
     * @param {string} formData.sample_story - Giveaway sample story
     * @param {string} formData.start_date - Giveaway start date
     * @param {string} formData.end_date - Giveaway end date
     * @param {string} formData.thank_you_message - Giveaway thank you message
     * @param {string} formData.send_coupon_code - Giveaway send coupon code
     * @param {string} formData.coupon_file - Giveaway coupon code csv file uploaded
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const dispatchCreateOrUpdateGiveawayAction = async (formData) => {
        const isCreated = giveaway && giveaway._id ? await updateGiveaway(formData) : await createGiveaway(formData);
        if (isCreated && isCreated.status === 1) {
            navigate(postAuthRoutes('giveaways').path);
        } else {
            setErrors(isCreated.errors);
        }
    }

    /**
     * function to cancel giveaway create or update
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const cancelGiveaway = () => {
        navigate(postAuthRoutes('giveaways').path);
    }

    // function getMaxDate() {
    //     let date;
    //     let duration;

    //     if (giveaway && giveaway.giveaway_plan) {
    //         duration = giveaway.giveaway_plan.duration;
    //     } else if (user && user.giveaway_plan) {
    //         duration = user.giveaway_plan.duration;
    //     }
    //     if ((data.giveaway_date && data.giveaway_date.length > 0) && duration) {
    //         if (data.giveaway_date.length === duration) {
    //             date = data.giveaway_date[data.giveaway_date.length - 1]
    //         }
    //     }

    //     return (date ? date : null);
    // }

    return (
        <Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${giveaway && giveaway._id ? postAuthRoutes('update_giveaways').name : postAuthRoutes('create_giveaways').name}`}</title>
            </Helmet>
            <Box>
                <CardHeader title={giveaway && giveaway._id ? postAuthRoutes('update_giveaways').name : postAuthRoutes('create_giveaways').name} />
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={6} lg={6}>
                            <Card >
                                {!isGiveawayLoading && <CardContent >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={1}
                                                label='Giveaway name'
                                                name='name'
                                                value={data.name}
                                                error={action.isSubmitted && (errors.name ? errors.name : '')}
                                                onChange={handleChange}
                                                autoFocus={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={2}
                                                label='Giveaway instagram handle'
                                                name='insta_handle'
                                                value={data.insta_handle ? data.insta_handle : (!isUserLoading && user) ? user.insta_handle : ''}
                                                error={action.isSubmitted && (errors.insta_handle ? errors.insta_handle : '')}
                                                onChange={handleChange}
                                                readOnly={true}
                                                startIcon = '@'
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <FormSelect
                                                tabIndex={1}
                                                label={`How often`}
                                                name={`time_between_post`}
                                                placeholder={`Select how often`}
                                                onChange={handleChange}
                                                data={timeBetweenPostOptions}
                                                value={data.time_between_post}
                                                error={(action.isSubmitted && (errors.time_between_post)) ? (errors.time_between_post) : ''}
                                            />
                                        </Grid> */}
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={4}
                                                label='Description'
                                                name='description'
                                                value={data.description}
                                                error={action.isSubmitted && (errors.description ? errors.description : '')}
                                                onChange={handleChange}
                                                multiline
                                                maxLength={1000}
                                                minRows={2}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <FormMultipleDatePicker
                                                tabIndex={5}
                                                label='Giveaway date'
                                                name='giveaway_date'
                                                value={data.giveaway_date}
                                                error={action.isSubmitted && errors.giveaway_date ? errors.giveaway_date : ''}
                                                onChange={handleChange}
                                                disablePast={true}
                                                disableDates={existing_giveaway_dates}
                                            // maxDate={getMaxDate()}
                                            // disableUnchosenDates={getMaxDate() ? true : false}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <FormRadio
                                                label={`Would you like to send a coupon code?`}
                                                name={`send_coupon_code`}
                                                onChange={handleChange}
                                                data={sendCouponCode}
                                                value={data.send_coupon_code}
                                                error={(action.isSubmitted && ((errors.send_coupon_code)) ? (errors.send_coupon_code) : '')}
                                            />
                                        </Grid>
                                        {data.send_coupon_code === 'yes' && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Box>
                                                <FormBrowseFile
                                                    tabIndex={10}
                                                    name="coupon_file"
                                                    error={(action.isSubmitted && (errors.coupon_file)) ? (errors.coupon_file) : ''}
                                                    placeholder="your coupon code csv"
                                                    value={data.coupon_file}
                                                    onChange={handleChange}
                                                    acceptType=".csv"
                                                    info={`Allowed Format: csv | Allowed Maximum Size: 2 MB`}
                                                />
                                                {!data.coupon_file && <Alert icon={<Info sx={{ fontSize: `14px` }} color="info" />} style={{ background: `#00000000`, padding: `0 4px`, marginTop: `4px`, fontSize: `12px` }}><a style={{ fontSize: `12px` }} href={sampleCouponFile} download>Click here to download the sample csv file</a></Alert>}
                                            </Box>
                                        </Grid>}
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormBrowseFile
                                                tabIndex={10}
                                                name="sample_story"
                                                error={(action.isSubmitted && (errors.sample_story)) ? (errors.sample_story) : ''}
                                                placeholder="your sample story"
                                                value={data.sample_story}
                                                onChange={handleChange}
                                                acceptType=".png,.jpg,.jpeg"
                                                info="Allowed Format: png, jpg, jpeg | Allowed Maximum Size: 2 MB | Suggested Aspect Ratio: 1080 x 1920"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={4}
                                                label='Thank you message'
                                                name='thank_you_message'
                                                value={data.thank_you_message}
                                                error={action.isSubmitted && (errors.thank_you_message ? errors.thank_you_message : '')}
                                                onChange={handleChange}
                                                multiline
                                                maxLength={250}
                                                minRows={2}
                                            />
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                                <SubmitButton label={giveaway && giveaway._id ? 'Update' : 'Create'} loading={isLoading} tabIndex={11} />
                                                <CancelButton onClick={cancelGiveaway} tabIndex={12} />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </CardContent>}
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Fragment>
    )
}

export { CreateOrUpdateGiveaway };
