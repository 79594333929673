import React, { useEffect, useState } from 'react';
import { Snackbar as MuiSnackbar, styled} from '@mui/material';
import Slide from '@mui/material/Slide';
import { useDispatch } from 'react-redux';
import { GlobalAction } from '../../redux/actions';

const StyledSnackbar = styled(MuiSnackbar)(({ theme }) => ({
    '& .MuiSnackbarContent-root': {
        color: theme ==='light' ? '#474747' : '#fff'
    }
}));

function Snackbar({ message, theme }) {

    const [isOpen, setIsOpen] = useState(message ? true : false);
    const dispatch = useDispatch();

    useEffect(() => {
        setIsOpen(message ? true : false)
        return () => {
            setTimeout(() => {
                dispatch(GlobalAction.showToastMessage(null))
            }, 3000);
        };
        //eslint-disable-next-line
    }, [message])

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <StyledSnackbar open={isOpen} onClose={handleClose} TransitionComponent={Slide} message={message} key={Slide.name} theme={theme}/>
    );
}


export { Snackbar };
