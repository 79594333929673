import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, } from 'react-router';
import { useNavigate, } from 'react-router-dom';
import { Helmet } from "react-helmet";

import { GlobalAction, SignInAction, UserAction } from '../../../redux/actions';

import { Menu as MenuIcon, Search, SupportAgent } from '@mui/icons-material';
import { AppBar, Divider, IconButton, ListItemIcon, MenuItem, Toolbar, Box, Menu } from '@mui/material';
import { ShortLogo } from '../../../_components/logo';
import { styled } from '@mui/styles';

import { getPostElementNameByPath, postAuthMenuRoutes, postAuthRoutes, preAuthRoutes } from '../../../routes';
import { AccountMenu, Notifications, ThemeMode } from '.'
import { useContext } from 'react';
import { AppNameContext } from '../../../_contexts';
import { RouteLink, SweetAlert } from '../../../_components';
import { Link } from 'react-router-dom';
import { WalletMenu } from './wallet-menu/WalletMenu';
import { Fragment } from 'react';
import { SelectCurrency } from './select-currency';

const StyledAppBar = styled(({ ...props }) => (<AppBar enableColorOnDark elevation={0} {...props} />))`
        zIndex: 1200;
`;

function Header() {

    const dispatch = useDispatch();
    const location = useLocation();
    const appName = useContext(AppNameContext);
    const navigate = useNavigate();

    const { check_brand_access_token_loading: isAccessTokenLoading, check_brand_access } = useSelector((state) => state.UserReducer);

    const setDrawer = (params) => dispatch(GlobalAction.setDrawer(params));
    const { isDrawerOpen: open } = useSelector((state) => state.GlobalReducer);

    const [pageTitle, setPageTitle] = useState('Loading')
    const [isCheckingAccessToken, setIsCheckingAccessToken] = useState(false)

    const toggleDrawer = () => {
        setDrawer(!open);
    };

    useEffect(() => {
        setPageTitle(getPostElementNameByPath(location.pathname));
    }, [location])


    useEffect(() => {
        dispatch(UserAction.getUserDetails());
        dispatch(UserAction.getUserPermission());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setIsCheckingAccessToken(true)
        dispatch(UserAction.checkBrandAccessToken());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isCheckingAccessToken && (!isAccessTokenLoading)) {
            if (check_brand_access === 'invalid') {
                setIsCheckingAccessToken(false);
                SweetAlert.fire({
                    title: 'Invalid access token!',
                    text: "Your facebook access token is invalid. Please login again using continue with facebook",
                    icon: 'warning',
                    confirmButtonText: 'Ok!',
                    reverseButtons: true,
                    showCancelButton: false,
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        dispatch(SignInAction.signOut()).then(() => {
                            dispatch({ type: 'RESET_USER' })
                            navigate(preAuthRoutes('login').path);
                        });
                    }
                })
            } else {
                setIsCheckingAccessToken(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAccessTokenLoading, check_brand_access])



    /** Redux actions and state */
    const [anchorElX, setAnchorElX] = useState(null);

    const openMenuX = Boolean(anchorElX);

    const handleClickX = (event) => {
        setAnchorElX(event.currentTarget);
    };

    const handleCloseX = () => {
        setAnchorElX(null);
    };


    const menuRoutes = Object.values(postAuthMenuRoutes());

    return (
        <Fragment>
            <Helmet>
                <title>{`${appName} | ${pageTitle}`}</title>
            </Helmet>
            <StyledAppBar position="sticky" >
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer} sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <MenuIcon />
                    </IconButton>
                    <IconButton onClick={handleClickX} sx={{ display: { xs: 'block', md: 'none' } }}>
                        <MenuIcon />
                    </IconButton>
                    <Menu anchorEl={anchorElX} open={openMenuX} onClose={handleCloseX} onClick={handleCloseX} PaperProps={{ elevation: 0 }} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} sx={{ display: { xs: 'block', md: 'none' } }} >
                        {
                            menuRoutes.map((route, index) => {

                                var splitPathName = location.pathname.split('/');
                                var parentPathName = splitPathName.slice(0, 2).join('/');

                                return (
                                    <Box key={index}>
                                        {route.hasSeparation && <Divider sx={{ margin: `5px 0` }} />}
                                        <MenuItem button component={Link} to={route.path} className={(route.path === location.pathname) ? `active` : ((parentPathName === route.path) ? 'active' : '')}>
                                            <ListItemIcon>
                                                <route.menuIcon />
                                            </ListItemIcon>{route.name}
                                        </MenuItem>
                                    </Box>
                                )
                            })
                        }

                    </Menu>

                    <ShortLogo style={{ marginLeft: 35 }} height="30" />
                    <Box sx={{ flexGrow: 1 }} />
                    <RouteLink to={postAuthRoutes('search').path} label={<IconButton ><Search /></IconButton>} />
                    <RouteLink to={postAuthRoutes('tickets').path} label={<IconButton ><SupportAgent /></IconButton>} />
                    <SelectCurrency />
                    <ThemeMode />
                    <Notifications />
                    <WalletMenu />
                    <AccountMenu />
                </Toolbar>
            </StyledAppBar>
        </Fragment>
    )
}



export { Header };
