import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chip, Grid, Stack } from '@mui/material';
import { RestaurantMenuAction } from '../../../../../redux/actions';
import { FormSelect } from '../../../../../_components/form';
import { handleInputChange } from '../../../../../_helpers';
import { Fragment } from 'react';
import { Dialog, DialogActionCloseBtn, DialogActionSaveBtn, DialogActions, DialogContent, DialogTitle } from '../../../../../_components';

// Initialize form input values to null
const inputs = { category: '' };

function RestaurantMenuFilter({ ...props }) {

	/** Initialize plugins and variables */
	const dispatch = useDispatch();

	/** Redux actions and state */
	const { restaurant_menu_category_select_list } = useSelector((state) => state.RestaurantMenuReducer);
	const menuCategorySelectList = (params) => dispatch(RestaurantMenuAction.menuCategorySelectList(params));

	/** Initialize and declare state */
	const { show, closeModal, applyFilter, restaurantMenuFilter } = props;
	const [data, setData] = useState({ ...inputs });
	const [filteredData, setFilteredData] = useState({});

	useEffect(() => {
		menuCategorySelectList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setData({ category: restaurantMenuFilter.category });

		let filtered = {};
		Object.keys(restaurantMenuFilter).forEach(function (item, i) {
			if (restaurantMenuFilter[item] && !restaurantMenuFilter['requested_by']) filtered[item] = restaurantMenuFilter[item];
		});
		setFilteredData({ ...filtered });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [restaurantMenuFilter]);

	/**
	 * function to close restaurant menu filter modal
	 * @param {string} category Entered category on restaurant menu filter modal
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	const onClose = () => {
		setData({ category: '' });
		closeModal(false);
	};

	/**
	 * function to clear restaurant menu filter modal
	 * @param {string} category Entered category on restaurant menu filter modal
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	const onClear = () => {
		setData({ category: '' });
		applyFilter({ category: '' });
		closeModal(false);
	};

	/**
	 * function to handle input changes and modify the value
	 * @param {string} e.name input name
	 * @param {string} e.value input value
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	function handleChange(e) {
		const { name, value } = handleInputChange(e);
		data[name] = value;
		setData({ ...data });
	}

	/**
	 * function to handle submit entered values
	 * @param {object} e form object
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	function handleSubmit(e) {
		e.preventDefault();
		applyFilter(data);
		closeModal(false);
	}

	/**
	 * function to clear all data entered on restaurant menu filter modal
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	const clearFilterItem = (item) => {
		const clearItem = { ...data };
		clearItem[item] = '';
		setData({ ...clearItem });
		applyFilter({ ...clearItem });
	};

	return (
		<Fragment>
			{Object.keys(filteredData).length > 0 && (
				<Grid container mb={2}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Stack direction="row" spacing={1}>
							{Object.keys(filteredData).map((item, i) => {
								if (item === 'category') {
									const category = restaurant_menu_category_select_list.find(function (category) {
										return category.value === filteredData[item];
									});
									return <Chip key={i} label={category.label} onDelete={() => clearFilterItem(item)} />;
								} else {
									return <Chip key={i} label={filteredData[item]} onDelete={() => clearFilterItem(item)} style={{ textTransform: `capitalize` }} />;
								}
							})}
						</Stack>
					</Grid>
				</Grid>
			)}
			<Dialog isDialogOpen={show} onClose={onClose} maxWidth="xs">
				<form onSubmit={handleSubmit} noValidate>
					<DialogTitle id="restaurantMenuFilter" onClose={onClose} title="Filter" />
					<DialogContent >
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<FormSelect tabIndex={1} label={`Category`} name={`category`} placeholder={`Select category`} onChange={handleChange} data={restaurant_menu_category_select_list} value={data.category} />
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions >
						<DialogActionSaveBtn tabIndex={4} text="Filter" />
						<DialogActionCloseBtn tabIndex={3} onClose={onClear} text="Clear" />
					</DialogActions>
				</form>
			</Dialog>
		</Fragment>
	);
}

export { RestaurantMenuFilter };
