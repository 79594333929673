import React from "react";
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    page: {
        flexDirection: "row",
        backgroundColor: "#E4E4E4",
        padding: 10,
    },
    tableContainer: {
        width: "100%",
        marginTop: 10,
    },
    table: {
        width: "100%",
        borderCollapse: "collapse",
    },
    tableHeader: {
        padding: 5,
    },
    headerText: {
        color: "black",
        fontSize: 12,
        fontWeight: 'bold',
    },
    tableRow: {
        borderBottomWidth: 1,
        borderColor: "#000",
        flexDirection: "row",
    },
    rowText: {
        color: "black",
        fontSize: 11,
    },
    tableCell: {
        padding: 8,
        flexGrow: 1,
        color: "#000",
        textAlign: "left",

    },
});

const ItemPdf = ({ todays_item_quantity }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.tableContainer}>
        <table style={styles.table}>
            <thead>
                <tr style={styles.tableRow}>
                    <th style={{ ...styles.tableCell, ...styles.tableHeader }}><Text style={styles.headerText}>#</Text></th>
                    <th style={{ ...styles.tableCell, ...styles.tableHeader }}><Text style={styles.headerText}>Item</Text></th>
                    <th style={{ ...styles.tableCell, ...styles.tableHeader }}><Text style={styles.headerText}>Item Quantity</Text></th>
                    <th style={{ ...styles.tableCell, ...styles.tableHeader }}><Text style={styles.headerText}>Status</Text></th>
                </tr>
            </thead>
            <tbody>
                {todays_item_quantity.map((item, index) => (
                    <tr key={index} style={styles.tableRow}>
                        <td style={styles.tableCell}><Text style={styles.rowText}>{index+1}</Text></td>
                        <td style={styles.tableCell}><Text style={styles.rowText}>{item.title}</Text></td>
                        <td style={styles.tableCell} align="center">
                            <Text style={styles.rowText}>{item.item_quantity}</Text>
                        </td>
                        <td style={styles.tableCell} align="center">
                        <Text style={styles.rowText}>{item.item_status_text}</Text>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
      </View>
    </Page>
  </Document>
);

export { ItemPdf };
