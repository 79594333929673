import React, { useEffect, useRef, useState } from "react";
import { Document, PDFDownloadLink, Page, StyleSheet, Text, View, Image, Svg } from "@react-pdf/renderer";
import QRCode from "react-qr-code";
import { GeneralButton } from "../../../../../../_components";
import { Download } from "@mui/icons-material";
import { Fragment } from "react";
import { INFLUENCER_APP_URL } from "../../../../../../_helpers";


const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    imageContainer: {
        marginLeft: 10,
        marginTop: 10,
        marginBottom: 10,
        width: '40%'
    },
    image: {
        objectFit: 'cover',
        width: '100%',
    },
    contentContainer: {
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
        marginLeft: 10,
        width: '60%',
        fontSize: 12
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 10
    },
    subtitle: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 5
    },
    description: {
        marginBottom: 10
    },
    qrCodeContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 10
    },
    qrCode: {
        width: 100,
        height: 100
    }
});
function PromoPdf({ ...props }) {

    const componentRef = useRef();

    const { promo_details } = props;
    const [qrCodeSrc, setQrCodeSrc] = useState('');
    const [qrCodeSvg, setQrCodeSvg] = useState();


    useEffect(() => {
        if (promo_details && promo_details._id) {
            const svg = componentRef.current.children[0];
            const svgData = new XMLSerializer().serializeToString(svg);
            setQrCodeSvg(svgData);
            setQrCodeSrc(`data:image/svg+xml;base64,${btoa(svgData)}`);
        }
    }, [promo_details])

    const MyDoc = () => (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.imageContainer}>
                    {promo_details && promo_details.fileBase64 && <Image style={styles.image} src={`data:image/${(promo_details.file).split('.').pop().toLowerCase()};base64, ${promo_details.fileBase64}`} />}
                </View>
                <View style={styles.contentContainer}>
                    <Text style={styles.title}>{promo_details.name}</Text>
                    <Text style={styles.subtitle}>{`@${promo_details.insta_handle}`}</Text>
                    <Text style={styles.description}>{promo_details.description}</Text>
                    <Text style={styles.description}>How to redeem your offer</Text>
                    <Text style={styles.description}>{`1. Post a story and mention @${promo_details.insta_handle} on it.`}</Text>

                    {qrCodeSrc && <View>
                        <Svg xml={qrCodeSvg} />
                    </View>}
                </View>
            </Page>
        </Document>
    );

    return (
        <Fragment>
            {(promo_details && promo_details._id) && <div>
                <PDFDownloadLink document={<MyDoc />} fileName="qrcode.pdf">
                    {({ blob, url, loading, error }) =>
                        loading ? <GeneralButton disabled={true} startIcon={<Download />} label='Download QR Code' /> : <GeneralButton startIcon={<Download />} label='Download QR Code' />
                    }
                </PDFDownloadLink>
                <div ref={componentRef} style={{ display: `none` }}>
                    <QRCode value={`${INFLUENCER_APP_URL}`} size={100} />
                </div>
            </div>}
        </Fragment>
    );
}

export { PromoPdf }