import {
    INVOICE_REQUEST, INVOICE_SUCCESS, INVOICE_FAILURE,
    INVOICE_DETAILS_REQUEST, INVOICE_DETAILS_SUCCESS, INVOICE_DETAILS_FAILURE
} from "../actions";

const initialState = {
    page: 1, per_page: 18, total: 0, total_pages: 0,
    invoices_loading: false, invoices: [], invoices_errors: {},
    invoice_details_loading: false, invoice_details: {}, invoice_details_errors: {},

};

export function InvoiceReducer(state = initialState, action) {
    switch (action.type) {
        /** begin::Plans list redux */
        case INVOICE_REQUEST: {
            return {
                ...state,
                invoices_loading: true,
                invoices_errors: {}
            };
        }
        case INVOICE_SUCCESS: {

            let newArray = [...state.invoices]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.invoices.forEach((payload) => {
                const planIndex = newArray.findIndex(invoice => invoice._id === payload._id);
                if (planIndex !== -1) {
                    newArray[planIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                invoices_loading: false,
                invoices: newArray,
                invoices_errors: {},
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };

        }
        case INVOICE_FAILURE: {
            return {
                ...state,
                invoices_loading: false,
                invoices_errors: action.errors,
                invoices: [],
            };
        }
        /** end::Plans list redux */

        /** begin::invoice details redux */
        case INVOICE_DETAILS_REQUEST: {
            return {
                ...state,
                invoice_details_loading: true,
                invoice_details_errors: {}
            };
        }
        case INVOICE_DETAILS_SUCCESS: {
            return {
                ...state,
                invoice_details_loading: false,
                invoice_details: action.data,
                invoice_details_errors: {}
            };
        }
        case INVOICE_DETAILS_FAILURE: {
            return {
                ...state,
                invoice_details_loading: false,
                invoice_details_errors: action.errors
            };
        }
        /** end::invoice details redux */
        default:
            return state;
    }
}
