module.exports = {
    /**
     * function to validate the registration form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author 
     * @created_at 30 May 2022
     */
    validate: (name, value, data = {}) => {
        const errors = {};
        switch (name) {
            case 'instagram_business_account': {
                errors.instagram_business_account = '';
                if (value === '' || value === false)
                    errors.instagram_business_account = 'Please check the box to confirm that you have an Instagram Business Account.';
                break;
            } case 'facebook_page': {
                errors.facebook_page = '';
                if (value === '' || value === false)
                    errors.facebook_page = 'Please check the box to confirm that you have connected your Instagram account to a Facebook Page.';
                break;
            } case 'messages': {
                errors.messages = '';
                if (value === '' || value === false)
                    errors.messages = 'Please check the box to confirm that you have enabled access to messages under connected tools.';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}