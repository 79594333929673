module.exports = {
    /**
     * function to validate the create coupon code form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author Akshay N
     * @created_at 30 May 2022
     */
    validate: (name, value, data = {}) => {
        const errors = {};
        switch (name) {
            case 'coupon_code': {
                errors.coupon_code = '';
                if (value === '')
                    errors.coupon_code = 'Enter coupon code.';
                break;
            }
            case 'description': {
                errors.description = '';
                if (value === '')
                    errors.description = 'Enter description.';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}