import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormSwitch } from "../../../../../_components/form";
import { Dialog, DialogContent, DialogActions, DialogActionCloseBtn, DialogActionSaveBtn, DialogTitle } from "../../../../../_components/dialog";
import { handleInputChange } from "../../../../../_helpers";
import { Fragment } from "react";

// Declare Restaurant configure data select options
const restaurantCategoryInputs = [
    { value: 'normal_configuration', label: 'Normal Order' },
    { value: 'pre_booking_configuration', label: 'Pre Booking Order' },
    { value: 'vehicle_configuration', label: 'Vehicle Order' },
    { value: 'delivery_configuration', label: 'Delivery Order' },
]

function RestaurantConfigureModal({ ...props }) {

    /** Initialize props values */
    const { show, closeModal, applyRestaurantConfigure, restaurantConfigure } = props;

    /** Initialize and declare state */
    const [data, setData] = useState({});

    useEffect(() => {
        if (restaurantConfigure !== undefined) {
            const categoryData = {};
            restaurantCategoryInputs.forEach(input => {
                categoryData[input.value] = restaurantConfigure.includes(input.value);
            });
            setData({ ...categoryData });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restaurantConfigure, show])

    /**
     * function to close restaurant Category modal
     * @param {string} configure_data Entered configure_data on restaurant Category modal
     * @return view
     * @author Naveen K
     * @created_at 09 Jul 2024
     */
    const onClose = () => {
        setData({ configure_data: [] });
        closeModal(false);
    }

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Naveen K
     * @created_at 09 Jul 2024
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author Naveen K
     * @created_at 09 Jul 2024
     */
    function handleSubmit(e) {
        e.preventDefault();
        applyRestaurantConfigure(data);
        closeModal(false);

    }

    return (
        <Fragment>
            <Dialog isDialogOpen={show} onClose={onClose} maxWidth='xs'>
                <form onSubmit={handleSubmit} noValidate >
                    <DialogTitle id="restaurantConfigure" onClose={onClose} title='Restaurant Configuration' />
                    <DialogContent >
                        <Grid container spacing={2}>
                            {restaurantCategoryInputs.map((restaurant_category, i) => {
                                return (
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormSwitch name={restaurant_category.value} value={data[restaurant_category.value]} label={`${restaurant_category.label}`} onChange={handleChange}/>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </DialogContent >
                    <DialogActions >
                        <DialogActionSaveBtn tabIndex={4} text='Update' />
                        <DialogActionCloseBtn tabIndex={3} onClose={onClose} text='Close' />
                    </DialogActions>
                </form>
            </Dialog>
        </Fragment>
    );
}

export { RestaurantConfigureModal }