import {
    SEARCH_REQUEST, SEARCH_SUCCESS, SEARCH_FAILURE,
    REDEEM_COUPON_REQUEST, REDEEM_COUPON_SUCCESS, REDEEM_COUPON_FAILURE,    
    GIVEAWAY_REDEEM_COUPON_REQUEST, GIVEAWAY_REDEEM_COUPON_SUCCESS, GIVEAWAY_REDEEM_COUPON_FAILURE,    
} from "../actions";

const initialState = {
    search_result_loading: false, search_result: [], search_result_errors: {},
    redeem_coupon_loading: false, redeem_coupon: [], redeem_coupon_errors: {},
};

export function SearchReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::List brand promos coupon codes redux */
        case SEARCH_REQUEST: {
            return {
                ...state,
                search_result_loading: true,
                search_result_errors: {}
            };
        }
        case SEARCH_SUCCESS: {

            let newArray = [...state.search_result]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }

            action.data.coupon_codes.forEach((payload) => {
                const couponCodeIndex = newArray.findIndex(coupon_code_data => (coupon_code_data.coupon_code === payload.coupon_code && coupon_code_data._id === payload._id));
                if (couponCodeIndex !== -1) {
                    newArray[couponCodeIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                search_result_loading: false,
                search_result: newArray,
                search_result_errors: {}
            };

        }
        case SEARCH_FAILURE: {
            return {
                ...state,
                search_result: [],
                search_result_loading: false,
                search_result_errors: action.errors
            };
        }
        /** end::List brand promos coupon codes redux */

        /** begin::Redeem coupon redux */
        case REDEEM_COUPON_REQUEST: {
            return {
                ...state,
                redeem_coupon_loading: true,
                redeem_coupon_errors: {}
            };
        }
        case REDEEM_COUPON_SUCCESS: {

            let newArray = [...state.search_result]; //making a new array

            if (newArray.length > 0) {
                const couponIndex = newArray.findIndex(coupons =>(coupons.coupon_code === action.data.coupon_code && coupons._id === action.data._id && coupons.category === 'promo'));
                if (couponIndex !== -1) {
                    newArray[couponIndex].status = action.data.status;
                    newArray[couponIndex].redeemed_at = action.data.redeemed_at;
                }
            }

            return {
                ...state,
                redeem_coupon_loading: false,
                search_result: newArray,
                redeem_coupon_errors: {}
            };

        }
        case REDEEM_COUPON_FAILURE: {
            return {
                ...state,
                redeem_coupon: [],
                redeem_coupon_loading: false,
                redeem_coupon_errors: action.errors
            };
        }
        /** end::List brand promos coupon codes redux */

        /** begin::Redeem coupon redux */
        case GIVEAWAY_REDEEM_COUPON_REQUEST: {
            return {
                ...state,
                redeem_coupon_loading: true,
                redeem_coupon_errors: {}
            };
        }
        case GIVEAWAY_REDEEM_COUPON_SUCCESS: {

            let newArray = [...state.search_result]; //making a new array

            if (newArray.length > 0) {
                const couponIndex = newArray.findIndex(coupons =>(coupons.coupon_code === action.data.coupon_code && coupons._id === action.data._id && coupons.category === 'giveaway'));
                if (couponIndex !== -1) {
                    newArray[couponIndex].status = action.data.status;
                    newArray[couponIndex].redeemed_at = action.data.redeemed_at;
                }
            }

            return {
                ...state,
                redeem_coupon_loading: false,
                search_result: newArray,
                redeem_coupon_errors: {}
            };

        }
        case GIVEAWAY_REDEEM_COUPON_FAILURE: {
            return {
                ...state,
                redeem_coupon: [],
                redeem_coupon_loading: false,
                redeem_coupon_errors: action.errors
            };
        }
        /** end::List brand promos coupon codes redux */

        default:
            return state;
    }
}
