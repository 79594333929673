import { Box, styled } from '@mui/material';
import React from 'react';

const FormBrowseFileStyled = styled(({ ...props }) => <Box {...props} />)`
	& .section {
		border: 1px solid rgb(206, 212, 218);
		cursor: pointer;
		border-radius: 5px;
		padding: 10px;
		min-height: 150px;
		max-height: 150px;
		align-items: center;
		vertical-align: middle;
		display: flex;
		text-align: center;
		width: 100%;
		& .thumb {
			display: inline-flex;
			border-radius: 2;
			padding: 4;
			position: relative;
			box-sizing: border-box;
			& :hover {
				a {
					color: #000;
				}
			}

			& .thumb-inner {
				display: flex;
				min-width: 0;
				overflow: hidden;

				& img {
					display: block;
					width: 100%;
					max-height: 100px;
					max-width: 100px;
				}
			}

			& .close-outer {
				position: absolute;
				right: 0;
				text-align: right;

				& .close-icon {
					color: #000;
					background: #fff;
					padding: 1px;
					margin: 6px;
					box-shadow: 0 4px 8px rgb(0 0 0 / 8%);
					border: 1px solid #ebeefd;
					cursor: pointer;
				}
			}
		}

		& .thumb-container {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}
	}
`;

export { FormBrowseFileStyled };
