export const INITIAL_LOADER = 'INITIAL_LOADER';
export const PAGE_LOADER = 'PAGE_LOADER';
export const SET_DRAWER = 'SET_DRAWER';
export const SET_TOAST = 'SET_TOAST';
export const SET_THEME = 'SET_THEME';
export const SET_THEME_LAYOUT = 'SET_THEME_LAYOUT';

export const GlobalAction = {

    /**
     * `initialLoader` is a function that returns an object with a `type` property set to the value of  `INITIAL_LOADER`
     *  constant and a `loader` property set to the value of the `loader` parameter passed to the function.
     *  This function is an action creator that creates an action object to be dispatched to the Redux store.
     *  The purpose of this action is to show an initial loader on the  page.
     * @param {Object} loader
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    initialLoader: (loader) => {
        return { type: INITIAL_LOADER, loader: loader };
    },

    /**
     * `pageLoader` is a function that returns an object with a `type` property set to the value of
     * `PAGE_LOADER` constant and a `loader` property set to the value of the `loader` parameter passed
     * to the function. This function is an action creator that creates an action object to be
     * dispatched to the Redux store. The purpose of this action is to show a page loader on the page.
     * @param {Object} loader
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    pageLoader: (loader) => {
        return { type: PAGE_LOADER, loader: loader };
    },

    /**
     * This is an action creator function that sets the value of the `isOpen` property in the Redux
     * store to the value of the `payload` parameter passed to the function. It also sets the value of
     * `is_drawer_open` key in the local storage to the value of `payload`. This action is used to
     * control the state of a drawer component in the application.
     * @param {Object} payload
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    setDrawer: (payload) => {
        localStorage.setItem('is_drawer_open', payload);
        return { type: SET_DRAWER, isOpen: payload };
    },

    /**
     * `showToastMessage` is an action creator function that creates an action object with a `type`
     * property set to the value of `SET_TOAST` constant and a `message` property set to the value of
     * the `message` parameter passed to the function. This action is used to show a notification
     * message in the application. When this action is dispatched to the Redux store, the state of the
     * application is updated with the new `message` value, which can then be used to display the
     * notification message to the user.
     * @param {String} message
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    showToastMessage: (message) => {
        return { type: SET_TOAST, message: message };
    },

    /**
     * `setTheme` is an action creator function that creates an action object with a `type` property
     * set to the value of `SET_THEME` constant and a `mode` property set to the value of the `mode`
     * parameter passed to the function. This action is used to set the theme mode in the application
     * state. When this action is dispatched to the Redux store, the state of the application is
     * updated with the new `mode` value, which can then be used to apply the appropriate theme to the
     * application.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    setTheme: (mode) => {
        return { type: SET_THEME, mode: mode };
    },

    /**
     * `setthemeLayout` is an action creator function that creates an action object with a
     * `type` property set to the value of `SET_THEME_LAYOUT` constant and a `mode` property
     * set to the value of the `mode` parameter passed to the function. This action is used to set the
     * theme mode for the navigation menu in the application state. When this action is dispatched to
     * the Redux store, the state of the application is updated with the new `mode` value, which can
     * then be used to apply the appropriate theme to the navigation menu.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    setThemeLayout: () => {
        return { type: SET_THEME_LAYOUT };
    }

};
