import { SignInServices } from "../services";
import { GlobalAction } from "./GlobalAction";

export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';

export const INSTAGRAM_SIGN_IN_REQUEST = 'INSTAGRAM_SIGN_IN_REQUEST';
export const INSTAGRAM_SIGN_IN_SUCCESS = 'INSTAGRAM_SIGN_IN_SUCCESS';
export const INSTAGRAM_SIGN_IN_FAILURE = 'INSTAGRAM_SIGN_IN_FAILURE';

export const SignInAction = {

    /**
     * Action for sign in
     * @param {Object} params - The params which are used for registration api.
     * @param {string} params.email - Email of the brand user
     * @param {string} params.password - Password of the brand user
     * @author Akshay N
     * @created_at 30 May 2022
     */
    signIn: (params) => {
        return dispatch => {
            dispatch(request(params));
            return SignInServices.signIn(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: SIGN_IN_REQUEST, request: request }; }
        function success(request, response) { return { type: SIGN_IN_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code }; }
        function failure(request, error) { return { type: SIGN_IN_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code }; };
    },

    /**
     * Action for sign out
     * @author Akshay N
     * @created_at 30 May 2022
     */
    signOut: () => {
        return dispatch => {
            return SignInServices.signOut()
                .then(
                    response => { return response; },
                    error => { return error; }
                );
        };

    },

    /**
     * Action for insta sign in
     * @param {Object} params - The params which are used for registration api.
     * @author Akshay N
     * @created_at 28 October 2022
     */
    instagramSignIn: (params) => {
        return dispatch => {
            dispatch(request(params));
            return SignInServices.instagramSignIn(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: INSTAGRAM_SIGN_IN_REQUEST, request: request }; }
        function success(request, response) { return { type: INSTAGRAM_SIGN_IN_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code }; }
        function failure(request, error) { return { type: INSTAGRAM_SIGN_IN_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code }; };
    },

    /**
     * Action for insta sign in
     * @param {Object} params - The params which are used for registration api.
     * @author Akshay N
     * @created_at 28 October 2022
     */
    getUserStatus: (params) => {
        return SignInServices.getUserStatus(params)
            .then(
                response => { return response; },
                error => { return error; }
            );

    },
};
