
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { Grid, Box } from '@mui/material';
import { Card } from '../../_components/card';

import image from "../../_theme/images/bg-sign-in-basic.jpeg";

import { postAuthRoutes } from '../../routes';
import { cookieServices } from '../../_helpers';

import { CopyRight, PreSignInStyled } from '.'

const PreSignIn = ({ children, ...props }) => {

    /** Initialize plugins and variables */
    let navigate = useNavigate();
    // const classes = useStyles();

    useEffect(() => {
        // if (cookieServices.get('accessToken')) {
        //     navigate(postAuthRoutes('home').path);
        // }
    }, [navigate])

    return (
        <PreSignInStyled sx={{ backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) => image && `${linearGradient(rgba(gradients.dark.main, 0.6), rgba(gradients.dark.state, 0.6))}, url(${image})`, paddingBottom: '30px' }} >
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" className='pre-signin-wrapper' >
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                    <Card>
                        <Box display="flex" alignItems="center">
                            <Box className='pre-signin-wrapper-item-inner' {...props}>
                                {children}
                            </Box>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
            <CopyRight />
        </PreSignInStyled>
    )
}

export { PreSignIn }
