import { Chip, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormSelect } from '../../../../../../_components/form';
import { Fragment } from 'react';
import { Dialog, DialogActionCloseBtn, DialogActionSaveBtn, DialogActions, DialogContent, DialogTitle, FormDateRangePicker } from '../../../../../../_components';
import { handleInputChange } from '../../../../../../_helpers';

// Initialize form input values to null
const inputs = { filter_status: '', order_created_at: '' };

// Declare filter status select options
const filterStatus = [
	{ value: 'active', label: 'Active' },
	{ value: 'inactive', label: 'Inactive' },
	{ value: 'order_placed', label: 'Order Placed' },
	{ value: 'delivered', label: 'Delivered' },
	{ value: 'paid', label: 'Paid' },
	{ value: 'order_ready', label: 'Order Ready' },
	{ value: 'in_progress', label: 'In Progress' },
];

function OrderHistoryFilter({ ...props }) {


    /** Initialize and declare state */
    const { show, closeModal, applyFilter, orderHistoryFilter } = props;
    const [data, setData] = useState({ ...inputs });
    const [filteredData, setFilteredData] = useState({});

    /**
     * function to close order history filter modal
     * @return view
     * @author Naveen
     * @created_at 11 Dec 2023
     */
    const onClose = () => {
        setData({ filter_status: '', order_created_at: '' });
        closeModal(false);
    }

    /**
     * function to clear order history filter modal
     * @return view
     * @author Naveen
     * @created_at 11 Dec 2023
     */
    const onClear = () => {
        setData({ filter_status: '', order_created_at: '' });
        applyFilter({ filter_status: '', order_created_at: '' });
        closeModal(false);
    }

    useEffect(() => {
        setData({ filter_status: orderHistoryFilter.filter_status, order_created_at: orderHistoryFilter.order_created_at })

        let filtered = {};
        Object.keys(orderHistoryFilter).forEach(function (item, i) {
            if (orderHistoryFilter[item]) {
                filtered[item] = orderHistoryFilter[item];
                return filtered[item];
            }
        });
        setFilteredData({ ...filtered });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderHistoryFilter])


    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Naveen
     * @created_at 11 Dec 2023
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author Naveen
     * @created_at 11 Dec 2023
     */
    function handleSubmit(e) {
        e.preventDefault();
        applyFilter(data);
        closeModal(false);
    }

    /**
     * function to clear all data entered on permission filter modal
     * @return view
     * @author Naveen
     * @created_at 11 Dec 2023
     */
    const clearFilterItem = (item) => {
        const clearItem = { ...data };
        clearItem[item] = '';
        setData({ ...clearItem });
        applyFilter({ ...clearItem });
    }

    return (
		<Fragment>
			{Object.keys(filteredData).length > 0 && (
				<Grid container mb={2}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Stack direction="row" spacing={1}>
							{Object.keys(filteredData).map((item, i) => {
								if (item === 'status') {
									const status = filterStatus.find(function (status) {
										return status.value === filteredData[item];
									});
									return <Chip key={i} label={status.label} onDelete={() => clearFilterItem(item)} />;
								} else {
									return <Chip key={i} label={filteredData[item]} onDelete={() => clearFilterItem(item)} style={{ textTransform: `capitalize` }} />;
								}
							})}
						</Stack>
					</Grid>
				</Grid>
			)}
			<Dialog isDialogOpen={show} onClose={onClose} maxWidth="xs">
				<form onSubmit={handleSubmit} noValidate>
					<DialogTitle id="orderHistoryFilter" onClose={onClose} title="Filter" />
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<FormSelect tabIndex={1} label={`Status`} name={`status`} placeholder={`Select status`} onChange={handleChange} data={filterStatus} value={data.filter_status} />
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<FormDateRangePicker tabIndex={2} label="Date" name="order_created_at" value={data.order_created_at} placeholder="dd/mm/yyyy - dd/mm/yyyy" onChange={handleChange} />
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<DialogActionSaveBtn tabIndex={4} text="Filter" />
						<DialogActionCloseBtn tabIndex={3} onClose={onClear} text="Clear" />
					</DialogActions>
				</form>
			</Dialog>
		</Fragment>
	);
}

export { OrderHistoryFilter }