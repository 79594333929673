import { GlobalAction } from ".";
import { ConnectAccountServices } from "../services";

export const ADD_NEW_FB_USER_TOKEN_REQUEST = 'ADD_NEW_FB_USER_TOKEN_REQUEST';
export const ADD_NEW_FB_USER_TOKEN_SUCCESS = 'ADD_NEW_FB_USER_TOKEN_SUCCESS';
export const ADD_NEW_FB_USER_TOKEN_FAILURE = 'ADD_NEW_FB_USER_TOKEN_FAILURE';

export const GET_CONNECTED_IG_ACCOUNT_REQUEST = 'GET_CONNECTED_IG_ACCOUNT_REQUEST';
export const GET_CONNECTED_IG_ACCOUNT_SUCCESS = 'GET_CONNECTED_IG_ACCOUNT_SUCCESS';
export const GET_CONNECTED_IG_ACCOUNT_FAILURE = 'GET_CONNECTED_IG_ACCOUNT_FAILURE';

export const ConnectAccountAction = {

    /**
     * Action to add new user token reply
     * @param {Object} params - The params which are used for the api
     * @author 
     * @created_at 12 July 2022
    */
    addNewUserToken: (params) => {
        return dispatch => {
            dispatch(request(params));
            return ConnectAccountServices.addNewUserToken(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ADD_NEW_FB_USER_TOKEN_REQUEST, request: request } }
        function success(request, response) { return { type: ADD_NEW_FB_USER_TOKEN_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ADD_NEW_FB_USER_TOKEN_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action to get connected account
     * @param {Object} params - The params which are used for the api
     * @author 
     * @created_at 12 July 2022
    */
    getConnectedAccount: (params) => {
        return dispatch => {
            dispatch(request(params));
            return ConnectAccountServices.getConnectedAccount(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_CONNECTED_IG_ACCOUNT_REQUEST, request: request } }
        function success(request, response) { return { type: GET_CONNECTED_IG_ACCOUNT_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_CONNECTED_IG_ACCOUNT_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

}