import {
    CREATE_WALLET_REQUEST, CREATE_WALLET_SUCCESS, CREATE_WALLET_FAILURE,
    CREATE_WALLET_CHECKOUT_SESSION_REQUEST, CREATE_WALLET_CHECKOUT_SESSION_SUCCESS, CREATE_WALLET_CHECKOUT_SESSION_FAILURE,
    WALLET_HISTORY_REQUEST, WALLET_HISTORY_SUCCESS, WALLET_HISTORY_FAILURE,
    WALLET_CHART_REQUEST, WALLET_CHART_SUCCESS, WALLET_CHART_FAILURE,
    WALLET_BALANCE_REQUEST, WALLET_BALANCE_SUCCESS, WALLET_BALANCE_FAILURE,
    WALLET_TOTAL_CREDIT_REQUEST, WALLET_TOTAL_CREDIT_SUCCESS, WALLET_TOTAL_CREDIT_FAILURE, 
    WALLET_MONTHLY_CREDIT_REQUEST, WALLET_MONTHLY_CREDIT_SUCCESS, WALLET_MONTHLY_CREDIT_FAILURE,
    WALLET_STATEMENTS_REQUEST, WALLET_STATEMENTS_SUCCESS, WALLET_STATEMENTS_FAILURE,
} from "../actions";

const initialState = {
    page: 1, per_page: 10, total: 0, total_pages: 0,
    create_brand_wallet_loading: false, create_brand_wallet_errors: {},
    create_wallet_checkout_session_loading: false, create_wallet_checkout_session_error: {},
    wallet_history_loading: false, wallet_history: [], wallet_history_errors: {},
    wallet_chart_loading: false, wallet_chart: [], wallet_chart_errors: {},
    wallet_balance_loading: false, wallet_balance: 0, wallet_balance_errors: {},
    wallet_total_credit_loading: false, wallet_total_credit: 0, wallet_total_credit_errors: {},
    wallet_monthly_credit_loading: false, wallet_monthly_credit: 0, wallet_monthly_credit_errors: {},
    wallet_statements_loading: false, wallet_statements: [], wallet_statements_errors: {},
};

export function WalletReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Create wallet redux */
        case CREATE_WALLET_REQUEST: {
            return {
                ...state,
                create_brand_wallet_loading: true,
                create_brand_wallet_errors: {}
            };
        }
        case CREATE_WALLET_SUCCESS: {
            let newArray = [...state.wallet_history]; //making a new array
            let newBalance = state.wallet_balance; //making a new balance

            let result = action.data;
            if (result.wallet && Object.keys(result.wallet).length > 0) {
                const walletIndex = newArray.findIndex(wallet => wallet._id === result.wallet._id);

                if (walletIndex !== -1) {
                    newArray[walletIndex].status = result.wallet.status;
                } else {
                    newArray = newArray.concat(result.wallet);
                }
                newBalance = result.balance;
            }
            
            return {
                ...state,
                create_brand_wallet_loading: false,
                create_brand_wallet_errors: {},
                wallet_history: newArray,
                wallet_balance: newBalance
            };

        }
        case CREATE_WALLET_FAILURE: {
            return {
                ...state,
                create_brand_wallet_loading: false,
                create_brand_wallet_errors: action.errors,
            };
        }
        /** end::Create wallet redux */

        /** begin::Wallet checkout session redux */
        case CREATE_WALLET_CHECKOUT_SESSION_REQUEST: {
            return {
                ...state,
                create_wallet_checkout_session_loading: true,
            };
        } case CREATE_WALLET_CHECKOUT_SESSION_SUCCESS: {

            return {
                ...state,
                create_wallet_checkout_session_loading: false,
            };
        } case CREATE_WALLET_CHECKOUT_SESSION_FAILURE: {
            return {
                ...state,
                create_wallet_checkout_session_loading: false,
            };
        }
        /** end::Wallet checkout session redux */

        /** begin::Wallet history list redux */
        case WALLET_HISTORY_REQUEST: {
            return {
                ...state,
                wallet_history_loading: true,
                wallet_history_errors: {}
            };
        }
        case WALLET_HISTORY_SUCCESS: {

            return {
                ...state,
                wallet_history_loading: false,
                wallet_history: action.data.wallets,
                wallet_history_errors: {},
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };

        }
        case WALLET_HISTORY_FAILURE: {
            return {
                ...state,
                wallet_history_loading: false,
                wallet_history_errors: action.errors,
                wallet_history: [],
            };
        }
        /** end::Wallet history list redux */

        /** begin::Wallet chart list redux */
        case WALLET_CHART_REQUEST: {
            return {
                ...state,
                wallet_chart_loading: true,
                wallet_chart_errors: {}
            };
        }
        case WALLET_CHART_SUCCESS: {

            return {
                ...state,
                wallet_chart_loading: false,
                wallet_chart: action.data,
                wallet_chart_errors: {},
            };

        }
        case WALLET_CHART_FAILURE: {
            return {
                ...state,
                wallet_chart_loading: false,
                wallet_chart_errors: action.errors,
                wallet_chart: [],
            };
        }
        /** end::Wallet chart list redux */
        
        /** begin::Wallet balance list redux */
        case WALLET_BALANCE_REQUEST: {
            return {
                ...state,
                wallet_balance_loading: true,
                wallet_balance_errors: {}
            };
        }
        case WALLET_BALANCE_SUCCESS: {

            return {
                ...state,
                wallet_balance_loading: false,
                wallet_balance: action.data.balance,
                wallet_balance_errors: {},
            };

        }
        case WALLET_BALANCE_FAILURE: {
            return {
                ...state,
                wallet_balance_loading: false,
                wallet_balance_errors: action.errors,
                wallet_balance: [],
            };
        }
        /** end::Wallet balance list redux */

        /** begin::Wallet total credit redux */
        case WALLET_TOTAL_CREDIT_REQUEST: {
            return {
                ...state,
                wallet_total_credit_loading: true,
                wallet_total_credit_errors: {}
            };
        }
        case WALLET_TOTAL_CREDIT_SUCCESS: {

            return {
                ...state,
                wallet_total_credit_loading: false,
                wallet_total_credit: action.data.total_credit,
                wallet_total_credit_errors: {},
            };

        }
        case WALLET_TOTAL_CREDIT_FAILURE: {
            return {
                ...state,
                wallet_total_credit_loading: false,
                wallet_total_credit_errors: action.errors,
                wallet_total_credit: [],
            };
        }
        /** end::Wallet balance list redux */

        /** begin::Wallet total credit redux */
        case WALLET_MONTHLY_CREDIT_REQUEST: {
            return {
                ...state,
                wallet_monthly_credit_loading: true,
                wallet_monthly_credit_errors: {}
            };
        }
        case WALLET_MONTHLY_CREDIT_SUCCESS: {

            return {
                ...state,
                wallet_monthly_credit_loading: false,
                wallet_monthly_credit: action.data.total_credit,
                wallet_monthly_credit_errors: {},
            };

        }
        case WALLET_MONTHLY_CREDIT_FAILURE: {
            return {
                ...state,
                wallet_monthly_credit_loading: false,
                wallet_monthly_credit_errors: action.errors,
                wallet_monthly_credit: [],
            };
        }
        /** end::Wallet balance list redux */

        /** begin::Wallet statements list redux */
        case WALLET_STATEMENTS_REQUEST: {
            return {
                ...state,
                wallet_statements_loading: true,
                wallet_statements_errors: {}
            };
        }
        case WALLET_STATEMENTS_SUCCESS: {

            return {
                ...state,
                wallet_statements_loading: false,
                wallet_statements: action.data.statements,
                wallet_statements_errors: {},
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };

        }
        case WALLET_STATEMENTS_FAILURE: {
            return {
                ...state,
                wallet_statements_loading: false,
                wallet_statements_errors: action.errors,
                wallet_statements: [],
            };
        }
        /** end::Wallet statements list redux */
        default:
            return state;
    }
}
