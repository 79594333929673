import React from 'react'
import { DialogStyled } from '.';

function Dialog({ isDialogOpen = "false", size, className = "", onClose, children, fullWidth = true, maxWidth = "md", loader = false, ...props }) {
    return (
        <DialogStyled open={isDialogOpen} size={size} className={className} onClose={onClose} fullWidth={fullWidth} maxWidth={maxWidth} {...props}>
            {children}
        </DialogStyled>
    )
}

export { Dialog }
