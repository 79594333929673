import React, { useEffect } from 'react'
import { IconButton, Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { postAuthRoutes } from '../../../../routes';
import { useDispatch, useSelector } from 'react-redux';
import { WalletAction } from '../../../../redux/actions';
import { CurrencySymbol } from '../../../../_components/currency-symbol';

function WalletMenu() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { wallet_chart_loading: isLoading, wallet_balance } = useSelector((state) => state.WalletReducer);

    /** Redux reducer actions */
    const getWalletBalance = () => dispatch(WalletAction.getWalletBalance());

    useEffect(() => {
        getWalletBalance()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClick = (event) => {
        navigate(postAuthRoutes('wallet').path);
    };

    return (
        <IconButton onClick={handleClick}  >
            <Chip icon={<CurrencySymbol style={{ marginLeft: '8px' }}/>} label={!isLoading && `${wallet_balance}`} />
        </IconButton>
    )
}

export { WalletMenu }
