import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Card, CardContent, CardHeader } from '../../../_components/card';
import { GeneralButton } from '../../../_components/controls';
import { CurrencyDataTable } from '.';
import { AbilityCan } from '../../../_helpers/permission/AbilityCan';
import { Fragment } from 'react';
import { postAuthRoutes } from '../../../routes';

function Currency() {
	/** Initialize plugins and variables */
	const dispatch = useDispatch();
	const navigate = useNavigate();

	/** Redux actions */
	const { user } = useSelector((state) => state.UserReducer);

	/** Initialize and declare state */
	const { per_page, page } = useSelector((state) => state.CurrencyReducer);
	const [payload, setPayload] = useState({ limit: per_page, page: page });

	/**
	 * function to handle click to direct to create currency page
	 * @author Akshay N
	 * @created_at 23 Apr 2024
	 */
	function createCurrency() {
		navigate(postAuthRoutes('create_currency').path);
	}

	return (
		<Fragment>
			<CardHeader
				title={postAuthRoutes('currency').name}
				action={
					<Stack spacing={1} direction="row">
						<AbilityCan I="brand_currency_create" passThrough={user.role_slug === 'developer' ? true : false}>
							<GeneralButton startIcon={<Add />} label={`Create Currency`} onClick={() => createCurrency()} />
						</AbilityCan>
					</Stack>
				}
			/>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Card>
						<CardContent>
							<CurrencyDataTable limit={payload.limit} page={payload.page} />
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Fragment>
	);
}

export { Currency };
