import React, { useEffect, useState } from 'react';
import { CreateOrderModal } from './create-order-modal';
import { GeneralButton } from '../../../../../_components/controls';


function CreateOrder() {

    const [showModal, setShowModal] = useState(false);

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        placeOrder();
    }

    /**
     * function to close plans update modal
     * @return view
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const closeModal = () => {
        setShowModal(!showModal);
    }

    /**
     * function to create wallet
     * @return view
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const placeOrder = () => {
        setShowModal(!showModal);
    }
	return (
        <React.Fragment>
            <CreateOrderModal show={showModal} closeModal={closeModal}/>

            <GeneralButton label='Place Order' onClick={() => handleChange()}  />

        </React.Fragment>
    )
}
export { CreateOrder };