import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Dialog, DialogContent, DialogActions, DialogActionCloseBtn,DialogActionSaveBtn, FormInput, FormRadio } from "../../../../_components";
import { Card, CardContent, CardMedia, Grid, Stack, Typography } from "@mui/material";
import questinMarkSvg from './question-mark.svg'
import { handleInputChange, validateForm } from "../../../../_helpers";
import { validate } from './validate';
import { RestaurantMenuAction } from "../../../../redux/actions";
import { useEffect } from "react";


// Initialize form input values to null
const inputs = { cgst: '', sgst: '', configure_gst: false, gstin: '' };

function ConfigureGstModal({ show, showLoader, data, closeModal }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();    

    /** Initialize and declare state */
    const [gstData, setGstData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    /** Redux actions and state */
    const { configure_gst_loading: isLoading, gst_details } = useSelector((state) => state.RestaurantMenuReducer);
    const configureGst = (params) => dispatch(RestaurantMenuAction.configureGst(params));

    useEffect(() => {
        if (data.cgst && show) {

            setGstData({
                ...gstData, 
                cgst: data.cgst,
                sgst: data.sgst,
                configure_gst: data.configure_gst,
                gstin: data.gstin,
            })
        }
    }, [data, show])
    
    /**
     * function to close wallet model
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const onClose = () => {
        closeModal(false);
        setGstData({ ...inputs })
        setErrors({ ...inputs })
    }


    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        const formErrors = {};
        for (const [name, value] of Object.entries(gstData)) {
            const inputError = validate(name, value, gstData);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchAction();

    }

    const dispatchAction = async () => {
        configureGst(gstData).then((result) => {
            closeModal(false);
            setGstData({ ...inputs })
            setErrors({ ...inputs })
        });
    }

    
    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author
     * @created_at 30 May 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, gstData)) })
        gstData[name] = value;
        setGstData({ ...gstData });
    }

    return (
        <Dialog isDialogOpen={show} onClose={onClose} loader={showLoader} maxWidth="sm" PaperProps={{ style: { padding: 0 } }}>
            <Card style={{ boxShadow: `none`, padding: 30 }}>
                <CardMedia component="img" image={questinMarkSvg} style={{ position: "absolute", padding: 0, bottom: 0, right: 0, top: 0, width: "60%", opacity: '0.1' }} />
                <form onSubmit={handleSubmit} noValidate className='ns-form' >
                    <DialogContent style={{ padding: 0 }}>
                        <CardContent style={{ display: `flex`, alignItems: `center`, padding: `2rem 1rem 0 1rem` }}>

                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" style={{ minHeight: `10px`, minWidth: `100%` }} spacing={3}>
                                <Typography variant='h3'>Are You Sure?</Typography>
                                {/* <Typography variant='body2'>Do you want to configure GST?</Typography> */}

                                <Stack direction="row" justifyContent="flex-end" alignItems="flex-start" style={{ minHeight: `100px`, minWidth: `60%` }} >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormRadio
                                                label={`Do you want to configure GST for your brand?`}
                                                name={`configure_gst`}
                                                onChange={handleChange}
                                                data={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                                                value={gstData.configure_gst}
                                                error={(action.isSubmitted && ((errors.configure_gst)) ? (errors.configure_gst) : '')}
                                            />
                                        </Grid>

                                        { gstData.configure_gst === 'true' && 
                                            <React.Fragment>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <FormInput 
                                                        tabIndex={1} 
                                                        label="CGST (%)" 
                                                        name="cgst" 
                                                        value={gstData.cgst} 
                                                        error={action.isSubmitted && errors.cgst ? errors.cgst : ''} 
                                                        onChange={handleChange} 
                                                        maskType={`digit-with-one-dot`} 
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <FormInput 
                                                        tabIndex={2} 
                                                        label="SGST (%)" 
                                                        name="sgst" 
                                                        value={gstData.sgst} 
                                                        error={action.isSubmitted && errors.sgst ? errors.sgst : ''} 
                                                        onChange={handleChange} 
                                                        maskType={`digit-with-one-dot`} 
                                                    />

                                                </Grid>

                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <FormInput 
                                                        tabIndex={2} 
                                                        label="GSTIN" 
                                                        name="gstin" 
                                                        value={gstData.gstin} 
                                                        error={action.isSubmitted && errors.gstin ? errors.gstin : ''} 
                                                        onChange={handleChange} 
                                                        maxLength={15}
                                                        maskType={`alphanumeric-with-underscore`} 
                                                    />

                                                </Grid>
                                            </React.Fragment>
                                        }
                                    </Grid>
                                </Stack>

                            </Stack>

                        </CardContent>
                    </DialogContent >
                    <DialogActions  style={{ padding: 0 }}>
                        <DialogActionSaveBtn tabIndex={3} text={'Configure'} isProcessing={action.isProcessing || isLoading} disabled={action.isProcessing || isLoading}/>
                        <DialogActionCloseBtn tabIndex={4} onClose={onClose} />
                    </DialogActions>
                </form>
            </Card>
        </Dialog>
    )
}

export { ConfigureGstModal };
