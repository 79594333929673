import { Grid } from '@mui/material';
import React, { useState } from 'react'
import { FormBrowseFile, Dialog, DialogContent, DialogActions, DialogActionCloseBtn, DialogActionSaveBtn, DialogTitle } from '../../../../../_components'
import { handleInputChange, validateForm } from '../../../../../_helpers';
import { useDispatch, useSelector } from 'react-redux';
import { validate } from ".";
import { UserAction } from '../../../../../redux/actions';

const inputs = { file: '' };

function UpdateLogoModal({ show, showLoader, closeModal, ...props }) {


    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux actions */
    const updateLogo = (params) => dispatch(UserAction.updateLogo(params));

    /** Redux reducer states */
    const { update_logo_loading: isLoading } = useSelector((state) => state.UserReducer);

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs});
    const [action, setAction] = useState({ isSubmitted: false });

    const onClose = () => {
        setData({ ...inputs });
        closeModal(false);
    }

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const handleChange = async (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        formData.append("file", data.file);
        dispatchUpdateLogoAction(formData);
    }

    const dispatchUpdateLogoAction = async (formData) => {
        const isUpdated = await updateLogo(formData);
        if (isUpdated && isUpdated.status === 1) {
            onClose();
        } else {
            setErrors(isUpdated.errors);
        }
    }

    return (
        <Dialog isDialogOpen={show} onClose={onClose} maxWidth='sm'>
            <form onSubmit={handleSubmit} noValidate >
                <DialogTitle id="updateLogo" onClose={onClose} title={`Update Logo`} />
                <DialogContent >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormBrowseFile
                                tabIndex={1}
                                name="file"
                                error={(action.isSubmitted && (errors.file)) ? (errors.file) : ''}
                                placeholder="your logo"
                                value={data.file}
                                onChange={handleChange}
                                acceptType=".png,.jpg,.jpeg"
                                info="Allowed Format: png, jpg, jpeg | Allowed Maximum Size: 2 MB | Suggested Aspect Ratio: 1080 x 1080"
                            />
                        </Grid>
                    </Grid>
                    <DialogActions  justifyContent={'center'}>
                        <DialogActionSaveBtn tabIndex={3} text='Submit' onClick={handleSubmit} loading={isLoading} disabled={isLoading}/>
                        <DialogActionCloseBtn tabIndex={2} onClose={onClose} />
                    </DialogActions>
                </DialogContent >
            </form>
        </Dialog>
    )
}

export { UpdateLogoModal }