import React from 'react';
import { Box, styled } from '@mui/material';

const DataTableStyled = styled(({ ...props }) => <Box {...props} />)`
	border: ${(props) => `${props.theme.borders.borderWidth[1]} solid ${props.theme.borders.borderColor}`};
	border-radius: ${(props) => props.theme.borders.borderRadius.md};
	.rdt_TableHead {
		z-index: unset;
	}
	.rdt_TableHeader {
		border-top-left-radius: ${(props) => props.theme.borders.borderRadius.md};
		border-top-right-radius: ${(props) => props.theme.borders.borderRadius.md};
		padding: ${(props) => `${props.theme.spacing(3)} ${props.theme.spacing(3)} ${props.theme.spacing(3)} ${props.theme.spacing(3)}`} !important;
		.MuiButton-root {
			min-width: 40px !important;
		}
	}
	header {
		min-height: 0 !important;
		padding: 0 !important;
		.MuiStack-root {
			padding-left: ${(props) => `${props.theme.spacing(5)}`};
			padding-right: ${(props) => `${props.theme.spacing(5)}`};
		}
	}

	.rdt_TableRow {
		border-bottom: ${(props) => `${props.theme.borders.borderWidth[1]} solid ${props.theme.borders.borderColor}`};
	}

	.bSqufN {
		border-radius: unset;
	}

	.rdt_TableHeadRow {
		border-bottom: ${(props) => `${props.theme.borders.borderWidth[1]} solid ${props.theme.borders.borderColor}`};
		min-height: ${(props) => `${props.theme.functions.pxToRem(50)}`};
		text-transform: uppercase;
		.rdt_TableCol:not(:first-of-type) {
			border-left: ${(props) => `${props.theme.borders.borderWidth[1]} solid ${props.theme.borders.borderColor}`};
		}
	}

	.rdt_Table {
		.rdt_TableCell:not(:first-of-type) {
			font-size: 12px !important;
			border-left: ${(props) => `${props.theme.borders.borderWidth[1]} solid ${props.theme.borders.borderColor}`};
		}
	}

	.rdt_TableRow {
		/* background-color: ${(props) => props.theme.palette.background.paper}; */
		/* ${(props) => `${props.theme.spacing(5)} ${props.theme.spacing(5)} ${props.theme.spacing(3)} ${props.theme.spacing(5)}`}; */
		.action-chip {
			text-transform: capitalize;
			border-radius: ${(props) => props.theme.borders.borderRadius.md};
		}
		.action-row {
			width: 100%;
			display: none;
		}
		&:hover {
			.action-chip {
				opacity: 0.1;
				text-transform: capitalize;
			}
			.action-row {
				display: flex;
				position: absolute;
				justify-content: center;
			}
		}
	}

	.rdt_Pagination {
		border-top: none;
		border-bottom-left-radius: ${(props) => props.theme.borders.borderRadius.md};
		border-bottom-right-radius: ${(props) => props.theme.borders.borderRadius.md};
		padding: ${(props) => `${props.theme.spacing(2)} ${props.theme.spacing(5)}`} !important;
	}
`;

export { DataTableStyled };
