module.exports = {
	/**
	 * function to validate the registration form inputs
	 * @param {string} name Input name
	 * @param {string} value Input value
	 * @param {object} [data={}] All input values stored in the state
	 * @author
	 * @created_at 09 Oct 2023
	 */
	validate: (name, value, data = {}) => {
		const errors = {};
		switch (name) {
			case 'title': {
				errors.title = '';
				if (value === '') errors.title = 'Enter Title';
				break;
			}
			case 'category': {
				errors.category = '';
				if (value === '') errors.category = 'Select Menu Category';
				break;
			}
			case 'description': {
				errors.description = '';
				if (value === '') errors.description = 'Enter description';
				break;
			}
			case 'is_non': {
				errors.is_non = '';
				if (value === '') errors.is_non = 'Select Veg or Non Veg';
				break;
			}
			case 'price': {
				errors.price = '';
				if (value === '' && data.have_portions === false) errors.price = 'Enter price';
				break;
			}
			case 'discount': {
				errors.discount = '';
				if (value === '' && data.have_portions === false) errors.discount = 'Enter Discount';
				break;
			}
			// case 'available_time_start': {
			// 	errors.available_time_start = '';
			// 	if (value === '') errors.available_time_start = 'Select Item Available From';
			// 	break;
			// }
			// case 'available_time_end': {
			// 	errors.available_time_end = '';
			// 	if (value === '') errors.available_time_end = 'Select Item Available From';
			// 	break;
			// }
			case 'image': {
				errors.image = '';
				if (value === '' || typeof value === 'undefined') {
					errors.image = 'Select Restaurant Menu Image . ';
				} else if (typeof value !== 'string') {
					let extension = value.name.split('.').pop();
					extension = extension.toLowerCase();
					let size = value.size;
					if (extension !== 'png' && extension !== 'jpg' && extension !== 'jpeg') {
						errors.image = 'Please choose a valid image format.';
					} else if (size >= 2000000) {
						errors.image = 'Select Restaurant Menu image size exceed. ';
					}
				}
				break;
			}
			case 'have_portions': {
				errors.have_portions = '';
				if (value === '') errors.have_portions = 'Select';
				break;
			}
			case 'item_type': {
				errors.item_type = '';
				if (value === '') errors.item_type = 'Select item type';
				break;
			}
			case 'has_add_ons': {
				errors.has_add_ons = '';
				if (value === '') errors.has_add_ons = 'Select add-ons';
				break;
			}
			case 'add_ons': {
				errors.add_ons = [];
				const addOnIds = value.map(addOn => addOn.add_on_id);
				const sameAddOnIds = addOnIds.filter((id, index) => addOnIds.indexOf(id) !== index);
				if (data.has_add_ons === 'true' && (value || value.length > 0)) {
					value.forEach((addOn, index) => {
						if (!addOn.add_on_id || addOn.add_on_id === '' || !addOn.price || addOn.price === '' || typeof addOn.is_non === 'undefined' || addOn.is_non === '') {
							errors.add_ons[index] = {};

							if (!addOn.add_on_id || addOn.add_on_id === '') {
								errors.add_ons[index].add_on_id = `Enter add-on name`;
							}
							if (!addOn.price || addOn.price === '') {
								errors.add_ons[index].price = `Enter add-on price`;
							}

						} else if (sameAddOnIds.includes(addOn.add_on_id)) {
							errors.add_ons[index] = {};
							errors.add_ons[index].add_on_id = 'Add-on already selected';
						}

					});
				}
				break;
			}
			case 'portions': {
				errors.portions = [];
				if (data.have_portions && (value || value.length > 0)) {
					value.forEach((portion, index) => {
						if (!portion.portion || portion.portion === '' || !portion.price || portion.price === '' || portion.slug === '') {
							errors.portions[index] = {};
							if (!portion.portion || portion.portion === '') {
								errors.portions[index].portion = `Enter portion`;
							}
							if (!portion.price || portion.price === '') {
								errors.portions[index].price = `Enter portion price`;
							}
							if (!portion.slug || portion.slug === '') {
								errors.portions[index].slug = `Select portion category`;
							}
							// if ((!portion.discount && portion.discount !== 0) || portion.discount === '') {
							// 	errors.portions[index].discount = `Enter portion discount`;
							// }
						}

					});
				}
				break;
			}
			default: {
				errors[name] = '';
				break;
			}
		}
		return errors;
	},
};
