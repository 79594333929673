import React, { useEffect, useState } from "react";
import queryString from 'query-string';
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { Grid, Stack, Box } from '@mui/material'
import { FilterAlt } from "@mui/icons-material";
import { postAuthRoutes } from "../../../routes";
import { InfluencerCard, InfluencerCardLoader, InfluencerFilter, InfluencerExport } from ".";
import { CardHeader } from "../../../_components/card";
import { FilterMenu, GeneralButton } from "../../../_components/controls";
import { FormSearchInput } from "../../../_components/form";
import { NoData } from '../../../_components/layout'
import { AbilityCan } from '../../../_helpers/permission/AbilityCan';
import { InfluencerAction } from "../../../redux/actions";

function Influencers() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Redux reducer state */
    const { user } = useSelector((state) => state.UserReducer);
    const { influencers_loading: isLoading, influencers } = useSelector((state) => state.InfluencerReducer);

    /** Redux reducer actions */
    const listInfluencer = (params) => dispatch(InfluencerAction.listInfluencer(params));

    /** Initialize and declare state */
    const [showInfluencerFilterModal, setShowInfluencerFilterModal] = useState(false);
    const [payload, setPayload] = useState({ limit: 100, page: 1, search: searchParams.get('q') ?? '', filter: { status: searchParams.get('status') ?? '' } });

    useEffect(() => {

        const queryParam = { ...payload.filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = payload.page;
        if (payload.search) queryParam.q = payload.search; else delete queryParam.q;
        if (payload.filter.status) queryParam.status = payload.filter.status; else delete queryParam.status;

        navigate(postAuthRoutes('influencer').path, { search: `?${queryString.stringify(queryParam)}` });
        getNext() // Initially load participants list
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search])

    /**
     * function to fetch influencer list
     * @param {Object} payload - The params which are used for the api
     * @param {string} payload.page - Page number to load
     * @param {string} payload.limit -  Count of the documents per page
     * @param {string} payload.filter - Filter data
     * @return json
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const getNext = async () => {
        const result = await listInfluencer(payload);
        if (result.status) {
            setPayload({ ...payload, page: payload.page + 1 });
        }
    }

    /**
     * function to open/close influencer filter modal
     * @param {string} null
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const influencerFilterModal = () => {
        setShowInfluencerFilterModal(!showInfluencerFilterModal);
    }

    /**
     * function to apply the selected filter options on the influencer list
     * @param {Object} filterData
     * @param {String} filterData.status Selected status from the influencer filter modal
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData });
    }


    /**
     * function to apply the search key words on the promo list
     * @param {String} text Search keyword used
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    return (
        <Box>
            <CardHeader
                title={postAuthRoutes('influencer').name}
                action={
                    <Stack spacing={1} direction="row">
                        <AbilityCan I="brand_influencer_search" passThrough={user.role_slug === 'developer' ? true : false}>
                            <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                        </AbilityCan>
                        <AbilityCan I="brand_influencer_filter" passThrough={user.role_slug === 'developer' ? true : false}>
                            <GeneralButton label={<FilterAlt />} onClick={() => influencerFilterModal()} />
                        </AbilityCan>
                        <AbilityCan I="brand_influencer_export" passThrough={user.role_slug === 'developer' ? true : false}>
                            <FilterMenu>
                                <InfluencerExport filter={payload.filter} />
                            </FilterMenu>
                        </AbilityCan>
                    </Stack>
                }
            />
            <InfluencerFilter show={showInfluencerFilterModal} closeModal={influencerFilterModal} applyFilter={applyFilter} promoFilter={payload.filter} />

            <InfiniteScroll dataLength={influencers.length} next={getNext} hasMore={true}>
                <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center">
                    {influencers.length > 0 &&
                        influencers.map((influencer, i) => {
                            return (
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={3} key={i}>
                                    <InfluencerCard influencer={influencer} />
                                </Grid>
                            );
                        })}
                    {isLoading && <InfluencerCardLoader />}
                    {!isLoading && influencers.length === 0 && <NoData content1={`Influencers`} content2={`Not Found`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} minHeight={`75vh`}></NoData>}
                </Grid>
            </InfiniteScroll>
        </Box>
    );
}


export { Influencers }