import React from 'react'
import { AttachFile, Event, Facebook, Instagram, MoreVert } from '@mui/icons-material'
import { Box, Chip, Divider, Grid, Stack, Card, CardContent, Typography, IconButton, Menu, MenuItem } from '@mui/material'

import { SchedulePostMedia } from './schedule-post-media'
import { postAuthRoutes } from '../../../../../routes';
import { useNavigate } from 'react-router-dom';
import { Fragment } from 'react';
import { useState } from 'react';

function SchedulePostCard({ post }) {

    /** Initialize and declare state */
    const [anchorEl, setAnchorEl] = useState(null);

    /** Initialize plugins and variables */
    const open = Boolean(anchorEl);
    let navigate = useNavigate();

    /**
     * function to handle click icon menu
     * @param {object} event form object
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * function to handle close icon menu
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * function to direct to update promo page
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleUpdateClick() {
        setAnchorEl(null);
        navigate(postAuthRoutes('update_schedule_post').path, { state: { post: post }, });
    }

    const postStatus = (post_status) => {

        let status = <Chip label={post_status} />;
        if (post_status === 'scheduled')
            status = <Chip size='small' label='Scheduled' color='success' />
        else if (['initializing', 'initialized', 'publishing'].includes(post_status))
            status = <Chip size='small' label='Publishing' color='info' />
        else if (post_status === 'published')
            status = <Chip size='small' label='Published' color='primary' />
        else if (post_status === 'failed')
            status = <Chip size='small' label='Failed' color='error' />
        else if (post_status === 'drafted')
            status = <Chip size='small' label='Drafted' />

        return <Fragment>{status}</Fragment>;

    }

    return (
        <Card style={{ "height": "100%" }}>
            <Grid container spacing={2} display="flex" alignItems="center">
                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                    <Box style={{ width: `100%` }}>
                        <SchedulePostMedia post={post} />

                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                    <CardContent>
                        <Box style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'right' }} mb={1} mt={2}>
                            <Box>
                                {postStatus(post.status)}
                                {post.status && ['scheduled', 'drafted', 'failed'].includes(post.status) && <Fragment>
                                    <IconButton aria-label="more" id="long-button" aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} ><MoreVert /></IconButton>
                                    <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button' }} anchorEl={anchorEl} open={open} onClose={handleClose}  >
                                        {post.status && ['scheduled', 'drafted', 'failed'].includes(post.status) &&
                                            <MenuItem onClick={handleUpdateClick}>{`Update`}</MenuItem>
                                        }
                                    </Menu>
                                </Fragment>}
                            </Box>
                        </Box>
                        <Typography variant="body2">{`${post.caption}`}</Typography>
                        <Divider />
                        {post.platform === 'instagram' &&
                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography variant="body2"><Instagram /> {`Instagram handle : `}</Typography>
                                <Typography variant="h6">{`@${post.insta_handle}`}</Typography>
                            </Box>
                        }
                        {post.platform === 'facebook' &&
                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography variant="body2"><Event /> {`Facebook Page : `}</Typography>
                                <Typography variant="h6">{`${post.facebook_page}`}</Typography>
                            </Box>
                        }
                        <Box display="flex" justifyContent="space-between" alignItems="center" >
                            <Typography variant="body2"><Event /> {`Scheduled date : `}</Typography>
                            <Typography variant="h6">{`${post.scheduled_time}`}</Typography>
                        </Box>
                        {post.published_at &&
                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography variant="body2"><Event /> {`Published At : `}</Typography>
                                <Typography variant="h6">{`${post.published_at}`}</Typography>
                            </Box>
                        }
                        <Divider />
                        <Stack spacing={1}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography variant="body2">{`Platform : `}</Typography>
                                <Chip label={`${post.platform}`} size="small" icon={(post.platform === 'facebook') ? <Facebook /> : <Instagram />} />
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography variant="body2">{`Post type : `}</Typography>
                                <Chip label={`${post.post_type}`} size="small" icon={<AttachFile />} />
                            </Box>
                        </Stack>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    )
}

export { SchedulePostCard }