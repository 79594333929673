import React from 'react';
import { CurrencyRupee } from '@mui/icons-material';
import { cookieServices } from '../../_helpers';

function CurrencySymbol({style}) {

  let currencySymbol = cookieServices.get('currencySymbol');

  return (
    <span style={style}> {currencySymbol ? <span dangerouslySetInnerHTML={{ __html: currencySymbol }} /> : <CurrencyRupee />} </span>
  );
}

export { CurrencySymbol };