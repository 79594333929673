import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { FilterAlt, History, CachedRounded } from '@mui/icons-material';
import { Box, Grid, Stack, Typography } from '@mui/material';
import queryString from 'query-string';
import { postAuthRoutes } from '../../../../routes';
import { RestaurantOrdersAction } from '../../../../redux//actions';
import { CardHeader } from '../../../../_components/card';
import { GeneralButton } from '../../../../_components/controls';
import { FormSearchInput } from '../../../../_components/form';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NoData } from '../../../../_components/layout';
import { RestaurantOrdersCard, RestaurantOrdersCardLoader } from './restaurant-orders-card';
import { RestaurantOrdersFilter } from './restaurant-orders-filter';
import { MonthlyCollectionWidget, DailyCollectionWidget } from './widgets';
import { AbilityCan } from '../../../../_helpers/permission/AbilityCan';
import { CreateOrder } from './create-order';

function RestaurantOrders() {
	/** Initialize plugins and variables */
	const dispatch = useDispatch();
	let navigate = useNavigate();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	/** Redux reducer state */
	const { restaurant_orders_loading: isLoading, restaurant_orders, restaurant_delivery_orders } = useSelector((state) => state.RestaurantOrdersReducer);
	const { user } = useSelector((state) => state.UserReducer);
	const listRestaurantOrders = (params) => dispatch(RestaurantOrdersAction.listRestaurantOrders(params));
	const listRestaurantDeliveryOrders = (params) => dispatch(RestaurantOrdersAction.listRestaurantDeliveryOrders(params));

	/** Initialize and declare state */
	const [payload, setPayload] = useState({
		limit: 100, // Number of records that are displayed when a page loads
		page: 1, // Page number to load
		search: searchParams.get('q') ?? '', // Search key to check for word anywhere in the record
		filter: {
			status: searchParams.get('status') ?? '', // Selected promo status to be filtered, initially it will be null
			order_placed_at: searchParams.get('order_placed_at') ?? '',
		},
	});

	const [showRestaurantOrderFilterModal, setShowRestaurantOrderFilterModal] = useState(false);
	const [refreshPage, setRefreshPage] = useState(false);

	useEffect(() => {
		/* Read the payload and append the parameters to the url for proper reload */
		const queryParam = { ...payload.filter };
		Object.keys(queryParam).forEach((element) => {
			if (queryParam[element] === '' || queryParam[element] === null) {
				delete queryParam[element];
			}
		});

		queryParam.p = payload.page;
		if (payload.search) queryParam.q = payload.search;
		else delete queryParam.q;
		if (payload.filter.status) queryParam.status = payload.filter.status;
		else delete queryParam.status;
		if (payload.filter.order_placed_at) queryParam.order_placed_at = payload.filter.order_placed_at;
		else delete queryParam.order_placed_at;

		const pathname = postAuthRoutes('restaurant_orders').path;
		navigate({ pathname, search: createSearchParams(`?${queryString.stringify(queryParam)}`).toString() });

		getNext();

		setInterval(async () => {
			const result = await listRestaurantOrders(payload);
			await listRestaurantDeliveryOrders(payload);
			if (result.status) {
				setPayload({ ...payload, page: payload.page + 1 }); // If result success, update payload state
			}
		}, 120000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payload.filter, payload.search, refreshPage]);

	/**
	 * function to open/close restaurant menu filter modal
	 * @param {string} null
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	const restaurantOrderFilterModal = () => {
		setShowRestaurantOrderFilterModal(!showRestaurantOrderFilterModal);
	};

	/**
	 * function to apply the selected filter options on the restaurant menu list
	 * @param {Object} filterData
	 * @param {String} filterData.category Selected category from the restaurant menu filter modal
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	const applyFilter = (filterData) => {
		setPayload({ ...payload, page: 1, filter: filterData });
	};

	/**
	 * function to apply the search key words on the restaurant menu list
	 * @param {String} text Search keyword used
	 * @return view
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	const applySearch = (text) => {
		setPayload({ ...payload, page: 1, search: text });
	};

	const getNext = async () => {
		const result = await listRestaurantOrders(payload);
		await listRestaurantDeliveryOrders(payload);
		if (result.status) {
			setPayload({ ...payload, page: payload.page + 1 }); // If result success, update payload state
		}
	};

	/**
	 * function to direct to restaurant history page
	 * @param {string} null
	 * @return view
	 * @author Naveen K
	 * @created_at 11 Nov 2023
	 */
	const restaurantOrderHistory = () => {
		navigate(postAuthRoutes('restaurant_order_history').path);
	};

	/**
	 * function to direct to todays restaurant order page
	 * @param {string} null
	 * @return view
	 * @author Naveen K
	 * @created_at 18 Nov 2023
	 */
	const todaysRestaurantOrder = () => {
		navigate(postAuthRoutes('todays_restaurant_pre_order').path);
	};

	return (
		<Box style={{ paddingBottom: '15px' }}>
			<CardHeader
				title={postAuthRoutes('restaurant_orders').name}
				action={
					<Stack spacing={1} direction="row">
					<AbilityCan I="brand_restaurant_order_search" passThrough={user.role_slug === 'developer' ? true : false}>
						<FormSearchInput getSearchText={applySearch} searchText={payload.search} />
					</AbilityCan>
					<AbilityCan I="brand_restaurant_order_filter" passThrough={user.role_slug === 'developer' ? true : false}>
						<GeneralButton label={<FilterAlt />} onClick={() => restaurantOrderFilterModal()} />
					</AbilityCan>
					<AbilityCan I="brand_restaurant_order_history" passThrough={user.role_slug === 'developer' ? true : false}>
						<GeneralButton label={<History />} onClick={()  => restaurantOrderHistory()} />
					</AbilityCan>
					<AbilityCan I="brand_todays_restaurant_order" passThrough={user.role_slug === 'developer' ? true : false}>
						<GeneralButton label="Todays Orders" onClick={()  => todaysRestaurantOrder()} />
					</AbilityCan>
					<AbilityCan I="brand_create_order" passThrough={user.role_slug === 'developer' ? true : false}>
						<CreateOrder/>
					</AbilityCan>
					<GeneralButton label={<CachedRounded />} onClick={() => setRefreshPage(pageRefresh => !pageRefresh)} />
					</Stack>
				}
			/>
			<RestaurantOrdersFilter show={showRestaurantOrderFilterModal} closeModal={restaurantOrderFilterModal} applyFilter={applyFilter} orderFilter={payload.filter} />
			<Grid container spacing={3} style={{ paddingBottom: '15px' }}>
				<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
					<DailyCollectionWidget />
				</Grid>
				<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
					<MonthlyCollectionWidget />
				</Grid>
			</Grid>

			<InfiniteScroll dataLength={restaurant_orders.length} next={getNext} hasMore={true}>
				<Grid container spacing={2} direction="row" justifyContent="left" alignItems={restaurant_orders.length > 0 ? 'stretch' : 'center'}>
					{restaurant_orders &&
						restaurant_orders.length > 0 &&
						restaurant_orders.map((restaurant_order, i) => {
							return (
								<Grid item xs={12} sm={12} md={12} lg={4} xl={4} key={i}>
									<RestaurantOrdersCard restaurant_order={restaurant_order} style={{ height: `200px` }} />
								</Grid>
							);
						})}
					{isLoading && <RestaurantOrdersCardLoader />}
					{!isLoading && restaurant_orders.length === 0 && <NoData content1={`Restaurant Orders`} content2={`Not Found`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} minHeight={`75vh`} />}
				</Grid>
				<Grid container spacing={2} direction="row" justifyContent="left" alignItems={restaurant_delivery_orders.length > 0 ? 'stretch' : 'center'} paddingTop={'10px'}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" justifyContent="left" alignItems="center">
						<Box>
							<Typography variant="h5">Delivery Orders</Typography>
						</Box>
					</Grid>
					{restaurant_delivery_orders &&
						restaurant_delivery_orders.length > 0 &&
						restaurant_delivery_orders.map((restaurant_delivery_order, i) => {
							return (
								<Grid item xs={12} sm={12} md={12} lg={4} xl={4} key={i}>
									<RestaurantOrdersCard restaurant_order={restaurant_delivery_order} style={{ height: `200px` }} />
								</Grid>
							);
						})}
					{isLoading && <RestaurantOrdersCardLoader />}
					{!isLoading && restaurant_delivery_orders.length === 0 && <NoData content1={`Restaurant Orders`} content2={`Not Found`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} minHeight={`75vh`} />}
				</Grid>
			</InfiniteScroll>
		</Box>
	);
}
export { RestaurantOrders };
