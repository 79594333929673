import { Chip, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogActions, DialogActionCloseBtn, DialogActionSaveBtn, DialogTitle } from "../../../../../_components/dialog";
import { Fragment } from "react";
import { FormDateRangePicker } from "../../../../../_components";
import { handleInputChange } from "../../../../../_helpers";

// Initialize form input values to null
const inputs = { created_date: '' };

function WalletStatementsFilter({ ...props }) {

    /** Initialize props values */
    const { show, closeModal, applyFilter, statementFilter } = props;

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [filteredData, setFilteredData] = useState({});

    useEffect(() => {
        setData({ created_date: statementFilter.created_date });

        let filtered = {};
        Object.keys(statementFilter).forEach(function (item, i) {
            if (statementFilter[item])
                filtered[item] = statementFilter[item];
        });
        setFilteredData({ ...filtered });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statementFilter]);

    /**
     * function to close ticket filter modal
     * @param {Array} created_date Entered date on ticket filter modal
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const onClose = () => {
        setData({ created_date: '' });
        closeModal(false);
    };

    /**
     * function to clear ticket filter modal
     * @param {Array} created_date Entered date on ticket filter modal
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const onClear = () => {
        setData({ created_date: '' });
        applyFilter({ created_date: '', });
        closeModal(false);
    };

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        applyFilter(data);
        closeModal(false);

    }

    /**
     * function to clear all data entered on ticket filter modal
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const clearFilterItem = (item) => {
        const clearItem = { ...data };
        clearItem[item] = '';
        setData({ ...clearItem });
        applyFilter({ ...clearItem });
    };

    return (
        <Fragment>
            {
                Object.keys(filteredData).length > 0 &&
                (
                    <Grid container mb={2} >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack direction="row" spacing={1}>
                                {
                                    Object.keys(filteredData).map((item, i) => {
                                        return <Chip key={i} label={filteredData[item]} onDelete={() => clearFilterItem(item)} style={{ textTransform: `capitalize` }} />;
                                    })
                                }
                            </Stack>
                        </Grid>
                    </Grid>
                )
            }
            <Dialog isDialogOpen={show} onClose={onClose} maxWidth='xs'>
                <form onSubmit={handleSubmit} noValidate >
                    <DialogTitle id="walletFilter" onClose={onClose} title='Filter' />
                    <DialogContent >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormDateRangePicker
                                    tabIndex={2}
                                    label='Created Date'
                                    name='created_date'
                                    value={data.created_date}
                                    placeholder="dd/mm/yyyy - dd/mm/yyyy"
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent >
                    <DialogActions >
                        <DialogActionSaveBtn tabIndex={4} text='Filter' />
                        <DialogActionCloseBtn tabIndex={3} onClose={onClear} text='Clear' />
                    </DialogActions>
                </form>
            </Dialog>
        </Fragment>
    );
}

export { WalletStatementsFilter };