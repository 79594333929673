import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Dialog, DialogContent, DialogActions, DialogActionCloseBtn,DialogActionSaveBtn, FormInput, FormRadio, FormSwitch } from "../../../../_components";
import { Card, CardContent, CardMedia, Grid, Stack, Typography } from "@mui/material";
import questinMarkSvg from './question-mark.svg'
import { handleInputChange, validateForm } from "../../../../_helpers";
import { validate } from './validate';
import { RestaurantMenuAction } from "../../../../redux/actions";
import { useEffect } from "react";


// Initialize form input values to null
const inputs = { future_orders: '', future_days: '' };

function FutureOrdersModal({ show, showLoader, data, closeModal }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();    

    /** Initialize and declare state */
    const [futureData, setFutureData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [isDataLoading, setIsDataLoading] = useState(true);


    /** Redux actions and state */
    const { set_future_orders_loading: isLoading, get_future_orders_details } = useSelector((state) => state.RestaurantMenuReducer);
    const setFutureOrders = (params) => dispatch(RestaurantMenuAction.setFutureOrders(params));

    useEffect(() => {
        if (data && Object.keys(data).length > 0 && show) {
            setFutureData({
                ...futureData, 
                future_orders: data.future_orders === true ? true : false,
                future_days: data.future_days
            })

            setIsDataLoading(false);
        }
    }, [data, show])
    
    /**
     * function to close wallet model
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const onClose = () => {
        closeModal(false);
        setFutureData({ ...inputs })
        setErrors({ ...inputs })
        setIsDataLoading(true);
    }


    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        const formErrors = {};
        for (const [name, value] of Object.entries(futureData)) {
            const inputError = validate(name, value, futureData);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchAction();

    }

    const dispatchAction = async () => {
        setFutureOrders(futureData).then((result) => {
            closeModal(false);
            setFutureData({ ...inputs })
            setErrors({ ...inputs })
            setIsDataLoading(true);
        });
    }

    
    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author
     * @created_at 30 May 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, futureData)) })
        futureData[name] = value;
        setFutureData({ ...futureData });
    }

    return (
        <Dialog isDialogOpen={show} onClose={onClose} loader={showLoader} maxWidth="sm" PaperProps={{ style: { padding: 0 } }}>
            <Card style={{ boxShadow: `none`, padding: 30 }}>
                <CardMedia component="img" image={questinMarkSvg} style={{ position: "absolute", padding: 0, bottom: 0, right: 0, top: 0, width: "60%", opacity: '0.1' }} />
                <form onSubmit={handleSubmit} noValidate className='ns-form' >
                    <DialogContent style={{ padding: 0 }}>
                        <CardContent style={{ display: `flex`, alignItems: `center`, padding: `2rem 1rem 0 1rem` }}>

                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" style={{ minHeight: `10px`, minWidth: `100%` }} spacing={3}>
                                <Typography variant='h3'>Are You Sure?</Typography>

                                <Stack direction="row" justifyContent="flex-end" alignItems="flex-start" style={{ minHeight: `100px`, minWidth: `60%` }} >
                                    <Grid container spacing={2}>

                                        { !isDataLoading && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormSwitch
                                                tabIndex={1}
                                                name="future_orders" 
                                                value={futureData.future_orders} 
                                                label="Do you want to enable future orders?" 
                                                error={action.isSubmitted && errors.future_orders ? errors.future_orders : ''} 
                                                onChange={handleChange} 
                                            />
                                        </Grid>}
                                        {futureData.future_orders && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={2}
                                                label='Future Days'
                                                name='future_days'
                                                value={futureData.future_days}
                                                error={action.isSubmitted && (errors.future_days ? errors.future_days : '')}
                                                onChange={handleChange}
                                                autoFocus={true}
                                                maskType={'digit-only'}
                                            />
                                        </Grid>}

                                    </Grid>
                                </Stack>

                            </Stack>

                        </CardContent>
                    </DialogContent >
                    <DialogActions  style={{ padding: 0 }}>
                        <DialogActionSaveBtn tabIndex={3} text={'Configure'} isProcessing={action.isProcessing || isLoading} disabled={action.isProcessing || isLoading}/>
                        <DialogActionCloseBtn tabIndex={4} onClose={onClose} />
                    </DialogActions>
                </form>
            </Card>
        </Dialog>
    )
}

export { FutureOrdersModal };
