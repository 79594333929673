import { GlobalAction } from ".";
import { CurrencyServices } from "../services";

export const CURRENCY_REQUEST = 'CURRENCY_REQUEST';
export const CURRENCY_SUCCESS = 'CURRENCY_SUCCESS';
export const CURRENCY_FAILURE = 'CURRENCY_FAILURE';

export const CREATE_CURRENCY_REQUEST = 'CREATE_CURRENCY_REQUEST';
export const CREATE_CURRENCY_SUCCESS = 'CREATE_CURRENCY_SUCCESS';
export const CREATE_CURRENCY_FAILURE = 'CREATE_CURRENCY_FAILURE';

export const UPDATE_CURRENCY_REQUEST = 'UPDATE_CURRENCY_REQUEST';
export const UPDATE_CURRENCY_SUCCESS = 'UPDATE_CURRENCY_SUCCESS';
export const UPDATE_CURRENCY_FAILURE = 'UPDATE_CURRENCY_FAILURE';

export const DELETE_CURRENCY_REQUEST = 'DELETE_CURRENCY_REQUEST';
export const DELETE_CURRENCY_SUCCESS = 'DELETE_CURRENCY_SUCCESS';
export const DELETE_CURRENCY_FAILURE = 'DELETE_CURRENCY_FAILURE';

export const CURRENCY_SELECT_LIST_REQUEST = 'CURRENCY_SELECT_LIST_REQUEST';
export const CURRENCY_SELECT_LIST_SUCCESS = 'CURRENCY_SELECT_LIST_SUCCESS';
export const CURRENCY_SELECT_LIST_FAILURE = 'CURRENCY_SELECT_LIST_FAILURE';

export const CurrencyAction = {

    /**
     * Action for list Currency
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 23 Apr 2024
    */
     listCurrency: (params) => {
        return dispatch => {
            dispatch(request(params));
            return CurrencyServices.listCurrency(params).then(
				(response) => {
					return dispatch(success(params, response));
				},
				(error) => {
					return dispatch(failure(params, error));
				},
			);
        };

        function request(request) { return { type: CURRENCY_REQUEST, request: request } }
        function success(request, response) { return { type: CURRENCY_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CURRENCY_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for Creating currency
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 23 Apr 2023
     */
    createCurrency: (params) => {
        return dispatch => {
            dispatch(request(params));
            return CurrencyServices.createCurrency(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        }

        function request(request) { return { type: CREATE_CURRENCY_REQUEST, request: request }; }
        function success(request, response) { return { type: CREATE_CURRENCY_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CREATE_CURRENCY_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action for update currency
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 23 Apr 2023
    */
    updateCurrency: (params) => {
        return dispatch => {
            dispatch(request(params));
            return CurrencyServices.updateCurrency(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_CURRENCY_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_CURRENCY_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_CURRENCY_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for deleting currency
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 23 Apr 2023
    */
    deleteCurrency: (params) => {
        return dispatch => {
            dispatch(request(params));
            return CurrencyServices.deleteCurrency(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: DELETE_CURRENCY_REQUEST, request: request } }
        function success(request, response) { return { type: DELETE_CURRENCY_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: DELETE_CURRENCY_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for currency select list
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 07 May 2024
    */
     currencySelectList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return CurrencyServices.currencySelectList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CURRENCY_SELECT_LIST_REQUEST, request: request } }
        function success(request, response) { return { type: CURRENCY_SELECT_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CURRENCY_SELECT_LIST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
}