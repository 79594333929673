import { GlobalAction } from ".";
import { PromoServices } from "../services";

export const CREATE_PROMO_REQUEST = 'CREATE_PROMO_REQUEST';
export const CREATE_PROMO_SUCCESS = 'CREATE_PROMO_SUCCESS';
export const CREATE_PROMO_FAILURE = 'CREATE_PROMO_FAILURE';

export const UPDATE_PROMO_REQUEST = 'UPDATE_PROMO_REQUEST';
export const UPDATE_PROMO_SUCCESS = 'UPDATE_PROMO_SUCCESS';
export const UPDATE_PROMO_FAILURE = 'UPDATE_PROMO_FAILURE';

export const PROMOS_REQUEST = 'PROMOS_REQUEST';
export const PROMOS_SUCCESS = 'PROMOS_SUCCESS';
export const PROMOS_FAILURE = 'PROMOS_FAILURE';

export const PROMO_COUNT_REQUEST = 'PROMO_COUNT_REQUEST';
export const PROMO_COUNT_SUCCESS = 'PROMO_COUNT_SUCCESS';
export const PROMO_COUNT_FAILURE = 'PROMO_COUNT_FAILURE';

export const PROMO_COUPON_CODES_REQUEST = 'PROMO_COUPON_CODES_REQUEST';
export const PROMO_COUPON_CODES_SUCCESS = 'PROMO_COUPON_CODES_SUCCESS';
export const PROMO_COUPON_CODES_FAILURE = 'PROMO_COUPON_CODES_FAILURE';

export const PROMO_STORIES_REQUEST = 'PROMO_STORIES_REQUEST';
export const PROMO_STORIES_SUCCESS = 'PROMO_STORIES_SUCCESS';
export const PROMO_STORIES_FAILURE = 'PROMO_STORIES_FAILURE';

export const CHANGE_PROMO_STATUS_REQUEST = 'CHANGE_PROMO_STATUS_REQUEST';
export const CHANGE_PROMO_STATUS_SUCCESS = 'CHANGE_PROMO_STATUS_SUCCESS';
export const CHANGE_PROMO_STATUS_FAILURE = 'CHANGE_PROMO_STATUS_FAILURE';

export const CHANGE_COUPON_STATUS_REQUEST = 'CHANGE_COUPON_STATUS_REQUEST';
export const CHANGE_COUPON_STATUS_SUCCESS = 'CHANGE_COUPON_STATUS_SUCCESS';
export const CHANGE_COUPON_STATUS_FAILURE = 'CHANGE_COUPON_STATUS_FAILURE';

export const CREATE_COUPON_CODE_REQUEST = 'CREATE_COUPON_CODE_REQUEST';
export const CREATE_COUPON_CODE_SUCCESS = 'CREATE_COUPON_CODE_SUCCESS';
export const CREATE_COUPON_CODE_FAILURE = 'CREATE_COUPON_CODE_FAILURE';

export const EXPORT_PROMO_REQUEST = 'EXPORT_PROMO_REQUEST';
export const EXPORT_PROMO_SUCCESS = 'EXPORT_PROMO_SUCCESS';
export const EXPORT_PROMO_FAILURE = 'EXPORT_PROMO_FAILURE';

export const BRAND_PROMO_DETAILS_REQUEST = 'BRAND_PROMO_DETAILS_REQUEST';
export const BRAND_PROMO_DETAILS_SUCCESS = 'BRAND_PROMO_DETAILS_SUCCESS';
export const BRAND_PROMO_DETAILS_FAILURE = 'BRAND_PROMO_DETAILS_FAILURE';

export const BRAND_PROMO_STORIES_REQUEST = 'BRAND_PROMO_STORIES_REQUEST';
export const BRAND_PROMO_STORIES_SUCCESS = 'BRAND_PROMO_STORIES_SUCCESS';
export const BRAND_PROMO_STORIES_FAILURE = 'BRAND_PROMO_STORIES_FAILURE';

export const BRAND_PROMO_COUPON_CODES_REQUEST = 'BRAND_PROMO_COUPON_CODES_REQUEST';
export const BRAND_PROMO_COUPON_CODES_SUCCESS = 'BRAND_PROMO_COUPON_CODES_SUCCESS';
export const BRAND_PROMO_COUPON_CODES_FAILURE = 'BRAND_PROMO_COUPON_CODES_FAILURE';

export const GENERATE_COUPON_CODES_REQUEST = 'GENERATE_COUPON_CODES_REQUEST';
export const GENERATE_COUPON_CODES_SUCCESS = 'GENERATE_COUPON_CODES_SUCCESS';
export const GENERATE_COUPON_CODES_FAILURE = 'GENERATE_COUPON_CODES_FAILURE';

export const GET_RUNNING_PROMOS_REQUEST = 'GET_RUNNING_PROMOS_REQUEST';
export const GET_RUNNING_PROMOS_SUCCESS = 'GET_RUNNING_PROMOS_SUCCESS';
export const GET_RUNNING_PROMOS_FAILURE = 'GET_RUNNING_PROMOS_FAILURE';

export const PromoAction = {

    /**
     * Action for create promo
     * @param {Object} params - The params which are used for the api
     * @param {String} params.name
     * @param {String} params.insta_handle
     * @param {String} params.description
     * @param {String} params.promo_type
     * @param {String} params.promo_start_date
     * @param {String} params.promo_end_date
     * @param {String} params.number_of_promos
     * @param {String} params.influencer_minimum_followers
     * @param {String} params.promo_cost_per_influencer
     * @param {Object} params.file
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    createPromo: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.createPromo(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CREATE_PROMO_REQUEST, request: request } }
        function success(request, response) { return { type: CREATE_PROMO_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CREATE_PROMO_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    /**
     * Action for update promo
     * @param {Object} params - The params which are used for the api
     * @param {String} params.promo_id
     * @param {String} params.name
     * @param {String} params.insta_handle
     * @param {String} params.description
     * @param {String} params.promo_type
     * @param {String} params.promo_start_date
     * @param {String} params.promo_end_date
     * @param {String} params.number_of_promos
     * @param {String} params.influencer_minimum_followers
     * @param {String} params.promo_cost_per_influencer
     * @param {Object} params.file
     * @author Akshay N 
     * @created_at 30 May 2022
    */
    updatePromo: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.updatePromo(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_PROMO_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_PROMO_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_PROMO_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    /**
     * Action for list brand promos
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    listBrandPromos: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.listBrandPromos(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: PROMOS_REQUEST, request: request } }
        function success(request, response) { return { type: PROMOS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: PROMOS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
    * Action to get promo count
    * @param {Object} params - The params which are used for the api
    * @author Akshay N
    * @created_at 30 May 2022
    */
    getPromoCount: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.getPromoCount(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: PROMO_COUNT_REQUEST, request: request } }
        function success(request, response) { return { type: PROMO_COUNT_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: PROMO_COUNT_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for list promo coupon codes
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    listPromoCouponCodes: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.listPromoCouponCodes(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: PROMO_COUPON_CODES_REQUEST, request: request } }
        function success(request, response) { return { type: PROMO_COUPON_CODES_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: PROMO_COUPON_CODES_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for change promo status
     * @param {Object} params - The params which are used for the api
     * @param {String} params.promo_id
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    changeStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.changeStatus(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_PROMO_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_PROMO_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_PROMO_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },


    /**
     * Action for change coupon status
     * @param {Object} params - The params which are used for the api
     * @param {String} params.coupon_id
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    changeCouponStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.changeCouponStatus(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_COUPON_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_COUPON_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_COUPON_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for create coupon code
     * @param {Object} params - The params which are used for the api
     * @param {String} params.promo_id
     * @param {String} params.coupon_code
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    createCouponCode: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.createCouponCode(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CREATE_COUPON_CODE_REQUEST, request: request } }
        function success(request, response) { return { type: CREATE_COUPON_CODE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CREATE_COUPON_CODE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for list promo stories
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    listPromoStories: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.listPromoStories(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: PROMO_STORIES_REQUEST, request: request } }
        function success(request, response) { return { type: PROMO_STORIES_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: PROMO_STORIES_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
    * Action to export promo csv
    * @param {Object} params - The params which are used for the api
    * @author Jasin
    * @created_at 30 May 2022
    */
    exportPromoCsv: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.exportPromoCsv(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: EXPORT_PROMO_REQUEST, request: request } }
        function success(request, response) { return { type: EXPORT_PROMO_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: EXPORT_PROMO_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get promo details
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.influencer_id
     * @author Jasin 
    */
    promoDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.promoDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: BRAND_PROMO_DETAILS_REQUEST, request: request } }
        function success(request, response) { return { type: BRAND_PROMO_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: BRAND_PROMO_DETAILS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for list promo stories
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     */
    listBrandPromoStories: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.listBrandPromoStories(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: BRAND_PROMO_STORIES_REQUEST, request: request } }
        function success(request, response) { return { type: BRAND_PROMO_STORIES_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: BRAND_PROMO_STORIES_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for list promo coupon codes
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     */
    listBrandPromoCouponCodes: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.listBrandPromoCouponCodes(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: BRAND_PROMO_COUPON_CODES_REQUEST, request: request } }
        function success(request, response) { return { type: BRAND_PROMO_COUPON_CODES_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: BRAND_PROMO_COUPON_CODES_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
    * Action to generate coupon codes
    * @param {Object} params - The params which are used for the api
    * @author Jasin
    * @created_at 30 May 2022
    */
    generateCouponCodes: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.generateCouponCodes(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: GENERATE_COUPON_CODES_REQUEST, request: request } }
        function success(request, response) { return { type: GENERATE_COUPON_CODES_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GENERATE_COUPON_CODES_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
    * Action to get running promos
    * @param {Object} params - The params which are used for the api
    * @author Jasin
    * @created_at 30 May 2022
    */
    getRunningPromos: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PromoServices.getRunningPromos(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: GET_RUNNING_PROMOS_REQUEST, request: request } }
        function success(request, response) { return { type: GET_RUNNING_PROMOS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_RUNNING_PROMOS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
};
