import { Business, SupportAgent, DynamicFeed, Home, LocalOffer, Notifications, Password, People, Person, RowingOutlined, Search, Message, LocalActivity, CalendarMonth, Schedule, CurrencyRupee, Restaurant, Aod, Instagram, TableRestaurant, AttachMoney } from "@mui/icons-material";
import { Dashboard } from "../app/post-signin/dashboard";
import { ChangePassword, Profile, ProfileDetails, BillingAddress, ActivityLog, Notifications as NotificationList, UpdateBillingAddress } from "../app/post-signin/profile";
import { CreateOrUpdatePromo, Promos } from "../app/post-signin/ad-run/promos";
import { Stories, StoriesDetail } from "../app/post-signin/ad-run/stories";
import { Messages } from "../app/post-signin/instagram/messages";
import { Influencers } from "../app/post-signin/influencer";
import { InfluencerDetails } from "../app/post-signin/influencer/influencer-details";
import { Tickets, CreateOrUpdateTicket, TicketDetails } from "../app/post-signin/tickets";
import { Search as SearchModule } from "../app/post-signin/search";
import { PromoDetails } from "../app/post-signin/ad-run/promos/promo-details/PromoDetails";
import { AddPassword } from "../app/post-signin/profile/add-password/AddPassword";
import { CreateOrUpdateGiveaway, GiveawayDetails, Giveaways } from "../app/post-signin/ad-run/giveaways";
import { GiveawaysCouponCodes } from "../app/post-signin/ad-run/giveaways/giveaways-coupon-codes";
import { Calendar } from "../app/post-signin/calendar/Calendar";
import { CreateOrUpdateSchedulePost, SchedulePost, SchedulePostDetails, SchedulePostSpecifications } from "../app/post-signin/instagram/schedule-post";
import { BrandWallet, Wallet, WalletStatements } from "../app/post-signin/wallet";
import { GenerateCouponCodes } from "../app/post-signin/ad-run/promos/promos-generate-coupon-codes";
import { RestaurantMenu, CreateRestaurantMenu, RestaurantMenuDetails, TodaysRestaurantOrdersTab, OrderMenu, RestaurantCart } from '../app/post-signin/restaurant';
import { RestaurantTables, CreateRestaurantTables } from '../app/post-signin/restaurant';
import { RestaurantOrders, RestaurantOrdersDetails, RestaurantOrderHistory, TodaysRestaurantOrder } from "../app/post-signin/restaurant";
import { CreateUser, User } from "../app/post-signin/users";
import { Currency } from "../app/post-signin/currency";
import { CreateCurrency } from "../app/post-signin/currency/create-currency";
import { ErrorElement, CurrencySymbol } from "../_components";

/**
 * path: the URL path of the route
 * name: the name of the route, used for display purposes
 * element: the React component to be rendered when the route is accessed
 * layout: the layout component to be used for rendering the route
 * isMenuItem: a boolean flag to indicate whether the route should be displayed in a menu
 * menuIcon: the icon to be used for the route in the menu
 * module: the module that the route belongs to
 * isModuleMenuItem: a boolean flag to indicate whether the route should be displayed in the module's menu
 * errorElement: the React component to be rendered when an error occurs while rendering the route
 * menuPermission: the permission required to access the route
 */

const allRoutes = {
	/** begin::welcome module */
	welcome: {
		path: '/welcome',
		name: 'Welcome Brand',
		element: <Dashboard />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: Home,
		module: 'welcome',
		isModuleMenuItem: false,
		menuPermission: 'brand_dashboard',
		errorElement: <ErrorElement />
	},
	/** end::welcome module */

	/** begin::profile module */
	profile: {
		path: "/profile/:type?",
		name: "Profile",
		element: <Profile />,
		layout: "/post-auth",
		isMenuItem: false,
		menuIcon: Person,
		module: 'profile',
		isModuleMenu: true,
		isModuleMenuItem: false,
		errorElement: <ErrorElement />,
	},
	profile_details: {
		path: "/profile/details",
		name: "Profile",
		element: <ProfileDetails />,
		layout: "/post-auth",
		isMenuItem: false,
		menuIcon: Person,
		module: 'profile',
		isModuleMenuItem: true,
		errorElement: <ErrorElement />,
		parentRoute: 'profile'
	},
	/** end::profile module */

	/** begin::notifications module */
	notifications: {
		path: '/profile/notifications',
		name: 'Notifications',
		element: <NotificationList />,
		layout: '/post-auth',
		menuIcon: Notifications,
		isMenuItem: false,
		module: 'profile',
		isModuleMenuItem: true,
		errorElement: <ErrorElement />,
		parentRoute: 'profile'
	},
	/** end::notifications module */

	/** begin::activity log module */
	activity_log: {
		path: '/profile/activity',
		name: 'Activity Log',
		element: <ActivityLog />,
		layout: '/post-auth',
		menuIcon: RowingOutlined,
		isMenuItem: false,
		module: 'profile',
		isModuleMenuItem: true,
		errorElement: <ErrorElement />,
		parentRoute: 'profile'
	},
	/** end::activity log module */

	/** begin::change-password module */
	change_password: {
		path: '/profile/change-password',
		name: 'Change Password',
		element: <ChangePassword />,
		layout: '/post-auth',
		menuIcon: Password,
		isMenuItem: false,
		module: 'profile',
		isModuleMenuItem: true,
		parentRoute: 'profile'
	},
	/** end::change-password module */

	/** begin::set-password module */
	set_password: {
		path: '/profile/set-password',
		name: 'Set Password',
		element: <AddPassword />,
		layout: '/post-auth',
		menuIcon: Password,
		isMenuItem: false,
		module: 'profile',
		isModuleMenuItem: true,
		parentRoute: 'profile'
	},
	/** end::set-password module */

	/** begin::billing address module */
	billing_address: {
		path: '/profile/billing-address',
		name: 'Billing Address',
		element: <BillingAddress />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: Business,
		module: 'profile',
		isModuleMenuItem: true,
		parentRoute: 'profile'
	},
	update_billing_address: {
		path: '/profile/billing-address/update',
		name: 'Update Billing Address',
		element: <UpdateBillingAddress />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: Business,
		module: 'profile',
		isModuleMenuItem: false,
		isMainElement: true,
		parentRoute: 'profile'
	},
	/** end::billing address module */

	/** begin::account status module */
	// 'account_status': {
	//     path: "/profile/status",
	//     name: "Account Status",
	//     element: AccountStatus,
	//     layout: "/post-auth",
	//     menuIcon: Warning,
	//     isMenuItem: false,
	//     module: 'profile',
	//     isModuleMenuItem: true
	// },
	/** end::account status module */

	/** begin::home module */
	home: {
		path: '/',
		name: 'Dashboard',
		element: <Dashboard />,
		layout: '/post-auth',
		isMenuItem: true,
		menuIcon: Home,
		module: 'dashboard',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_dashboard',
	},
	/** end::home module */

	/** begin::promos module */
	promos: {
		path: '/promos',
		name: 'Promos',
		element: <Promos />,
		layout: '/post-auth',
		isMenuItem: true,
		menuIcon: LocalOffer,
		module: 'promos',
		isModuleMenuItem: true,
		isMainElement: true,
		hasSeparation: true,
		menuPermission: 'brand_promos',
		errorElement: <ErrorElement />
	},
	create_promos: {
		path: '/promos/create',
		name: 'Create Promo',
		element: <CreateOrUpdatePromo />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: LocalOffer,
		module: 'promos',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_promos_create',
		errorElement: <ErrorElement />
	},
	update_promos: {
		path: '/promos/update',
		name: 'Update Promo',
		element: <CreateOrUpdatePromo />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: LocalOffer,
		module: 'promos',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_promos_update',
		errorElement: <ErrorElement />
	},
	promo_details: {
		path: '/promos/details',
		name: 'Promo Details',
		element: <PromoDetails />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: LocalOffer,
		module: 'promos',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_promos_details',
		errorElement: <ErrorElement />,
	},
	generate_coupons: {
		path: '/promos/coupons/generate',
		name: 'Generate Coupon Codes',
		element: <GenerateCouponCodes />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: LocalOffer,
		module: 'promos',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_promos_generate_coupon',
		errorElement: <ErrorElement />,
	},
	/** end::promos module */

	/** begin::giveaways module */
	giveaways: {
		path: '/giveaways',
		name: 'Giveaways',
		element: <Giveaways />,
		layout: '/post-auth',
		isMenuItem: true,
		menuIcon: LocalActivity,
		module: 'giveaways',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_giveaway',
		errorElement: <ErrorElement />,
	},
	create_giveaways: {
		path: '/giveaways/create',
		name: 'Create Giveaway',
		element: <CreateOrUpdateGiveaway />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: LocalActivity,
		module: 'giveaways',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_giveaway_create',
		errorElement: <ErrorElement />,
	},
	update_giveaways: {
		path: '/giveaways/update',
		name: 'Update Giveaway',
		element: <CreateOrUpdateGiveaway />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: LocalActivity,
		module: 'giveaways',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_giveaway_update',
		errorElement: <ErrorElement />,
	},
	giveaway_details: {
		path: '/giveaways/details',
		name: 'Giveaway Details',
		element: <GiveawayDetails />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: LocalActivity,
		module: 'giveaways',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_giveaway_details',
		errorElement: <ErrorElement />,
	},
	giveaways_coupon_codes: {
		path: '/giveaways/codes',
		name: 'Giveaway Coupon Codes',
		element: <GiveawaysCouponCodes />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: LocalActivity,
		module: 'giveaways',
		isModuleMenuItem: false,
		isMainElement: true,
		errorElement: <ErrorElement />,
	},
	/** end::giveaways module */

	/** begin::stories module */
	stories: {
		path: '/stories',
		name: 'Stories',
		element: <Stories />,
		layout: '/post-auth',
		isMenuItem: true,
		menuIcon: DynamicFeed,
		module: 'stories',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_stories',
		errorElement: <ErrorElement />
	},
	stories_detail: {
		path: '/stories/detail',
		name: 'Stories Detail',
		element: <StoriesDetail />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: DynamicFeed,
		module: 'stories',
		isModuleMenuItem: false,
		isMainElement: true,
		menuPermission: 'brand_stories_details',
		errorElement: <ErrorElement />
	},
	/** end::stories module */

	/** begin::influencers module */
	influencer: {
		path: '/influencers',
		name: 'Influencers',
		element: <Influencers />,
		layout: '/post-auth',
		isMenuItem: true,
		menuIcon: People,
		module: 'influencer',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_influencer',
		errorElement: <ErrorElement />,
	},
	influencer_details: {
		path: '/influencers/details',
		name: 'Influencer Details',
		element: <InfluencerDetails />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: People,
		module: 'influencer',
		isModuleMenuItem: true,
		isMainElement: true,
		hasSeparation: true,
		menuPermission: 'brand_influencer_details',
		errorElement: <ErrorElement />,
	},
	/** end::influencers module */

	/** begin::schedule post module */
	'instagram': {
		path: "/instagram",
		name: "Instagram",
		layout: "/post-auth",
		isMenuItem: true,
		menuIcon: Instagram,
		module: 'instagram',
		isModuleMenuItem: true,
		menuPermission: 'ard',
		errorElement: <ErrorElement />,
		hasSeparation: true
	},
	schedule_post: {
		path: '/instagram/schedule-post',
		name: 'Schedule Post',
		element: <SchedulePost />,
		layout: '/post-auth',
		isMenuItem: true,
		menuIcon: Schedule,
		module: 'schedule_post',
		isModuleMenuItem: true,
		isMainElement: true,
		hasSeparation: true,
		menuPermission: 'brand_schedule_post',
		errorElement: <ErrorElement />,
		parentRoute: 'instagram'
	},
	schedule_post_details: {
		path: '/instagram/schedule-post/details',
		name: 'Schedule Post Details',
		element: <SchedulePostDetails />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: Schedule,
		module: 'schedule_post',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_schedule_post_details',
		errorElement: <ErrorElement />,
		parentRoute: 'instagram'
	},
	create_schedule_post: {
		path: '/instagram/schedule-post/create',
		name: 'Create Post',
		element: <CreateOrUpdateSchedulePost />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: Schedule,
		module: 'schedule_post',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_schedule_post_create',
		errorElement: <ErrorElement />,
		parentRoute: 'instagram'
	},
	update_schedule_post: {
		path: '/instagram/schedule-post/update',
		name: 'Update Post',
		element: <CreateOrUpdateSchedulePost />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: Schedule,
		module: 'schedule_post',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_schedule_post_update',
		errorElement: <ErrorElement />,
		parentRoute: 'instagram'
	},
	schedule_post_specifications: {
		path: '/instagram/schedule-post/specifications',
		name: 'Media Specifications',
		element: <SchedulePostSpecifications />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: Schedule,
		module: 'schedule_post',
		isModuleMenuItem: true,
		isMainElement: true,
		errorElement: <ErrorElement />,
		parentRoute: 'instagram'
	},
	/** end::schedule post module */

	/** begin::insights module */
	// 'insights': {
	//     path: "/insights",
	//     name: "Insights",
	//     element: IgInsights,
	//     layout: "/post-auth",
	//     isMenuItem: true,
	//     menuIcon: Insights,
	//     module: 'insights',
	//     isModuleMenuItem: true,
	//     isMainElement: true,
	//     hasSeparation: true
	// },
	// 'insights_charts': {
	//     path: "/insights/chart",
	//     name: "Insights",
	//     element: InsightsCharts,
	//     layout: "/post-auth",
	//     isMenuItem: false,
	//     menuIcon: Insights,
	//     module: 'insights',
	//     isModuleMenuItem: true,
	//     isMainElement: true,
	//     hasSeparation: true
	// },
	/** begin::insights module */

	/** begin::messages module */
	messages: {
		path: '/instagram/messages',
		name: 'Messages',
		element: <Messages />,
		layout: '/post-auth',
		isMenuItem: true,
		menuIcon: Message,
		module: 'messages',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_messages',
		errorElement: <ErrorElement />,
		parentRoute: 'instagram'
	},
	/** begin::insights module */

	/** begin::calendar module */
	calendar: {
		path: '/calendar',
		name: 'Calendar',
		element: <Calendar />,
		layout: '/post-auth',
		isMenuItem: true,
		menuIcon: CalendarMonth,
		module: 'calendar',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_calender',
		errorElement: <ErrorElement />,
		hasSeparation: true,
	},
	/** begin::insights module */

	/** begin::plans module */
	// 'plans': {
	//     path: "/plans",
	//     name: "Plans",
	//     element: Plans,
	//     layout: "/post-auth",
	//     isMenuItem: true,
	//     menuIcon: Payments,
	//     module: 'plans',
	//     isModuleMenuItem: true,
	//     isMainElement: true,
	//     hasSeparation: true
	// },
	// 'payment_preferences': {
	//     path: "/plans/payment-preferences",
	//     name: "Payment Preferences",
	//     element: PaymentMethod,
	//     layout: "/post-auth",
	//     isMenuItem: false,
	//     menuIcon: Payments,
	//     module: 'plans',
	//     isModuleMenuItem: false,
	//     isMainElement: true
	// },
	// /** end::plans module */

	// /** begin::invoice module */
	// 'invoice': {
	//     path: "/invoice",
	//     name: "Invoice",
	//     element: Invoice,
	//     layout: "/post-auth",
	//     isMenuItem: true,
	//     menuIcon: CreditScore,
	//     module: 'invoice',
	//     isModuleMenuItem: true,
	//     isMainElement: true,
	// },

	// 'invoice_details': {
	//     path: "/invoice/details",
	//     name: "Invoice Details",
	//     element: InvoiceDetails,
	//     layout: "/post-auth",
	//     isMenuItem: false,
	//     menuIcon: CreditScore,
	//     module: 'invoice',
	//     isModuleMenuItem: true,
	//     isMainElement: true,
	//     hasSeparation: true
	// },
	/** end::invoice module */

	/** begin::search coupon module */
	search: {
		path: '/search',
		name: 'Search',
		element: <SearchModule />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: Search,
		module: 'search',
		isModuleMenuItem: false,
		isMainElement: true,
		hasSeparation: true,
		menuPermission: 'brand_search',
		errorElement: <ErrorElement />,
	},
	/** end::search coupon module */

	/** begin::tickets module */
	tickets: {
		path: '/tickets',
		name: 'Support Center',
		element: <Tickets />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: SupportAgent,
		module: 'tickets',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_support_center',
		errorElement: <ErrorElement />,
	},
	create_ticket: {
		path: '/tickets/create',
		name: 'Create Ticket',
		element: <CreateOrUpdateTicket />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: SupportAgent,
		module: 'tickets',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_support_center_create',
		errorElement: <ErrorElement />,
	},
	update_ticket: {
		path: '/tickets/update',
		name: 'Update Ticket',
		element: <CreateOrUpdateTicket />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: SupportAgent,
		module: 'tickets',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_support_center_update',
		errorElement: <ErrorElement />,
	},
	ticket_details: {
		path: '/tickets/detail',
		name: 'Ticket Detail',
		element: <TicketDetails />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: SupportAgent,
		module: 'tickets',
		isModuleMenuItem: false,
		isMainElement: true,
		menuPermission: 'brand_support_center_details',
		errorElement: <ErrorElement />,
	},
	/** end::tickets module */

	/** begin::wallet module */
	wallet: {
		path: '/wallet',
		name: 'Wallet',
		element: <Wallet />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: CurrencySymbol,
		module: 'wallet',
		isModuleMenu: true,
		isModuleMenuItem: false,
		isMainElement: true,
		errorElement: <ErrorElement />,
	},
	wallet_statements: {
		path: '/wallet/statements',
		name: 'Wallet Statements',
		element: <WalletStatements />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: CurrencySymbol,
		module: 'wallet',
		isModuleMenuItem: true,
		isMainElement: true,
		errorElement: <ErrorElement />,
	},
	/** end::wallet module */

	/** begin::restaurant tables module */
	restaurant_tables: {
		path: '/restaurant/tables',
		name: 'Restaurant Tables',
		element: <RestaurantTables />,
		layout: '/post-auth',
		isMenuItem: true,
		menuIcon: TableRestaurant,
		module: 'restaurant_menu',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_restaurant_menu_table',
		errorElement: <ErrorElement />,
	},
	create_restaurant_tables: {
		path: '/restaurant/tables/create',
		name: 'Create Table',
		element: <CreateRestaurantTables />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: Restaurant,
		module: 'restaurant_menu',
		isModuleMenuItem: true,
		isMainElement: true,
		errorElement: <ErrorElement />,
	},
	update_restaurant_tables: {
		path: '/restaurant/tables/update',
		name: 'Update Table',
		element: <CreateRestaurantTables />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: Restaurant,
		module: 'restaurant_menu',
		isModuleMenuItem: true,
		isMainElement: true,
		errorElement: <ErrorElement />,
	},
	/** end::restaurant tables module */

	/** begin::restaurant menu module */
	restaurant_menu: {
		path: '/restaurant/menu',
		name: 'Restaurant Menu',
		element: <RestaurantMenu />,
		layout: '/post-auth',
		isMenuItem: true,
		menuIcon: Restaurant,
		module: 'restaurant_menu',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_restaurant_menu',
		errorElement: <ErrorElement />,
	},
	create_restaurant_menu: {
		path: '/restaurant/menu/create',
		name: 'Create Menu',
		element: <CreateRestaurantMenu />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: Restaurant,
		module: 'restaurant_menu',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_restaurant_menu_create',
		errorElement: <ErrorElement />,
	},
	update_restaurant_menu: {
		path: '/restaurant/menu/update',
		name: 'Update Restaurant Menu',
		element: <CreateRestaurantMenu />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: Restaurant,
		module: 'restaurant_menu',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_restaurant_menu_update',
		errorElement: <ErrorElement />,
	},
	restaurant_menu_details: {
		path: '/restaurant/menu/details',
		name: 'Menu Details',
		element: <RestaurantMenuDetails />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: Restaurant,
		module: 'restaurant_menu',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_restaurant_menu_details',
		errorElement: <ErrorElement />,
	},
	/** end::restaurant menu module */

	/** begin::restaurant orders module */
	restaurant_orders: {
		path: '/restaurant/orders',
		name: 'Restaurant Orders',
		element: <RestaurantOrders />,
		layout: '/post-auth',
		isMenuItem: true,
		menuIcon: Aod,
		module: 'restaurant_orders',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_restaurant_order',
		errorElement: <ErrorElement />,
	},
	restaurant_order_details: {
		path: '/restaurant/orders/details',
		name: 'Restaurant Order Details',
		element: <RestaurantOrdersDetails />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: Aod,
		module: 'restaurant_orders',
		isModuleMenuItem: true,
		isMainElement: true,
		errorElement: <ErrorElement />,
	},
	restaurant_order_history: {
		path: '/restaurant/orders/history',
		name: 'Restaurant Order History',
		element: <RestaurantOrderHistory />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: Aod,
		module: 'restaurant_orders_history',
		isModuleMenuItem: true,
		isMainElement: true,
		errorElement: <ErrorElement />,
	},
	todays_restaurant_pre_order: {
		path: '/restaurant/orders/todays-order',
		name: 'Todays Restaurant Pre Orders ',
		element: <TodaysRestaurantOrdersTab />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: Aod,
		module: 'restaurant_orders',
		isModuleMenuItem: true,
		isMainElement: true,
		errorElement: <ErrorElement />,
	},
	order_menu: {
		path: '/restaurant/orders/create-order/menu',
		name: 'Menu',
		element: <OrderMenu />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: Aod,
		module: 'restaurant_orders',
		isModuleMenuItem: true,
		isMainElement: true,
		errorElement: <ErrorElement />,
	},
	/** end::restaurant orders module */


	/** begin::user module */
	users: {
		path: '/users',
		name: 'Users',
		element: <User />,
		layout: '/post-auth',
		isMenuItem: true,
		menuIcon: LocalOffer,
		module: 'users',
		isModuleMenuItem: true,
		isMainElement: true,
		hasSeparation: true,
	},
	create_users: {
		path: '/users/create',
		name: 'Create User',
		element: <CreateUser />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: LocalOffer,
		module: 'users',
		isModuleMenuItem: true,
		isMainElement: true,
	},
	update_users: {
		path: '/users/update',
		name: 'Update User',
		element: <CreateUser />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: LocalOffer,
		module: 'users',
		isModuleMenuItem: true,
		isMainElement: true,
	},

	/** end::user module */

	/** begin::currency module */
	currency: {
		path: '/currency',
		name: 'Currency',
		element: <Currency />,
		layout: '/post-auth',
		isMenuItem: true,
		menuIcon: AttachMoney,
		module: 'currency',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_currency',
		errorElement: <ErrorElement />,
	},
	create_currency: {
		path: '/currency/create',
		name: 'Create Currency',
		element: <CreateCurrency />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: AttachMoney,
		module: 'currency',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_create_currency',
		errorElement: <ErrorElement />,
	},
	update_currency: {
		path: '/currency/update',
		name: 'Update Currency',
		element: <CreateCurrency />,
		layout: '/post-auth',
		isMenuItem: false,
		menuIcon: AttachMoney,
		module: 'currency',
		isModuleMenuItem: true,
		isMainElement: true,
		menuPermission: 'brand_update_currency',
		errorElement: <ErrorElement />,
	},
	/** end::currency module */
};


/**
 * The function returns all routes or a specific set of routes based on the key type provided.
 * @param {String} [keyType=null] The parameter `keyType` is an optional parameter that can be passed to
 * the `postAuthRoutes` function. If a `keyType` is provided, the function will return only the routes associated
 * with that `keyType` from the `allRoutes` object.
 * @return {Object} The function takes an optional parameter `keyType` and returns either all the routes in the
 * `allRoutes` object if `keyType` is not provided or the routes corresponding to the `keyType` if it is provided.
 * @author Akshay N
 * @created_at 21/05/2023
 */
const postAuthRoutes = (keyType = null) => {
	return keyType ? allRoutes[keyType] : allRoutes;
};

/**
 * This function filters all routes based on whether they are menu items or module menu items with a  specific module,
 * and returns the filtered routes with modified paths.
 * @param {String} [module=false] - The `module` parameter is an optional string parameter that specifies the name of
 * a module.  If provided, the function will filter the routes to only include those that have `isModuleMenuItem` set to
 * `true` and whose `module` property matches the provided `module` string.
 * @returns {Object} The function `postAuthMenuRoutes` is returning an array of route objects that have either `isMenuItem`
 * or `isModuleMenuItem` properties set to `true`.If the `module` parameter is provided, it filters the routes to only
 * include those with `isModuleMenuItem` set to `true` and matching the  provided `module` name.
 * @author Akshay N
 * @created_at 02/04/2023
 */
const postAuthMenuRoutes = (module = false) => {
	/* The below code is a JavaScript function that filters an array of objects representing routes.
	The function takes an optional argument `module`, which is used to filter the routes based on
	whether they are module menu items or regular menu items. */
	return Object.values(allRoutes).filter(item => {
		return !module
			? (item.isMenuItem === true)
			: (item.isModuleMenuItem === true && item.module === module)
				? { ...item, path: item.path.split("/").pop() }
				: null;
	});
};

/**
 * The function creates an array of post-authentication menu bar routes by iterating over an object of routes
 * and organizing them based on their parent routes.
 * @param {String} [module=false] - This is the module name. If you want to get all the menu items, then pass
 * false. If you want to get all the menu items for a specific module, then pass the module name.
 * @return {Array} The function `postAuthMenuBarRoutes` is returning an array of objects that represent the menu bar
 * routes for a post-authentication user.  The objects in the array contain information about each route, such as
 * the route's name, path, and whether it is a child of another route. The function iterates over an input object called
 * `allRoutes`, filters out routes that are not menu items, and organizes the remaining routes into return array.
 * @author Akshay N
 * @created_at 02/04/2023
 */
const postAuthMenuBarRoutes = () => {

	const postSignInRoutes = allRoutes;

	let postRoutes = [];

	// Iterating over the object.
	for (const key in postSignInRoutes) {

		// Checking if the object has the property.
		if (postSignInRoutes.hasOwnProperty(key)) {
			if (postSignInRoutes[key].isMenuItem) {

				// Checking if the route has a parent route.
				if (postSignInRoutes[key].parentRoute) {

					// Finding the index of the parent route in the array of objects.
					const parentRoute = postSignInRoutes[key].parentRoute;
					var postRouteIndex = postRoutes.findIndex(function (route) {
						return route.slug === parentRoute;
					});

					// Checking if the parent route has any children. If not, it is creating an empty array
					// and pushing the child route into it. */
					if (!postRoutes[postRouteIndex]['children'])
						postRoutes[postRouteIndex]['children'] = [];

					postRoutes[postRouteIndex]['children'].push({ ...postSignInRoutes[key], slug: key });

				} else
					postRoutes.push({ ...postSignInRoutes[key], slug: key });
			}
		}
	}

	return postRoutes;
};

/**
 * This function returns the name of a post element based on its path.
 * @param {String} path - The `path` parameter is a string representing the URL path of a route in a web application.
 * @returns {String} The function `getPostElementNameByPath` returns the name of the element associated with the
 * given path, which is obtained by searching for the path in the `allRoutes` object and returning the
 * corresponding `name` property. If no matching path is found, an empty string is returned.
 * @author Akshay N
 * @created_at 02/04/2023
 */
const getPostElementNameByPath = (path) => {
	if (path) {
		const elementItem = Object.values(allRoutes).find(item => item.path === path);
		return elementItem ? elementItem.name : '';
	}
};

export { postAuthRoutes, postAuthMenuRoutes, postAuthMenuBarRoutes, getPostElementNameByPath };
