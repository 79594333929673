import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';


import { FormInput, FormFreeSoloAutocomplete } from '../../../../../_components/form';
import { Card, CardContent, CardHeader } from '../../../../../_components/card';
import { Grid, Stack, Box } from '@mui/material';
import { RestaurantMenuAction } from '../../../../../redux/actions';
import { validate } from './validate';
import { CancelButton, SubmitButton } from '../../../../../_components';
import { postAuthRoutes } from '../../../../../routes';
import { handleInputChange, validateForm } from '../../../../../_helpers';

// Initialize form input values to null
const inputs = { restaurant_table_id: '', short_code: '', name: '', category: '', description: '' };

function CreateRestaurantTables() {
	/** Initialize plugins and variables */
	const dispatch = useDispatch();
	const navigate = useNavigate();

	/** Plan id and details on update page */
	const location = useLocation();
	const { restaurantTable } = location.state ?? {};

	/** Redux actions and state */
	const createRestaurantTables = (params) => dispatch(RestaurantMenuAction.createRestaurantTables(params));
	const updateRestaurantTables = (params) => dispatch(RestaurantMenuAction.updateRestaurantTables(params));
	const getRestaurantTableCategories = (params) => dispatch(RestaurantMenuAction.getRestaurantTableCategories(params));
	const { create_restaurant_tables_loading, create_restaurant_tables_errors, restaurant_table_category_list, restaurant_table_category_list_loading } = useSelector((state) => state.RestaurantMenuReducer);

	/** Initialize and declare state */
	const [data, setData] = useState({ ...inputs });
	const [errors, setErrors] = useState({ ...inputs });
	const [action, setAction] = useState({ isSubmitted: false });
	const [response, setResponse] = useState({});

	useEffect(() => {
		setErrors({ ...create_restaurant_tables_errors });
	}, [create_restaurant_tables_errors]);

	/**
	 * function to handle input changes and modify the value
	 * @param {string} null
	 * @author Naveen K
	 * @created_at 10 Oct 2023
	 */
	function handleChange(e) {
		const { name, value } = handleInputChange(e);
		setErrors({ ...errors, ...validate(name, value, data) });
		data[name] = value;
		setData({ ...data });
	}

	useEffect(() => {
		if (restaurantTable && restaurantTable._id) {
			setData({
				restaurant_table_id: restaurantTable._id,
				short_code: restaurantTable.short_code,
				name: restaurantTable.name,
				category: restaurantTable.category,
				description: restaurantTable.description,
			});
		}
	}, [restaurantTable]);

	useEffect(() => {
		getRestaurantTableCategories();
	}, []);

	/**
	 * function to handle submit entered values
	 * @param {object} e form object
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	function handleSubmit(e) {
		e.preventDefault();
		setAction({ isSubmitted: true });

		const formErrors = {};
		for (const [name, value] of Object.entries(data)) {
			const inputError = validate(name, value, data);
			formErrors[name] = inputError[name];
		}
		let valid = validateForm(formErrors);
		if (!valid) {
			setErrors(formErrors);
			setAction({ isSubmitted: true });
			return;
		}

		addRestaurantTable(data);
	}

	/**
	 * function to create/update restaurant table
	 * @param {object} formData form object
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	async function addRestaurantTable(formData) {
		const isCreated = restaurantTable && restaurantTable._id ? await updateRestaurantTables(formData) : await createRestaurantTables(formData);
		setResponse(isCreated);
	}

	/**
	 * Function to cancel create/update restaurant table
	 * @param {object} formData form object
	 * @author Naveen K
	 * @created_at 09 Oct 2023
	 */
	function cancelTableCreate() {
		let redirectPath = postAuthRoutes('restaurant_tables').path;
		navigate(redirectPath);
	}

	useEffect(() => {
		if (response.status === 1) {
			navigate(postAuthRoutes('restaurant_tables').path);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [response]);

	return (
		<Box py={3}>
			<CardHeader title={restaurantTable && restaurantTable._id ? postAuthRoutes('update_restaurant_tables').name : postAuthRoutes('create_restaurant_tables').name} />
			<form onSubmit={handleSubmit} noValidate>
				<Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
					<Grid item xs={12} md={6} lg={6}>
						<Card >
							<CardContent >
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<FormInput
											tabIndex={2}
											label='Name'
											name='name'
											value={data.name}
											error={action.isSubmitted && (errors.name ? errors.name : '')}
											onChange={handleChange}
											autoFocus={true}
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<FormInput
											tabIndex={1}
											label='Short code'
											name='short_code'
											value={data.short_code}
											error={action.isSubmitted && (errors.short_code ? errors.short_code : '')}
											onChange={handleChange}
											maskType='alphanumeric-with-hyphen'
										/>
									</Grid>
									{!restaurant_table_category_list_loading && (
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<FormFreeSoloAutocomplete
												name="category"
												label="Category"
												value={data.category}
												onChange={handleChange}
												type="text"
												maxLength={255}
												tabIndex={3}
												error={action.isSubmitted && (errors.category ? errors.category : '')}
												inputOptions={restaurant_table_category_list}
											/>
										</Grid>
									)}
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<FormInput
											tabIndex={4}
											label='Description'
											name='description'
											value={data.description}
											error={action.isSubmitted && (errors.description ? errors.description : '')}
											onChange={handleChange}
											multiline
											maxLength={1000}
											minRows={2}
										/>
									</Grid>
									<Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
										<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
											<SubmitButton label={restaurantTable && restaurantTable._id ? 'Update' : 'Create'} loading={create_restaurant_tables_loading} tabIndex={5} />
											<CancelButton onClick={cancelTableCreate} tabIndex={6} />
										</Stack>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</form>
		</Box>
	);
}

export { CreateRestaurantTables };
