import paper from "../../../_root/components/paper/paper";
import linearGradient from "../../../_root/functions/linearGradient";
import borders from "../../base/borders";
import { alpha, darken } from "@mui/material";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ...paper,
    styleOverrides: {
        ...paper.styleOverrides,
        root: {
            ...paper.styleOverrides.root,
            backgroundColor: `none`,
            background: linearGradient(alpha(colors.background.card, 1), darken(colors.background.card, 0.4)),
            border: `${borders.borderWidth[1]} solid ${borders.borderColor}`,
        }
    },
};
