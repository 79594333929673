import React from 'react'
import { useLocation, Outlet } from 'react-router';
import { Link } from 'react-router-dom'

import { Container, Grid, IconButton, Menu, Box } from '@mui/material'
import { MenuList, MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material';

import { postAuthMenuRoutes } from '../../../routes';

function Profile() {


    /** Initialize plugins and variables */
    const location = useLocation();
    const menuRoutes = Object.values(postAuthMenuRoutes('profile'));

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Box>
                        <MenuList >
                            {menuRoutes.map((route, index) => (
                                <MenuItem key={index} component={Link} to={route.path} className={(route.path === location.pathname) ? `active` : ``}>
                                    <ListItemIcon>
                                        <route.menuIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>{route.name}</ListItemText>
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Outlet />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}




export { Profile };
