import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { FormControl, FormHelperText } from "@mui/material";
import React from "react";

const FormRadio = ({ name, label, data, className, value, onChange, isReadOnly, tabIndex, error, info, ...props }) => {

    const handleChange = (e) => { onChange(e); }

    return (
        <FormControl fullWidth  >
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <RadioGroup row aria-labelledby={label} name={name} value={value} onChange={handleChange}>
                {data.map((radio, index) => {
                    return <FormControlLabel key={index} value={radio.value} disabled={isReadOnly} control={<Radio size="small" />} label={radio.label} />
                })}
            </RadioGroup>
            {(info) && <FormHelperText id={`helper_${name}`}>{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error>{error}</FormHelperText>}
        </FormControl>
    );
};


export { FormRadio };
