// eslint-disable-next-line import/no-anonymous-default-export
export default {
	defaultProps: {},
	styleOverrides: {
		root: {
			marginLeft: `0`,
			marginTop: `15`,
			padding: `0`
		}
	},
	variants: []
};
