import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Card, CardContent, CardHeader } from '../../../../../../_components/card';
import { TodaysItemQuantityDataTable, TodaysItemQuantityPdf } from '.';

function TodaysItemQuantity() {

    /** Initialize plugins and variables */
	const { search } = useLocation();

	const searchParams = new URLSearchParams(search);

	/** Redux reducer state */

	const [payload, setPayload] = useState({ limit: 100, page: 1, per_page: 10, filter: { mobile: searchParams.get('mobile') ?? ''}, search: searchParams.get('q') ?? '' });


    return (
		<React.Fragment>
				<Box>
					<CardHeader
						action={
							<Stack spacing={1} direction="row">
								<TodaysItemQuantityPdf />
							</Stack>
						}
					/>
				</Box>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Card>
							<CardContent>
							
								<TodaysItemQuantityDataTable filter={payload.filter} search={payload.search} limit={payload.limit} page={payload.page} per_page={payload.per_page}/>
							</CardContent>
						</Card>
					</Grid>
				</Grid>

		</React.Fragment>
	)
}

export { TodaysItemQuantity };