import React, { useEffect } from 'react'
import { Avatar, Badge, Chip, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import { Card, CardContent, CardHeader } from '../../../../_components/card'
import { Box } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux';
import { AddCircle, CheckCircle, InsertEmoticon, Instagram, LocalPhone, MailOutline } from '@mui/icons-material';
import { StatisticsWidget } from '../../../../_components/widgets';
import { makeStyles } from '@mui/styles';
import { UpdateLogoModal } from './update-logo';
import { useState } from 'react';
import { PlanCard } from '../../plans/plan-card/PlanCard';
import { RestaurantMenuAction } from '../../../../redux/actions';


const useStyles = makeStyles((theme) => ({
    fullSizeIcon: {
        border: '3px solid #333'
    },
    circularBadge: {
        borderRadius: '50%', // Ensures the Badge stays circular
        cursor: 'pointer',
    },
}));


function ProfileDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { user_loading: isLoading, user } = useSelector((state) => state.UserReducer);
    const [showUpdateLogoModal, setShowUpdateLogoModal] = useState(false);
    const { gst_details, gst_details_loading: isGstLoading } = useSelector((state) => state.RestaurantMenuReducer);
    const getGstDetails = (params) => dispatch(RestaurantMenuAction.getGstDetails(params));

    const classes = useStyles();

    /**
     * function to open/close FAQ delete modal
     * @return view
     * @author Naveen
     * @created_at 10 Jan 2023
     */
    const updateLogoModal = async () => {
        setShowUpdateLogoModal(!showUpdateLogoModal)
    }

            
    useEffect(() => {
        getGstDetails();
    }, [])


    if (!isLoading)
        return (
            <Box>
                <Card>
                    <CardContent>
                        <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Badge onClick={() => updateLogoModal()} overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} badgeContent={<AddCircle fontSize='medium' />} classes={{ badge: classes.circularBadge }}>
                                        <Avatar sx={{ width: 150, height: 150 }} src={user.insta_profile.profile_picture_url}>
                                            {user.short_name}
                                        </Avatar>
                                    </Badge>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                    <Typography variant="h4">{user.name}</Typography>
                                    <Chip label={user.insta_profile.is_private ? 'Private' : 'Public'} color={user.insta_profile.is_private ? 'error' : 'success'} />
                                </Stack>
                                <Typography variant="h6" display={`flex`} alignItems='center'><Instagram color='primary' /> &nbsp;{`@${user.insta_handle}`}</Typography>
                                <Typography variant="h6" display={`flex`} alignItems='center'><MailOutline color='primary' />&nbsp;{user.email}&nbsp;{user.email_verify_status ? <CheckCircle color='success' size='small' /> : <CheckCircle color='secondary' size='small' />}</Typography>
                                <Typography variant="h6" display={`flex`} alignItems='center'><LocalPhone color='primary' /> &nbsp;{user.mobile_number}&nbsp;{user.mobile_verify_status ? <CheckCircle color='success' size='small' /> : <CheckCircle color='secondary' size='small' />}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Grid container spacing={3} mt={1}>
                    <Grid item xs={12} md={12} lg={12}><Box><StatisticsWidget color="dark" icon={<InsertEmoticon />} title="Followers" count={user.insta_profile.followers_count} /></Box></Grid>
                </Grid>
                {!isGstLoading && gst_details && Object.keys(gst_details).length > 0 && gst_details.configure_gst && <Grid container spacing={3} mt={1}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Card sx={{ p: { xs: `24px !important`, md: `70px !important` } }}>
                            <CardHeader title='GST Details' />
                            <CardContent>
                            <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <Stack spacing={1}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body2">{`CGST (%) : `}</Typography>
                                        <Typography variant="body2" align="right" >{gst_details.cgst}</Typography>
                                    </Box>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body2">{`SGST (%) : `}</Typography>
                                        <Typography variant="body2" align="right" >{gst_details.sgst}</Typography>
                                    </Box>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body2">{`GSTIN : `}</Typography>
                                        <Typography variant="body2" align="right" >{gst_details.gstin}</Typography>
                                    </Box>
                                    
                                </Stack>
                                </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>}

                {user.plan && <Grid container spacing={3} mt={1}>
                    <Grid item xs={12} md={6} lg={4}>
                        <PlanCard planDetails={user.plan} />
                    </Grid>
                </Grid>}


                <UpdateLogoModal show={showUpdateLogoModal} closeModal={updateLogoModal} />

            </Box>
        )
    else
        return (
            <Card>
                <CardContent>
                    <CircularProgress />
                </CardContent>
            </Card>
        )
}

export { ProfileDetails };
