import { apiServices } from "../../_helpers";


export const MessagesServices = {

    getConversationsList: (params) => {
        return apiServices.post('/brand/conversations/list', params)
            .then((response) => { return response; })
    },
    getConversationDetails: (params) => {
        return apiServices.post('/brand/conversations/details', params)
            .then((response) => { return response; })
    },
    sendDirectMessage: (params) => {
        return apiServices.post('/brand/conversations/send', params)
            .then((response) => { return response; })
    }


};
