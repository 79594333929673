import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Event } from '@mui/icons-material';
import { Box, CardMedia, Divider, Grid, Stack, Card, CardContent, Typography } from '@mui/material';
import { Fragment } from "react";
import { NoData } from "../../../../../_components/layout";
import { CardHeader } from '../../../../../_components/card';
import { CurrencySymbol } from '../../../../../_components/currency-symbol';
import { postAuthRoutes } from '../../../../../routes';
import { RestaurantMenuAction } from "../../../../../redux/actions";


function RestaurantMenuDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);

    const brandId = params.get('bId'); // restaurant id from url

    /** Redux actions and state */
    const { restaurant_menu_details_loading: isLoading, restaurant_menu_details } = useSelector((state) => state.RestaurantMenuReducer);
    const restaurantMenuDetails = (params) => dispatch(RestaurantMenuAction.restaurantMenuDetails(params));

    useEffect(() => {
        restaurantMenuDetails({ brand_id: brandId });
        //eslint-disable-next-line
    }, [brandId]);

    return (
        <Fragment>
            {!isLoading && Object.keys(restaurant_menu_details).length === 0 && <NoData content1={`No`} content2={`Restaurant Menu available`} minHeight={`55vh`} ></NoData>}
            {(!isLoading && Object.keys(restaurant_menu_details).length > 0) &&
                <Fragment>
                    <Box>
                        <CardHeader title={postAuthRoutes('restaurant_menu_details').name} />
                    </Box>
                    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2} display="flex" alignItems="center" justifyContent="center">
                        <Card style={{ height: '80%', width: '80%' }}>
                            <Grid container spacing={2} display="flex" alignItems="center">
                                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                    <Box style={{ width: `100%` }}>
                                        <CardMedia
                                            component={'img'}
                                            src={restaurant_menu_details.image}
                                            onError={(e) => {
                                                e.target.src = restaurant_menu_details.error_image_url;
                                            }}
                                            sx={{ position: 'relative', padding: 0, margin: 0, width: '100%', height: '900%', borderTopRightRadius: 0, borderBottomRightRadius: { xs: 0, sm: 0, md: 4 }, borderBottomLeftRadius: { xs: 0, sm: 0, md: 4 } }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                    <CardContent>
                                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} mb={1} mt={2}>
                                            <Typography variant="h5" sx={{ cursor: `pointer` }}>{`${restaurant_menu_details.title}`}</Typography>
                                        </Box>
                                        <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>{`${restaurant_menu_details.description}`}</Typography>
                                        <Divider />
                                        <Stack spacing={1}>
                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                <Typography variant="body2">{`Category : `}</Typography>
                                                <Typography variant="body2" align="right" style={{ whiteSpace: 'pre-line' }}>{`${restaurant_menu_details.category}`}</Typography>
                                            </Box>
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography variant="body2" align="left" style={{ minWidth: '35%' }}>{`Non Veg : `}</Typography>
                                                <Typography variant="body2" align="right" style={{ whiteSpace: 'pre-line' }}>{`${restaurant_menu_details.is_non === true ? ' Yes ' : ' No '}`}</Typography>
                                            </Box>
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography variant="body2" align="left" style={{ minWidth: '35%' }}>{`Price : `}</Typography>
                                                <Typography variant="body2" align="right" style={{ whiteSpace: 'pre-line' }}><CurrencySymbol />{`${restaurant_menu_details.price}`}</Typography>
                                            </Box>
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography variant="body2" align="left" style={{ minWidth: '35%' }}>{`Discount : `}</Typography>
                                                <Typography variant="body2" align="right" style={{ whiteSpace: 'pre-line' }}>{`${restaurant_menu_details.discount} %`}</Typography>
                                            </Box>
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography variant="body2" align="left" style={{ minWidth: '35%' }}>{`Item Category : `}</Typography>
                                                <Typography variant="body2" align="right" style={{ whiteSpace: 'pre-line' }}>{`${restaurant_menu_details.item_type}`}</Typography>
                                            </Box>
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography variant="body2" align="left" style={{ minWidth: '35%' }}>{`Does the item have portions? : `}</Typography>
                                                <Typography variant="body2" align="right" style={{ whiteSpace: 'pre-line' }}>{`${typeof restaurant_menu_details.have_portions === 'undefined' || restaurant_menu_details.have_portions ? 'Yes' : 'No'}`}</Typography>
                                            </Box>
                                            {restaurant_menu_details && restaurant_menu_details.portions && restaurant_menu_details.portions.length > 0 && (
                                                <Fragment>
                                                    <Divider />
                                                    <Typography variant="body2" align="center" style={{ minWidth: '35%' }}>{`Portions`}</Typography>
                                                    {typeof restaurant_menu_details.have_portions !== 'undefined' &&
                                                        restaurant_menu_details.have_portions &&
                                                        restaurant_menu_details.portions.map((portions, i) => {
                                                            return (
                                                                <Box display="flex" justifyContent="space-between">
                                                                    <Typography variant="body2" align="left" style={{ minWidth: '35%' }}>{`${portions.portion}`}</Typography>
                                                                    <Typography variant="body2" align="right" style={{ whiteSpace: 'pre-line' }}>
                                                                        <CurrencySymbol />
                                                                        {`${portions.price}`}
                                                                    </Typography>
                                                                </Box>
                                                            );
                                                        })}
                                                </Fragment>
                                            )}
                                            {restaurant_menu_details && restaurant_menu_details.item_type && restaurant_menu_details.item_type === 'main' && restaurant_menu_details.add_ons && restaurant_menu_details.add_ons.length > 0 && (
                                                <Fragment>
                                                    <Divider />
                                                    <Typography variant="body2" align="center" style={{ minWidth: '35%' }}>{`Add-Ons`}</Typography>
                                                    {restaurant_menu_details.add_ons.map((add_on, i) => {
                                                        return (
                                                            <Box display="flex" justifyContent="space-between">
                                                                <Typography variant="body2" align="left" style={{ minWidth: '35%' }}>{`${add_on.name}`}</Typography>
                                                                <Typography variant="body2" align="right" style={{ whiteSpace: 'pre-line' }}>
                                                                    <CurrencySymbol />
                                                                    {`${add_on.price}`}
                                                                </Typography>
                                                            </Box>
                                                        );
                                                    })}
                                                </Fragment>
                                            )}
                                        </Stack>
                                        <Divider />
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <Typography variant="body2">
                                                <Event /> {`Available from : `}
                                            </Typography>
                                            <Typography variant="h6">{`${new Date(restaurant_menu_details.available_time_start).toLocaleString()}`}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <Typography variant="body2">
                                                <Event /> {`Available Till : `}
                                            </Typography>
                                            <Typography variant="h6">{`${new Date(restaurant_menu_details.available_time_end).toLocaleString()}`}</Typography>
                                        </Box>
                                    </CardContent>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Fragment>
            }
        </Fragment>
    );

}

export { RestaurantMenuDetails };