import React from 'react'
import queryString from 'query-string';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom'
import { Grid, ListItemIcon, MenuItem, Stack, Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { GiveawayAction, PromoAction } from '../../../../redux/actions'
import { Add, CloudDownload, FilterAlt } from '@mui/icons-material';
import { postAuthRoutes } from '../../../../routes'
import { CardHeader } from '../../../../_components/card'
import { FormSearchInput } from '../../../../_components/form';
import { UpdateDataStatusDialog } from '../../../../_components/dialog'
import { RouteLink, GeneralButton, FilterMenu } from '../../../../_components/controls'
import { NoData } from '../../../../_components/layout'
import { PromoCard, PromoCardLoader, PromoFilter, PromoExport } from '.'
import { Fragment } from 'react';
import { AbilityCan } from '../../../../_helpers/permission/AbilityCan';

function Promos() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Redux actions */
    const listBrandPromos = (params) => dispatch(PromoAction.listBrandPromos(params));
    const changeStatus = (params) => dispatch(PromoAction.changeStatus(params));
    const getRunningPromos = (params) => dispatch(PromoAction.getRunningPromos(params));
    const getRunningGiveaways = (params) => dispatch(GiveawayAction.getRunningGiveaways(params));

    /** Redux reducer states */
    const { promos_loading: isLoading, promos } = useSelector((state) => state.PromoReducer);
    const { user } = useSelector((state) => state.UserReducer);

    /** Initialize and declare state */
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });
    const [showPromoFilterModal, setShowPromoFilterModal] = useState(false);
    const [payload, setPayload] = useState({
        limit: 100, // Number of records that are displayed when a page loads
        page: 1, // Page number to load
        search: searchParams.get('q') ?? '', // Search key to check for word anywhere in the record
        filter: {
            status: searchParams.get('status') ?? '', // Selected promo status to be filtered, initially it will be null
            promo_start_date: searchParams.get('promo_start_date') ?? ''  // Selected promo start date to be filtered, initially it will be null
        },
    });

    useEffect(() => {

        /* Read the payload and append the parameters to the url for proper reload */
        const queryParam = { ...payload.filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = payload.page;
        if (payload.search) queryParam.q = payload.search; else delete queryParam.q;
        if (payload.filter.status) queryParam.status = payload.filter.status; else delete queryParam.status;
        if (payload.filter.promo_start_date) queryParam.promo_start_date = payload.filter.promo_start_date; else delete queryParam.promo_start_date;

        const pathname = postAuthRoutes('promos').path;
        navigate({ pathname, search: createSearchParams(`?${queryString.stringify(queryParam)}`).toString() });

        getNext()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search])

    useEffect(() => {
        getRunningPromos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * function to fetch promo list
     * @param {Object} payload - The params which are used for the api
     * @param {string} payload.page - Page number to load
     * @param {string} payload.limit -  Count of the documents per page
     * @param {string} payload.filter - Filter data
     * @param {string} payload.search - Search key
     * @return json
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const getNext = async () => {
        const result = await listBrandPromos(payload);
        if (result.status) {
            setPayload({ ...payload, page: payload.page + 1 }); // If result success, update payload state
        }
    }

    /**
     * function to open/close status update modal
     * @param {string} id - Id of the data which we need to update.
     * @param {string} status -  Current status of the data which we need to update.
     * @param {string} item - Current item of the data which we need to update.
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const statusUpdateModal = ({ id, status, item }) => {
        setStatusData({ id: id, status: status, item: '' });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    /**
     * function to update promo status
     * @param {string} id - Id of the data which we need to update.
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const updateStatusAction = async (id) => {
        setShowStatusUpdateModal(!showStatusUpdateModal);
        changeStatus({ 'promo_id': id }).then((result) => {
            getRunningGiveaways();
            getRunningPromos();
        });;
    }

    /**
     * function to open/close promo filter modal
     * @param {string} null
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const promoFilterModal = () => {
        setShowPromoFilterModal(!showPromoFilterModal);
    }

    /**
     * function to apply the selected filter options on the promo list
     * @param {Object} filterData
     * @param {String} filterData.status Selected status from the promo filter modal
     * @param {String} filterData.promo_start_date Selected start date form the promo filter modal
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData });
    }

    /**
     * function to apply the search key words on the promo list
     * @param {String} text Search keyword used
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    return (
        <Fragment>
            <Box>
                <CardHeader
                    title={postAuthRoutes('promos').name}
                    action={
                        <Fragment>
                            <Stack spacing={1} direction="row" alignItems={`center`} sx={{ display: { xs: 'none', md: 'flex' } }}>
                                <AbilityCan I="brand_promos_generate" passThrough={user.role_slug === 'developer' ? true : false}>
                                    <RouteLink to={postAuthRoutes('generate_coupons').path} label={<GeneralButton startIcon={<CloudDownload />} label={postAuthRoutes('generate_coupons').name} />} />
                                </AbilityCan>
                                <AbilityCan I="brand_promos_create" passThrough={user.role_slug === 'developer' ? true : false}>
                                    <RouteLink to={postAuthRoutes('create_promos').path} label={<GeneralButton startIcon={<Add />} label={postAuthRoutes('create_promos').name} />} />
                                </AbilityCan>
                                <AbilityCan I="brand_promos_search" passThrough={user.role_slug === 'developer' ? true : false}>
                                    <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                                </AbilityCan>
                                <AbilityCan I="brand_promos_filter" passThrough={user.role_slug === 'developer' ? true : false}>
                                    <GeneralButton label={<FilterAlt />} onClick={() => promoFilterModal()} />
                                </AbilityCan>
                                <AbilityCan I="brand_promos_export" passThrough={user.role_slug === 'developer' ? true : false}>
                                    <FilterMenu>
                                        <PromoExport filter={payload.filter} />
                                    </FilterMenu>
                                </AbilityCan>
                            </Stack>

                            <Stack spacing={1} direction="row" alignItems={`center`} sx={{ display: { xs: 'flex', md: 'none' } }}>
                                <RouteLink to={postAuthRoutes('create_promos').path} label={<GeneralButton startIcon={<Add />} label="Create" />} sx={{ display: { xs: 'block', md: 'none' } }} />
                                <FilterMenu>
                                    <MenuItem
                                        onClick={() => {
                                            promoFilterModal();
                                        }}
                                    >
                                        <ListItemIcon>
                                            <FilterAlt fontSize="small" />
                                        </ListItemIcon>
                                        Filter
                                    </MenuItem>
                                    <PromoExport filter={payload.filter} />
                                </FilterMenu>
                            </Stack>
                        </Fragment>
                    }
                />
                <PromoFilter show={showPromoFilterModal} closeModal={promoFilterModal} applyFilter={applyFilter} promoFilter={payload.filter} />
                <InfiniteScroll dataLength={promos.length} next={getNext} hasMore={true}>
                    <Grid container spacing={2} direction="row" justifyContent="left" alignItems="stretch">
                        {promos.length > 0 &&
                            promos.map((promo, i) => {
                                return (
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} key={i}>
                                        <PromoCard promo={promo} statusUpdateModal={statusUpdateModal} />
                                    </Grid>
                                );
                            })}
                        {isLoading && <PromoCardLoader />}
                        {!isLoading && promos.length === 0 && <NoData content1={`Promos`} content2={`Not Found`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} minHeight={`75vh`} />}
                    </Grid>
                </InfiniteScroll>
            </Box>
            <UpdateDataStatusDialog show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />
        </Fragment>
    );
}

export { Promos }