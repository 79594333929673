import React, { useEffect, useState } from 'react'
import queryString from 'query-string';
import { DataTable } from '../../../_components/data-table/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { Chip } from '@mui/material';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { postAuthRoutes } from '../../../routes';
import { Fragment } from 'react';
import { WalletAction } from '../../../redux/actions';

function WalletHistoryDataTable({ limit, page, filter, ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /** Redux actions and state */
    const { per_page, total, wallet_history_loading: isLoading, wallet_history } = useSelector((state) => state.WalletReducer);
    const listWalletHistory = (params) => dispatch(WalletAction.listWalletHistory(params));

    /** Initialize and declare state */
    const [sort, setSort] = useState({ '_id': 'asc' });
    const [payload, setPayload] = useState({ page, limit, filter });

    const columns = [
        { name: '#', selector: (row, i) => (row._id), sortField: '_id', center: true, width: '10%', cell: (row, i) => ((per_page * (payload.page - 1)) + (i + 1)) },
        { name: 'Amount (RS)', selector: (row, i) => (row.amount), sortField: 'name', sortable: true, center: true, width: '30%' },
        { name: 'Created At', selector: (row, i) => (row.created_at), sortField: 'created_at', sortable: true, center: true, width: '30%' },
        { name: 'Status', selector: (row, i) => (row.status), sortField: 'status', center: true, sortable: true, width: '30%', cell: row => (<StatusRow row={row} />) },
    ];

    /**
     * function to display status value on rows
     * @param {Object} row
     * @param {string} row.status - status values on the row
     * @return json
     * @author
     * @created_at 14 June 2022
     */
    const StatusRow = ({ row }) => {

        let status = <Chip label={row.status} className="badge-status capitalize" />;
        if (row.status === 'Success')
            status = <Chip label={row.status} color="success" className="badge-status capitalize" />;
        else if (row.status === 'Failed')
            status = <Chip label={row.status} color="error" className="badge-status capitalize" />;
        else
            status = <Chip label={row.status} color="info" className="badge-status capitalize" />;

        return <Fragment>{status}</Fragment>;

    }

    useEffect(() => {
        setPayload({ page, limit, sort, filter });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, limit, filter])

    useEffect(() => {

        const queryParam = { ...filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = page;
        const pathname = postAuthRoutes('wallet').path;
        navigate({ pathname, search: createSearchParams(`?${queryString.stringify(queryParam)}`).toString() });

        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.page, payload.filter])

    /**
     * function to fetch wallet history
     * @param {Object} payload - The params which are used for the api
     * @param {string} payload.page - Page number to load
     * @param {string} payload.limit -  Count of the documents per page
     * @param {string} payload.filter - Filter data
     * @param {string} payload.search - Search key
     * @return json
     */
    const getData = async () => {
        await listWalletHistory(payload);
    }

    /**
     * function to sort columns
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setSort({ [sortField]: sortDirection })
    }

    /**
     * function to select columns
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const handleSelect = (data) => {
        // console.log(data.selectedRows)
    }

    /**
     * function to handle page
     * @param {String} page - Page number to load
     * @return view
     * @author
     * @created_at 14 June 2022
     */

    const handlePage = (page) => {
        setPayload({ ...payload, page: page });
    }

    return (
        <DataTable
            loading={isLoading}
            columns={columns}
            data={wallet_history}
            total={total}
            per_page={per_page}
            setPage={page => handlePage(page)}
            handleSort={handleSort}
            handleSelect={handleSelect}
        />
    )
}


export { WalletHistoryDataTable };
