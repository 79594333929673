import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validate } from './validate';
import { Card, CardContent, CardMedia, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RestaurantMenuAction, RestaurantOrdersAction } from "../../../../../../redux/actions";
import { handleInputChange, validateForm } from "../../../../../../_helpers";
import { Dialog, DialogActionCloseBtn, DialogActionSaveBtn, DialogActions, DialogContent } from "../../../../../../_components/dialog";
import { FormSelect } from "../../../../../../_components/form/form-select";
import { FormTelePhoneInput } from "../../../../../../_components/form/form-tele-input";
import { postAuthRoutes } from "../../../../../../routes";

// Initialize form input values to null
const inputs = { mobile_number: '', table_id: '' };

function CreateOrderModal({ show, showLoader, data, closeModal }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /** Initialize and declare state */
    const [orderData, setOrderData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    /** Redux actions and state */
    const getRestaurantTableSelectList = (params) => dispatch(RestaurantMenuAction.getRestaurantTableSelectList(params));
    const createOrder = (params) => dispatch(RestaurantOrdersAction.createOrder(params));
    const { restaurant_table_select_list_loading: isLoading, restaurant_table_select_list } = useSelector((state) => state.RestaurantMenuReducer);
    const { brand_create_order_loading: isCreateOrderLoading, brand_create_order_requests } = useSelector((state) => state.RestaurantOrdersReducer);

    useEffect(() => {
		getRestaurantTableSelectList();
	}, []);

    useEffect(() => {
        if (brand_create_order_requests && brand_create_order_requests.status === 1) {
            navigate(postAuthRoutes('order_menu').path);

        }
    }, [brand_create_order_requests])

    /**
     * function to close wallet model
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const onClose = () => {
        closeModal(false);
        setOrderData({ ...inputs })
        setErrors({ ...inputs })
    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        const formErrors = {};
        for (const [name, value] of Object.entries(orderData)) {
            const inputError = validate(name, value, orderData);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchAction();

    }

    const dispatchAction = async () => {
        createOrder(orderData).then((result) => {
            closeModal(false);
            setOrderData({ ...inputs })
            setErrors({ ...inputs })
        });
    }

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author
     * @created_at 30 May 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, orderData)) })
        orderData[name] = value;
        setOrderData({ ...orderData });
    }

    return (
        <Dialog isDialogOpen={show} onClose={onClose} loader={showLoader} maxWidth="sm" PaperProps={{ style: { padding: 0 } }}>
            <Card style={{ boxShadow: `none`, padding: 30 }}>
                <form onSubmit={handleSubmit} noValidate className='ns-form' >
                    <DialogContent style={{ padding: 0 }}>
                        <CardContent style={{ display: `flex`, alignItems: `center`, padding: `2rem 1rem 0 1rem` }}>

                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" style={{ minHeight: `10px`, minWidth: `100%` }} spacing={3}>
                                {/* <Typography variant='h3'>Are You Sure?</Typography> */}
                                {/* <Typography variant='body2'>Do you want to configure GST?</Typography> */}

                                <Stack direction="row" justifyContent="flex-end" alignItems="flex-start" style={{ minHeight: `100px`, minWidth: `60%` }} >
                                    <Grid container spacing={2}>
                                        {!isLoading &&
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormSelect
                                                    label="Table"
                                                    name="table_id"
                                                    placeholder="Table"
                                                    onChange={handleChange}
                                                    value={orderData.table_id}
                                                    error={(action.isSubmitted && (errors.table_id)) ? (errors.table_id) : ''}
                                                    data={restaurant_table_select_list}
                                                    tabIndex={1}
                                                />
                                            </Grid>
                                        }
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormTelePhoneInput
                                                tabIndex={13}
                                                label='Mobile Number'
                                                name='mobile_number'
                                                value={orderData.mobile_number}
                                                error={action.isSubmitted && errors.mobile_number ? errors.mobile_number : ''}
                                                onChange={handleChange}
                                                maskType={`digit-only`}
                                                inputProps={{ maxLength: 12 }}
                                                autoComplete={`mobile_number`}
                                                placeholder="Mobile number"
                                                disabled={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </DialogContent >
                    <DialogActions  style={{ padding: 0 }}>
                        <DialogActionSaveBtn tabIndex={3} text={'Order'} isProcessing={action.isProcessing || isLoading || isCreateOrderLoading} disabled={action.isProcessing || isLoading}/>
                        <DialogActionCloseBtn tabIndex={4} onClose={onClose} />
                    </DialogActions>
                </form>
            </Card>
        </Dialog>
    )
}

export { CreateOrderModal };