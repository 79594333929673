import { Card, CardContent } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ConversationDetail } from './conversation-details'
import { ConversationReply } from './conversation-reply'
import { InfluencerNotSelected, ConversationPanelLoader } from '.';
import { ConversationListNotFound } from '../conversations-list';
import { Fragment } from 'react';

function ConversationPanel() {


    /** Initialize plugins and variables */
    let { search } = useLocation();
    const params = new URLSearchParams(search);

    /** Redux actions and state */
    const { conversations_list_loading: isLoading, conversations_list } = useSelector((state) => state.MessagesReducer);

    /** Initialize and declare state */
    const cId = params.get('cId');


    return (
        <Card sx={{ mb: 1, height: `810px` }}>
            <CardContent sx={{ p: `1rem !important`, height: `100%` }}>
                {isLoading ? <ConversationPanelLoader /> : ((!isLoading && conversations_list.length === 0) ? <ConversationListNotFound /> : (!cId ? <InfluencerNotSelected /> : <Fragment><ConversationDetail /><ConversationReply /></Fragment>))}
            </CardContent>
        </Card>
    )
}

export { ConversationPanel }