import { Box, CardMedia } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles';

/** Initialize Story card Style */
const StyledCardMedia = styled(({ ...props }) => (<CardMedia {...props} />))`
        position: relative;
        padding: 0;
        margin: 0;
        width: 100%;
        border-top-right-radius: 0; 
        border-bottom-right-radius: 0;
`;


function StoryMediaCard({ thumbnailUrl, ...props }) {
    return (
        <Box style={{ width: `100%` }}>
            <StyledCardMedia component={"img"} src={thumbnailUrl}  {...props} />
        </Box>
    )
}

export { StoryMediaCard }