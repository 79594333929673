import { Category, MoreVert } from '@mui/icons-material';
import { Box, Chip, Grid, IconButton, Menu, MenuItem, CardContent, Typography, CardHeader, CardMedia } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { postAuthRoutes } from '../../../../../routes';
import { INFLUENCER_APP_URL } from "../../../../../_helpers";
import { AbilityCan } from '../../../../../_helpers/permission/AbilityCan';
import QRCode from 'qrcode';
import { useNavigate } from 'react-router-dom';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { RestaurantTablesCardStyled } from '.';

function RestaurantTablesCard({ restaurant_table, statusUpdateModal }) {

    /** Initialize form statusData values from promo */
    const statusData = { id: restaurant_table._id, status: restaurant_table.status, item: '' };

    /** Redux reducer state */
	const { user } = useSelector((state) => state.UserReducer);

    /** Initialize and declare state */
    const [anchorEl, setAnchorEl] = useState(null);
    const [qrcodeImage, setQrcodeImage] = useState('');

    /** Initialize plugins and variables */
    const open = Boolean(anchorEl);
    let navigate = useNavigate();

    useEffect(() => {
        if (restaurant_table.brand_id) {
            if (restaurant_table.brand_id && restaurant_table._id) {
                const link = `${INFLUENCER_APP_URL}/restaurant/auth/${restaurant_table.brand_id}/${restaurant_table._id}`;
                if (link) {
                    QRCode.toDataURL(link, { width: 800, margin: 2, color: { dark: '#000000ff', light: '#ffffffff' } }, (err, link) => {
                        if (err) return console.error(err);
                        setQrcodeImage(link);
                    });
                }
            } else if(restaurant_table.brand_id && restaurant_table.booking_type_url){
                const link = `${INFLUENCER_APP_URL}/restaurant/auth/${restaurant_table.brand_id}/${restaurant_table.booking_type_url}`;
                if (link) {
                    QRCode.toDataURL(link, { width: 800, margin: 2, color: { dark: '#000000ff', light: '#ffffffff' } }, (err, link) => {
                        if (err) return console.error(err);
                        setQrcodeImage(link);
                    });
                }
                
            }
        }
    }, [restaurant_table.brand_id, restaurant_table._id]);

    /**
     * function to handle click icon menu
     * @param {object} event form object
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * function to handle close icon menu
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * function to direct to update promo page
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleUpdateClick() {
        setAnchorEl(null);
        navigate(postAuthRoutes('update_restaurant_tables').path, { state: { restaurantTable: restaurant_table }, });
    }

    /**
     * function to change promo status
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleStatusChangeClick() {
        setAnchorEl(null);
        setTimeout(() => {
            statusUpdateModal(statusData);
        }, 100);
    }
    return (
        <RestaurantTablesCardStyled>
            <Grid container spacing={2} display="flex" alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CardHeader
                        action={
                            !restaurant_table.booking_type_url && <Fragment>
                                <IconButton aria-label="more" id="long-button" aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} ><MoreVert /></IconButton>
                                <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button' }} anchorEl={anchorEl} open={open} onClose={handleClose}  >
                                    <AbilityCan I="brand_restaurant_table_update" passThrough={user.role_slug === 'developer' ? true : false}>
                                        <MenuItem onClick={handleUpdateClick}>{`Update`}</MenuItem>
                                    </AbilityCan>
                                    <AbilityCan I="brand_restaurant_table_status" passThrough={user.role_slug === 'developer' ? true : false}>
                                        <MenuItem onClick={handleStatusChangeClick}>{restaurant_table.status === 'active' ? 'Deactivate' : 'Activate'}</MenuItem>
                                    </AbilityCan>
                                </Menu>
                            </Fragment>
                        }
                        title={<Fragment>{restaurant_table.short_code} {!restaurant_table.booking_type_url &&<Chip size='small' label={restaurant_table.status === 'active' ? 'Active' : 'Inactive'} color={restaurant_table.status === 'active' ? 'success' : 'error'} />}</Fragment>}
                        subheader={!restaurant_table.booking_type_url && `${restaurant_table.created_at}`}
                    />
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" justifyContent="center" alignItems="center">
                                <CardMedia height={`200px`} component={"img"} src={qrcodeImage} />
                            </Grid>
                            {!restaurant_table.booking_type_url && 
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" justifyContent="left" alignItems="center">
                                    <Box>
                                        <Typography variant="h5">{`${restaurant_table.name}`}</Typography>
                                        <Typography variant="body2" sx={{ wordBreak: `break-word`, paddingBottom: 12 }}>{`${restaurant_table.description}`}</Typography>
                                        <Chip size='small' label={restaurant_table.category} color={'info'} />
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                    </CardContent>
                </Grid>
            </Grid>
        </RestaurantTablesCardStyled>
    );
}

export { RestaurantTablesCard };