import React, { useEffect, useState } from 'react';
import { Page, Text, Image, Document, StyleSheet, View } from '@react-pdf/renderer';
import QRCode from 'qrcode';
import { INFLUENCER_APP_URL } from '../../../../../../_helpers';

const styles = StyleSheet.create({
	page: {
		paddingTop: 10,
		paddingBottom: 10,
		paddingHorizontal: 10,
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'space-around'
	},
	body: {
		flexDirection: 'column',
		paddingLeft: 20,
		paddingBottom: 10,
	},
	flexContainer: {
		display: 'flex',
		flexDirection: 'wrap',
	},
	title: {
		fontSize: 24,
		textAlign: 'center',
		color: 'black',
		marginHorizontal: 10,
	},
	image: {
		marginVertical: 15,
		marginHorizontal: 0,
		width: 100,
		height: 100,
	},
	pageNumber: {
		position: 'absolute',
		fontSize: 6,
		bottom: 20,
		left: 0,
		right: 0,
		textAlign: 'center',
		color: 'black',
	},
});

const RestaurantTablePdf = ({ ...props }) => {

	const { restaurant_tables } = props;
	const [tableData, setTableData] = useState([]);

	useEffect(() => {
		if (restaurant_tables) {
			const restaurantTables = [];

			restaurant_tables.forEach((restaurant) => {

				const imageUrl = `${INFLUENCER_APP_URL}/auth/${restaurant.brand_id}/${restaurant._id}`;

				if (imageUrl) {
					QRCode.toDataURL(
						imageUrl,
						{
							width: 800,
							margin: 2,
							color: {
								dark: '#000000ff',
								light: '#ffffffff',
							},
						},
						(err, imageUrl) => {
							if (err) return console.error(err);
							restaurant.QrCode = imageUrl;
							restaurantTables.push(restaurant);
						},
					);
				}
			});
			setTableData(restaurantTables);
		}
	}, [restaurant_tables]);

	return (
		<Document>
			<Page size="A4" style={{ ...styles.page, backgroundColor: 'white' }} wrap={false}>
				{tableData && tableData.length !== 0 &&
					tableData.map((table, index) => {
						return (
							<View style={styles.body}>
								<Text style={styles.title}> {table.short_code} </Text>
								<Image style={styles.image} src={table.QrCode} />
							</View>
						);
					})
				}
				<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
			</Page>
		</Document>
	);
};

export { RestaurantTablePdf };