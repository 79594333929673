import { Event, MoreVert } from '@mui/icons-material';
import { Box, CardMedia, Chip, Divider, Grid, IconButton, Menu, MenuItem, Stack, Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { postAuthRoutes } from '../../../../../routes';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { Fragment } from 'react';
import { useState } from 'react';
import { AbilityCan } from '../../../../../_helpers/permission/AbilityCan';
import { CurrencySymbol } from '../../../../../_components/currency-symbol';
import { RestaurantMenuCardStyled } from './RestaurantMenuCardStyled';

function RestaurantMenuCard({ restaurantMenu, statusUpdateModal, restaurantMenuDeleteModal, hasAction = true }) {
	/** Initialize form statusData values from restaurantMenu */
	const statusData = { id: restaurantMenu._id, status: restaurantMenu.status, title: '' };

	/** Initialize and declare state */
	const [anchorEl, setAnchorEl] = useState(null);
	const { user } = useSelector((state) => state.UserReducer);

	/** Initialize plugins and variables */
	const open = Boolean(anchorEl);
	let navigate = useNavigate();

	/**
	 * function to handle click icon menu
	 * @param {object} event form object
	 * @author Naveen K
	 * @created_at 13 Oct 2023
	 */
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	/**
	 * function to handle close icon menu
	 * @author Naveen K
	 * @created_at 13 Oct 2023
	 */
	const handleClose = () => {
		setAnchorEl(null);
	};

	/**
	 * function to direct to update restaurant menu page
	 * @return view
	 * @author Naveen K
	 * @created_at 13 Oct 2023
	 */
	function handleUpdateClick() {
		setAnchorEl(null);
		navigate(postAuthRoutes('update_restaurant_menu').path, { state: { restaurantMenu: restaurantMenu }, });
	}

	/**
	 * function to direct to delete restaurant menu Modal
	 * @return view
	 * @author Naveen K
	 * @created_at 13 Oct 2023
	 */
	function handleMenuDeleteClick() {
		setTimeout(() => {
			restaurantMenuDeleteModal({ id: restaurantMenu._id });
		}, 100);
	}

	/**
	 * function to change restaurant menu status
	 * @return view
	 * @author Naveen K
	 * @created_at 13 Oct 2023
	 */
	function handleStatusChangeClick() {
		setAnchorEl(null);
		setTimeout(() => {
			statusUpdateModal(statusData);
		}, 100);
	}

	/**
	 * function to direct to restaurant menu details page
	 * @return view
	 * @author Naveen
	 * @created_at 21 Nov 2023
	 */
	function handleRestaurantDetailsClick() {
		setAnchorEl(null);
		const pathname = postAuthRoutes('restaurant_menu_details').path;
		navigate({ pathname, search: createSearchParams(`?bId=${restaurantMenu._id}`).toString() });
	}

	return (
		<RestaurantMenuCardStyled>
			<Grid container spacing={2} display="flex" alignItems="center">
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Box style={{ width: `100%` }}>
						<CardMedia component={'img'} src={restaurantMenu.image} onError={(e) => { e.target.src = restaurantMenu.error_image_url; }} sx={{ position: 'relative', padding: 0, margin: 0, width: '100%', height: '900%', borderTopRightRadius: 0, borderBottomRightRadius: { xs: 0, sm: 0, md: 4 }, borderBottomLeftRadius: { xs: 0, sm: 0, md: 4 } }} />
					</Box>
					<CardContent>
						<Box style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }} mb={1} mt={2}>
							<Typography variant="h5" sx={{ cursor: `pointer`, flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>{`${restaurantMenu.title}`}</Typography>
							<Box>
								<Chip size="small" label={restaurantMenu.status === 'active' ? 'Active' : 'Inactive'} color={restaurantMenu.status === 'active' ? 'success' : 'error'} />
								{hasAction && (
									<Fragment>
										<IconButton aria-label="more" id="long-button" aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={handleClick}>
											<MoreVert />
										</IconButton>
										<Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button' }} anchorEl={anchorEl} open={open} onClose={handleClose}>
											<AbilityCan I="brand_restaurant_menu_update" passThrough={user.role_slug === 'developer' ? true : false}>
												<MenuItem onClick={handleUpdateClick}>{`Update`}</MenuItem>
											</AbilityCan>
											<AbilityCan I="brand_restaurant_menu_change_status" passThrough={user.role_slug === 'developer' ? true : false}>
												<MenuItem onClick={handleStatusChangeClick}>{restaurantMenu.status === 'active' ? 'Deactivate' : 'Activate'}</MenuItem>
											</AbilityCan>
											<AbilityCan I="brand_restaurant_menu_delete" passThrough={user.role_slug === 'developer' ? true : false}>
												<MenuItem onClick={handleMenuDeleteClick}>{`Delete`}</MenuItem>
											</AbilityCan>
											<AbilityCan I="brand_restaurant_menu_details" passThrough={user.role_slug === 'developer' ? true : false}>
												<MenuItem onClick={handleRestaurantDetailsClick}>{`Details`}</MenuItem>
											</AbilityCan>
										</Menu>
									</Fragment>
								)}
							</Box>
						</Box>
						<Typography variant="body2" style={{ minHeight: `60px`, maxHeight: `150px`, overflow: 'hidden', textOverflow: 'ellipsis' }} >{`${restaurantMenu.description}`}</Typography>
						<Divider />
						<Stack spacing={1}>
							<Box display="flex" justifyContent="space-between" alignItems="center">
								<Typography variant="body2">{`Category : `}</Typography>
								<Typography variant="body2" align="right" >{`${restaurantMenu.category}`}</Typography>
							</Box>
							<Box display="flex" justifyContent="space-between">
								<Typography variant="body2" align="left" style={{ minWidth: '35%' }}>{`Non Veg : `}</Typography>
								<Typography variant="body2" align="right" >{`${restaurantMenu.is_non === true ? ' Yes ' : ' No '}`}</Typography>
							</Box>
							<Box display="flex" justifyContent="space-between">
								<Typography variant="body2" align="left" style={{ minWidth: '35%' }}>{`Price : `}</Typography>
								<Typography variant="body2" align="right" style={{ display: `flex`, alignItems: `center` }}><CurrencySymbol />{`${restaurantMenu.price}`}</Typography>
							</Box>
							<Box display="flex" justifyContent="space-between">
								<Typography variant="body2" align="left" style={{ minWidth: '35%' }}>{`Discount : `}</Typography>
								<Typography variant="body2" align="right" >{restaurantMenu.discount ? <Box style={{ display: `flex`, alignItems: `center` }}><CurrencySymbol />{restaurantMenu.discounted_price} &nbsp;&nbsp; ({restaurantMenu.discount}%)</Box> : `-`}</Typography>
							</Box>
							<Box display="flex" justifyContent="space-between">
								<Typography variant="body2" align="left" style={{ minWidth: '35%' }}>{`Item Category : `}</Typography>
								<Typography variant="body2" align="right" style={{ textTransform: 'capitalize' }}>{`${restaurantMenu.item_type}`}</Typography>
							</Box>
							<Box display="flex" justifyContent="space-between">
								<Typography variant="body2" align="left" style={{ minWidth: '35%' }}>{`Does the item have portions? : `}</Typography>
								<Typography variant="body2" align="right" >{`${typeof restaurantMenu.have_portions === 'undefined' || restaurantMenu.have_portions ? 'Yes' : 'No'}`}</Typography>
							</Box>
						</Stack>
						<Divider />
						<Box display="flex" justifyContent="space-between" alignItems="center">
							<Typography variant="body2">
								<Event /> {`Available from : `}
							</Typography>
							<Typography variant="h6">{restaurantMenu.start_time || '-'}</Typography>
						</Box>
						<Box display="flex" justifyContent="space-between" alignItems="center">
							<Typography variant="body2">
								<Event /> {`Available Till : `}
							</Typography>
							<Typography variant="h6">{restaurantMenu.end_time || '-'}</Typography>
						</Box>
					</CardContent>
				</Grid>
			</Grid>
		</RestaurantMenuCardStyled>
	);
}

export { RestaurantMenuCard };