import { Dialog, Slide, styled } from '@mui/material';
import React, { forwardRef } from 'react';

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const DialogStyled = styled(({ ...props }) => <Dialog TransitionComponent={Transition} {...props} />)`
	padding: ${(props) => props.theme.spacing(4)};
	&:last-child {
		padding-bottom: ${(props) => props.theme.spacing(4)};
	}
`;

export { DialogStyled };
