module.exports = {
    /**
     * function to validate the registration form inputs
     * @author Naveen
     * @created_at 23 Apr 2023
     */

    validate: (name, value, data = {}) => {
        const errors = {};
        switch (name) {

            case 'name': {
                errors.name = '';
                if (value === '')
                    errors.name = 'Enter Currency Name';
                break;
            } case 'answer': {
                errors.answer = '';
                if (value === '')
                    errors.answer = 'Enter HTML';
                break;
            } default: {
                errors[name] = '';
                break;
            }

        }
        return errors;
    }
}