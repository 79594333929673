import React, { useEffect, useState } from 'react'
import queryString from 'query-string';
import { DataTable } from '../../../_components';
import { useDispatch, useSelector } from 'react-redux';
import { CurrencyAction } from '../../../redux/actions';
import { Button, ButtonGroup, Chip } from '@mui/material';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { postAuthRoutes } from '../../../routes';
import { Edit, Delete } from '@mui/icons-material';
import { AbilityCan } from '../../../_helpers/permission/AbilityCan';
import { DeleteDataDialog } from '../../../_components/dialog';
import { Fragment } from 'react';

function CurrencyDataTable({ limit, page, ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /** Redux reducer state */
    const { per_page, total, currency_loading: isLoading, currency } = useSelector((state) => state.CurrencyReducer);
    const deleteCurrency = (params) => dispatch(CurrencyAction.deleteCurrency(params));
    const { user } = useSelector((state) => state.UserReducer);

    /** Redux reducer actions */
    const listCurrency = (params) => dispatch(CurrencyAction.listCurrency(params));

    /** Initialize and declare state */
    const [sort, setSort] = useState({ '_id': 'asc' });
    const [payload, setPayload] = useState({ page, limit });

    useEffect(() => {
        setPayload({ page, limit, sort });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, limit])


    useEffect(() => {

        const queryParam = {};
        queryParam.p = page;

        const pathname = postAuthRoutes('currency').path;
        navigate({ pathname, search: createSearchParams(`?${queryString.stringify(queryParam)}`).toString() });

        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * function to fetch currency list
     * @return json
     * @author Naveen K
     * @created_at 23 Apr 2024
     */
    const getData = async () => {
        await listCurrency(payload);
    }

    /** Initialize column headers and values */
    const columns = [
        { name: '#', selector: (row, i) => (row._id), sortField: '_id', center: true, width: '5%', cell: (row, i) => { return ((per_page * (payload.page - 1)) + (i + 1)) } },
        { name: 'Name', selector: (row, i) => (row.name), sortField: 'name', sortable: true, width: '8%' },
        { name: 'HTML', selector: (row, i) => (row.html), sortField: 'html', center: false, sortable: true, width: '67%', wrap: true },
        { name: 'Created Date', selector: (row, i) => (row.created_at), sortField: 'created_at', center: true, sortable: true, width: '12%' },
        { name: 'Symbol', selector: (row, i) => (row.icon), sortField: 'status', center: true, sortable: true, width: '8%', cell: row => (<IconRow row={row} />) },
    ];

    /**
     * function to display icon and update options on rows
     * @param {Object} row
     * @param {string} row.status - status values on the row
     * @return json
     * @author Naveen K
     * @created_at 23 Apr 2024
     */
    const IconRow = ({ row }) => {

        let status = <div dangerouslySetInnerHTML={{ __html: row.html }} />;
        const [currencyDeleteData, setCurrencyDeleteData] = useState({ id: '' });
        const [showDeleteModal, setShowDeleteModal] = useState(false);

        /**
         * function to update currency on the rows
         * @param {Object} row
         * @return json
         * @author Naveen K
         * @created_at 23 Apr 2024
         */
        function handleUpdateClick(row) {
            navigate(postAuthRoutes('update_currency').path, { state: { cId: row } });
        }

        /**
         * function to open/close Currency delete modal
         * @return view
         * @author Naveen
         * @created_at 23 Apr 2024
         */
        const currencyDeleteModal = async (row) => {
            setCurrencyDeleteData({ id: row._id })
            setShowDeleteModal(!showDeleteModal)
        }

        /**
         * Function to delete Currency
         * @author Naveen
         * @created_at 23 Apr 2024
         */
        const currencyDeleteAction = async (id) => {
            setShowDeleteModal(!showDeleteModal)
            await deleteCurrency({ 'currencyId': id });
        }

        let iconHover = (
            <ButtonGroup variant="text" size="small" className='action-row'>
                <AbilityCan I="brand_currency_update" passThrough={user.role_slug === 'developer' ? true : false}>
                    <Button onClick={() => handleUpdateClick(row)}> <Edit /></Button>
                </AbilityCan>
                <AbilityCan I='admin_faq_delete' passThrough={(user.role_slug === 'developer') ? true : false}>
                    <Button onClick={() => currencyDeleteModal(row)}> <Delete /></Button>
                </AbilityCan>
            </ButtonGroup>
        )
        return (
            <Fragment>
                {status}
                {iconHover}
                <DeleteDataDialog show={showDeleteModal} closeModal={currencyDeleteModal} data={currencyDeleteData} deleteModal={currencyDeleteAction} />
            </Fragment>);

    }

    /**
     * function to handle page
     * @param {String} page - Page number to load
     * @return view
     * @author Naveen K
     * @created_at 23 Apr 2024
     */
    const handlePage = (page) => {
        setPayload({ ...payload, page: page });
    }

    /**
     * function to sort columns
     * @return view
     * @author Naveen K
     * @created_at 23 Apr 2024
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setSort({ [sortField]: sortDirection })
    }

    return (
        <DataTable
            loading={isLoading}
            columns={columns}
            data={currency.currency_values}
            total={total}
            per_page={per_page}
            setPage={page => handlePage(page)}
            handleSort={handleSort}
            paginationDefaultPage={0}
            noDataComponentData={{ content1: 'Currency', content2: 'Not Found' }}
        />
    )

}
export { CurrencyDataTable };