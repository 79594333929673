
module.exports = {
    /**
     * function to validate the promo create or update form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author Akshay N
     * @created_at 28 May 2022
     */
    validate: (name, value, data = {}) => {
        //eslint-disable-next-line
        const errors = {};
        const igExtensions = ["jpeg", "jpg", "mov", "mp4"];
        const fbExtensions = ["jpeg", "jpg", "png", "tiff", "bmp", "3gpp2", "3gpp", "asf", "avi", "dat", "divx", "dv", "f4v", "flv", "gif", "mp2t", "m4v", "mkv", "mod", "mov", "mp4", "mpeg", "mpg", "wmv"];

        const igReelExtensions = ["mov", "mp4"];
        const fbReelExtensions = ["mp4"];

        const videoExtensions = ["3gpp2", "3gpp", "asf", "avi", "dat", "divx", "dv", "f4v", "flv", "gif", "mp2t", "m4v", "mkv", "mod", "mov", "mp4", "mpeg", "mpg", "wmv"];
        const imageExtensions = ["jpeg", "jpg", "png", "tiff", "bmp"];

        switch (name) {
            case 'platforms': {
                errors.platforms = '';
                if (value === '' || value.length === 0)
                    errors.platforms = 'Select publishing platform.';
                break;
            } case 'scheduled_time': {
                errors.scheduled_time = '';
                if (value === '' && (data.scheduling_option && data.scheduling_option !== 'publish_now'))
                    errors.scheduled_time = 'Select schedule date.';
                break;
            } case 'scheduling_option': {
                errors.scheduling_option = '';
                if (value === '')
                    errors.scheduling_option = 'Select scheduling option.';
                break;
            } case 'media_files': {
                errors.media_files = '';
                if (value === '' || value === [] || typeof value === 'undefined' || value.length === 0) {
                    errors.media_files = 'Select post. ';
                } else if (typeof value !== 'string') {
                    if (value.length > 0 && typeof value[0] !== 'string') {
                        let extensions = data.platforms.includes('instagram') ? igExtensions : fbExtensions;

                        if (data.post_type === 'reels') {
                            extensions = data.platforms.includes('facebook') ? fbReelExtensions : igReelExtensions; //extensions when reel

                        }
                        
                        const validFiles = value.filter(file => {
                            let extension = (file.name).split('.').pop();
                            extension = extension.toLowerCase();
                            return extensions.includes(extension);
                        });
                        if (validFiles.length === 0){
                            errors.media_files = 'Please choose a valid file format.';
                        }
                        const videoFiles = validFiles.filter(file => {
                            let extension = (file.name).split('.').pop();
                            extension = extension.toLowerCase();
                            return videoExtensions.includes(extension);
                        });
                        if (videoFiles.length > 1) {
                            errors.media_files = 'The number of video file selection must not exceed 1.';
                        }
                        const imageFiles = validFiles.filter(file => {
                            let extension = (file.name).split('.').pop();
                            extension = extension.toLowerCase();
                            return imageExtensions.includes(extension);
                        });
                        if (videoFiles.length > 0 && imageFiles.length > 0) {
                            errors.media_files = 'Select either an image or a video file.'; //error shown when both image and video is selected
                        }
                    }
                } 

                if (value.length > 10 || (data.post_type && data.post_type === 'reels' && (value.length > 1))) {
                    const numSelection = (data.post_type && data.post_type === 'reels') ? '1' : '10';
                    errors.media_files = `The number of selection must not exceed ${numSelection}.`;
                } 
                
                break;
            } case 'post_type': {
                errors.post_type = '';
                if (value === '')
                    errors.post_type = 'Select post type.';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}