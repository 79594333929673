import {
	RESTAURANT_MENU_REQUEST, RESTAURANT_MENU_SUCCESS, RESTAURANT_MENU_FAILURE,
	RESTAURANT_MENU_CREATE_REQUEST, RESTAURANT_MENU_CREATE_SUCCESS, RESTAURANT_MENU_CREATE_FAILURE,
	UPDATE_RESTAURANT_MENU_REQUEST, UPDATE_RESTAURANT_MENU_SUCCESS, UPDATE_RESTAURANT_MENU_FAILURE,
	CHANGE_RESTAURANT_MENU_STATUS_REQUEST, CHANGE_RESTAURANT_MENU_STATUS_SUCCESS, CHANGE_RESTAURANT_MENU_STATUS_FAILURE,
	DELETE_RESTAURANT_MENU_REQUEST, DELETE_RESTAURANT_MENU_SUCCESS, DELETE_RESTAURANT_MENU_FAILURE,
	RESTAURANT_MENU_SELECT_LIST_REQUEST, RESTAURANT_MENU_SELECT_LIST_SUCCESS, RESTAURANT_MENU_SELECT_LIST_FAILURE,
	RESTAURANT_TABLES_REQUEST, RESTAURANT_TABLES_SUCCESS, RESTAURANT_TABLES_FAILURE,
	RESTAURANT_TABLES_CREATE_REQUEST, RESTAURANT_TABLES_CREATE_SUCCESS, RESTAURANT_TABLES_CREATE_FAILURE,
	UPDATE_RESTAURANT_TABLES_REQUEST, UPDATE_RESTAURANT_TABLES_SUCCESS, UPDATE_RESTAURANT_TABLES_FAILURE,
	CHANGE_RESTAURANT_TABLES_STATUS_REQUEST, CHANGE_RESTAURANT_TABLES_STATUS_SUCCESS, CHANGE_RESTAURANT_TABLES_STATUS_FAILURE,
	RESTAURANT_TABLE_CATEGORIES_REQUEST, RESTAURANT_TABLE_CATEGORIES_SUCCESS, RESTAURANT_TABLE_CATEGORIES_FAILURE,
	ITEM_TYPE_SELECT_LIST_REQUEST, ITEM_TYPE_SELECT_LIST_SUCCESS, ITEM_TYPE_SELECT_LIST_FAILURE,
	ADD_ON_SELECT_LIST_REQUEST, ADD_ON_SELECT_LIST_SUCCESS, ADD_ON_SELECT_LIST_FAILURE,
	RESTAURANT_MENU_DETAILS_REQUEST, RESTAURANT_MENU_DETAILS_SUCCESS, RESTAURANT_MENU_DETAILS_FAILURE,
	CONFIGURE_GST_REQUEST, CONFIGURE_GST_SUCCESS, CONFIGURE_GST_FAILURE,
    GST_DETAILS_REQUEST, GST_DETAILS_SUCCESS, GST_DETAILS_FAILURE,
	RESTAURANT_MENU_CATEGORY_SELECT_LIST_REQUEST, RESTAURANT_MENU_CATEGORY_SELECT_LIST_SUCCESS, RESTAURANT_MENU_CATEGORY_SELECT_LIST_FAILURE,
    SET_FUTURE_ORDERS_REQUEST, SET_FUTURE_ORDERS_SUCCESS, SET_FUTURE_ORDERS_FAILURE,
	GET_FUTURE_ORDERS_DETAILS_REQUEST,GET_FUTURE_ORDERS_DETAILS_SUCCESS,GET_FUTURE_ORDERS_DETAILS_FAILURE,
	RESTAURANT_TABLE_SELECT_LIST_REQUEST, RESTAURANT_TABLE_SELECT_LIST_SUCCESS, RESTAURANT_TABLE_SELECT_LIST_FAILURE,
	GET_ORDER_MENU_LIST_REQUEST, GET_ORDER_MENU_LIST_SUCCESS, GET_ORDER_MENU_LIST_FAILURE,
	SELECT_MENU_ITEM_REQUEST, SELECT_MENU_ITEM_FAILURE, SELECT_MENU_ITEM_SUCCESS, UNSELECT_MENU_ITEM,
} from '../actions';

const initialState = {
	page: 1,
	per_page: 100,
	total: 0,
	total_pages: 0,
	restaurant_menu_loading: false,
	restaurant_menu: [],
	restaurant_menu_errors: {},
	create_restaurant_menu_loading: false,
	create_restaurant_menu_errors: {},
	update_restaurant_menu_loading: false,
	update_restaurant_menu_errors: {},
	change_restaurant_menu_status_loading: false,
	change_restaurant_menu_status_errors: {},
	restaurant_menu_select_list_loading: false,
	restaurant_menu_select_list: [],
	restaurant_menu_select_list_errors: {},
	restaurant_tables_loading: false,
	restaurant_tables: [],
	restaurant_tables_errors: {},
	create_restaurant_tables_loading: false,
	create_restaurant_tables_errors: {},
	change_restaurant_tables_status_loading: false,
	change_restaurant_tables_status_errors: {},
	restaurant_table_category_list_loading: false, restaurant_table_category_list: [], restaurant_table_category_list_errors: {},
	restaurant_item_type_list_loading: false, restaurant_item_type_list: [], restaurant_item_type_list_errors: {},
 	add_on_select_list_loading: false, add_on_select_list: [], add_on_select_list_errors: {},
	restaurant_menu_details_loading: false, restaurant_menu_details: {}, restaurant_menu_details_errors: {},
	configure_gst_loading: false, configure_gst_errors: {},
	gst_details_loading: false, gst_details: {}, gst_details_errors: {},
	restaurant_menu_category_select_list_loading: false,
	restaurant_menu_category_select_list: [],
	restaurant_menu_category_select_list_errors: {},
	set_future_orders_loading: false, set_future_orders: {}, set_future_orders_errors: {},
	get_future_orders_details_loading: false, get_future_orders_details: {}, get_future_orders_details_errors: {},
	restaurant_table_select_list_loading: false, restaurant_table_select_list: [], restaurant_table_select_list_errors: {},
	get_order_menu_list_loading: false, get_order_menu_list: [], get_order_menu_list_errors: {},
	is_menu_item_selected: false, selected_menu_item_loading: false, selected_menu_item: {}, selected_menu_item_errors: {},

};

export function RestaurantMenuReducer(state = initialState, action) {
	switch (action.type) {
		/** begin::List restaurant menu redux */
		case RESTAURANT_MENU_REQUEST: {
			return {
				...state,
				restaurant_menu_loading: true,
				restaurant_menu_errors: {},
			};
		}
		case RESTAURANT_MENU_SUCCESS: {
			let newArray = [...state.restaurant_menu]; //making a new array
			if (action.data.page === 1) {
				newArray = [];
			}
			action.data.restaurantMenu.forEach((payload) => {
				const restaurantMenuIndex = newArray.findIndex((restaurant_menus) => restaurant_menus._id === payload._id);
				if (restaurantMenuIndex !== -1) {
					newArray[restaurantMenuIndex] = payload;
				} else {
					newArray = newArray.concat(payload);
				}
			});

			return {
				...state,
				restaurant_menu_loading: false,
				restaurant_menu: newArray,
				restaurant_menu_errors: {},
			};
		}
		case RESTAURANT_MENU_FAILURE: {
			return {
				...state,
				restaurant_menu_loading: false,
				restaurant_menu_errors: action.errors,
				restaurant_menu: [],
			};
		}
		/** end::List restaurant menu redux */

		/** begin::Create restaurant menu redux */
		case RESTAURANT_MENU_CREATE_REQUEST: {
			return {
				...state,
				create_restaurant_menu_loading: true,
				create_restaurant_menu_errors: {},
			};
		}
		case RESTAURANT_MENU_CREATE_SUCCESS: {
			let restaurantMenuArray = [...state.restaurant_menu]; //making a new array
			const restaurantMenuIndex = restaurantMenuArray.findIndex((restaurantMenu) => restaurantMenu._id === action.data._id);

			if (restaurantMenuIndex !== -1) {
				restaurantMenuArray[restaurantMenuIndex].status = action.data.status;
			}

			return {
				...state,
				create_restaurant_menu_loading: false,
				restaurant_menu: restaurantMenuArray,
				create_restaurant_menu_errors: {},
			};
		}
		case RESTAURANT_MENU_CREATE_FAILURE: {
			return {
				...state,
				create_restaurant_menu_loading: false,
				create_restaurant_menu_errors: action.errors,
			};
		}

		/** end::Create restaurant menu redux */

		/** begin::Update restaurant menu redux */
		case UPDATE_RESTAURANT_MENU_REQUEST: {
			return {
				...state,
				update_restaurant_menu_loading: true,
				update_restaurant_menu_errors: {},
			};
		}
		case UPDATE_RESTAURANT_MENU_SUCCESS: {
			let restaurantMenuArray = [...state.restaurant_menu];
			const restaurantMenuIndex = restaurantMenuArray.findIndex((restaurantMenus) => restaurantMenus._id === action.data._id);
			if (restaurantMenuIndex !== -1) {
				restaurantMenuArray[restaurantMenuIndex] = action.data;
			}

			return {
				...state,
				restaurant_menu: restaurantMenuArray,
				update_restaurant_menu_loading: false,
				update_restaurant_menu: action.data,
				update_restaurant_menu_errors: {},
			};
		}
		case UPDATE_RESTAURANT_MENU_FAILURE: {
			return {
				...state,
				update_restaurant_menu_loading: false,
				update_restaurant_menu_errors: action.errors,
			};
		}
		/** end::Update restaurant menu redux */

		/** begin::Change restaurant menu status redux */
		case CHANGE_RESTAURANT_MENU_STATUS_REQUEST: {
			return {
				...state,
				change_restaurant_menu_status_loading: true,
				change_restaurant_menu_status_errors: {},
			};
		}
		case CHANGE_RESTAURANT_MENU_STATUS_SUCCESS: {
			let newArray = [...state.restaurant_menu];
			const restaurantMenuIndex = newArray.findIndex((restaurantMenus) => restaurantMenus._id === action.data._id);

			if (restaurantMenuIndex !== -1) {
				newArray[restaurantMenuIndex].status = action.data.status;
			}

			return {
				...state,
				change_restaurant_menu_status_loading: false,
				change_restaurant_menu_status_errors: {},
				restaurant_menu: newArray,
			};
		}
		case CHANGE_RESTAURANT_MENU_STATUS_FAILURE: {
			return {
				...state,
				change_restaurant_menu_status_loading: false,
				change_restaurant_menu_status_errors: action.errors,
			};
		}
		/** end::Change restaurant menu status redux */

		/** begin::Delete restaurant menu redux */
		case DELETE_RESTAURANT_MENU_REQUEST: {
			return {
				...state,
				delete_restaurant_menu_loading: true,
				delete_restaurant_menu_errors: {},
			};
		}
		case DELETE_RESTAURANT_MENU_SUCCESS: {
			let restaurantMenuArray = [...state.restaurant_menu];

			const RestaurantMenuFilter = restaurantMenuArray.filter((restaurantMenus) => restaurantMenus._id !== action.data._id);

			return {
				...state,
				restaurant_menu: RestaurantMenuFilter,
				delete_restaurant_menu_loading: false,
				delete_restaurant_menu: action.data,
				delete_restaurant_menu_errors: {},
			};
		}
		case DELETE_RESTAURANT_MENU_FAILURE: {
			return {
				...state,
				delete_restaurant_menu_loading: false,
				delete_restaurant_menu_errors: action.errors,
			};
		}
		/** end::Delete restaurant menu redux */

		/** begin::Restaurant Menu select list redux */
		case RESTAURANT_MENU_SELECT_LIST_REQUEST: {
			return {
				...state,
				restaurant_menu_select_list_loading: true,
				restaurant_menu_select_list_errors: {},
			};
		}
		case RESTAURANT_MENU_SELECT_LIST_SUCCESS: {
			return {
				...state,
				restaurant_menu_select_list_loading: false,
				restaurant_menu_select_list_errors: {},
				restaurant_menu_select_list: action.data,
			};
		}
		case RESTAURANT_MENU_SELECT_LIST_FAILURE: {
			return {
				...state,
				restaurant_menu_select_list_loading: false,
				restaurant_menu_select_list_errors: action.errors,
				restaurant_menu_select_list: [],
			};
		}
		/** end::Restaurant Menu select list redux */
		/** begin::List restaurant tables redux */
		case RESTAURANT_TABLES_REQUEST: {
			return {
				...state,
				restaurant_tables_loading: true,
				restaurant_tables_errors: {},
			};
		}
		case RESTAURANT_TABLES_SUCCESS: {
			let newArray = [...state.restaurant_tables]; //making a new array
			if (action.data.page === 1) {
				newArray = [];
			}
			action.data.restaurant_tables.forEach((payload) => {
				const restaurantTablesIndex = newArray.findIndex((restaurant_tables) => restaurant_tables._id === payload._id);
				if (restaurantTablesIndex !== -1) {
					newArray[restaurantTablesIndex] = payload;
				} else {
					newArray = newArray.concat(payload);
				}
			});

			return {
				...state,
				restaurant_tables_loading: false,
				restaurant_tables: newArray,
				restaurant_tables_errors: {},
			};
		}
		case RESTAURANT_TABLES_FAILURE: {
			return {
				...state,
				restaurant_tables_loading: false,
				restaurant_tables_errors: action.errors,
				restaurant_tables: [],
			};
		}
		/** end::List restaurant tables redux */

		/** begin::Create restaurant tables redux */
		case RESTAURANT_TABLES_CREATE_REQUEST: {
			return {
				...state,
				create_restaurant_tables_loading: true,
				create_restaurant_tables_errors: {},
			};
		}
		case RESTAURANT_TABLES_CREATE_SUCCESS: {
			let restaurantMenuArray = [...state.restaurant_menu]; //making a new array
			const restaurantMenuIndex = restaurantMenuArray.findIndex((restaurantMenu) => restaurantMenu._id === action.data._id);

			if (restaurantMenuIndex !== -1) {
				restaurantMenuArray[restaurantMenuIndex].status = action.data.status;
			}

			return {
				...state,
				create_restaurant_tables_loading: false,
				restaurant_tables: restaurantMenuArray,
				create_restaurant_tables_errors: {},
			};
		}
		case RESTAURANT_TABLES_CREATE_FAILURE: {
			return {
				...state,
				create_restaurant_tables_loading: false,
				create_restaurant_tables_errors: action.errors,
			};
		}

		/** end::Create restaurant tables redux */

		/** begin::Update restaurant tables redux */
		case UPDATE_RESTAURANT_TABLES_REQUEST: {
			return {
				...state,
				create_restaurant_tables_loading: true,
				create_restaurant_tables_errors: {},
			};
		}
		case UPDATE_RESTAURANT_TABLES_SUCCESS: {
			let restaurantTablesArray = [...state.restaurant_menu];
			const restaurantTablesIndex = restaurantTablesArray.findIndex((restaurantTables) => restaurantTables._id === action.data._id);
			if (restaurantTablesIndex !== -1) {
				restaurantTablesArray[restaurantTablesIndex] = action.data;
			}

			return {
				...state,
				restaurant_tables: restaurantTablesArray,
				create_restaurant_tables_loading: false,
				create_restaurant_tables: action.data,
				create_restaurant_tables_errors: {},
			};
		}
		case UPDATE_RESTAURANT_TABLES_FAILURE: {
			return {
				...state,
				create_restaurant_tables_loading: false,
				create_restaurant_tables_errors: action.errors,
			};
		}
		/** end::Update restaurant tables redux */

		/** begin::Change restaurant menu status redux */
		case CHANGE_RESTAURANT_TABLES_STATUS_REQUEST: {
			return {
				...state,
				change_restaurant_tables_status_loading: true,
				change_restaurant_tables_status_errors: {},
			};
		}
		case CHANGE_RESTAURANT_TABLES_STATUS_SUCCESS: {
			let newArray = [...state.restaurant_tables];
			const restaurantTablesIndex = newArray.findIndex((restaurantTables) => restaurantTables._id === action.data._id);

			if (restaurantTablesIndex !== -1) {
				newArray[restaurantTablesIndex].status = action.data.status;
			}

			return {
				...state,
				change_restaurant_tables_status_loading: false,
				change_restaurant_tables_status_errors: {},
				restaurant_tables: newArray,
			};
		}
		case CHANGE_RESTAURANT_TABLES_STATUS_FAILURE: {
			return {
				...state,
				change_restaurant_tables_status_loading: false,
				change_restaurant_tables_status_errors: action.errors,
			};
		}
		/** end::Change restaurant tables status redux */


		/** begin::category select list redux */
		case RESTAURANT_TABLE_CATEGORIES_REQUEST: {
			return {
				...state,
				restaurant_table_category_list_loading: true,
				restaurant_table_category_list_errors: {}
			};
		}
		case RESTAURANT_TABLE_CATEGORIES_SUCCESS: {

			return {
				...state,
				restaurant_table_category_list_loading: false,
				restaurant_table_category_list_errors: {},
				restaurant_table_category_list: action.data

			};

		}
		case RESTAURANT_TABLE_CATEGORIES_FAILURE: {
			return {
				...state,
				restaurant_table_category_list_loading: false,
				restaurant_table_category_list_errors: action.errors,
				restaurant_table_category_list: []
			};
		}
		/** end::category select list redux */

		/** begin::category select list redux */
		case ITEM_TYPE_SELECT_LIST_REQUEST: {
			return {
				...state,
				restaurant_item_type_list_loading: true,
				restaurant_item_type_list_errors: {}
			};
		}
		case ITEM_TYPE_SELECT_LIST_SUCCESS: {

			return {
				...state,
				restaurant_item_type_list_loading: false,
				restaurant_item_type_list_errors: {},
				restaurant_item_type_list: action.data

			};

		}
		case ITEM_TYPE_SELECT_LIST_FAILURE: {
			return {
				...state,
				restaurant_item_type_list_loading: false,
				restaurant_item_type_list_errors: action.errors,
				restaurant_item_type_list: []
			};
		}
		/** end::category select list redux */

		/** begin::Add on select list redux */
		case ADD_ON_SELECT_LIST_REQUEST: {
			return {
				...state,
				add_on_select_list_loading: true,
				add_on_select_list_errors: {}
			};
		}
		case ADD_ON_SELECT_LIST_SUCCESS: {

			return {
				...state,
				add_on_select_list_loading: false,
				add_on_select_list_errors: {},
				add_on_select_list: action.data

			};

		}
		case ADD_ON_SELECT_LIST_FAILURE: {
			return {
				...state,
				add_on_select_list_loading: false,
				add_on_select_list_errors: action.errors,
				add_on_select_list: []
			};
		}
		/** end::Add on select list redux */
		/** begin::restaurant menu details redux */
        case RESTAURANT_MENU_DETAILS_REQUEST: {
            return {
                ...state,
                restaurant_menu_details_loading: true,
                restaurant_menu_details_errors: {}
            };
        }
        case RESTAURANT_MENU_DETAILS_SUCCESS: {
            return {
                ...state,
                restaurant_menu_details_loading: false,
                restaurant_menu_details: action.data,
                restaurant_menu_details_errors: {}
            };
        }
        case RESTAURANT_MENU_DETAILS_FAILURE: {
            return {
                ...state,
                restaurant_menu_details_loading: false,
                restaurant_menu_details_errors: action.errors
            };
        }
        /** end::restaurant menu details redux */

		/** begin::Create restaurant menu redux */
		case CONFIGURE_GST_REQUEST: {
			return {
				...state,
				configure_gst_loading: true,
				configure_gst_errors: {},
			};
		}
		case CONFIGURE_GST_SUCCESS: {
			return {
				...state,
				configure_gst_loading: false,
				gst_details: action.data,
				configure_gst_errors: {},
			};
		}
		case CONFIGURE_GST_FAILURE: {
			return {
				...state,
				configure_gst_loading: false,
				configure_gst_errors: action.errors,
			};
		}

		/** end::Create restaurant menu redux */

		/** begin::restaurant menu details redux */
        case GST_DETAILS_REQUEST: {
            return {
                ...state,
                gst_details_loading: true,
                gst_details_errors: {}
            };
        }
        case GST_DETAILS_SUCCESS: {
            return {
                ...state,
                gst_details_loading: false,
                gst_details: action.data,
                gst_details_errors: {}
            };
        }
        case GST_DETAILS_FAILURE: {
            return {
                ...state,
                gst_details_loading: false,
                gst_details_errors: action.errors
            };
        }
        /** end::restaurant menu details redux */

		/** begin::Restaurant Menu category select list redux */
		case RESTAURANT_MENU_CATEGORY_SELECT_LIST_REQUEST: {
			return {
				...state,
				restaurant_menu_category_select_list_loading: true,
				restaurant_menu_category_select_list_errors: {},
			};
		}
		case RESTAURANT_MENU_CATEGORY_SELECT_LIST_SUCCESS: {
			return {
				...state,
				restaurant_menu_category_select_list_loading: false,
				restaurant_menu_category_select_list_errors: {},
				restaurant_menu_category_select_list: action.data,
			};
		}
		case RESTAURANT_MENU_CATEGORY_SELECT_LIST_FAILURE: {
			return {
				...state,
				restaurant_menu_category_select_list_loading: false,
				restaurant_menu_category_select_list_errors: action.errors,
				restaurant_menu_category_select_list: [],
			};
		}
		/** end::Restaurant Menu select list redux */

		
		/** begin::restaurant menu details redux */
        case SET_FUTURE_ORDERS_REQUEST: {
            return {
                ...state,
                set_future_orders_loading: true,
                set_future_orders_errors: {}
            };
        }
        case SET_FUTURE_ORDERS_SUCCESS: {

            return {
                ...state,
                set_future_orders_loading: false,
                set_future_orders: action.data,
                get_future_orders_details: action.data,
                set_future_orders_errors: {}
            };
        }
        case SET_FUTURE_ORDERS_FAILURE: {
            return {
                ...state,
                set_future_orders_loading: false,
                set_future_orders_errors: action.errors
            };
        }
        /** end::restaurant menu details redux */

		
		/** begin::restaurant menu details redux */
        case GET_FUTURE_ORDERS_DETAILS_REQUEST: {
            return {
                ...state,
                get_future_orders_details_loading: true,
                get_future_orders_details_errors: {}
            };
        }
        case GET_FUTURE_ORDERS_DETAILS_SUCCESS: {
            return {
                ...state,
                get_future_orders_details_loading: false,
                get_future_orders_details: action.data,
                get_future_orders_details_errors: {}
            };
        }
        case GET_FUTURE_ORDERS_DETAILS_FAILURE: {
            return {
                ...state,
                get_future_orders_details_loading: false,
                get_future_orders_details_errors: action.errors
            };
        }
        /** end::restaurant menu details redux */

		/** begin::restaurant table select list redux */
		case RESTAURANT_TABLE_SELECT_LIST_REQUEST: {
			return {
				...state,
				restaurant_table_select_list_loading: true,
				restaurant_table_select_list_errors: {}
			};
		}
		case RESTAURANT_TABLE_SELECT_LIST_SUCCESS: {

			return {
				...state,
				restaurant_table_select_list_loading: false,
				restaurant_table_select_list_errors: {},
				restaurant_table_select_list: action.data

			};

		}
		case RESTAURANT_TABLE_SELECT_LIST_FAILURE: {
			return {
				...state,
				restaurant_table_select_list_loading: false,
				restaurant_table_select_list_errors: action.errors,
				restaurant_table_select_list: []
			};
		}
		/** end::restaurant table select list redux */

		/** begin::restaurant menu list redux */
        case GET_ORDER_MENU_LIST_REQUEST: {
            return {
                ...state,
                get_order_menu_list_loading: true,
                get_order_menu_list_errors: {}
            };
        }
        case GET_ORDER_MENU_LIST_SUCCESS: {
            return {
                ...state,
                get_order_menu_list_loading: false,
                get_order_menu_list: action.data.items,
                get_order_menu_list_errors: {}
            };
        }
        case GET_ORDER_MENU_LIST_FAILURE: {
            return {
                ...state,
                get_order_menu_list_loading: false,
                get_order_menu_list_errors: action.errors
            };
        }
        /** end::restaurant menu list redux */

		case SELECT_MENU_ITEM_REQUEST: {
            return {
                ...state,
                is_menu_item_selected: true,
                selected_menu_item_loading: true,
                selected_menu_item_errors: {}
            };
        }
        case SELECT_MENU_ITEM_SUCCESS: {
            return {
                ...state,
                is_menu_item_selected: true,
                selected_menu_item_loading: false,
                selected_menu_item: action.data,
                selected_menu_item_errors: {}
            };
        }
        case SELECT_MENU_ITEM_FAILURE: {
            return {
                ...state,
                is_menu_item_selected: false,
                selected_menu_item_loading: false,
                selected_menu_item_errors: action.errors
            };
        }

		case UNSELECT_MENU_ITEM: {
            return {
                ...state,
                is_menu_item_selected: false,
                selected_menu_item_loading: false,
                selected_menu_item: {},
                selected_menu_item_errors: {}
            };
        }
		default:
			return state;
	}
}
