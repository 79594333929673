import { apiServices } from "../../_helpers";


export const SchedulePostServices = {

    getSchedulePostCalendar: (params) => {

		return apiServices.post('/brand/schedule-post/calendar', params)
			.then((response) => { return response; })
	},

	/**
     * Service for schedule post details
     * @author Jasin 
     */
	schedulePostDetails: (params) => {
        return apiServices.post('/brand/schedule-post/details', params)
            .then((response) => { return response; })

    },

    createSchedulePost: (params) => {
        return apiServices.post('/brand/schedule-post/schedule', params, { headers: { 'Content-Type': 'multipart/form-data' }, timeout:1800000 })
            .then((response) => { return response; })

    },

    
    updateSchedulePost: (params) => {
        return apiServices.put('/brand/schedule-post/schedule/update', params, { headers: { 'Content-Type': 'multipart/form-data' }, timeout:1800000 })
            .then((response) => { return response; })

    },
}
