import { GlobalAction } from ".";
import { PlanServices } from "../services";

export const PLANS_REQUEST = 'PLANS_REQUEST';
export const PLANS_SUCCESS = 'PLANS_SUCCESS';
export const PLANS_FAILURE = 'PLANS_FAILURE';

export const UPDATE_BRAND_PLAN_REQUEST = 'UPDATE_BRAND_PLAN_REQUEST';
export const UPDATE_BRAND_PLAN_SUCCESS = 'UPDATE_BRAND_PLAN_SUCCESS';
export const UPDATE_BRAND_PLAN_FAILURE = 'UPDATE_BRAND_PLAN_FAILURE';

export const CREATE_CHECKOUT_SESSION_REQUEST = 'CREATE_CHECKOUT_SESSION_REQUEST';
export const CREATE_CHECKOUT_SESSION_SUCCESS = 'CREATE_CHECKOUT_SESSION_SUCCESS';
export const CREATE_CHECKOUT_SESSION_FAILURE = 'CREATE_CHECKOUT_SESSION_FAILURE';

export const GIVEAWAY_PLANS_REQUEST = 'GIVEAWAY_PLANS_REQUEST';
export const GIVEAWAY_PLANS_SUCCESS = 'GIVEAWAY_PLANS_SUCCESS';
export const GIVEAWAY_PLANS_FAILURE = 'GIVEAWAY_PLANS_FAILURE';

export const CREATE_GIVEAWAY_CHECKOUT_SESSION_REQUEST = 'CREATE_GIVEAWAY_CHECKOUT_SESSION_REQUEST';
export const CREATE_GIVEAWAY_CHECKOUT_SESSION_SUCCESS = 'CREATE_GIVEAWAY_CHECKOUT_SESSION_SUCCESS';
export const CREATE_GIVEAWAY_CHECKOUT_SESSION_FAILURE = 'CREATE_GIVEAWAY_CHECKOUT_SESSION_FAILURE';

export const PlanAction = {

    /**
     * Action for list plans
     * @param {Object} params - The params which are used for the api
     * @author Akshay N
     * @created_at 30 May 2022
     */
    listPlans: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PlanServices.listPlans(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: PLANS_REQUEST, request: request } }
        function success(request, response) { return { type: PLANS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: PLANS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for list plans
     * @param {Object} params - The params which are used for the api
     * @author Akshay N
     * @created_at 30 May 2022
     */
    updateBrandPlan: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PlanServices.updateBrandPlan(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: UPDATE_BRAND_PLAN_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_BRAND_PLAN_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_BRAND_PLAN_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    createCheckoutSession: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PlanServices.createCheckoutSession(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(request, response)); },
                    error => { return dispatch(failure(error)); }
                );
        };


        function request(params) { return { type: CREATE_CHECKOUT_SESSION_REQUEST, request: params } }
        function success(params, response) { return { type: CREATE_CHECKOUT_SESSION_SUCCESS, request: params, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: CREATE_CHECKOUT_SESSION_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action for list giveaway plans
     * @param {Object} params - The params which are used for the api
     * @author Akshay N
     * @created_at 30 May 2022
     */
    listGiveawayPlans: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PlanServices.listGiveawayPlans(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: GIVEAWAY_PLANS_REQUEST, request: request } }
        function success(request, response) { return { type: GIVEAWAY_PLANS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GIVEAWAY_PLANS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    createGiveawayCheckoutSession: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PlanServices.createGiveawayCheckoutSession(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(request, response)); },
                    error => { return dispatch(failure(error)); }
                );
        };


        function request(params) { return { type: CREATE_GIVEAWAY_CHECKOUT_SESSION_REQUEST, request: params } }
        function success(params, response) { return { type: CREATE_GIVEAWAY_CHECKOUT_SESSION_SUCCESS, request: params, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: CREATE_GIVEAWAY_CHECKOUT_SESSION_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

};
