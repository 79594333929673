import React from 'react'
import { styled, Box } from '@mui/material';

const LineTypographyStyled = styled(({ ...props }) => (<Box {...props} />))`
    display: flex;
    align-items: center;
    & .left-line {
        border-bottom: 1px solid #ffffff1f;
        width: 100%;
    }
    & .text-wrapper{
        padding: 0 10px 0 10px;
    }
    & .right-line {
        border-bottom: 1px solid #ffffff1f;
        width: 100%;
    }
`;

export { LineTypographyStyled }
