import { Box, Grid } from "@mui/material";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Fragment } from "react";
import { NoData } from "../../../../_components";
import { CouponCodeCard, CouponCodeCardLoader } from "../../ad-run/coupon-codes";

function CouponCodes({ coupons, isLoading, getNext }) {

    /**
     * function to list next coupon
     * @param {string} null
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const getNextCoupon = async () => {
        getNext();
    }

    return (
        <Box >
            <Fragment>
                <InfiniteScroll dataLength={coupons.length} next={getNextCoupon} hasMore={true}>
                    <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center" >
                        {
                            coupons.length > 0 && coupons.map((coupon_code, i) => {
                                return <Grid key={i} item xs={12} sm={12} md={3} lg={3} xl={3} ><CouponCodeCard canChangeStatus={false} hasAction={true} {...coupon_code} isClickable={true} canRedeem={true} /></Grid>;
                            })
                        }
                        {isLoading && (<CouponCodeCardLoader />)}
                        {!isLoading && coupons.length === 0 && <NoData content1={`Coupon Codes`} content2={`Not Found`} description={`Please search for another coupon code.`} />}
                    </Grid>
                </InfiniteScroll>
            </Fragment>
        </Box>
    )
}

export { CouponCodes }
