import globals from "../../_root/base/globals";
import colors from "./colors";

const { info, link, text } = colors;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...globals,
  "a, a:link, a:visited": {
    color: `${link.main} !important`,
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${link.main} !important`,
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
  ".active": {
    backgroundColor: `${link.main} !important`,
    color: `${text.main} !important`,
    ".MuiSvgIcon-root": {
      color: `${text.main} !important`,
    }
  }
};
