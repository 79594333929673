import React from 'react';
import { TableLoader } from '../loader';
import { NoData } from '../layout/no-data';
import ReactDataTable, { createTheme } from 'react-data-table-component';
import { DataTableStyled } from '.';
import { useSelector } from 'react-redux';

function DataTable({ columns, data, dense, loading, total, per_page, setPage, handleSort, handleSelect = () => { }, paginationDefaultPage, actions, ...props }) {
	const { theme } = useSelector((state) => state.GlobalReducer);

	createTheme('dark', {
		background: {
			default: 'transparent',
		},
		// selected: {
		// 	default: 'transparent'
		// },
		// highlightOnHover: {
		// 	default: 'transparent'
		// },
		striped: {
			default: 'transparent'
		},
	});

	createTheme('light', {
		background: {
			default: 'transparent',
		},
		selected: {
			default: 'transparent'
		},
		highlightOnHover: {
			default: 'transparent'
		},
		striped: {
			default: 'transparent'
		},
	});

	return (
		<DataTableStyled>
			<ReactDataTable theme={theme} columns={columns} data={data} progressPending={loading} progressComponent={<TableLoader />} pointerOnHover={true} paginationTotalRows={total} paginationPerPage={per_page} paginationComponentOptions={{ noRowsPerPage: true }} onChangePage={(page) => setPage(page)} onSort={handleSort} striped={true} dense={dense} noHeader={actions ? false : true} highlightOnHover={true} onSelectedRowsChange={handleSelect} pagination paginationServer noDataComponent={<NoData content1={``} content2={'Data Not Found'} description={'If using a custom view, try adjusting the filters,Otherwise, create some data.'} minHeight={`75vh`} />} paginationDefaultPage={paginationDefaultPage} paginationResetDefaultPage={paginationDefaultPage} responsive={true} actions={actions} fixedHeader={true} subHeader={true} {...props} />
		</DataTableStyled>
	);
}

export { DataTable };
