import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { GlobalAction } from './redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppNameContext } from './_contexts';
import { useEffect, useMemo, useState } from 'react';
import { APP_NAME } from './_helpers';
import { ThemeProvider } from '@mui/system';
import themeLight from './_theme/theme-light'
import themeDark from './_theme/theme-dark'
import { CssBaseline, useMediaQuery } from '@mui/material';
import { InitialLoader, PageLoader, Snackbar } from './_components';
import { getBrowserRouter } from "./routes";
import './App.scss';

function App() {

	const dispatch = useDispatch();
	const { pageLoader, initialLoader, toastMessage: toast, theme } = useSelector((state) => state.GlobalReducer);
	const [isDarkMode, setIsDarkMode] = useState(false)

	useEffect(() => {
		dispatch(GlobalAction.initialLoader(false))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

	useMemo(() => setIsDarkMode(theme === 'dark' ? true : ((theme === 'light') ? (false) : prefersDarkMode)), [prefersDarkMode, theme]);


	return (
		<AppNameContext.Provider value={APP_NAME}>
			<HelmetProvider>
				<ThemeProvider theme={isDarkMode ? themeDark : themeLight}>
					<CssBaseline />
					<RouterProvider router={createBrowserRouter(getBrowserRouter())} />
					<PageLoader loader={pageLoader} />
					<InitialLoader loader={initialLoader} />
					<Snackbar message={toast} theme={theme}/>
				</ThemeProvider>
			</HelmetProvider>
		</AppNameContext.Provider>
	);
}

export { App };
