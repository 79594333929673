import {
    ADD_NEW_FB_USER_TOKEN_REQUEST, ADD_NEW_FB_USER_TOKEN_SUCCESS, ADD_NEW_FB_USER_TOKEN_FAILURE,
    GET_CONNECTED_IG_ACCOUNT_REQUEST, GET_CONNECTED_IG_ACCOUNT_SUCCESS, GET_CONNECTED_IG_ACCOUNT_FAILURE,

} from "../actions";

const initialState = {
    add_fb_user_token_loading: false, add_fb_user_token_errors: {},
    connected_ig_account_loading: false, connected_ig_account: {}, connected_ig_account_errors: {},
};

export function ConnectAccountReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Add token redux */
        case ADD_NEW_FB_USER_TOKEN_REQUEST: {
            return {
                ...state,
                add_fb_user_token_loading: true,
                add_fb_user_token_errors: {}
            };
        }
        case ADD_NEW_FB_USER_TOKEN_SUCCESS: {
            return {
                ...state,
                add_fb_user_token_loading: false,
                connected_ig_account: action.data,
                add_fb_user_token_errors: {},

            };
        }
        case ADD_NEW_FB_USER_TOKEN_FAILURE: {
            return {
                ...state,
                add_fb_user_token_loading: false,
                add_fb_user_token_errors: action.errors
            };
        }
        /** end::Add token redux */

        /** begin::Get connected account redux */
        case GET_CONNECTED_IG_ACCOUNT_REQUEST: {
            return {
                ...state,
                connected_ig_account_loading: true,
                connected_ig_account_errors: {}
            };
        }
        case GET_CONNECTED_IG_ACCOUNT_SUCCESS: {
            return {
                ...state,
                connected_ig_account_loading: false,
                connected_ig_account: action.data,
                connected_ig_account_errors: {},

            };
        }
        case GET_CONNECTED_IG_ACCOUNT_FAILURE: {
            return {
                ...state,
                connected_ig_account_loading: false,
                connected_ig_account_errors: action.errors,
                add_fb_user_token_errors: {},

            };
        }
        /** end::Get connected account redux */

        default:
            return state;
    }
}