import { apiServices } from '../../_helpers';


export const CurrencyServices = {

    /**
     * Service for list currency
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 23 April 2024
    */
    listCurrency: (params) => {
        return apiServices.post('brand/currency/list', params)
            .then((response) => { return response; })
    },

    /**
	 * Service for Creating Currency
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 23 Apr 2024
	 */
	createCurrency: (params) => {
		return apiServices.post('/brand/currency/create', params).then((response) => {
			return response;
		});
	},

	/**
	 * Service for update Currency
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 23 Apr 2024
	 */
	updateCurrency: (params) => {
		return apiServices.put('/brand/currency/update', params).then((response) => {
			return response;
		});
	},

    /**
	 * Service for deleting currency
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 23 Apr 2024
	 */
	deleteCurrency: (params) => {
		return apiServices.put('/brand/currency/delete', params).then((response) => {
			return response;
		});
	},

	/**
     * Service for Currency select list
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 06 May 2024
    */
    currencySelectList: (params) => {
        return apiServices.post('brand/currency/select-list', params)
            .then((response) => { return response; })
    },

}