import React from "react";
import { Card } from "../card";
import { Box } from "../box/Box";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { Typography } from "@mui/material";
import { Fragment } from "react";

// Material Dashboard 2 React components

function StatisticsWidget({ color = "info", title, count, percentage = null, icon }) {
    return (
        <Card sx={{ boxShadow: 1 }}>
            <Box display="flex" justifyContent="space-between" pt={1} px={2} pb={percentage ? 0 : 2}>
                <Box variant="gradient" bgColor={color} color={color === "light" ? "dark" : "white"} coloredShadow={color} borderRadius="xl" display="flex" justifyContent="center" alignItems="center" width="4rem" height="4rem" mt={-3} >
                    <Icon fontSize="medium" color="inherit" style={{ width: `1.7rem`, height: `1.7rem` }}> {icon} </Icon>
                </Box>
                <Box textAlign="right" lineHeight={1.25}>
                    <Typography variant="button" fontWeight="light" color="text"> {title} </Typography>
                    <Typography variant="h4" color="text">{count}</Typography>
                </Box>
            </Box>
            {percentage &&
                <Fragment>
                    <Divider />
                    <Box pb={2} px={2}>
                        <Typography component="p" variant="button" color="text" display="flex" sx={{ flexWrap: 'wrap', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal' }}>
                            {percentage.amount && <Typography component="span" variant="button" fontWeight="bold" color={percentage.color} sx={{ whiteSpace: 'nowrap'}}> {percentage.amount}&nbsp; </Typography>}
                            {percentage.label}
                        </Typography>
                    </Box>
                </Fragment>
            }
        </Card>
    );
}

export { StatisticsWidget };
