
module.exports = {
    /**
     * function to validate the promo create or update form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author Akshay N
     * @created_at 28 May 2022
     */
    validate: (name, value, data = {}) => {
        //eslint-disable-next-line
        const errors = {};
        switch (name) {
            case 'name': {
                errors.name = '';
                if (value === '')
                    errors.name = 'Enter your promo name.';
                break;
            } case 'description': {
                errors.description = '';
                if (value === '')
                    errors.description = 'Enter description for the promo.';
                break;
            } case 'promo_type': {
                errors.promo_type = '';
                if (value === '')
                    errors.promo_type = 'Select promo type.';
                break;
            } case 'file': {
                errors.file = '';
                if (value === '' || value === [] || typeof value === 'undefined') {
                    errors.file = 'Select promo sample post. ';
                } else if (typeof value !== 'string') {
                    let extension = (value.name).split('.').pop();
                    extension = extension.toLowerCase();
                    let size = value.size;

                    if (extension !== 'png' && extension !== 'jpg' && extension !== 'jpeg') {
                        errors.file = 'Please choose a valid file format.';
                    } else if (size >= 2000000) {
                        errors.file = 'Selected promo sample file size exceeded.File size must be below 2 MB.';
                    }
                }
                break;
            } case 'coupon_file': {
                errors.coupon_file = '';
                if (data.payment_method === 'coupon') {
                    if (value === '' || value === [] || typeof value === 'undefined') {
                        errors.coupon_file = 'Select promo coupon code csv. ';
                    } else if (typeof value !== 'string') {
                        let extension = (value.name).split('.').pop();
                        extension = extension.toLowerCase();
                        let size = value.size;
                        if (extension !== 'csv') {
                            errors.coupon_file = 'Please choose a valid file format.';
                        } else if (size >= 2000000) {
                            errors.coupon_file = 'Selected coupon code csv file size exceeded. ';
                        }
                    }
                }
                break;
            } case 'promo_start_date': {
                errors.promo_start_date = '';
                if (value === '')
                    errors.promo_start_date = 'Select promo start date and time.';
                break;
            } case 'promo_end_date': {
                errors.promo_end_date = '';
                if (value === '')
                    errors.promo_end_date = 'Select promo end date and time.';
                break;
            } case 'number_of_promos': {
                errors.number_of_promos = '';
                if (value === '')
                    errors.number_of_promos = 'Enter maximum promos required.';
                break;
            } case 'promo_cost_per_influencer': {
                errors.promo_cost_per_influencer = '';
                if (data.payment_method === 'price') {
                    if (value === '')
                        errors.promo_cost_per_influencer = 'Enter promo cost per influencer.';
                }
                break;
            } case 'issue_coupons_at': {
                errors.issue_coupons_at = '';
                if (value === '')
                    errors.issue_coupons_at = 'Select issue coupons.';
                break;
            } case 'coupon_message': {
                errors.coupon_message = '';
                if (value === '')
                    errors.coupon_message = 'Enter coupon message.';
                break;
            } case 'thank_you_message': {
                errors.thank_you_message = '';
                if (value === '' && data.issue_coupons_at === 'after_24_hours')
                    errors.thank_you_message = 'Enter thank you message';
                break;
            } case 'time_between_post': {
                errors.time_between_post = '';
                if (value === '')
                    errors.time_between_post = 'Select how often you can post.';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}