import React from 'react';
import queryString from 'query-string';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { Grid, Stack, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Add, FilterAlt } from '@mui/icons-material';
import { postAuthRoutes } from '../../../../routes';
import { CardHeader } from '../../../../_components/card';
import { FormSearchInput } from '../../../../_components/form';
import { UpdateDataStatusDialog } from '../../../../_components/dialog';
import { GeneralButton } from '../../../../_components/controls';
import { NoData } from '../../../../_components/layout';
import { GiveawayCard, GiveawayCardLoader, GiveawayFilter } from '.';
import { AbilityCan } from '../../../../_helpers/permission/AbilityCan';
import { Fragment } from 'react';
import { GiveawayAction, PromoAction, UserAction } from '../../../../redux/actions';

function Giveaways() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Redux actions */
    const listBrandGiveaways = (params) => dispatch(GiveawayAction.listBrandGiveaways(params));
    const changeStatus = (params) => dispatch(GiveawayAction.changeStatus(params));
    const getUserDetails = () => dispatch(UserAction.getUserDetails());
    const getRunningGiveaways = (params) => dispatch(GiveawayAction.getRunningGiveaways(params));
    const getRunningPromos = (params) => dispatch(PromoAction.getRunningPromos(params));


    /** Redux reducer states */
    const { user } = useSelector((state) => state.UserReducer);
    const { giveaways_loading: isLoading, giveaways } = useSelector((state) => state.GiveawayReducer);

    /** Initialize and declare state */
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });
    const [showGiveawayFilterModal, setShowGiveawayFilterModal] = useState(false);
    const [payload, setPayload] = useState({
        limit: 100, // Number of records that are displayed when a page loads
        page: 1, // Page number to load
        search: searchParams.get('q') ?? '', // Search key to check for word anywhere in the record
        filter: {
            status: searchParams.get('status') ?? '', // Selected giveaway status to be filtered, initially it will be null
            start_date: searchParams.get('start_date') ?? ''  // Selected giveaway start date to be filtered, initially it will be null
        },
    });

    useEffect(() => {

        /* Read the payload and append the parameters to the url for proper reload */
        const queryParam = { ...payload.filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = payload.page;
        if (payload.search) queryParam.q = payload.search; else delete queryParam.q;
        if (payload.filter.status) queryParam.status = payload.filter.status; else delete queryParam.status;
        if (payload.filter.start_date) queryParam.start_date = payload.filter.start_date; else delete queryParam.start_date;

        const pathname = postAuthRoutes('giveaways').path;
        navigate({ pathname, search: createSearchParams(`?${queryString.stringify(queryParam)}`).toString() });

        getNext();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search]);

    useEffect(() => {

        getRunningGiveaways();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getUserDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * function to fetch giveaway list
     * @param {Object} payload - The params which are used for the api
     * @param {string} payload.page - Page number to load
     * @param {string} payload.limit -  Count of the documents per page
     * @param {string} payload.filter - Filter data
     * @param {string} payload.search - Search key
     * @return json
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const getNext = async () => {
        const result = await listBrandGiveaways(payload);
        if (result.status) {
            setPayload({ ...payload, page: payload.page + 1 }); // If result success, update payload state
        }
    };

    /**
     * function to open/close status update modal
     * @param {string} id - Id of the data which we need to update.
     * @param {string} status -  Current status of the data which we need to update.
     * @param {string} item - Current item of the data which we need to update.
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const statusUpdateModal = ({ id, status, item }) => {
        setStatusData({ id: id, status: status, item: '' });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    };

    /**
     * function to update giveaway status
     * @param {string} id - Id of the data which we need to update.
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const updateStatusAction = async (id) => {
        setShowStatusUpdateModal(!showStatusUpdateModal);
        changeStatus({ 'giveaway_id': id }).then((result) => {
            getRunningGiveaways();
            getRunningPromos();
        });
    };

    /**
     * function to open/close giveaway filter modal
     * @param {string} null
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const giveawayFilterModal = () => {
        setShowGiveawayFilterModal(!showGiveawayFilterModal);
    };

    /**
     * function to apply the selected filter options on the giveaway list
     * @param {Object} filterData
     * @param {String} filterData.status Selected status from the giveaway filter modal
     * @param {String} filterData.start_date Selected start date form the giveaway filter modal
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData });
    };

    /**
     * function to apply the search key words on the giveaway list
     * @param {String} text Search keyword used
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    };

    const createGiveaway = () => {
        navigate(postAuthRoutes('create_giveaways').path);
    };

    return (
        <Fragment>
            <Box>
                <CardHeader
                    title={postAuthRoutes('giveaways').name}
                    action={
                        <Fragment>
                            <Stack spacing={1} direction="row" alignItems={`center`} sx={{ display: { xs: 'none', md: 'flex' } }}>
                                {/* {!isLoading && user.giveaway_plan && <GeneralButton startIcon={<Add />} label={postAuthRoutes('create_giveaways').name} disabled={!user.giveaway_plan} onClick={() => createGiveaway()} />} */}
                                <AbilityCan I="brand_giveaway_create" passThrough={user.role_slug === 'developer' ? true : false}>
                                    <GeneralButton startIcon={<Add />} label={postAuthRoutes('create_giveaways').name} onClick={() => createGiveaway()} />
                                </AbilityCan>
                                <AbilityCan I="brand_giveaway_search" passThrough={user.role_slug === 'developer' ? true : false}>
                                    <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                                </AbilityCan>
                                <AbilityCan I="brand_giveaway_filter" passThrough={user.role_slug === 'developer' ? true : false}>
                                    <GeneralButton label={<FilterAlt />} onClick={() => giveawayFilterModal()} />
                                </AbilityCan>
                            </Stack>

                            <Stack spacing={1} direction="row" alignItems={`center`} sx={{ display: { xs: 'flex', md: 'none' } }}>
                                {/* {!isLoading && user.giveaway_plan && <GeneralButton startIcon={<Add />} label={postAuthRoutes('create_giveaways').name} disabled={!user.giveaway_plan} onClick={() => createGiveaway()} />} */}
                                <GeneralButton startIcon={<Add />} label={postAuthRoutes('create_giveaways').name} onClick={() => createGiveaway()} />
                                <GeneralButton label={<FilterAlt />} onClick={() => giveawayFilterModal()} />
                            </Stack>
                        </Fragment>
                    } />
                {/* {!isLoading && !user.giveaway_plan && (
                    <Alert severity="error" variant="filled" sx={{ padding: '20px 32px', marginBottom: 2 }} action={<GeneralButton startIcon={<Sell />} label={`Buy Giveaway Plan`} onClick={buyPlanClick} />}>
                        Giveaway plan not found. Please purchase a giveaway plan to proceed.
                    </Alert>
                )} */}
                <GiveawayFilter show={showGiveawayFilterModal} closeModal={giveawayFilterModal} applyFilter={applyFilter} giveawayFilter={payload.filter} />
                <InfiniteScroll dataLength={giveaways.length} next={getNext} hasMore={true} >
                    <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center">
                        {
                            (giveaways.length > 0) && giveaways.map((giveaway, i) => {
                                return (
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={i}><GiveawayCard giveaway={giveaway} statusUpdateModal={statusUpdateModal} /> </Grid>
                                );
                            })
                        }
                        {isLoading && <GiveawayCardLoader />}
                        {/* {!isLoading && !user.giveaway_plan && giveaways.length === 0 && <NoData content1={`Giveaway`} content2={`Plan Not Found`} description={`Currently, You don't have a giveaway plan.Please purchase a plan to proceed.`} minHeight={`75vh`}><GeneralButton label={`Buy Giveaway Plan`} onClick={buyPlanClick} /></NoData>} */}
                        {/* {!isLoading && user.giveaway_plan && giveaways.length === 0 && <NoData content1={`Giveaways`} content2={`Not Found`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} minHeight={`75vh`} />} */}
                        {!isLoading && giveaways.length === 0 && <NoData content1={`Giveaways`} content2={`Not Found`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} minHeight={`75vh`} />}
                    </Grid>
                </InfiniteScroll>
            </Box>
            <UpdateDataStatusDialog show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />
        </Fragment>
    );
}

export { Giveaways };