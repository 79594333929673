import React from 'react';
import { styled, Box } from '@mui/material';

const PreSignInStyled = styled(({ ...props }) => <Box justifyContent="center" alignItems="center" position="absolute" width="100%" minHeight="100vh" {...props} />)`
	& .pre-signin-wrapper {
		height: 100vh;
		& .pre-signin-wrapper-item-inner {
			min-width: ${(props) => `${props.theme.functions.pxToRem(320)}`};
			max-width: ${(props) => `${props.theme.functions.pxToRem(680)}`};
			min-height: ${(props) => `${props.theme.functions.pxToRem(240)}`};
			padding: ${(props) => `${props.theme.spacing(5)}`};
			padding-bottom: ${(props) => `${props.theme.spacing(6)}`};
		}
	}
`;

export { PreSignInStyled };
