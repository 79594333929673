import React, { useEffect, useState } from "react";
import { Email, Facebook, LinkedIn, WhatsApp } from "@mui/icons-material";
import { ListItemIcon, MenuItem } from "@mui/material";
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, WhatsappShareButton } from "react-share";
import { Fragment } from "react";
const { INFLUENCER_APP_URL } = require("../../../../../../_helpers/config");

function GiveawayShare({ giveaway_details = {}, ...props }) {

    /** Initialize props values */
    const { giveawayId, } = props;
    const { name } = giveaway_details;
    const [link, setLink] = useState('');

    useEffect(() => {
        if (giveawayId) {
            setLink(`${INFLUENCER_APP_URL}/share/giveaway/${giveawayId}`);
        }
    }, [giveawayId]);

    /**
     * function email share
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const EmailShare = () => {
        return (
            <EmailShareButton
                subject={`Giveaway - ${name ?? ''}`}
                url={link}
            >
                <Fragment>
                    <ListItemIcon>
                        <Email fontSize="small" />
                    </ListItemIcon>
                    Email
                </Fragment>

            </EmailShareButton>
        );
    };

    /**
     * function facebook share
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const FacebookShare = () => {
        return (
            <FacebookShareButton
                quote={`Giveaway - ${name ?? ''}`}
                url={link}
            >
                <ListItemIcon>
                    <Facebook fontSize="small" />
                </ListItemIcon>
                Facebook
            </FacebookShareButton>
        );
    };

    /**
    * function linkedin share
    * @return view
    * @author Akshay N
    * @created_at 30 May 2022
    */
    const LinkedInShare = () => {
        return (
            <LinkedinShareButton
                title={`Giveaway - ${name ?? ''}`}
                url={link}
            >
                <ListItemIcon>
                    <LinkedIn fontSize="small" />
                </ListItemIcon>
                LinkedIn
            </LinkedinShareButton>
        );
    };

    /**
     * function linkedin share
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const WhatsAppShare = () => {
        return (
            <WhatsappShareButton
                title={`Giveaway - ${name ?? ''}`}
                url={link}
            >
                <ListItemIcon>
                    <WhatsApp fontSize="small" />
                </ListItemIcon>
                WhatsApp
            </WhatsappShareButton>
        );
    };



    return (
        <Fragment>
            <MenuItem disabled={link ? false : true} onClick={(e) => { e.stopPropagation(); }} key={1}>
                <EmailShare />
            </MenuItem>
            <MenuItem disabled={link ? false : true} onClick={(e) => { e.stopPropagation(); }} key={2}>
                <FacebookShare />
            </MenuItem>
            <MenuItem disabled={link ? false : true} onClick={(e) => { e.stopPropagation(); }} key={3}>
                <LinkedInShare />
            </MenuItem>
            <MenuItem disabled={link ? false : true} onClick={(e) => { e.stopPropagation(); }} key={4}>
                <WhatsAppShare />
            </MenuItem>
        </Fragment>
    );
}

export { GiveawayShare };