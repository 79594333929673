import React from 'react'
import { Button } from '@mui/material'

function DialogActionCloseBtn({ text = "Close", className, tabIndex, onClose, props }) {
    return (
        <Button className={className} type="button" variant="contained" color="secondary" tabIndex={tabIndex} data-dismiss="modal" onClick={() => onClose()} {...props}>
            {text}
        </Button>
    )
}

export { DialogActionCloseBtn }
