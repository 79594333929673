

import React from 'react';
import { Box, Divider, Skeleton, Stack } from '@mui/material';
import { Fragment } from 'react';

function ConversationListLoader({ ...props }) {

    var rows = [];
    for (var i = 0; i < 10; i++) {
        rows.push(
            <Fragment>
                <Box key={i} sx={{ minHeight: `80px`, display: `flex`, alignItems: `center`, justifyContent: `center` }} >
                    <Box sx={{ width: `100%` }}>
                        <Stack direction="row" alignItems="center" sx={{ width: `100%`, pl: 4, pr: 4, pt: 0, pb: 1 }} spacing={2} >
                            <Skeleton animation="wave" variant='circular' height="40px" width="45px" />
                            <Box sx={{ width: `100%` }}>
                                <Skeleton animation="wave" height={20} width="70%" />
                                <Skeleton animation="wave" height={15} width="60%" />
                            </Box>
                        </Stack>
                    </Box>
                </Box>
                <Divider sx={{ m: 0 }} />
            </Fragment>
        )
    }

    return <Fragment> {rows} </Fragment>
}

export { ConversationListLoader };
