import { Avatar, Box, Card, CardActionArea, CardContent, Divider, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { MessagesAction } from '../../../../../redux/actions';
import { postAuthRoutes } from '../../../../../routes';
import queryString from 'query-string';
import { ConversationListLoader, ConversationListNotFound } from '.';
import { Fragment } from 'react';

function ConversationsList() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();
    const navigate = useNavigate();

    const params = new URLSearchParams(search);

    /** Initialize and declare state */
    const [payload, setPayload] = useState({ cId: params.get('cId') ?? '', pId: params.get('pId') ?? '' });

    /** Redux actions and state */
    const { conversations_list_loading: isLoading, conversations_list } = useSelector((state) => state.MessagesReducer);
    const getConversionsList = (params) => dispatch(MessagesAction.getConversationsList(params));

    useEffect(() => {
        getConversionsList();
        //eslint-disable-next-line
    }, [])


    const viewMessage = (cId, pId) => {
        setPayload({ ...payload, cId: cId, pId: pId });
    }

    useEffect(() => {

        const queryParam = {};
        if (payload.cId) queryParam.cId = payload.cId; else delete queryParam.cId;
        if (payload.pId) queryParam.pId = payload.pId; else delete queryParam.pId;

        const pathname = postAuthRoutes('messages').path;
        navigate({ pathname, search: createSearchParams(`?${queryString.stringify(queryParam)}`).toString() });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.pId, payload.cId])


    return (
        <Card sx={{ mb: 1, height: `810px` }} >
            <CardContent sx={{ p: 0, height: `100%` }}>
                {!isLoading && conversations_list.length > 0 && conversations_list.map((conversation, i) => {
                    return (
                        <Fragment key={i}>
                            <Card sx={{ height: `80px`, backgroundColor: `inherit`, borderRadius: 0, display: `flex`, alignItems: `center`, justifyContent: `center` }} elevation={1} >
                                <CardActionArea onClick={() => viewMessage(conversation.conversation_id, conversation.participant.id)}>
                                    <CardContent sx={{ padding: 0 }}>
                                        <Box sx={{ pl: 4, pr: 4, pt: 1, pb: 1 }} >
                                            <Stack direction="row" alignItems="center" spacing={2} >
                                                <Avatar src={conversation.participant.insta_profile && conversation.participant.insta_profile.profile_picture_url} />
                                                <Box sx={{ width: `100%` }}>
                                                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                        <Typography variant="h6">{conversation.participant.name ? conversation.participant.name : conversation.participant.username}</Typography>
                                                    </Stack>
                                                    <Typography variant="body2" sx={{ fontSize: 12 }}>{conversation.updated_time}</Typography>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                            <Divider sx={{ m: 0 }} />
                        </Fragment>
                    )
                })}
                {!isLoading && conversations_list.length === 0 && <ConversationListNotFound />}
                {isLoading && <ConversationListLoader />}
            </CardContent>
        </Card>
    )

}

export { ConversationsList }