import { SchedulePostServices } from "../services";
import { GlobalAction } from "./GlobalAction";

export const GET_SCHEDULE_POST_CALENDAR_REQUEST = 'GET_SCHEDULE_POST_CALENDAR_REQUEST';
export const GET_SCHEDULE_POST_CALENDAR_SUCCESS = 'GET_SCHEDULE_POST_CALENDAR_SUCCESS';
export const GET_SCHEDULE_POST_CALENDAR_FAILURE = 'GET_SCHEDULE_POST_CALENDAR_FAILURE';

export const SCHEDULE_POST_DETAILS_REQUEST = 'SCHEDULE_POST_DETAILS_REQUEST';
export const SCHEDULE_POST_DETAILS_SUCCESS = 'SCHEDULE_POST_DETAILS_SUCCESS';
export const SCHEDULE_POST_DETAILS_FAILURE = 'SCHEDULE_POST_DETAILS_FAILURE';

export const CREATE_SCHEDULE_POST_REQUEST = 'CREATE_SCHEDULE_POST_REQUEST';
export const CREATE_SCHEDULE_POST_SUCCESS = 'CREATE_SCHEDULE_POST_SUCCESS';
export const CREATE_SCHEDULE_POST_FAILURE = 'CREATE_SCHEDULE_POST_FAILURE';

export const UPDATE_SCHEDULE_POST_REQUEST = 'UPDATE_SCHEDULE_POST_REQUEST';
export const UPDATE_SCHEDULE_POST_SUCCESS = 'UPDATE_SCHEDULE_POST_SUCCESS';
export const UPDATE_SCHEDULE_POST_FAILURE = 'UPDATE_SCHEDULE_POST_FAILURE';

export const SchedulePostAction = {

    /**
     * Action get schedule post calendar
     *
     * @author Jasin 
     */
    getSchedulePostCalendar: (params) => {
        return dispatch => {
            dispatch(request(params));
            return SchedulePostServices.getSchedulePostCalendar(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_SCHEDULE_POST_CALENDAR_REQUEST, request: request } }
        function success(request, response) { return { type: GET_SCHEDULE_POST_CALENDAR_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_SCHEDULE_POST_CALENDAR_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get post details
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
    */
    schedulePostDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return SchedulePostServices.schedulePostDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: SCHEDULE_POST_DETAILS_REQUEST, request: request } }
        function success(request, response) { return { type: SCHEDULE_POST_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: SCHEDULE_POST_DETAILS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for create promo
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 30 May 2022
     */
    createSchedulePost: (params) => {
        return dispatch => {
            dispatch(request(params));
            return SchedulePostServices.createSchedulePost(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CREATE_SCHEDULE_POST_REQUEST, request: request } }
        function success(request, response) { return { type: CREATE_SCHEDULE_POST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CREATE_SCHEDULE_POST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    updateSchedulePost: (params) => {
        return dispatch => {
            dispatch(request(params));
            return SchedulePostServices.updateSchedulePost(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_SCHEDULE_POST_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_SCHEDULE_POST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_SCHEDULE_POST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

}

