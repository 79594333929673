import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component';
import { Grid, Stack } from '@mui/material'
import { CardHeader } from '../../../../../../_components/card'
import { UpdateDataStatusDialog } from '../../../../../../_components/dialog'
import { Add, FilterAlt } from '@mui/icons-material'
import { PromoAction } from '../../../../../../redux/actions';
import { FormSearchInput } from '../../../../../../_components/form';
import { PromosCouponCodesFilter, CreateOrUpdatePromosCouponCode } from '.'
import queryString from 'query-string';
import { Fragment } from 'react';
import { NoData } from '../../../../../../_components';
import { GeneralButton } from '../../../../../../_components';
import { CouponCodeCard, CouponCodeCardLoader } from '../../../coupon-codes';

function PromosCouponCodes() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const promoId = searchParams.get('pId'); // promo id from url

    /** Initialize and declare state */
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });
    const [showCreateCouponModal, setShowCreateCouponModal] = useState(false);
    const [showCouponFilterModal, setShowCouponFilterModal] = useState(false);
    const [payload, setPayload] = useState({ promo_id: promoId, limit: 100, page: 1, filter: { status: searchParams.get('couponStatus') ?? '' }, search: searchParams.get('couponsQuery') ?? '' });


    /** Redux actions and state */
    const { coupon_codes_loading: isLoading, coupon_codes } = useSelector((state) => state.PromoReducer);
    const listPromoCouponCodes = (params) => dispatch(PromoAction.listPromoCouponCodes(params));
    const changeCouponStatus = (params) => dispatch(PromoAction.changeCouponStatus(params));

    useEffect(() => {

        const queryParam = {};
        for (var key of searchParams.keys()) {
            const element = searchParams.get(key);

            if (element === "" || element === null) {
                delete queryParam[key];
            }
            else {
                queryParam[key] = element;
            }
        }

        queryParam.pId = promoId;
        if (payload.search) queryParam.couponsQuery = payload.search; else delete queryParam.couponsQuery;
        if (payload.filter.status) queryParam.couponStatus = payload.filter.status; else delete queryParam.couponStatus;

        navigate({ search: `?${queryString.stringify(queryParam)}` })

        getNext();
        //eslint-disable-next-line
    }, [promoId, payload.filter, payload.search])

    /**
     * function to fetch promo coupon codes list
     * @param {Object} payload - The params which are used for the api
     * @param {string} payload.page - Page number to load
     * @param {string} payload.limit -  Count of the documents per page
     * @param {string} payload.filter - Filter data
     * @return json
     * @author
     * @created_at 30 May 2022
     */
    const getNext = async () => {
        const result = await listPromoCouponCodes(payload);
        if (result.status)
            setPayload({ ...payload, page: payload.page + 1 });
    }

    /**
     * function to open/close status update modal
     * @param {string} id
     * @param {string} status
     * @return view
     * @author
     * @created_at 30 May 2022
     */
    const statusUpdateModal = ({ id, status, item }) => {
        setStatusData({ id: id, status: status, item: item });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    /**
     * function to change coupon code status
     * @param {string} id
     * @return view
     * @author
     * @created_at 30 May 2022
     */
    const updateStatusAction = async (id) => {
        setShowStatusUpdateModal(!showStatusUpdateModal);
        await changeCouponStatus({ 'coupon_id': id });
    }

    /**
     * function to open/close create coupon code modal
     * @param {string} null
     * @return view
     * @author
     * @created_at 30 May 2022
     */
    const createCouponCodesModal = () => {
        setShowCreateCouponModal(!showCreateCouponModal);
    }

    /**
     * function to open/close filter modal
     * @param {string} null
     * @return view
     * @author
     * @created_at 30 May 2022
     */
    const couponFilterModal = () => {
        setShowCouponFilterModal(!showCouponFilterModal);
    }

    /**
     * function to apply filter data to payload
     * @param {string} null
     * @return view
     * @author
     * @created_at 30 May 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData });
    }

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    return (
        <Fragment>
            <CardHeader
                action={(
                    <Stack spacing={1} direction="row">
                        <GeneralButton startIcon={<Add />} label='Create Coupon Codes' onClick={() => createCouponCodesModal()} />
                        <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                        <GeneralButton label={<FilterAlt />} onClick={() => couponFilterModal()} />
                    </Stack>
                )}
            />
            <PromosCouponCodesFilter show={showCouponFilterModal} closeModal={couponFilterModal} applyFilter={applyFilter} couponFilter={payload.filter} />
            <InfiniteScroll dataLength={coupon_codes.length} next={getNext} hasMore={true}>
                <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center" >
                    {
                        coupon_codes.length > 0 && coupon_codes.map((coupon_code, i) => {
                            return <Grid key={i} item xs={12} sm={12} md={3} lg={3} xl={3}><CouponCodeCard category={'promo'} {...coupon_code} promo={{ '_id': promoId }} height='170px' isClickable={true} hasAction={true} canChangeStatus={true} statusUpdateModal={statusUpdateModal} canRedeem={true} /></Grid>;
                        })
                    }
                    {(isLoading && coupon_codes.length === 0) && <CouponCodeCardLoader />}
                    {!isLoading && coupon_codes.length === 0 && <NoData content1={`No`} content2={`Coupon codes available`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} minHeight={`55vh`} />}
                </Grid>
            </InfiniteScroll>
            <CreateOrUpdatePromosCouponCode show={showCreateCouponModal} closeModal={createCouponCodesModal} promo_id={promoId} />
            <UpdateDataStatusDialog show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />
        </Fragment>
    )
}

export { PromosCouponCodes }
