import { apiServices, cookieServices } from '../../_helpers';


export const RestaurantOrdersServices = {
    /**
     * Service to list restaurant orders
     * @param {Object} params - The params which are used for change password api
     * @author Naveen K
     * @created_at 09 Oct 2023
     */
    listRestaurantOrders: (params) => {
        return apiServices.post('/brand/restaurant-orders/list', params).then((response) => {
            return response;
        });
    },


    /**
     * Service for change order status
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 13 Jan 2023
     */
    changeStatus: (params) => {
        return apiServices.put('/brand/restaurant-orders/change-status', params)
            .then((response) => { return response; });

    },

    /**
     * Service for change order status
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 13 Jan 2023
     */
    changeItemStatus: (params) => {
        return apiServices.put('/brand/restaurant-orders/change-item-status', params)
            .then((response) => { return response; });

    },

    /**
     * Service for change order status
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 13 Jan 2023
     */
    restaurantOrderDetails: (params) => {
        return apiServices.post('/brand/restaurant-orders/details', params)
            .then((response) => { return response; });

    },

    /**
     * Service to generate restauratnt bill
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 13 Jan 2023
     */
    generateRestaurantBill: (params) => {
        return apiServices.post('/brand/restaurant-orders/generate-bill', params)
            .then((response) => { return response; });

    },


    /**
     * Service to generate restauratnt bill
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 13 Jan 2023
     */
    getRestaurantOrderBill: (params) => {
        return apiServices.post('/brand/restaurant-orders/bill', params)
            .then((response) => { return response; });

    },


    /**
     * Service to monthly collection
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 13 Jan 2023
     */
    monthlyOrderCollection: (params) => {
        return apiServices.post('/brand/restaurant-orders/collection/monthly', params)
            .then((response) => { return response; });

    },


    /**
     * Service to daily collection
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 13 Jan 2023
     */
    dailyOrderCollection: (params) => {
        return apiServices.post('/brand/restaurant-orders/collection/daily', params)
            .then((response) => { return response; });

    },

    /**
     * Service to get total orders collections
     * @param {Object} params - The params which are used for change password api
     * @author Naveen K
     * @created_at 12 Dec 2023
     */
    totalOrderCollection: (params) => {
        return apiServices.post('/brand/restaurant-orders/collection/total', params).then((response) => {
            return response;
        });
    },

    /**
     * Service to list restaurant orders history
     * @author Naveen K
     * @created_at 11 Dec 2023
     */
    restaurantOrdersHistory: (params) => {
        return apiServices.post('/brand/restaurant-orders/history', params).then((response) => {
            return response;
        });
    },

    /**
     * Service to list todays restaurant orders
     * @author Naveen K
     * @created_at 18 Dec 2023
     */
    todaysRestaurantOrders: (params) => {
        return apiServices.post('/brand/restaurant-orders/todays-order', params).then((response) => {
            return response;
        });
    },

    /**
	 * Service for mobile number select list
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 18 Dec 2023
	 */
	mobileSelectList: (params) => {
		return apiServices.post('/brand/restaurant-orders/mobile-select-list', params).then((response) => {
			return response;
		});
	},

    changePreOrderStatus: (params) => {
        return apiServices.put('/brand/restaurant-orders/pre-order/change-status', params)
            .then((response) => { return response; });

    },

        /**
     * Service to generate restauratnt bill
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 13 Jan 2023
     */
    generateRestaurantPreOrderBill: (params) => {
        return apiServices.post('/brand/restaurant-orders/pre-order/generate-bill', params)
            .then((response) => { return response; });

    },

    todaysItemQuantity: (params) => {
        return apiServices.post('/brand/restaurant-orders/pre-order/item-quantity', params)
            .then((response) => { return response; });

    },

    todaysItemChangeStatus: (params) => {
        return apiServices.put('/brand/restaurant-orders/pre-order/item/change-status', params)
            .then((response) => { return response; });

    },

     /**
     * Service to create order
     * @param {Object} params - The params which are used for the api
     * @author Jasin
     * @created_at 13 Jan 2023
     */
    createOrder: (params) => {
        return apiServices.post('/brand/restaurant-orders/create-order', params)
            .then((response) => {
                let date = new Date();
                date.setTime(date.getTime() + (0.5 * 60 * 24 * 60 * 1000));
                const options = { path: '/', expires: date };
                cookieServices.set('orderId', response.data.order_id, options);
                cookieServices.set('tableId', response.data.table_id, options);
                return response;
            });

    },

    addToCart: (params) => {
        return apiServices.post('/brand/restaurant-cart/add', params)
            .then((response) => { return response; });
    },

    updateCartItemQuantity: (params) => {
        return apiServices.post('/brand/restaurant-cart/update-quantity', params)
            .then((response) => { return response; });
    },

    removeItemFromCart: (params) => {
        return apiServices.post('/brand/restaurant-cart/remove', params)
            .then((response) => { return response; });
    },

    getCartList: (params) => {
        return apiServices.post('/brand/restaurant-cart/list', params)
            .then((response) => { return response; });
    },

    placeOrder: (params) => {
        return apiServices.post('/brand/restaurant-orders/place-order', params)
            .then((response) => { return response; });
    },

    /**
     * Service to list restaurant delivery orders
     * @param {Object} params - The params which are used for api
     * @author Naveen K
     * @created_at 06 Jun 2024
     */
    listRestaurantDeliveryOrders: (params) => {
        return apiServices.post('/brand/restaurant-orders/delivery-list', params).then((response) => {
            return response;
        });
    },

};