import React from 'react';
import { styled, Card } from '@mui/material';

const RestaurantOrdersCardStyled = styled(({ ...props }) => <Card {...props} />)`
	min-height: 480px;
	height: 100%;
	& .MuiCardHeader-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	& .MuiCardContent-root {
		padding-bottom: 1rem !important;
		overflow-x: auto;
	}
	& .MuiCardActions-root {
		padding: 1rem 2rem 2rem;
		display: flex;
    	flex-wrap: wrap;
		& .MuiButton-root {
			margin-right: 8px !important;
			margin-top: 8px !important;
		}
	}

	& .items {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	& .MuiTable-root {
		width: 100%;
		table-layout: fixed;
	}
`;

export { RestaurantOrdersCardStyled };
