import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchAction } from "../../../redux/actions";
import { FormSearchInput } from "../../../_components/form";
import { CouponCodes } from "./coupon-codes";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import queryString from 'query-string';
import { CardHeader } from "../../../_components/card";
import { Fragment } from "react";
import { postAuthRoutes } from "../../../routes";

function Search() {

    /** Initialize plugins and variables */
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Redux reducer state */
    const { search_result_loading: isLoading, search_result } = useSelector((state) => state.SearchReducer);

    /** Redux reducer actions */
    const getSearchResult = (params) => dispatch(SearchAction.search(params));

    /** Initialize and declare state */
    const [payload, setPayload] = useState({ limit: 100, page: 1, search: searchParams.get('q') ?? '' });

    /**
     * function to apply the search key words on the search list
     * @param {String} text Search keyword used
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    };

    useEffect(() => {

        const queryParam = {};
        queryParam.p = payload.page;
        if (payload.search) queryParam.q = payload.search; else delete queryParam.q;
        const pathname = postAuthRoutes('search').path;
        navigate({ pathname, search: createSearchParams(`?${queryString.stringify(queryParam)}`).toString() });

        getNext();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.search, payload.limit]);

    /**
     * function to fetch search list
     * @param {Object} payload - The params which are used for the api
     * @param {string} payload.page - Page number to load
     * @param {string} payload.limit -  Count of the documents per page
     * @param {string} payload.search - Search key
     * @return json
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const getNext = async () => {
        const result = await getSearchResult(payload);
        if (result.status)
            setPayload({ ...payload, page: payload.page + 1 });
    };

    return (
        <Fragment>
            <Box>
                <CardHeader title={postAuthRoutes('search').name} />
            </Box>
            <Box sx={{ pt: payload.search ? '0px' : '30vh', pb: 5 }}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={12} md={payload.search ? 4 : 8} lg={payload.search ? 4 : 8} xl={payload.search ? 4 : 8} >
                        <FormSearchInput getSearchText={applySearch} searchText={payload.search} sx={{ zoom: payload.search ? '1.2' : '1.9' }} />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                {payload.search &&
                    (
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <CouponCodes coupons={search_result} getNext={getNext} isLoading={isLoading} />
                            </Grid>
                        </Grid>
                    )
                }
            </Box>
        </Fragment>
    );
}


export { Search };