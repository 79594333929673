import { Facebook } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SignInAction, GlobalAction } from "../../../redux/actions";
import { useFacebookSDK } from "../../../_helpers/initFacebookSdk";
import { SignInModal } from "./sign-in-modal";
import { preRegisterRoutes } from "../../../routes/preRegisterRoutes";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";


function InstagramSignIn() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /** Initialize and declare state */
    const [showSignInCouponCouponModal, setShowSignInCouponModal] = useState(false);

    /** Redux reducer state */
    const { loading: isLoading, instagram_sign_in_loading: isInstaSignInLoading, user } = useSelector((state) => state.SignInReducer);

    const instagramSignIn = (params) => dispatch(SignInAction.instagramSignIn(params));
    const toastMessage = (params) => dispatch(GlobalAction.showToastMessage(params));

    const { fbsdk: { newUser, authResponse }, handleLogin } = useFacebookSDK();
    useEffect(() => {
        if (newUser) {
            instagramSignIn(authResponse).then(isSignIn => {
                if (isSignIn.status === 1) {
                    const brand = isSignIn.data;
                    const redirectRoute = preRegisterRoutes(brand);
                    toastMessage(isSignIn.message);
                    navigate(redirectRoute);
                } else {
                    toastMessage(isSignIn.message);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newUser, authResponse]);

    useEffect(() => {
        if (Object.keys(user).length > 0 && user.status) {
            const redirectRoute = preRegisterRoutes(user);
            navigate(redirectRoute);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * function to open/close redeem coupon Update modal
     * @param {string} null
     * @return view
     * @author
     * @created_at 21 June 2022
     */
    const signInModal = () => {
        setShowSignInCouponModal(!showSignInCouponCouponModal);
    };

    /**
     * function to handle click to direct to story details page
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleSignInModalClick() {
        signInModal();
    }

    return (
        <Fragment>
            {/* <Button
                // style={{ marginBottom: `20px` }}
                startIcon={<Facebook />}
                type="button"
                variant="contained"
                onClick={handleLogin}
                disabled={isLoading || isInstaSignInLoading}
                color={`primary`}
                fullWidth
            >
                Continue with Facebook
            </Button> */}

            <Button
                // style={{ marginBottom: `20px` }}
                startIcon={<Facebook />}
                type="button"
                variant="contained"
                onClick={handleSignInModalClick}
                disabled={isLoading || isInstaSignInLoading}
                color={`primary`}
                fullWidth
            >
                Continue with Facebook
            </Button>

            <SignInModal show={showSignInCouponCouponModal} closeModal={signInModal} handleLogin={handleLogin} />

        </Fragment>
    );
}

export { InstagramSignIn };
