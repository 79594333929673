import React from 'react';
import { DialogActions, styled } from '@mui/material';

const DialogActionsStyled = styled(({ ...props }) => <DialogActions {...props} />)`
	justify-content: flex-start;
	& .MuiStack-root{
		width: 100%;
	}
`;

export { DialogActionsStyled };
