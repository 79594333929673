import { Box, Chip, CardContent, CardActions, Typography, CardHeader, Stack, SvgIcon } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RestaurantOrdersAction } from '../../../../../redux/actions';
import { postAuthRoutes } from '../../../../../routes';
import { GeneralButton, CurrencySymbol } from '../../../../../_components';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { Fragment } from 'react';
import { RestaurantOrderBillModal } from '../restaurant-order-bill/RestaurantOrderBillModal';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { RestaurantOrdersCardStyled } from './RestaurantOrdersCardStyled';
import { RestaurantStatusChangeModal } from './restaurant-status-change-modal';
import { ReactComponent as portionIcon } from '../../../../../_theme/images/restaurant/portion.svg';
import { INFLUENCER_APP_URL } from "../../../../../_helpers/config";

const statusLabels = {
	'active': { text: 'Active', color: 'info' },
	'open': { text: 'Open', color: 'info' },
	'order_placed': { text: 'Order Placed', color: 'info' },
	'cooking_in_progress': { text: 'Cooking In progress', color: 'warning' },
	'ready_to_deliver': { text: 'Ready To Deliver', color: 'warning' },
	'delivered': { text: 'Delivered', color: 'warning' },
	'bill_issued': { text: 'Bill issued', color: 'info' },
	'paid': { text: 'Paid', color: 'success' },
	'cancelled': { text: 'Order Cancelled', color: 'error' },
};


function RestaurantOrdersCard({ restaurant_order }) {

	const { _id, table_short_code, order_status, items, order_id, order_otp, order_type, mobile_number, delivery_address } = restaurant_order;

	/** Initialize plugins and variables */
	let navigate = useNavigate();
	const dispatch = useDispatch();

	/** Initialize and declare state */
	const [anchorEl, setAnchorEl] = useState(null);
	const [showOrderBillModal, setShowOrderBillModal] = useState(false);
	const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
	const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });

	const { change_restaurant_item_status_loading: isItemLoading, change_restaurant_order_status_loading: isLoading } = useSelector((state) => state.RestaurantOrdersReducer);

	const changeStatus = (params) => dispatch(RestaurantOrdersAction.changeStatus(params));

	const changeItemStatus = (params) => dispatch(RestaurantOrdersAction.changeItemStatus(params));

	function handleGenerateBillClick() {
		setAnchorEl(null);
		setTimeout(() => { orderModal(); }, 100);
	}

	function handleStatusChangeClick(order_id, status) {
		setAnchorEl(null);
		setTimeout(() => {
			statusUpdateModal({ id: order_id, status });
		}, 100);
	}

	const orderModal = () => {
		setShowOrderBillModal(!showOrderBillModal);
	};

	const itemStatusChangeAction = async (order_id, item_id, status) => {
		await changeItemStatus({ order_id, item_id, status });
	};

	const changeBillGeneratedStatus = async (order_id) => {
		await changeStatus({ order_id, status: 'bill_issued' });
	};


	const updateOrderStatus = async (order_id, status) => {
		if (status === 'bill_issued')
			handleGenerateBillClick();
		else if (status === 'paid' || status === 'cancelled')
			handleStatusChangeClick(order_id, status);
		else
			await changeStatus({ order_id, status });
	};


	const isButtonDisabled = (order_status, status = 'bill_issued', main = false) => {
		const statusIndex = Object.keys(statusLabels).indexOf(status);
		const orderStatusIndex = Object.keys(statusLabels).indexOf(order_status);
		if (main) {
			return (statusIndex <= orderStatusIndex || (status === 'paid' && order_status !== 'bill_issued')) ? true : false;
		}
		return orderStatusIndex >= statusIndex;
	};

	const getNextStatus = (status) => {
		const currentIndex = Object.keys(statusLabels).indexOf(status);
		if (currentIndex < Object.keys(statusLabels).length - 1) {
			const nextAction = Object.keys(statusLabels)[currentIndex + 1];
			return nextAction;
		}
		return '';
	};

	const OrderStatus = ({ order_status, order_otp }) => {
		const orderStatus = statusLabels[order_status];
		return (
			<Stack direction="row" spacing={2}>
				<Typography variant="body2" gutterBottom>{order_otp}</Typography>
				<Chip size="small" label={orderStatus.text} color={orderStatus.color} />
			</Stack>
		);
	};

	const ActionRow = ({ order_id, item_id, item_status }) => {
		const nextStatusKey = getNextStatus(item_status);
		let nextStatus = statusLabels[nextStatusKey];
		let isItemDisabled = isButtonDisabled(item_status);

		if (item_status === 'order_placed' || item_status === 'cooking_in_progress' || item_status === 'ready_to_deliver' || item_status === 'delivered') {
			return <GeneralButton size='small' label={nextStatus.text} color={nextStatus.color} onClick={() => itemStatusChangeAction(order_id, item_id, nextStatusKey)} disabled={isItemDisabled} />;
		}
		else {
			return '-';
		}


	};

	const OrderActionButtons = () => {
		let buttons = Object.keys(statusLabels).map((status) => {
			if (status !== 'order_placed' && status !== 'open') {
				let isItemDisabled = isButtonDisabled(order_status, status, true);
				let statusData = statusLabels[status];
				if (status === 'bill_issued')
					statusData.text = 'Requested for Bill';
				return <GeneralButton size='medium' label={statusData.text} color={statusData.color} onClick={() => updateOrderStatus(order_id, status)} disabled={isItemDisabled} />;
			}
			return '';
		});
		return <Box>{buttons}</Box>;
	};

	const statusUpdateModal = ({ id, status, item }) => {
		setStatusData({ id: id, status: status, item: '' });
		setShowStatusUpdateModal(!showStatusUpdateModal);
	};

	const updateStatusAction = async (id) => {
		setShowStatusUpdateModal(!showStatusUpdateModal);
		await changeStatus({ order_id: statusData.id, status: statusData.status });
	};

	/**
	 * function to redirect to view bill page
	 * @param {string} null
	 * @return view
	 * @author Naveen K
	 * @created_at 22 Jul 2024
	 */
	const viewBillDetails = () => {
		const navigateBillUrl = `${INFLUENCER_APP_URL}/restaurant/order-bill-details/${order_id}`;
		window.open(navigateBillUrl, '_blank');
	}

	return (
		<Fragment>
			<RestaurantOrdersCardStyled >
				<CardHeader title={<Fragment>{order_type && order_type === 'order_by_vehicle' ? _id : order_type && order_type === 'delivery' ? mobile_number : table_short_code} <OrderStatus order_status={order_status} order_otp={order_otp} /></Fragment>} ></CardHeader>
				{order_status === 'bill_issued' && (
					<Box display="flex" justifyContent="center" alignItems="center" paddingLeft={'10px'} paddingRight={'10px'}>
						<GeneralButton size='medium' label={'View Bill'} onClick={() => viewBillDetails()} fullWidth/>
					</Box>
				)}
				{delivery_address &&
					<Box display="flex" justifyContent="flex-start" alignItems="center" paddingLeft={'10px'}>
						<Stack spacing={1}>
							<Typography variant="body1">{`Delivery Address: `}</Typography>
							<Typography variant="body2" style={{ height: `60px` }}>{`${delivery_address}`}</Typography>
						</Stack>
					</Box>
				}
				{items.length > 0 &&
					<Fragment>
						<CardContent>
							<Box className='items'>
								<Table>
									<TableHead style={{ display: `table-header-group` }}>
										<TableRow>
											<TableCell width={`40%`} align="center">Item </TableCell>
											<TableCell width={`5%`} align="center" style={{ whiteSpace: 'nowrap', paddingLeft: 0 }}>Qty </TableCell>
											<TableCell width={`30%`} align="center">Current Status </TableCell>
											<TableCell width={`30%`} align="center">Action </TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{items.map((row) => (
											<TableRow key={row.cart_id} >
												<TableCell align="center">
													<Typography variant='body2' style={{ textTransform: 'uppercase' }}> {`${row.title}`} {row.have_portions ? `(${row.portion_name})` : ``} </Typography>
													<Typography variant='caption' color="secondary" style={{ display: `flex`, alignItems: `center`, justifyContent: `center`, fontWeight: 'bold' }}>
														<Box><CurrencySymbol />{`${row.discounted_price} X ${row.quantity}`}</Box>
													</Typography>
												</TableCell>
												<TableCell align="center" style={{ padding: 0 }}><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>{row.quantity}</Box></TableCell>
												<TableCell align="center"><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>{statusLabels[row.order_item_status].text || ''}</Box></TableCell>
												<TableCell align="center"><ActionRow order_id={order_id} item_id={row.order_item_id} item_status={row.order_item_status} /></TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</Box>
						</CardContent>
						<CardActions>
							<OrderActionButtons />
						</CardActions>
					</Fragment>
				}
				{items.length === 0 && (
					<CardContent sx={{ opacity:'0.25'}}>
						<Box display={'flex'} justifyContent={'center'}>
							<SvgIcon component={portionIcon} viewBox="0 0 180 180" color="disabled" sx={{ fontSize: 350 }} />
						</Box>
					</CardContent>
				)}
			</RestaurantOrdersCardStyled>
			<RestaurantOrderBillModal show={showOrderBillModal} closeModal={orderModal} orderId={order_id} />
			<RestaurantStatusChangeModal show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />
		</Fragment>
	);
}

export { RestaurantOrdersCard };