import { CalendarServices } from "../services";

export const GET_BRAND_CALENDAR_REQUEST = 'GET_BRAND_CALENDAR_REQUEST';
export const GET_BRAND_CALENDAR_SUCCESS = 'GET_BRAND_CALENDAR_SUCCESS';
export const GET_BRAND_CALENDAR_FAILURE = 'GET_BRAND_CALENDAR_FAILURE';

export const CalendarAction = {

    /**
     * Action get brand calendar
     *
     * @author Jasin
     */
    getCalendar: (params) => {
        return dispatch => {
            dispatch(request(params));
            return CalendarServices.getCalendar(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_BRAND_CALENDAR_REQUEST, request: request } }
        function success(request, response) { return { type: GET_BRAND_CALENDAR_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_BRAND_CALENDAR_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

}
