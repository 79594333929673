import React from 'react'
import { Box } from '@mui/material';
import { LineTypographyStyled } from '.';

function LineTypography({ children }) {
    return (
        <LineTypographyStyled>
            <Box className={`left-line`} />
            <Box className={`text-wrapper`}> {children} </Box>
            <Box className={`right-line`} />
        </LineTypographyStyled>
    );
}

export { LineTypography }