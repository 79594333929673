import React, { useState } from "react";
import { IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { FormHelperText } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { maskInput } from "../_helpers";
import { FormPasswordStyled } from ".";


/**
 * Declare default value of input properties
 */
const defaultProps = {
    isReadOnly: false,
    maxLength: 255,
    className: '',
    placeholder: '',
};

const FormPassword = ({ name, label, placeholder, type, className, id, value, onChange, isReadOnly, maxLength, maskType, tabIndex, error, info, ...props }) => {

    const [values, setValues] = useState({ password: '', showPassword: false });

    const checkKeyPress = (e) => {
        return maskInput(maskType, e);
    };

    const handleChange = (e) => {
        onChange(e);
    }

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <FormPasswordStyled fullWidth  {...props} >
            <InputLabel htmlFor={name}>{label}</InputLabel>
            <OutlinedInput
                aria-describedby={`helper_${name}`}
                type={values.showPassword ? 'text' : 'password'}
                placeholder={placeholder ? placeholder : label}
                name={name}
                value={value}
                onChange={handleChange}
                onKeyPress={checkKeyPress}
                label={label}
                autoComplete={`password`}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {values.showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                        </IconButton>
                    </InputAdornment>
                }
                error={error ? true : false}
                {...props}
            />
            {(info) && <FormHelperText id={`helper_${name}`}>{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error >{error}</FormHelperText>}
        </FormPasswordStyled>
    );
};

FormPassword.defaultProps = defaultProps;

export { FormPassword };
