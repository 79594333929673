import { Container, Grid } from '@mui/material'
import React from 'react'
import { postAuthRoutes } from '../../../../routes'
import { CardHeader } from '../../../../_components/card/card-header/CardHeader'
import { ConversationsList, ConversationPanel } from '.'
import { Fragment } from 'react'

function Messages() {

    /** Initialize plugins and variables */

    /** Redux actions and state */

    /** Initialize and declare state */

    return (
        <Fragment>
            <CardHeader
                title={postAuthRoutes('messages').name}
            />
            <Container>
                <Grid container spacing={2} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                        <ConversationsList />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} >
                        <ConversationPanel />
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    )
}

export { Messages }