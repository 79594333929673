import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from "react-helmet";

import { Box, Grid, Typography } from '@mui/material';
import { FormInput, FormPassword, FormSelect } from '../../../_components/form';
import { SubmitButton } from '../../../_components/controls';

import { APP_NAME, handleInputChange, validateForm } from '../../../_helpers';
import { PreSignIn } from '../../../_layouts/pre-signin';
import { preAuthRoutes } from '../../../routes';
import { Header } from '../../../_layouts/pre-signin/header';

import { RegisterAction, SignInAction, UserAction, GlobalAction, CurrencyAction } from '../../../redux/actions';
import { validate } from '.'
import { preRegisterRoutes } from '../../../routes/preRegisterRoutes';
import { Fragment } from 'react';

function CreateAccount() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();

    const params = new URLSearchParams(search);
    const approvalKey = params.get('aKey'); // invoice id from url

    // Initialize form input values to null
    const inputs = { name: '', email: '', mobile_number: '', password: '', confirm_password: '', approval_key: approvalKey, brand_category: '', currency: '' };

    /** Redux actions and state */
    const { theme } = useSelector((state) => state.GlobalReducer);
    const { insta_signup_loading: isLoading, insta_register_errors: registrationErrors } = useSelector((state) => state.RegisterReducer);
    const createAccount = (params) => dispatch(RegisterAction.createAccount(params));
    const brandCategorySelectList = (params) => dispatch(UserAction.brandCategorySelectList(params));
    const currencySelectList = (params) => dispatch(CurrencyAction.currencySelectList(params));
    const notify = (msg) => dispatch(GlobalAction.showToastMessage(msg));
    const { brand_category_select_list } = useSelector((state) => state.UserReducer);
    const { currency_select_list } = useSelector((state) => state.CurrencyReducer);

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {
        setErrors({ ...registrationErrors });
    }, [registrationErrors])

    useEffect(() => {
        brandCategorySelectList();
        currencySelectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        if (approvalKey) {
            SignInAction.getUserStatus({ approval_key: approvalKey }).then((data) => {
                if (data && data.status === 1) {
                    let brand = data.data;
                    const redirectRoute = preRegisterRoutes(brand);
                    navigate(redirectRoute);
                } else {
                    navigate(preAuthRoutes('login').path, { search: `` });
                }
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle registration form submit action
     * @param {object} e form object
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchRegisterAction();
    }

    /**
     * function to dispatch registration api
     * @author Akshay N
     * @created_at 30 May 2022
     */
    async function dispatchRegisterAction() {
        const registered = await createAccount(data);
        if (registered && registered.status === 1) {
            notify(registered.message)
            let brand = registered.data;
            const redirectRoute = preRegisterRoutes(brand);
            navigate(redirectRoute);
        }
    }

    return (
        <Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${preAuthRoutes('create_account').name}`}</title>
            </Helmet>
            <PreSignIn>
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3}>
                        <Grid item xs={5} sm={5} m={0} p={0}>
                            <Header title={`${preAuthRoutes('create_account').name}`} />
                        </Grid>
                        <Grid item xs={7} sm={7} >
                            <Grid container spacing={2} >
                                <Grid item xs={12} sm={12} >
                                    <Typography variant="h4" fontWeight="medium" color={theme === 'dark' ? "#fff" : "#inherit"} align="center" mt={2} >{`${preAuthRoutes('create_account').name}`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormInput
                                        tabIndex={1}
                                        label='Name'
                                        name='name'
                                        value={data.name}
                                        error={action.isSubmitted && errors.name ? errors.name : ''}
                                        onChange={handleChange}
                                        autoFocus={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormInput
                                        tabIndex={2}
                                        label='Email'
                                        name='email'
                                        value={data.email}
                                        error={action.isSubmitted && errors.email ? errors.email : ''}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormInput
                                        tabIndex={3}
                                        label='Mobile Number'
                                        name='mobile_number'
                                        value={data.mobile_number}
                                        error={action.isSubmitted && errors.mobile_number ? errors.mobile_number : ''}
                                        onChange={handleChange}
                                        maskType={`digit-only`}
                                        inputProps={{ maxLength: 12 }}
                                        autoComplete={`mobile_number`}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <FormSelect
                                        tabIndex={4}
                                        label={`Brand Category`}
                                        name={`brand_category`}
                                        placeholder={`Select brand category`}
                                        onChange={handleChange}
                                        data={brand_category_select_list}
                                        className="col-md-6"
                                        value={data.brand_category}
                                        error={action.isSubmitted && errors.brand_category ? errors.brand_category : ''}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <FormSelect
                                        tabIndex={4}
                                        label={`Currency`}
                                        name={`currency`}
                                        placeholder={`Select currency`}
                                        onChange={handleChange}
                                        data={currency_select_list}
                                        className="col-md-6"
                                        value={data.currency}
                                        error={action.isSubmitted && errors.currency ? errors.currency : ''}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormPassword
                                        tabIndex={5}
                                        label='Password'
                                        name='password'
                                        value={data.password}
                                        error={action.isSubmitted && errors.password ? errors.password : ''}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormPassword
                                        tabIndex={6}
                                        label='Re-enter password'
                                        name='confirm_password'
                                        value={data.confirm_password}
                                        error={action.isSubmitted && errors.confirm_password ? errors.confirm_password : ''}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} style={{ paddingTop: `0` }}>
                                    <Typography variant="caption">Use 6 or more characters with a mix of letters, numbers and symbols</Typography>
                                </Grid>
                            </Grid>
                            <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
                                <SubmitButton tabIndex={4} label={'Create'} loading={isLoading} fullWidth />
                            </Box>
                        </Grid>
                    </Grid>
                </form>

            </PreSignIn>
        </Fragment>
    );
}

export { CreateAccount };
