import { GlobalAction } from ".";
import { AppUserServices } from "../services";


export const GET_APP_USERS_REQUEST = 'GET_APP_USERS_REQUEST';
export const GET_APP_USERS_SUCCESS = 'GET_APP_USERS_SUCCESS';
export const GET_APP_USERS_FAILURE = 'GET_APP_USERS_FAILURE';

export const ADD_APP_USER_REQUEST = 'ADD_APP_USER_REQUEST';
export const ADD_APP_USER_SUCCESS = 'ADD_APP_USER_SUCCESS';
export const ADD_APP_USER_FAILURE = 'ADD_APP_USER_FAILURE';

export const UPDATE_APP_USER_REQUEST = 'UPDATE_APP_USER_REQUEST';
export const UPDATE_APP_USER_SUCCESS = 'UPDATE_APP_USER_SUCCESS';
export const UPDATE_APP_USER_FAILURE = 'UPDATE_APP_USER_FAILURE';

export const CHANGE_APP_USER_STATUS_REQUEST = 'CHANGE_APP_USER_STATUS_REQUEST';
export const CHANGE_APP_USER_STATUS_SUCCESS = 'CHANGE_APP_USER_STATUS_SUCCESS';
export const CHANGE_APP_USER_STATUS_FAILURE = 'CHANGE_APP_USER_STATUS_FAILURE';

export const USER_ACCOUNT_TYPE_SELECT_LIST_REQUEST = 'USER_ACCOUNT_TYPE_SELECT_LIST_REQUEST';
export const USER_ACCOUNT_TYPE_SELECT_LIST_SUCCESS = 'USER_ACCOUNT_TYPE_SELECT_LIST_SUCCESS';
export const USER_ACCOUNT_TYPE_SELECT_LIST_FAILURE = 'USER_ACCOUNT_TYPE_SELECT_LIST_FAILURE';

export const AppUserAction = {

    /**
     * Action get User list 
     * @param {Object} params - The params which are used for the api
     * @author 
     * @created_at 15 June 2022
     */
    getUsersList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return AppUserServices.getUsersList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_APP_USERS_REQUEST, request: request } }
        function success(request, response) { return { type: GET_APP_USERS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_APP_USERS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action add user 
     * @param {Object} params - The params which are used for the api
     * @author 
     * @created_at 15 June 2022
     */
    addUser: (params) => {
        return dispatch => {
            dispatch(request(params));
            return AppUserServices.addUser(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ADD_APP_USER_REQUEST, request: request } }
        function success(request, response) { return { type: ADD_APP_USER_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ADD_APP_USER_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action update user 
     * @param {Object} params - The params which are used for the api
     * @author 
     * @created_at 15 June 2022
     */
    updateUser: (params) => {
        return dispatch => {
            dispatch(request(params));
            return AppUserServices.updateUser(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_APP_USER_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_APP_USER_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_APP_USER_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action to change user status 
     * @param {Object} params - The params which are used for the api
     * @author 
     * @created_at 15 June 2022
     */
    changeUserStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return AppUserServices.changeUserStatus(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_APP_USER_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_APP_USER_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_APP_USER_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action for Category select list
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 11 Oct 2023
    */
    accountTypeSelectList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return AppUserServices.accountTypeSelectList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: USER_ACCOUNT_TYPE_SELECT_LIST_REQUEST, request: request } }
        function success(request, response) { return { type: USER_ACCOUNT_TYPE_SELECT_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: USER_ACCOUNT_TYPE_SELECT_LIST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
};



