import borders from "../../base/borders";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	defaultProps: {},
	styleOverrides: {
		root: {
			display: 'flex',
			flexDirection: 'column',
			position: 'relative',
			minWidth: 0,
			wordWrap: 'break-word',
			backgroundClip: 'border-box',
			borderRadius: borders.borderRadius.xxl,
			overflow: 'visible',
			boxShadow: 'none'
		}
	},
	variants: []
};
