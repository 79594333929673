import React from 'react';
import { styled } from '@mui/material';
import { Dialog } from '../../../../../_components';

const RestaurantOrderBillModalStyled = styled(({ ...props }) => <Dialog maxWidth="md" {...props} />)`
	& .MuiPaper-root {
		border-radius: ${(props) => props.theme.borders.borderRadius.section};
		padding: ${(props) => `${props.theme.spacing(3)}`};
		display: flex;
		border: none;
		align-items: left;
		background: ${(props) => (props.view === 'order_placed' ? `#005600` : props.theme.palette.background.card)};
		& .items {
			& .MuiTableCell-root {
				padding: 5px;
			}
		}
		& .content {
			margin-top: 10px;
			& .MuiGrid-container {
				padding: 5px;
				border-bottom: 1px dashed #545454;
			}
			& .MuiGrid-item:nth-of-type(2) {
				display: flex;
				justify-content: right;
			}
		}
		& .total-amount {
			padding: 5px;
			padding-top: 15px;
			padding-bottom: 15px;
			margin-top: 10px;
			& .MuiGrid-item:nth-of-type(2) {
				display: flex;
				justify-content: right;
			}
		}

		& .action {
			width: 100%;
			padding: 20px;
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}
`;

export { RestaurantOrderBillModalStyled };
