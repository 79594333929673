import { SignIn, CreateAccount, BillingAddress, ForgotPassword, ConfirmEmail, ResetPassword } from "../app/pre-signin";
import { ErrorElement } from "../_components/layout/error-element";

/**
 * path: the URL path of the route
 * name: the name of the route, used for display purposes
 * element: the React component to be rendered when the route is accessed
 * layout: the layout component to be used for rendering the route
 * isMenuItem: a boolean flag to indicate whether the route should be displayed in a menu
 * menuIcon: the icon to be used for the route in the menu
 * module: the module that the route belongs to
 * isModuleMenuItem: a boolean flag to indicate whether the route should be displayed in the module's menu
 * isMainElement: a boolean flag to indicate whether the route should be displayed as the main content of the page
 * errorElement: the React component to be rendered when an error occurs while rendering the route
 * menuPermission: the permission required to access the route
 */

const allRoutes = {

    'login': {
        path: "/login",
        name: "Log in",
        element: <SignIn />,
        layout: "/pre-auth",
        isMenuItem: false,
        menuIcon: '',
        module: 'auth',
        isModuleMenuItem: false,
        isMainElement: true,
        errorElement: <ErrorElement />,
        menuPermission: ''
    },
    'create_account': {
        path: "/create-account",
        name: "Create Account",
        element: <CreateAccount />,
        layout: "/pre-auth",
        isMainElement: true,
        errorElement: <ErrorElement />
    },
    'billing_address': {
        path: "/billing-address",
        name: "Billing Address",
        element: <BillingAddress />,
        layout: "/pre-auth",
        isMainElement: true,
        errorElement: <ErrorElement />
    },
    'forgot_password': {
        path: "/forgot-password",
        name: "Forgot password",
        element: <ForgotPassword />,
        layout: "/pre-auth",
        isMainElement: true,
        errorElement: <ErrorElement />
    },
    'confirm_email': {
        path: "/confirm-email/:key",
        name: "Confirm email address",
        element: <ConfirmEmail />,
        layout: "/pre-auth",
        isMainElement: true,
        errorElement: <ErrorElement />
    },
    'reset_password': {
        path: "/reset-password",
        name: "Reset password",
        element: <ResetPassword />,
        layout: "/pre-auth",
        isMainElement: true,
        errorElement: <ErrorElement />
    },
    'non_facebook_user': {
        path: "/non_facebook_user",
        name: "Do not have a facebook account",
        element: <CreateAccount />,
        layout: "/pre-auth",
        isMainElement: true,
        errorElement: <ErrorElement />
    },
};


/**
 * The function returns all routes or routes of a specific key type based on the input parameter.
 * @param {String} [keyType=null] - The `keyType` parameter is an optional parameter that specifies the type of
 * key to be used for authentication. If a `keyType` is provided, the function returns only the routes
 * that require that specific type of key for authentication. If `keyType` is not provided, the
 * function returns all
 * @returns {Object} The function `preAuthRoutes` is being returned. The function takes an optional parameter
 * `keyType` and returns either all routes or routes specific to the `keyType` provided. The
 * `allRoutes` variable is likely an object containing different routes for different types of users or
 * access levels.
 * @author Akshay N
 * @created_at 02/04/2023
 */
const preAuthRoutes = (keyType = null) => {
    return keyType ? allRoutes[keyType] : allRoutes;
};

export { preAuthRoutes };