import React from 'react';
import { styled, Box } from '@mui/material';

const RestaurantCartStyled = styled(({ ...props }) => <Box {...props} />)`
	& .place-order {
		padding-top: 20px;
		width: 100%;
	}
`;

export { RestaurantCartStyled };
