import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { Collapse, Divider, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Box } from '@mui/material';
import { ShortLogo } from '../../../_components';

import { postAuthMenuBarRoutes } from '../../../routes';
import { SideBarStyled } from './SideBarStyled';
import { ExpandLess, ExpandMore, Menu as MenuIcon } from '@mui/icons-material';
import { GlobalAction } from '../../../redux/actions';
import { AbilityCan } from '../../../_helpers/permission/AbilityCan';
import { Fragment } from 'react';

function SideBar() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const location = useLocation();
    const menuRoutes = Object.values(postAuthMenuBarRoutes());

    /* Destructuring the state from redux store. */
    const setDrawer = (params) => dispatch(GlobalAction.setDrawer(params));
    const { isDrawerOpen } = useSelector((state) => state.GlobalReducer);
    const { user } = useSelector((state) => state.UserReducer);

    /* Initializing the state with the default values. */
    const [anchorEl, setAnchorEl] = useState(null);
    const [opens, setOpens] = useState(false);
    const [isHovered, setIsHovered] = useState(false)

    const normalRegistrationRoutes = ["promos", "create_promos", "update_promos", "promo_details", "generate_coupons", "giveaways", "create_giveaways", "update_giveaways", "giveaway_details", "giveaways_coupon_codes", "stories", "stories_detail", "influencer", "influencer_details", "instagram", "schedule_post", "schedule_post_details", "create_schedule_post", "update_schedule_post", "schedule_post_specifications", "messages", "calendar", "search"];
    const normalMenuRoutes = menuRoutes.filter(obj => !normalRegistrationRoutes.includes(obj.slug));
    const selectedMenuRoutes = user.registration_slug === 'normal_registration' ? normalMenuRoutes : menuRoutes;

    /**
     * This function sets the anchor element and toggles the state of a boolean variable.
     * @param {Object} event - The event parameter is an object that contains information about the event that
     * triggered the handleClick function. This could be a mouse click, a keyboard press, or any other
     * type of user interaction with the application. The event object contains properties such as the
     * target element, the type of event, and any additional data
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpens(!opens);
    };

    /**
     * The function handleClose sets the value of AnchorEl to null.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const handleClose = () => { setAnchorEl(null); };

    /**
     * The function toggles the state of a drawer and sets the hovered state to false.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const toggleDrawer = () => {
        setDrawer(!isDrawerOpen);
        setIsHovered(false);
    };

    /**
     * This is a function that sets a state variable to true when the mouse enters a certain area.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const onMouseEnter = () => { setIsHovered(true); };

    /**
     * The function sets the state of "isHovered" to false when the mouse leaves an element.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const onMouseLeave = () => { setIsHovered(false); };


    return (
        <SideBarStyled>
            <Drawer variant="permanent" open={isDrawerOpen} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className="menu-vertical">
                <Box className={`${isDrawerOpen ? `drawer-open` : ``} ${isHovered ? `is-hovered` : ``}`} >
                    <Box className='menu-bar-action'>
                        <ShortLogo height="20" className="logo" />
                        <IconButton edge="start" color='warning' onClick={toggleDrawer} >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    <Box className='menu-list'>
                        <List>
                            {selectedMenuRoutes.map((route, index) => {

                                var splitPathName = location.pathname.split('/');
                                var parentPathName = splitPathName.slice(0, 2).join('/');

                                if (route.hasOwnProperty('children')) {

                                    const isParentActive = route.children.some((routeChild, index) => {
                                        return (routeChild.path === location.pathname) ? `active` : ((parentPathName === routeChild.path) ? 'active' : '')
                                    })

                                    return (
                                        <Fragment key={index}>
                                            <AbilityCan key={index} I={route.menuPermission} passThrough={((!route.menuPermission) || (user.role_slug === 'developer')) ? true : false}>
                                                {route.hasSeparation && <Divider sx={{ margin: `5px 0` }} />}
                                                <ListItemButton onClick={handleClick} className={isParentActive ? 'active' : ''} >
                                                    <ListItemIcon>   <route.menuIcon />  </ListItemIcon>
                                                    <ListItemText className={`menu-name ${isDrawerOpen ? `show-menu-name` : `hide-menu-name`}`} >{route.name}</ListItemText>
                                                    {((isDrawerOpen || isHovered)) && <ListItemIcon className='list-item-action'>{opens ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>}
                                                </ListItemButton>
                                                <Collapse in={((isDrawerOpen || isHovered)) && opens} unmountOnExit>
                                                    <List component="div" disablePadding>
                                                        {route.children.map((routeChild, index) => {
                                                            return (
                                                                <ListItemButton key={index} component={Link} to={routeChild.path} className={(routeChild.path === location.pathname) ? `sub-menu-active` : ((parentPathName === routeChild.path) ? 'active' : '')}>
                                                                    <ListItemText className={`menu-name ${isDrawerOpen ? `show-menu-name` : `hide-menu-name`}`} >{routeChild.name}</ListItemText>
                                                                </ListItemButton>
                                                            )
                                                        })}
                                                    </List>
                                                </Collapse>
                                            </AbilityCan>
                                        </Fragment>
                                    )
                                } else {
                                    return (
                                        <Fragment key={index}>
                                            <AbilityCan key={index} I={route.menuPermission} passThrough={((!route.menuPermission) || (user.role_slug === 'developer')) ? true : false}>
                                                {route.hasSeparation && <Divider sx={{ margin: `5px 0` }} />}
                                                <ListItemButton component={Link} to={route.path} className={(route.path === location.pathname) ? `active` : ((parentPathName === route.path) ? 'active' : '')}>
                                                    <ListItemIcon>
                                                        <route.menuIcon />
                                                    </ListItemIcon>
                                                    <ListItemText className={`menu-name ${isDrawerOpen ? `show-menu-name` : `hide-menu-name`}`} >{route.name}</ListItemText>
                                                </ListItemButton>
                                            </AbilityCan>
                                        </Fragment>
                                    )
                                }
                            })}

                        </List>
                    </Box>
                </Box>
            </Drawer>
        </SideBarStyled>
    )
}

export { SideBar };
