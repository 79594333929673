import React, { useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid } from '@mui/material';
import { Calendar as FullCalendar } from '../../../_components';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { Fragment } from 'react';
import { postAuthRoutes } from '../../../routes/postAuthRoutes';
import { Card, CardContent, CardHeader } from '../../../_components';
import { CalendarAction } from '../../../redux/actions';

function Calendar() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const myRef = useRef();

    const { brand_calendar } = useSelector((state) => state.CalendarReducer);

    const getCalendarData = (params) => dispatch(CalendarAction.getCalendar(params));

    const handleViewRender = async ({ start, end }) => {
        await getCalendarData({
            start: new Date(start).toISOString(),
            end: new Date(end).toISOString()
        });
    };

    function handleClick(e) {

        let type = e.event.extendedProps.type;
        let id = e.event.extendedProps._id;

        if (type === 'promo') {
            const pathname = postAuthRoutes('promo_details').path;
            navigate({ pathname, search: createSearchParams(`?pId=${id}`).toString() });
        } else if (type === 'giveaway') {
            const pathname = postAuthRoutes('giveaway_details').path;
            navigate({ pathname, search: createSearchParams(`?gId=${id}`).toString() });
        }
    }

    return (
        <Fragment>
            <Box>
                <CardHeader title={postAuthRoutes('calendar').name} />
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card>
                        <CardContent>
                            <FullCalendar ref={myRef} events={brand_calendar} handleViewRender={handleViewRender} handleClick={handleClick} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export { Calendar };