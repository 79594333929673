import { postAuthRoutes, preAuthRoutes } from ".";

const preRegisterRoutes = (brand) => {
    if (brand.status === 'insta_register_completed') {
        return ({ pathname: preAuthRoutes('create_account').path, search: `?aKey=${brand.approval_key}` })
    } else if (brand.status === 'account_created') {
        return ({ pathname: preAuthRoutes('billing_address').path, search: `?aKey=${brand.approval_key}&bId=${brand._id}` })
    } else if (brand.status === 'active') {
        return ({ pathname: postAuthRoutes('welcome').path, search: `` })
    } else {
        return ({ pathname: preAuthRoutes('login').path, search: `` })
    }
}

export { preRegisterRoutes }