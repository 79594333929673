import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';

import { postAuthRoutes, preAuthRoutes } from '../../../routes';

import { PreSignIn, Header } from '../../../_layouts/pre-signin';
import { Box, Grid, Typography } from '@mui/material';
import { FormInput, FormPassword } from '../../../_components/form';
import { SubmitButton, RouteLink } from '../../../_components/controls';
import { LineTypography } from '../../../_components/typography';

import { InstagramSignIn } from '../instagram-sign-in';

import { APP_NAME, handleInputChange, validateForm } from '../../../_helpers';
import { SignInAction } from '../../../redux/actions';
import { validate } from '.';

// Initialize form input values to null
const initialPayload = { email: '', password: '' };

function SignIn() {

    /** Initialize plugins and variables */
    const navigate = useNavigate();
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { sign_in_loading, sign_in_errors, instagram_sign_in_loading } = useSelector((state) => state.SignInReducer);
    const signIn = (params) => dispatch(SignInAction.signIn(params));

    /** Initialize and declare state */
    const [payload, setPayload] = useState({ ...initialPayload });
    const [action, setAction] = useState({ isSubmitted: false });
    const [response, setResponse] = useState({});
    const [errors, setErrors] = useState({ ...initialPayload, remember_me: '' });

    useEffect(() => {
        setErrors({ ...sign_in_errors });
    }, [sign_in_errors]);

    useEffect(() => {
        if (response.status === 1)
            navigate(postAuthRoutes('home').path);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    /**
     * function to handle input changes and alter the value
     * @param object e input object with name and value
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, payload)) });
        payload[name] = value;
        setPayload({ ...payload });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(payload)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchSignInAction();
    }

    async function dispatchSignInAction() {
        let response = await signIn(payload);
        setResponse(response);
    }

    return (
        <Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${preAuthRoutes('login').name}`}</title>
            </Helmet>
            <PreSignIn >
                <Grid container spacing={3} >
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5} m={0} p={0}>
                        <Header title={`${preAuthRoutes('login').name}`} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7} >
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container spacing={2} >
                                <Grid item xs={12} sm={12} p="0">
                                    <Typography variant="h4" fontWeight="medium" color="white" align="center" mt={2} >{`Login`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} p="0">
                                    <FormInput
                                        tabIndex={1}
                                        label='Email/Mobile number'
                                        name='email'
                                        value={payload.email}
                                        error={action.isSubmitted && errors.email ? errors.email : ''}
                                        onChange={handleChange}
                                        autoFocus={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} p="0">
                                    <FormPassword tabIndex={1} label='Password' name='password' value={payload.password} error={action.isSubmitted && errors.password ? errors.password : ''} onChange={handleChange} />
                                </Grid>
                            </Grid>
                            <Box display="flex" alignItems="center" justifyContent="left" mt={1}>
                                <RouteLink to={preAuthRoutes('forgot_password').path} label={preAuthRoutes('forgot_password').name} />
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} mb={2}>
                                <SubmitButton label={'Log In'} loading={sign_in_loading || instagram_sign_in_loading} fullWidth />
                            </Box>
                        </form>
                        <LineTypography> OR </LineTypography>
                        <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
                            <InstagramSignIn />
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="left" mt={1}>
                            <RouteLink to={preAuthRoutes('non_facebook_user').path} label={preAuthRoutes('non_facebook_user').name} />
                        </Box>
                    </Grid>
                </Grid>
            </PreSignIn>
        </Fragment>
    );
}

export { SignIn };
