import borders from "../../base/borders";
import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      borderRadius: borders.borderRadius.lg,
      margin: `${pxToRem(16)} ${pxToRem(16)} 0`,
    },

    media: {
      width: "auto",
    },
  },
  variants: []
};
