import { Autocomplete, FormHelperText, TextField, createFilterOptions } from '@mui/material';
import React, { useState } from 'react';
import { FormAutocompleteStyled } from '.';

const filter = createFilterOptions();

const FormFreeSoloAutocomplete = ({ name, label, placeholder, className, id, value, onChange, isReadOnly = false, maxLength = 255, tabIndex, error, info, inputOptions, ...props }) => {

    const [options, setOptions] = useState(inputOptions && inputOptions.length > 0 ? inputOptions : []);
    const [inputValue, setInputValue] = useState(value);

    const handleChange = (event, newValue) => {
        event.preventDefault();
        let inputValue = typeof newValue === 'string' ? newValue : (newValue && newValue.inputValue);

        if (inputValue) {

            const faqIndex = options.findIndex(option => option.name.toLowerCase() === inputValue.toLowerCase());
            if (faqIndex === -1) {
                const newOption = { name: inputValue, id: (newValue && newValue.id) || '' };
                setOptions([...options, newOption]);
            } else {
                inputValue = options[faqIndex];
            }
            // const isInputValueInOptions = options.some(option => option.name.toLowerCase() === inputValue.toLowerCase());
            // if (!isInputValueInOptions) {
            //     const newOption = { name: inputValue, id: (newValue && newValue.id) || '' };
            //     setOptions([...options, newOption]);
            // }
            // const target = {
            //     name,
            //     value: inputValue,
            //     type: 'autocomplete'
            // };
            const target = {
                name,
                value: inputValue,
                type: 'autocomplete'
            };
            onChange({ target });
        }

    };

    const handleOnInputChange = (e, s) => {
        setInputValue(s);
    };

    const handleOnBlur = (e) => {
        e.preventDefault();
        handleChange(e, inputValue);

    };

    return (
        <FormAutocompleteStyled fullWidth error >
            <Autocomplete
                value={value}
                onChange={handleChange}
                onInputChange={handleOnInputChange}
                onBlur={handleOnBlur}
                tabIndex={tabIndex}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some((option) => inputValue === option.name);
                    if (inputValue !== '' && !isExisting) {
                        filtered.push({ id: '', inputValue, name: `Add "${inputValue}"` });
                    }
                    return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={
                    options.map((option, index) => {
                        return { id: option.id, inputValue: option.name, name: option.name };
                    })
                }
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.name;
                }}
                renderOption={(props, option) => <li {...props} >{option.name}</li>}
                freeSolo
                renderInput={(params) => (<TextField {...params} fullWidth label={label} />)}

            />
            {(info) && <FormHelperText id={`helper_${name}`}>{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error >{error}</FormHelperText>}

        </FormAutocompleteStyled>

    );
};

export { FormFreeSoloAutocomplete };