import { apiServices, cookieServices } from '../../_helpers';


export const AppUserServices = {
    /**
     * Service to get user list
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 June 2022
     */
    getUsersList: (params) => {
        return apiServices.post('/brand/users/list', params)
            .then((response) => { return response; })
    },

    /**
     * Service to add user
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 June 2022
     */
    addUser: (params) => {
        return apiServices.post('/brand/users/add', params)
            .then((response) => { return response; })
    },

    /**
     * Service to update user
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 June 2022
     */
    updateUser: (params) => {
        return apiServices.put('/brand/users/update', params)
            .then((response) => { return response; })
    },
    
    /**
     * Service to change user status
     * @param {Object} params - The params which are used for the api
     * @author Jasin 
     * @created_at 15 June 2022
     */
    changeUserStatus: (params) => {
        return apiServices.put('/brand/users/change-status', params)
            .then((response) => { return response; })
    },

    	/**
	 * Service for account type select list
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 11 Oct 2023
	 */
    accountTypeSelectList: (params) => {
		return apiServices.post('/brand/users/account-type-select-list', params).then((response) => {
			return response;
		});
	},
};
