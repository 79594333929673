import React, { useRef } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { Box, Typography } from '@mui/material';
import { Facebook, Instagram } from '@mui/icons-material';

const Calendar = ({ events, handleViewRender, handleClick, ...props }) => {

	const myRef = useRef();
	const getIcon = (icon) => {
		switch (icon) {
			case "facebook":
				return <Facebook />;
			case "instagram":
				return <Instagram />;
			default:
				return '';
		}
	};

	return (
		<Box ref={myRef}>
			<FullCalendar
				plugins={[dayGridPlugin]}
				initialView="dayGridMonth"
				displayEventTime={false}
				events={events}
				eventDisplay="block"
				datesSet={handleViewRender}
				eventContent={function (info) {
					let event = info.event;
					let desc = event.extendedProps.description ? event.extendedProps.description : '';
					let icon = event.extendedProps.icon ? event.extendedProps.icon : '';
					return (<Typography align="left" variant="subtitle2" paragraph={false}>{icon ? getIcon(icon) : ''}{desc}<b>{event.title}</b></Typography>)
				}}
				eventClick={handleClick}
			/>
		</Box>
	);
};

export { Calendar };
