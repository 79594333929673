import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GeneralButton } from "../../../../_components";
import { ConfigureGstModal } from "./ConfigureGstModal";
import { RestaurantMenuAction } from "../../../../redux/actions";
import { useEffect } from "react";
import { Add } from "@mui/icons-material";

function ConfigureGst({ ...props }) {
    
    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);


    /** Redux actions and state */
    const { configure_gst_loading: isLoading, gst_details, gst_details_loading: isGstLoading } = useSelector((state) => state.RestaurantMenuReducer);
    const getGstDetails = (params) => dispatch(RestaurantMenuAction.getGstDetails(params));


        
    useEffect(() => {
        getGstDetails();
    }, [])

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        configureGst();
    }

    /**
     * function to close plans update modal
     * @return view
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const closeModal = () => {
        setShowModal(!showModal);
    }

    /**
     * function to create wallet
     * @return view
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const configureGst = () => {
        setShowModal(!showModal);
    }

    return (
        <React.Fragment>
            <ConfigureGstModal show={showModal} closeModal={closeModal} data={gst_details} />

            <GeneralButton startIcon={<Add />} label='Configure GST' onClick={() => handleChange()} disabled={isLoading || isGstLoading}/>
        </React.Fragment>
    )
}

export { ConfigureGst };
