import React, { Fragment, useEffect, useState } from 'react';
import { postAuthRoutes } from '../../../../../routes';
import { TodaysItemQuantity, TodaysRestaurantOrder } from '.';

import { PageHeader, Tabs } from '../../../../../_components';



function TodaysRestaurantOrdersTab() {

    return (
		<Fragment>
            <Fragment>
                <PageHeader 
					title={postAuthRoutes('todays_restaurant_pre_order').name}
				/>
                <Tabs
                    groupName={'roTab'}
                    data={[
                        { slug: 'pre_orders', label: "Todays Restaurant Pre Orders", component: <TodaysRestaurantOrder /> },
                        { slug: 'item_quantity', label: "Item Quantity", component: <TodaysItemQuantity /> },
                    ]}
                />
            </Fragment>
        </Fragment>
	)
}

export { TodaysRestaurantOrdersTab };