module.exports = {
    /**
     * function to validate the registration form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author 
     * @created_at 30 May 2022
     */
    validate: (name, value, data = {}) => {
        const errors = {};
        switch (name) {
            case 'new_status': {
                errors.new_status = '';
                if (value === '')
                    errors.new_status = 'Select Status';
                break;
            } case 'remark': {
                errors.remark = '';
                if (value === '')
                    errors.remark = 'Enter Remark';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}