import {
    PLANS_REQUEST, PLANS_SUCCESS, PLANS_FAILURE, UPDATE_BRAND_PLAN_REQUEST, UPDATE_BRAND_PLAN_SUCCESS, UPDATE_BRAND_PLAN_FAILURE,
    CREATE_CHECKOUT_SESSION_REQUEST, CREATE_CHECKOUT_SESSION_SUCCESS, CREATE_CHECKOUT_SESSION_FAILURE,
    GIVEAWAY_PLANS_REQUEST, GIVEAWAY_PLANS_SUCCESS, GIVEAWAY_PLANS_FAILURE,
    CREATE_GIVEAWAY_CHECKOUT_SESSION_REQUEST, CREATE_GIVEAWAY_CHECKOUT_SESSION_SUCCESS, CREATE_GIVEAWAY_CHECKOUT_SESSION_FAILURE,
} from "../actions";

const initialState = {
    plans_loading: false, plans: [], plans_errors: {},
    update_brand_plan_loading: false, update_brand_plan_errors: {},
    create_checkout_session_loading: false, create_checkout_session_error: {},
    giveaway_plans_loading: false, giveaway_plans: [], giveaway_plans_errors: {},
    create_giveaway_checkout_session_loading: false, create_giveaway_checkout_session_error: {},
};

export function PlanReducer(state = initialState, action) {
    switch (action.type) {
        /** begin::Plans list redux */
        case PLANS_REQUEST: {
            return {
                ...state,
                plans_loading: true,
                plans_errors: {}
            };
        }
        case PLANS_SUCCESS: {

            let newArray = [...state.plans]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.plans.forEach((payload) => {
                const planIndex = newArray.findIndex(plans => plans._id === payload._id);
                if (planIndex !== -1) {
                    newArray[planIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                plans_loading: false,
                plans: newArray,
                plans_errors: {}
            };

        }
        case PLANS_FAILURE: {
            return {
                ...state,
                plans_loading: false,
                plans_errors: action.errors,
                plans: [],
            };
        }
        /** end::Plans list redux */

        /** begin::Plans list redux */
        case UPDATE_BRAND_PLAN_REQUEST: {
            return {
                ...state,
                update_brand_plan_loading: true,
                update_brand_plan_errors: {}
            };
        }
        case UPDATE_BRAND_PLAN_SUCCESS: {

            let newArray = [...state.plans]; //making a new array
            let newGiveawayPlanArray = [...state.giveaway_plans]; //making a new array

            if (action.data.category === 'giveaway') {
                if (action.data.plan) {
                    newGiveawayPlanArray.forEach((plan) => {
                        plan.current_giveaway_plan = action.data.plan
                    });   
                }
            } else {
                if (action.data.plan) {
                    newArray.forEach((plan) => {
                        plan.current_plan = action.data.plan
                    });   
                }
            }


            return {
                ...state,
                update_brand_plan_loading: false,
                plans: newArray,
                giveaway_plans: newGiveawayPlanArray,
                update_brand_plan_errors: {}
            };

        }
        case UPDATE_BRAND_PLAN_FAILURE: {
            return {
                ...state,
                update_brand_plan_loading: false,
                update_brand_plan_errors: action.errors,
                plans: [],
            };
        }
        /** end::Plans list redux */

        /** begin::Designation redux */
        case CREATE_CHECKOUT_SESSION_REQUEST: {
            return {
                ...state,
                create_checkout_session_loading: true,
            };
        } case CREATE_CHECKOUT_SESSION_SUCCESS: {

            return {
                ...state,
                create_checkout_session_loading: false,
            };
        } case CREATE_CHECKOUT_SESSION_FAILURE: {
            return {
                ...state,
                create_checkout_session_loading: false,
                payment_details_error: action.error
            };
        }
        /** end::Designation redux */

        /** begin::Giveaway Plans list redux */
        case GIVEAWAY_PLANS_REQUEST: {
            return {
                ...state,
                giveaway_plans_loading: true,
                giveaway_plans_errors: {}
            };
        }
        case GIVEAWAY_PLANS_SUCCESS: {

            let newArray = [...state.giveaway_plans]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.plans.forEach((payload) => {
                const planIndex = newArray.findIndex(giveaway_plans => giveaway_plans._id === payload._id);
                if (planIndex !== -1) {
                    newArray[planIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                giveaway_plans_loading: false,
                giveaway_plans: newArray,
                giveaway_plans_errors: {}
            };

        }
        case GIVEAWAY_PLANS_FAILURE: {
            return {
                ...state,
                giveaway_plans_loading: false,
                giveaway_plans_errors: action.errors,
                giveaway_plans: [],
            };
        }
        /** end::Giveaway Plans list redux */

        /** begin::Create giveaway checkout session redux */
        case CREATE_GIVEAWAY_CHECKOUT_SESSION_REQUEST: {
            return {
                ...state,
                create_giveaway_checkout_session_loading: true,
            };
        } case CREATE_GIVEAWAY_CHECKOUT_SESSION_SUCCESS: {

            return {
                ...state,
                create_giveaway_checkout_session_loading: false,
            };
        } case CREATE_GIVEAWAY_CHECKOUT_SESSION_FAILURE: {
            return {
                ...state,
                create_giveaway_checkout_session_loading: false,
                create_giveaway_checkout_session_error: action.error
            };
        }
        /** end::Create giveaway checkout session redux */

        default:
            return state;
    }
}
