
module.exports = {
    /**
     * function to validate the promo create or update form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author Akshay N
     * @created_at 28 May 2022
     */
    validate: (name, value, data = {}) => {
        //eslint-disable-next-line
        const errors = {};
        switch (name) {
            case 'prefix': {
                errors.prefix = '';
                if (value === '')
                    errors.prefix = 'Enter prefix.';
                break;
            } case 'count': {
                errors.count = '';
                if (value === '') {
                    errors.count = 'Enter coupon codes count.';
                } else if (value < 1) {
                    errors.count = 'Coupon codes count must be greater than zero.';
                }
                break;
            } case 'description': {
                errors.description = '';
                if (value === '')
                    errors.description = 'Enter description.';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}